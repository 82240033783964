import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as CopyIcon } from "../../assests/svg/solar_copy-outline.svg";
import "react-toastify/dist/ReactToastify.css";
import "../dialog/NavigationInstruction.scss";
import { Link } from "react-router-dom";

const CongratsMessageTemplate = ({
  openCongratsDialog,
  setOpenCongratsDialog,
}) => {
  return (
    <>
      {/* <div className="navigation-instruction"> */}
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openCongratsDialog}
        className="navigation-instruction-dialog"
      >
        <div className="close">
          <div
            className="close-icon"
            onClick={() => setOpenCongratsDialog(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="want-to-pay-game-container">
          <h3>CONGRATULATIONS, YOU HAVE WON!</h3>
          <div className="navigation-instruction-wrapper">
            <p className="discount-code">
              Copy the discount code and use it at checkout
            </p>
          </div>
          <div className="code-container">
            <p>ONEWAYXWINNER25</p>
            <div>
              <CopyIcon />
            </div>
          </div>
        </div>
      </Dialog>
      {/* </div> */}
    </>
  );
};

export default CongratsMessageTemplate;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content:has(.custEnv) {
  margin: 0 !important;
}

.home-section:has(.custEnv) {
  padding-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/style/components/dashboard/CustomizeStore.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;;AACA;EACI,4BAAA;AAEJ","sourcesContent":[".main-content:has(.custEnv){\n    margin: 0!important;\n}\n.home-section:has(.custEnv){\n    padding-bottom: 0!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/bitcoin-icons_cross-filled.svg";
import "react-toastify/dist/ReactToastify.css";
import "../dialog/NavigationInstruction.scss";
import { Link } from "react-router-dom";
import GameRules from "./GameRules";

const WantToPlayScreen = ({
  openWantToPlayDialog,setOpenWantToPlayDialog
}) => {
  const [openGameRulesDialog, setOpenGameRulesDialog] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenGameRulesDialog(true);
  };
  return (
    <>
     {!openGameRulesDialog &&
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openWantToPlayDialog}
        className="navigation-instruction-dialog"
      >
      <div className="close">
      <div className="close-icon" onClick={()=>setOpenWantToPlayDialog(false)}>
          <CloseIcon/>
        </div>
      </div>
       
        <form className="want-to-pay-game-container"onSubmit={handleSubmit}>
       
          <h3>WANT TO PLAY A GAME?</h3>
          <div className="navigation-instruction-wrapper">
          <input type="text" placeholder="Enter your email*"/>
         
          </div>
         
          
          <div className="enter-btn">
            <button type="submit">Submit & Play</button>
          </div>
        </form>
      </Dialog>
}
      {/* </div> */}
      {openGameRulesDialog && (
        <GameRules
          openGameRulesDialog={openGameRulesDialog}
          setOpenGameRulesDialog={setOpenGameRulesDialog}
        />
      )}
    </>
  );
};

export default WantToPlayScreen;

import React, { useState } from "react";
import { ReactComponent as ToggleBar } from "../assets/svg/mingcute_settings-6-line.svg";
import "../style/components/three/ProductCustomizationBar.scss";
import { ArrowForward, Close } from "@mui/icons-material";
import dummyText from "../assets/images/dummyText.png";
import useModelStore from "./gabar/util/modelStore";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../fetcher/fetchData";
import ApiConfig from "../helper/ApiConfig";
import { v4 as uuidv4 } from 'uuid';
import useAuthStore from "../store/authStore";

const ProductCustomizationBar = ({ productData }) => {
  const [open, setOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState([]);
  const {token, setLoading} = useAuthStore();
  const setModelPosition = useModelStore((state) => state.setModelPosition);
  const navigate = useNavigate();

  const handleLocationChange = (product, location) => {    
    setModelPosition(product.product_uid, getPositionFromLocation(location));    
    setFormValues((prevFormValues) => {
      const updatedValues = [...prevFormValues];
      const existingIndex = updatedValues.findIndex(
        (item) => item.product_uid === product.product_uid
      );

      const newEntry = {
        product_uid: product.product_uid,
        productPosition: location,
        product_id: product.product_id,
      };

      if (existingIndex >= 0) {
        updatedValues[existingIndex] = newEntry; // Update existing entry
      } else {
        updatedValues.push(newEntry); // Add new entry
      }
      return updatedValues;
    });
  };

  const getPositionFromLocation = (location) => {
    switch (location) {
      case "location0":
        return [1000, 1000, 1000];
      case "location1":
        return [-5.01337813128718, 0.75, -6.020227980977997];
      case "location2":
        return [1.9916866740908432, 1.55, 13.53984951053118];
      case "location3":
        return [-5.953889676150517, 2.3, 21.4005676390457];
      case "location4":
        return [-30.49039677915537, 2.3, 13.560054539298182];
      case "location5":
        return [-30.321714223481858, 1.55, 0.935591221166235];
      case "location6":
        return [-26.521714223481858, 0.75, -7.335591221166235];
      default:
        return [0, 0, 0];
    }
  };


  async function handleProductMapping(product_id, product_uid, x, y, z) {
    let val = {
      product_id: product_id,
      product_uid: product_uid,
      x_coordinate: x,
      y_coordinate: y,
      z_coordinate: z,
    };
    setLoading(true);
    const response = await fetchData(
      ApiConfig.productMapping,
      setLoading,
      "post",
      val,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleUUID() {
    const uuid = uuidv4();
    setLoading(true);
    const response = await fetchData(
      ApiConfig.brandTokenUpdate,
      setLoading,
      "post",
      {
        brand_param: uuid,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
  }

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 4 },
      { authorization: `Bearer ${token}` }
    );
    if (response.data.statusCode === 200) {
      navigate("/dashboard/add-game");
    }
  }

  const handleData = () => {    
    formValues.map((product) => {      
      handleProductMapping(
        product.product_id,
        product.product_uid,
        getPositionFromLocation(product.productPosition)[0],
        getPositionFromLocation(product.productPosition)[1],
        getPositionFromLocation(product.productPosition)[2]
      );
    });
    handleUUID();
    updateStep();
    navigate('/dashboard/add-game');
  };


  return (
    <>
      <div
        className="productCustomizationBar-button"
        onClick={() => setOpen((prev) => !prev)}
      >
        <ToggleBar />
      </div>
      <div className={`productCustomizationBar ${open ? "active" : ""}`}>
        <Close className="close" onClick={() => setOpen((prev) => !prev)} />
        <ul className="menu-header">
          <li className={step === 1 ? "active" : ""} onClick={() => setStep(1)}>
            Merchandise
          </li>
          <li className={step === 2 ? "active" : ""} onClick={() => setStep(2)}>
            Environment
          </li>
        </ul>
        <div style={{ marginLeft: "2vw" }}>
          {step === 1 ? (
            <>
              <div className="productContainer">
                {productData !== null &&
                  productData.map((product, index) => (
                    <div key={index} className="product-box">
                      <div className="product-detail">
                        <img src={product.product_image} alt="product-image" />
                        <p>{`Product ${index + 1}`}</p>
                        <p>{product.name}</p>
                      </div>
                      <div className="product-location">
                        <p>Select Location</p>
                        <select
                          defaultValue="location0"
                          onChange={(e) =>
                            handleLocationChange(product, e.target.value)
                          }
                        >
                          <option value="location0">--Select Position--</option>
                          <option value="location1">Location 1</option>
                          <option value="location2">Location 2</option>
                          <option value="location3">Location 3</option>
                          <option value="location4">Location 4</option>
                          <option value="location5">Location 5</option>
                          <option value="location6">Location 6</option>
                        </select>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="buttons">
                <button type="button" onClick={() => handleData()}>Save & Next</button>
                <button type="button" onClick={() => navigate('/dashboard/add-game')}>
                  Skip <ArrowForward />{" "}
                </button>
              </div>
            </>
          ) : (
            <>
              <p
                className="sub-header"
                style={{ color: "rgba(238, 58, 36, 1)", margin: "2vw 0" }}
              >
                COMING SOON
              </p>
              <img src={dummyText} alt="comming-soon" sty />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductCustomizationBar;

import "./ui.css";
import "./font/Univers-light-normal.ttf";
import "./font/UniversBlack.ttf";
import "./font/UniversLTStd.otf";
import "./font/UniversLTStd-Bold.otf";
import axios from "axios";
import useUIStore from "../../utils2/uistore";
import Product from "./Product/Product";
import Header from "./Header/Header";
import ProductList from "./ProductList/ProductList";
import ThankYou from "./ThankYou/ThankYou";
import Instruction from "./Instruction/Instruction";
import Cart from "./ShoppingCart/Cart";
import TradeMark from "./TradeMark/TradeMark";
import { useEffect, useState } from "react";
import useShopifyStore from "../../utils2/shopifyStore";
import { Player, SoundOnOff } from "./Audio/AudioFinal";
import Game_Popups from "../Game/Game";
import KeyboardIcon from "./KeyboardIcon/KeyboardIcon";
import ViewportHeightSetter from "../../utils2/useViewportHeightSetter";
import Header2 from '../../../components/Header';


export default function UIfinal({musicUrl, finalScreen = false}) {
  return (
    <>
      <div className="no-text-select">
        <ViewportHeightSetter />
        {/* <Header2 variation={2}/> */}
        <Instruction />
        { musicUrl && 
          <>
            <Player musicUrl={musicUrl} finalScreen = {finalScreen}/>
            <SoundOnOff />
          </>          
        }
        <TradeMark />
        <KeyboardIcon />
        
      </div>
    </>
  );
}


import React, { useState } from "react";
import "./FailedToRetrieve.scss";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "../assests/svg/info-icon.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import { ReactComponent as CloseIcon } from "../assests/svg/close.svg";
import ContactInformation from "./ContactInformation";
export default function FailedToRetrieve({ failedToRetrieve, setFailedToRetrieve, close }) {
  const [contactUsPageOpen, setContactUsPageOpen]=useState(false);
  const handleCloseDialog=()=>{
    setFailedToRetrieve(false);
    setContactUsPageOpen(false)
  }
  return (
    <>
      <Dialog open={failedToRetrieve} className="failed-container" style={{display:contactUsPageOpen ? 'none' : 'block'}} maxWidth='xl'>
      <Box component="div" className="failed-inner-container">
        <Box component="div" className="close-dialog">
            <CloseIcon  style={{cursor:'pointer'}} onClick={close}/>
          </Box>
   
        {/* </Box> */}
      <Box component='div' className="failed-wrapper">
        <Box component="div" className="failed-content">
        <Box component="div">
            <InfoIcon />
          </Box>
          <Box component="div" className="failed-text">
            <h2>Failed to Retrieve</h2>
            <p>Your eCommerce URL is not valid or supported by ONEWAYX.</p>
          </Box>

          <Box component="div">
            <button className="contact-us" onClick={()=>setContactUsPageOpen(true)}>
              Contact Us{" "}
              <span>
                <ArrowIcon />
              </span>
            </button>
          </Box>
        </Box>
        </Box>
        </Box>
      </Dialog>
      {/* <div className="failed-container">
        <div className="failed-wrapper">
          <div className="close-dialog">
            <CloseIcon />
          </div>
         
        </div>
      </div> */}
      {contactUsPageOpen && 
       <ContactInformation contactUsPageOpen={contactUsPageOpen} setContactUsPageOpen={setContactUsPageOpen}
       setFailedToRetrieve={setFailedToRetrieve}
        close={handleCloseDialog}/>
       }
    </>
  );
}

import React, { useEffect, useState } from "react";
import Campaign from "./experience/Campaign";
import UI from "./ui/UI";
import Loading from "./ui/Loading/Loader";
import useAudioContainer from "./util/useAudioContainer";
// import AddtoCart from "../AddtoCart";
import Cart from "../Cart";
import PopupGame from "../game/PopupGame";
import AddtoCart2 from "../AddtoCart2";
import useStore from "./util/3dstore";

const Gabar = ({
  variation = 0,
  productData,
  audioFile,
  cartProductDetails,
  gameData,
}) => {
  const { isCartOpen, isProductCartOpen, productDatas, closeProductCart } =
    useAudioContainer();
  const { isEntryAnimationDone } = useStore();
  // const gameData = [
  //   {
  //       "created_at": "2024-11-29 10:13:07",
  //       "updated_at": "2024-11-29 10:13:07",
  //       "brand_game_id": 146,
  //       "brand_id": 187,
  //       "game_id": 2,
  //       "player_time_limit": 1,
  //       "trigger_limit": 10,
  //       "coupon_code": "abc",
  //       "status": 1,
  //       "is_active": 0,
  //       "game_quiz_masters": [
  //           {
  //               "created_at": "2024-11-29 10:13:07",
  //               "updated_at": "2024-11-29 10:13:07",
  //               "quiz_master_id": 61,
  //               "game_id": 1,
  //               "brand_id": 187,
  //               "question": "[{\"question\":\"Question Test 1\",\"options\":[\"1\",\"2\",\"3\"],\"correct_answer\":\"1\"},{\"question\":\"Question Test 2\",\"options\":[\"1\",\"2\",\"3\"],\"correct_answer\":\"1\"},{\"question\":\"Question Test 3\",\"options\":[\"1\",\"2\",\"3\"],\"correct_answer\":\"1\"}]",
  //               "quiz_status": 1
  //           }
  //       ]
  //     }
  // ];

  const handlePopup = () => {
    closeProductCart();
  };
  return (
    <div className="GabarApp" style={{ height: "100%", position: "relative" }}>
      <Campaign
        variation={variation}
        productData={productData}
        cartProductDetails={cartProductDetails}
      />
      <UI musicUrl={audioFile} variation={variation} />
      <Loading />
      {variation === 3 && (
        <>
          {isProductCartOpen && (
            <>
              {/* <AddtoCart data={productDatas} closePopup={handlePopup} /> */}
              <AddtoCart2 data={productDatas} closePopup={handlePopup} />
            </>
          )}
          {isCartOpen && <Cart />}
          {gameData !== null && isEntryAnimationDone && gameData.length > 0 && (
            <PopupGame gameData={gameData} />
          )}
        </>
      )}
    </div>
  );
};

export default Gabar;

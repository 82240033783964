import {
  Box,
  Dialog,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from "../../../assets/svg/info-red.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close-red.svg";
import "../../../style/components/store/FailedToRetrieve.scss";

export default function ProductLimitExceed({productLimitExceed, setProductLimitExceed}) {
  const navigate = useNavigate();
  return (
    <>
    <Dialog  className="product-limit-container" open={productLimitExceed}>   
    <Box component="div" className="product-limit-wrapper">
    <Box component="div" className="close-dialog" onClick={()=>setProductLimitExceed(false)}>
            <CloseIcon style={{cursor:'pointer'}} />
          </Box>
          <Box component="div" className="product-limit-content">
          <Box component="div">
              <InfoIcon />
            </Box>
            <Box component="div" className="product-limit-text">
              <h2>Product Limit Exceeded!</h2>
              <p>The environment you have selected supports only 10 products.</p>
            </Box>
            <Box component="div">
              <button className="contact-us" onClick={()=>navigate('/dashboard/subscription')}>
              Upgrade{" "}
                <span>
                  <ArrowIcon />
                </span>
              </button>
            </Box>
          </Box>
        </Box>    
    </Dialog>     
    </>
  )
}
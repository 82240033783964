import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assests/images/white-logo.svg';
import { ReactComponent as SuccessIcon } from "../../assests/svg/password-reset-success.svg";
import { Tooltip } from '@mui/material';
export default function PasswordResetSuccess() {
    const navigate = useNavigate();
    return <div className="main-container clearfix" style={{background: "linear-gradient(74.69deg, #9747FF 0.33%, #EE3A24 97.52%)"}}>
    <header className='header-absolute2 sigupHeader'>
            <nav>
                <ul>
                    <li>
                        <Tooltip title="Back to Website">
                            <Link to="https://www.onewayx.com/" target="_blank">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </Tooltip>
                    </li>
                </ul>
            </nav>
        </header>
        <div className='email-verification-section'>
        <div className="email-verification-container">
            <div className="header-container">
                <SuccessIcon></SuccessIcon>
                <div className='email-verification-heading'>
                <h2>Password Reset Successful</h2>
                <p>Login using new password</p>
                </div>
                
            </div>
            <div className="footer-container">
                <button className="button2" onClick={()=>navigate('/login')}>Login</button>
            </div>
        </div>
        </div>
       
    </div>
}
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";

import { ReactComponent as Controller } from '../assests/images/controller.svg';
import { ReactComponent as SignupIcon } from '../assests/svg/signup-icon.svg';
import './Home.scss';
import { ReactComponent as PauseIcon } from "../assests/svg/pause-icon.svg";
import Header from "../components/Header";
import tabletFrame from "../assests/images/tablet.png"  
import placeholderSrc from "../assests/images/homepagePlaceholderImage.png"

function Home() {
  const navigate=useNavigate();
  const [playState, setPlayState] = useState(true);
  const [videoLoaded,setVideoLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef();

  useEffect(()=>{
    const tokenData = localStorage.getItem('sessionState');
    let userData = JSON.parse(tokenData);
      
      if(tokenData!== null && userData.isAuthenticated === true){
        let token = userData.token;
        const getEnvDetails = async () => {
          setLoading(false);
          const response = await fetchData(
            `${ApiConfig.env}`,
            setLoading,
            "post",
            {},
            { authorization: `Bearer ${token}` }
          );
          if (response.statusCode === 401 || response.statusCode ===500) {
            navigate('/login');
          }
          else if (response.status === 200) {
            navigate('/dashboard');
          }
          
          setLoading(true);
        };
        getEnvDetails();
      }
    
    else{
      setLoading(true)
    }
  },[])

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  function pauseAndPlayVideo() {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPlayState(true);
    } else {
      videoRef.current.pause();
      setPlayState(false);
    }
  }
  return (
    <>
    {
      loading &&<>
      <Header variation={1}></Header>
      <section className="hero-section">
        <div className="row row-1">
          <div className="video-container" onClick={pauseAndPlayVideo}>
            <img src={tabletFrame} className="tablet-frame" alt="Tablet Frame" />
            <video ref={videoRef}
                className="video-file"
                width="100%"
                autoPlay
                muted
                loop
                onLoadedData={handleVideoLoaded}
                style={{ display: videoLoaded ? 'block' : 'none', width: '100%' }}
              >
                <source src="https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/landing-page/Platform-Guide.mp4" type="video/mp4" />
              </video>
              {!videoLoaded && (
                <img
                  src={placeholderSrc}
                  alt="Video placeholder"
                  style={{ width: '100%', height: 'auto' }}
                />
              )}
              {!playState ? (
                <PauseIcon
                  className="pause-icon"
                  onClick={() => {
                    videoRef.current.play();
                    setPlayState(true);
                  }}
                ></PauseIcon>
              ) : null}
          </div>
          <div className="col col-5 col-b" style={{minWidth:'34%'}}>
            <h2 className="heading">BRING YOUR <br />BRAND EXPERIENCE<br /> TO LIFE</h2>
            <p className="text">Transform your 2D websites into 3D immersive virtual stores</p>
            <div className="cta-container">
              <div>
              {/* <Link to="/play-around" className="button">
                <Controller />
                <span>Play Around</span>
              </Link>
              <p className="subtext">No obligations, let’s have some fun.</p> */}
              </div>
              <div>
              <Link to="/signup" className="button">
                <SignupIcon />
                <span>Sign Up</span>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
}
    </>
  );
}

export default Home;

import React from "react";
import "./ContactInfo.scss";
import { ReactComponent as InfoIcon } from "../assests/svg/info-icon.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import { ReactComponent as CloseIcon } from "../assests/svg/close-red.svg";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ThankyouPage from "./ThankyouPage";
import ProductLimitExceed from "./ProductLimitExceed";
export default function ContactInformation({

  contactUsPageOpen,
  setContactUsPageOpen,
  setFailedToRetrieve,
  close
}) {
  
const [openThankyouPage, setOpenThankyouPage]=useState(false)
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      websiteurl: "",
      message: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please Enter valid Email")
        .matches(/@[^.]*\./, "Please Enter valid Email")
        .required("Email is required"),
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      websiteurl: Yup.string().required("Website Url is required"),
      message:Yup.string().required("Message is required"),
    }),
    onSubmit: handleSubmit,
  });
  function handleSubmit() {
    // alert("submitted");
   setOpenThankyouPage(true)
  }
  const handleCloseDialog=()=>{

    setContactUsPageOpen(false)
    setOpenThankyouPage(false)
    setFailedToRetrieve(false)
  }

  return (
    <>
      <Dialog
        open={contactUsPageOpen}
        className="contact-form-container"
        maxWidth="xl"
        style={{display: openThankyouPage ? 'none' : 'block'}}
      >
        <Box component="div" className="contact-form-wrapper">
          <Box component="div" className="close-dialog">
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={close}
            />
          </Box>

          {/* </Box> */}
          <Box component="div" className="contact-form-inner-wrapper" >
            <form onSubmit={formik.handleSubmit}>
              <Box component="div" className="contact-info-container">
                <Box component="div" className="firstname">
                  <label>
                    First Name <span className="required-field">*</span>
                  </label>

                  <input
                    type="text"
                    placeholder="Your First Name*"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                    name="first_name"
                    id="first_name"
                  />
                  {formik.touched.first_name && formik.errors.first_name ? (
                  
                  <small className="error-bg-white" style={{fontSize:'0.8325vw',display:'flex',alignItems:'center',position:'relative', left:'0.35vw'}}>{formik.errors.first_name}</small>
                   
                  ) : null}
                </Box>
                <Box component="div" className="lastname">
                  <label>
                    Last Name <span className="required-field">*</span>
                  </label>

                  <input
                    type="text"
                    placeholder="Your Last Name*"
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                    name="last_name"
                    id="last_name"
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                  
                  <small className="error-bg-white" style={{fontSize:'0.8325vw',display:'flex',alignItems:'center',position:'relative', left:'0.35vw'}}>{formik.errors.last_name}</small>
                   
                  ) : null}
                </Box>
              </Box>
              <Box component="div" className="email">
                <label >
                  Email <span className="required-field">*</span>
                </label>

                <input
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  id="email"
                  name="email"
                />
                {formik.touched.email && formik.errors.email ? (
                  
                  <small className="error-bg-white" style={{fontSize:'0.8325vw',display:'flex',alignItems:'center',position:'relative', left:'0.35vw'}}>{formik.errors.email}</small>
                   
                  ) : null}
              </Box>
              <Box component="div" className="website-url">
                <label>
                  Website URL <span className="required-field">*</span>
                </label>

                <input
                  placeholder="Website URL*"
                  type="text"
                  name="websiteurl"
                  id="websiteurl"
                  value={formik.values.websiteurl}
                  onChange={formik.handleChange}
                />
                {formik.touched.websiteurl && formik.errors.websiteurl ? (
                  
                  <small className="error-bg-white" style={{fontSize:'0.8325vw',display:'flex',alignItems:'center',position:'relative', left:'0.35vw'}}>{formik.errors.websiteurl}</small>
                   
                  ) : null}
              </Box>
              <Box component="div" className="Message">
                <label htmlFor="message">
                  Message <span className="required-field">*</span>
                </label>

                <textarea
                  placeholder="Type here..."
                  rows="5"
                  cols="50"
                  name="message"
                  id="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                 {formik.touched.message && formik.errors.message ? (
                  
                  <small className="error-bg-white" style={{fontSize:'0.8325vw',display:'flex',alignItems:'center',position:'relative', left:'0.35vw'}}>{formik.errors.message}</small>
                   
                  ) : null}
              </Box>
              <Box component="div" className="submit">
                <button type="submit" >
                  Submit{" "}
                  <span>
                    <ArrowIcon />
                  </span>
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Dialog>
      {openThankyouPage && <ThankyouPage openThankyouPage={openThankyouPage} setOpenThankyouPage={setOpenThankyouPage} close={handleCloseDialog}/>}
     
    </>
  );
}

import React, { useState } from "react";
import "./Profile.scss";
import "./CompanyProfile.scss";
import Profile from "./Profile";
import Subscription from "./Subscription";
export default function CompanyProfile() {
  const [tab, setTab] = useState("profile");
  return (
    <>
      <div className="profile">
        <div>
          <h2>Company Profile</h2>
        </div>
        <div className="profile-tab">
          <button
            onClick={() => setTab("profile")}
            className={"profile-btn" + (tab === "profile" ? " active" : "")}
          >
            Profile
          </button>
          <button
            onClick={() => setTab("subscription")}
            className={
              "subscription-btn" + (tab === "subscription" ? " active" : "")
            }
          >
            Subscription
          </button>
        </div>
        <div>{tab === "profile" ? <Profile /> : <Subscription />}</div>
      </div>
    </>
  );
}

import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function Refresh() {
    const navigate = useNavigate();

    useEffect(() => {
        const hasReloaded = localStorage.getItem('hasReloaded');
        if (!hasReloaded) {
            localStorage.setItem('hasReloaded', true);
            window.location.reload(false);
        } else {
            navigate(`/dashboard/select-store`);
        }
    }, [navigate]);

    return null; // No need for JSX since this component doesn't render anything
}

export default Refresh;

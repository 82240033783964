import { create } from "zustand";

const useGameStore = create((set) => ({
  //GET UI VISIBLE
  showGameOnEnterPopup: false,
  showGameNextQuestionPopup: false,
  showGame25WinPopup: false,
  showGame40WinPopup: false,
  showGameDiscoveryWinPopup: false,
  showGameInstructionsPopup: false,
  showGameLostPopup: false,
  showGamePlayDemo: false,
  showGameWaitPopup: false,
  showGameBoardAttemptedPopup: false,
  showGameThankYouPopup: false,
  showGameUIPopup: false,
  isUserGameRegistered: false,
  timer: null,
  startTimer: false,
  fadeIn: false,
  fadeDelay: 0,
  rowIndex: null,
  flashIndex: 0,
  isGamePlaying: false,
  gameStartTimestamp: null,
  selectedRandomQuestions: [],

  //SET UI VISIBLE

  handleShowGameOnEnterPopup: (val) =>
    set((state) => ({ showGameOnEnterPopup: val })),
  handleShowGameNextQuestionPopup: (val) =>
    set((state) => ({ showGameNextQuestionPopup: val })),
  handleShowGameOnEnterPopup: (val) =>
    set((state) => ({ showGameOnEnterPopup: val })),
  handleShowGame25WinPopup: (val) =>
    set((state) => ({ showGame25WinPopup: val })),
  handleShowGame40WinPopup: (val) =>
    set((state) => ({ showGame40WinPopup: val })),
  handleShowGameDiscoveryWinPopup: (val) =>
    set((state) => ({ showGameDiscoveryWinPopup: val })),
  handleShowGameInstructionsPopup: (val) =>
    set((state) => ({ showGameInstructionsPopup: val })),
  handleShowGameLostPopup: (val) =>
    set((state) => ({ showGameLostPopup: val })),
  handleShowGamePlayDemo: (val) => set((state) => ({ showGamePlayDemo: val })),
  handleShowGameWaitPopup: (val) =>
    set((state) => ({ showGameWaitPopup: val })),
  handleShowGameBoardAttemptedPopup: (val) =>
    set((state) => ({ showGameBoardAttemptedPopup: val })),
  handleShowGameThankYouPopup: (val) =>
    set((state) => ({ showGameThankYouPopup: val })),
  handleShowGameUIPopup: (val) => set((state) => ({ showGameUIPopup: val })),
  setUserGameRegistered: (val) =>
    set((state) => ({ isUserGameRegistered: val })),
  handleStartTimer: (val) => set((state) => ({ startTimer: val })),
  handleFadeInAnimation: (val) => set((state) => ({ fadeIn: val })),
  setFadeDelay: (val) => set((state) => ({ fadeDelay: val })),
  setRowIndex: (val) => set((state) => ({ rowIndex: val })),
  setFlashIndex: (val) => set((state) => ({ flashIndex: val })),
  setGamePlaying: (val) => set((state) => ({ isGamePlaying: val })),
  setGameStartTimestamp: (val) => set((state) => ({ gameStartTimestamp: val })),
  setSelectedRandomQuestions: (val) =>
    set((state) => ({ selectedRandomQuestions: val })),

  //GET GAMEPLAY
  correctAnswers: 0,
  wrongAnswers: 0,
  registeredEmail: null,
  gamesPlayed: JSON.parse(localStorage.getItem("gamesPlayed")) || 0,
  lastGameTime:
    new Date(JSON.parse(localStorage.getItem("lastGameTime"))) || null,
  nextPlayableTime:
    new Date(JSON.parse(localStorage.getItem("nextPlayableTime"))) || null,
  activeBoardIndex: null,
  isGameBoardClickMove: false,
  isGameClick: false,
  isGameLocked:
    new Date() >= new Date(JSON.parse(localStorage.getItem("nextPlayableTime")))
      ? false
      : true,

  //SET  GAMEPLAY
  handleWrongAnswers: (val) => set((state) => ({ wrongAnswers: val })),
  handleCorrectAnswers: (val) => set((state) => ({ correctAnswers: val })),
  handleGamesPlayed: (val) => set((state) => ({ gamesPlayed: val })),
  handleLastGameTime: (val) => set((state) => ({ lastGameTime: val })),
  handleNextPlayableTime: (val) => set((state) => ({ nextPlayableTime: val })),
  handleNextGameTimeAllowed: (val) =>
    set((state) => ({ nextGameTimeAllowed: val })),
  handleGameLock: (val) => set((state) => ({ isGameLocked: val })),
  handleGameReset: (nextPlayableTime) =>
    set((state) => ({
      correctAnswers: 0,
      gamesPlayed: 0,
      registeredEmail: null,
      lastGameTime: new Date(),
    })),

  handleRegisteredEmail: (val) => set((state) => ({ registeredEmail: val })),
  handleActiveBoardIndex: (val) => set((state) => ({ activeBoardIndex: val })),
  setGameClick: (val) => set((state) => ({ isGameClick: val })),

  //GET GAME TIMER
  timePassed_1: 0,
  activeQuestion: 0,
  quizFinished: false,

  //SET GAME TIMER
  setTimePassed_1: (val) => set((state) => ({ timePassed_1: val })),
  setActiveQuestion: (val) => set((state) => ({ activeQuestion: val })),
  setQuizFinished: (val) => set((state) => ({ quizFinished: val })),
}));

export default useGameStore;

import { Dialog, IconButton, Tooltip } from "@mui/material";
import { ReactComponent as EmailNotFoundIcon } from "../../assests/svg/email-not-found.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assests/svg/close-dialog-icon.svg";
export default function EmailNotFound({errorMessage,openEmailNotFoundPopup, setOpenEmailNotFoundPopup, close }) {
  const navigate = useNavigate();
 
   const onSignupBtnClick= ()=> {
      navigate('/signup');
    }
 

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
     open={openEmailNotFoundPopup}
     
      className="common-dialog-container"
    >
      <div className="email-not-found-container">
        <IconButton className="close-dialog-icon-button" onClick={close}>
          <Tooltip title="Close Dialog">
            <CloseIcon className="close-dialog-icon" />
          </Tooltip>
        </IconButton>
        <div className="header-container">
          <div className="email-not-found-content" >
            <EmailNotFoundIcon/>
            <div className="email-not-found-heading">
            <h2>{errorMessage}</h2>
            <p>Create Your ONEWAYX account</p>
            </div>
           
           
            <div className="email-not-found footer-container">
              <button className="button2" type="submit" onClick={onSignupBtnClick}>
              Sign Up Here
              </button>
            </div>
            <div className="return-login">
              <Link onClick={close}>&larr;Return to Login</Link>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from '../components/AuthGuard';
import Home from '../pages/Home';
import Signup from './login-signup/Signup';
import EmailVerification from './login-signup/EmailVerification';
import ResetPassword from './login-signup/ResetPassword';
import PasswordResetSuccess from './login-signup/PasswordResetSuccess';
import Login from './login-signup/Login';
import Dashboard from './dashboard/Dashboard';
import Homepage from '../pages/dashboard/Homepage';
import PasswordVerification from './login-signup/PasswordVerification';
import Subscription from './dashboard/Subscription';
import CompanyProfile from './dashboard/CompanyProfile';
import Spinner from '../helper/Spinner';
import ComingSoon from './dashboard/ComingSoon';
import EnvStore from './dashboard/store/EnvStore';
import SelectEnv from './dashboard/store/SelectEnv';
import AddProducts from './dashboard/store/AddProducts';
import CouponCodeQuiz from './dashboard/game/CouponCodeQuiz';
import QuizGame from './dashboard/game/QuizGame';
import Game from './dashboard/game/Game';
import Enviroment from './dashboard/store/Enviroment';
import ProductAnalytic from './dashboard/store/ProductAnalytic';
import ActiveGame from './dashboard/game/ActiveGame';
import Analytics from '../pages/dashboard/Analytics';
import SpinTheWheel from './dashboard/game/SpinTheWheel';
import CustomizeEnv from './dashboard/store/CustomizeEnv';
import CustomizeStore from './dashboard/store/CustomizeStore';
import Env from '../enviroments/gabar/Env';
import AccountCreated from './login-signup/AccountCreated';

const SystemRoutes = () => {
  return (
    <>
        <Suspense fallback={<Spinner/>}>
        
             <Routes>
           
                <Route path='/'>
               
                   <Route index element={<Home/>}/>

                    {/* // SignUp & login  */}
                    <Route path='signup' element={<Signup/>}/>
                    <Route path='Email-verification' element={<EmailVerification/>}/>
                    <Route path='set-password/*' element={<ResetPassword/>} />
                    <Route path='verify-email/*' element={<ResetPassword/>} />
                    <Route path='account-created' element={<AccountCreated/>} />
                    <Route path="resetsuccess" element={<PasswordResetSuccess/>} />
                    <Route path="login/*" element={<Login />} />
                    <Route path="password-verification" element={<PasswordVerification/>} />

                    {/* //  Dashboard  */}
                    <Route path="dashboard" element={<AuthGuard><Dashboard/></AuthGuard>}>
                        <Route path="" element={<AuthGuard><Homepage/></AuthGuard>}/>
                        <Route path="subscription" element={<AuthGuard><Subscription/></AuthGuard>} />
                        <Route path="company-profile" element={<CompanyProfile/>}></Route> 
                        <Route path="coming-soon" element={<AuthGuard><ComingSoon/></AuthGuard>} />                      

                        <Route path="select-enviroment" element={<AuthGuard><SelectEnv/></AuthGuard>}></Route>
                        <Route path="customize-enviroment" element={<AuthGuard><CustomizeEnv/></AuthGuard>}></Route>
                        <Route path="select-store" element={<AuthGuard><EnvStore/></AuthGuard>}></Route>
                        <Route path="add-products" element={<AuthGuard><AddProducts/></AuthGuard>}></Route> 
                        <Route path="customize-store" element={<AuthGuard><CustomizeStore/></AuthGuard>}></Route> 
                        <Route path="add-game" element={<AuthGuard><Game/></AuthGuard>}></Route>
                        <Route path="add-coupon-code" element={<AuthGuard><CouponCodeQuiz/></AuthGuard>}/> 
                        <Route path="quiz" element={<AuthGuard><QuizGame/></AuthGuard>} />
                        <Route path="spin" element={<AuthGuard><SpinTheWheel/></AuthGuard>} /> 

                        <Route path="enviroment" element={<AuthGuard><Enviroment/></AuthGuard>}></Route>
                        <Route path="product-analytics" element={<AuthGuard><ProductAnalytic/></AuthGuard>}></Route>
                        <Route path="game" element={<AuthGuard><ActiveGame/></AuthGuard>}></Route> 
                        <Route path="analytics" element={<AuthGuard><Analytics/></AuthGuard>}></Route>
                    </Route>

                    <Route path="/:companyName/playAround/*" element={<Env/>}/>
                   
                </Route>
            </Routes> 
        </Suspense>
    </>
  )
}

export default SystemRoutes
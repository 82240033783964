import "./Home2.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Loading from "./components2/UI/Loading/Loader";
import Landscape from "./components2/UI/Landscape/Landscape";
import {
  EnterFullScreenButton,
  ExitFullScreenButton,
} from "./components2/Controls/FullScreen";
import UI from "./components2/UI/UI";
import { useEffect, useState } from "react";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CampaignProduct from "./components2/Experience/CampaignProduct";

export default function GabarProduct() {
    const handle = useFullScreenHandle();
    const [audioFile, setAudioFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();

    useEffect(() => {
      if (!sessionStorage.getItem('hasReloadedCustomPro')) {
        sessionStorage.setItem('hasReloadedCustomPro', 'true');
        window.location.reload();
      }
    }, []);

    useEffect(() => {
      const getEnvDetails = async () => {
        setLoading(false);
        const file = await fetchData(
          ApiConfig.fileMapping,
                setLoading,
                "GET",
                {},
                { authorization: `Bearer ${token}` }
        );
        if (file?.status === 401 || file?.status === 500) {  
          navigate("/login");      
        }        
        else if(file?.status === 200 ){          
          setAudioFile(file?.data?.data?.audio_result[file?.data.data.audio_result.length - 1]?.audio_url || null);
          setVideoFile(file?.data?.data?.video_result[0]?.video_url || null)
        }
        setLoading(true);
      };
      getEnvDetails();
    }, []);



  return (
    <>
    <div className="App">
      <div className="ReactFullScreen">
        <EnterFullScreenButton handle={handle} />
        <FullScreen handle={handle}>
          <div>
            <ExitFullScreenButton handle={handle} />
            <UI musicUrl={null}/>
            <CampaignProduct videoUrl={videoFile}/>
          </div>
        </FullScreen>
      </div>
    </div>
    <Loading />
    <Landscape />
  </>
  );
}
import React from "react";
import "./PaymentSubmission.scss";
import { ReactComponent as AngleIcon } from "../assests/svg/angle-icon.svg";
import { ReactComponent as CalenderIcon } from "../assests/svg/calender-icon.svg";
import { ReactComponent as TimerIcon } from "../assests/svg/timer-icon.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import Header from "./Header";
export default function PaymentSubmission() {
  return (
    <>
      <div className="dashboard-header">
        <Header variation={3}></Header>
      </div>
      <div className="payment-submission-container">
        <div className="payment-submission-wrapper">
          <h2>Payment Form for Subscription</h2>
          <div className="subscription-details-subheading">
            <span>Subscription Details</span>
            <span>
              <AngleIcon />
            </span>
            <span>Payment</span>
          </div>
          <div className="payment-form">
            <div className="payment-form-content">
              <div className="payment-form-content-header">
                <p>
                  <span>
                    <CalenderIcon />
                  </span>
                  Starter Plan
                </p>
                <p>
                  <span>
                    <TimerIcon />
                  </span>
                  Annually
                </p>
                <p>
                  <span>
                    <TimerIcon />
                  </span>
                  Subscription Validity : 20-02-2024
                </p>
              </div>

              <div className="payment-form-content-subheader">
                <div className="subscription-plan-container">
                  <p>Subscription Plan Description</p>
                  <div className="subscription-plan">
                    <ul className="subscription-plan-list">
                      <li>
                        Customisable Environment <span></span>
                      </li>
                      <li>
                        ECommerce Integeration <span></span>
                      </li>
                      <li>
                        10 Products <span></span>
                      </li>
                      <li>
                        1 Gamification <span></span>
                      </li>
                      <li>
                        Visitor Analytics <span></span>
                      </li>
                      <li>
                        Heatmap <span></span>
                      </li>
                      <li>
                        Sales Conversion <span></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="starter-plan">
                <p>Starter Plan</p>
                  <div className="starter-plan-desc">
                    <div className="subscription-plan-charges">
                   
                    <p>Subscription</p>
                    <div className="plans">
                      <p>Anually</p>
                      <p className="charges">£899/month</p>
                    </div>
                     <hr/>
                     <p className="subtotal">Subtotal <span>£799/month</span></p>
                     <p className="tax">Tax 18% <span>£100/month</span></p>
                     <p className="total">TOTAL<span>£899/month <br/><small style={{textAlign:'right'}}>incl. tax</small></span></p>
                    <button className="proceed">Proceed to Pay <span><ArrowIcon/></span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// import React, { useEffect, useRef } from "react";
// import { useGLTF, useAnimations, useCursor } from "@react-three/drei";
// import useStore from "../../utils2/3dstore";
// import useUIStore from "../../utils2/uistore";
// import useAnalytics from "../../utils2/google-analytics/useGTAGTracker";

// function Info_Icon({ name, position }) {
//   const group = useRef();
//   const { nodes, materials, animations } = useGLTF(
//     "/assets/models/info-icon.glb"
//   );
//   return (
//     <group ref={group} dispose={null} position={position}>
//       <group name="Scene">
//         <mesh
//           name="I_Icon"
//           geometry={nodes.I_Icon.geometry}
//           material={materials.i_Icon_Material}
//           visible={true}
//           material-opacity={0.5}
//           scale={0.5}
//         />
//       </group>
//     </group>
//   );
// }
// export function Info_Click({ position, name, currentItem, clickPosition }) {
//   const { nodes, materials } = useGLTF("/assets/models/info-click.glb");
//   const {
//     handleShowProductList,
//     handleItems,
//     getData,
//     handleCurrentItem,
//     handleActiveImage,
//     setShowActiveImage,
//   } = useUIStore();
//   const { isMovingToTarget } = useStore();
//   const { trackProductInfoView } = useAnalytics();
//   return (
//     <group dispose={null}>
//       <Info_Icon name={name} position={position} />
//       <mesh
//         name={name}
//         position={clickPosition}
//         geometry={nodes.Cylinder.geometry}
//         material={nodes.Cylinder.material}
//         visible={true}
//         // material-opacity={1}
//         onClick={(e) => {
//           if (!isMovingToTarget) {
//             e.stopPropagation();
//             getData("helo");
//             trackProductInfoView(currentItem.title);
//             handleCurrentItem(currentItem);
//             handleActiveImage(
//               currentItem?.images?.map((el, index) => (
//                 <div className="zoomed-image" key={index}>
//                   <img
//                     src={el}
//                     className="main-image"
//                     alt={`Info_Click_Zoomed_Image ${index}`}
//                   />
//                   {/* <div className="image-vertical-divider"/> */}
//                 </div>
//               ))
//             );
//             handleShowProductList(false);
//             handleItems(true);
//             setTimeout(() => setShowActiveImage(true), 1000);
//           }
//         }}
//       />
//     </group>
//   );
// }

// useGLTF.preload("/assets/models/info-click.glb");
// useGLTF.preload("/assets/models/info-icon.glb");


import React from 'react'

const Info_Click = () => {
  return (
    <div></div>
  )
}

export default Info_Click
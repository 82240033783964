import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { ReactComponent as CloseIcon } from "../assests/svg/close-dialog-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SubtractIcon } from "../assests/svg/minus.svg";
import "./ProductDetails.scss";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { logEvent } from '../components/analytic/Analytic';
import { useNavigate } from "react-router-dom";

function Card(props) {
  const [counterData, changeCounterData] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isCartLoaded, setCartLoaded] = useState(false);
  const [cartData, setCartData] = useState({ checkoutId: "", quantity: counterData, variantId: "" });
  const navigate = useNavigate();

  useEffect(() => {
    if (props.data && props.data.title && props.data.product_uid) {
      logEvent(`products_clicked`, {
        // event_category: 'Ecommerce',
        // event_label: JSON.stringify({ 
          product_name: props.data.title,
          product_id: props.data.product_uid,
          product_image: props.data.productDetail.images[0].src,
        // })
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (props.varID.length !== 0) {
      setCartData({
        variantId: '',
        quantity: counterData,
        brandId: props.brandID,
        checkoutId: props.varID,
      });
    } else {
      setCartData({
        variantId: '',
        quantity: counterData,
        brandId: props.brandID,
      });
    }
  }, [counterData, props.varID, props.brandID]);

  useEffect(() => {
    const initialOptions = props.data.productDetail.options.reduce((acc, option) => {
      acc[option.name.toLowerCase()] = option.values[0];
      return acc;
    }, {});
    setSelectedOptions(initialOptions);
  }, [props.data.productDetail.options]);

  useEffect(() => {
    updateSelectedVariant();
  }, [selectedOptions]);

  const updateOption = (name, value) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [name.toLowerCase()]: value
    }));
  };

  const updateSelectedVariant = () => {
    changeCounterData(1);
    const variant = props.data.productDetail.variants.find(variant => {
      return Object.keys(selectedOptions).every(key => {
        return variant[`option${Object.keys(selectedOptions).indexOf(key) + 1}`] === selectedOptions[key];
      });
    });
    setSelectedVariant(variant);
  };

  const incrementCounter = () => {
    if (counterData < (selectedVariant ? selectedVariant.inventory_quantity : 0)) {
      changeCounterData(counterData + 1);
    }
  };

  const decrementCounter = () => {
    if (counterData > 1) {
      changeCounterData(counterData - 1);
    }
  };

  const handleAddToBag = async () => {
    props.closePopup(false);
    const temp = { ...cartData, variantId: `gid://shopify/ProductVariant/${selectedVariant.id}` };
    if (selectedVariant && counterData > 0) {
      setCartLoaded(true);
      const response = await fetchData(
        ApiConfig.addToCart,
        setCartLoaded,
        "post",
        temp,
        {}
      );
      setCartLoaded(false);

      if (response.status === 401 ||response.status === 500) {
        navigate("/login");
      }

      if (response.status === 200) {
        props.getId(response.data.checkout.id);
        props.productCount(counterData);
        props.checkout(response.data.checkout.webUrl);
        logEvent(`products_add_to_cart`, {
            product_uid_cart: selectedVariant.product_id,
            product_value: counterData,
        });
      }
    }
  };

  const close = () => {
    props.closePopup(false);
  };

  return (
    <div className="product-detail-container">
      <div className="product-detail-wrapper">
        <div className="product-detail-title">
          <h3>{props.data.title}</h3>
          <div className="close-icon">
            <Tooltip title="Close Dialog">
              <CloseIcon
                className="close-dialog-icon"
                onClick={close}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        </div>
        <div className="product-image">
          <div className="product-big-img">
            <img src={props.data.productDetail.images[selectedImage].src} alt="Product" />
          </div>
          <hr />
          <div className="product-small-img">
            {props.data.productDetail.images.map((data, index) => (
              <div key={index} className={`img-${index + 1}`}>
                <img
                  src={data.src}
                  alt="images"
                  onClick={() => setSelectedImage(index)}
                />
              </div>
            ))}
          </div>
        </div>
        {props.data.productDetail.options.map((data, index) => (
          <div key={index} className={`product-${data.name.toLowerCase()}`}>
            <h3 className="title">{data.name}</h3>
            <div className={`${data.name.toLowerCase()}-container`}>
              {data.name.toLowerCase() === "color" ? (
                <div className="product-colors" style={{ display: "flex" }}>{data.values.map((value, valueIndex) => (
                  <div
                    key={valueIndex}
                    className={`color ${selectedOptions[data.name.toLowerCase()] === value ? 'selected' : ''}`}
                    style={{ backgroundColor: `${value}` }}
                    onClick={() => updateOption(data.name, value)}
                  ></div>
                ))}</div>
              ) : (
                <div className="container-list">{data.values.map((value, valueIndex) => (
                  <button
                    key={valueIndex}
                    className={selectedOptions[data.name.toLowerCase()] === value ? 'selected' : ''}
                    onClick={() => updateOption(data.name, value)}
                  >
                    {value}
                  </button>
                ))}</div>
              )}
            </div>
          </div>
        ))}
        <div className="prod-description">
          <p dangerouslySetInnerHTML={{ __html: props.data.productDetail.body_html }} />
        </div>
        <div className="prod-price">
          <p>£{selectedVariant ? selectedVariant.price : '0.00'}</p>
        </div>
        <div className="prod-button-container">
          <button className="prod-count">
            <span><SubtractIcon style={{ cursor: 'pointer' }} onClick={decrementCounter} /></span>
            <span className="count">{counterData}</span>
            <span><AddIcon style={{ cursor: 'pointer' }} onClick={incrementCounter} /></span>
          </button>
          <button className="add-to-bag" onClick={handleAddToBag}>Add to Bag</button>
        </div>
      </div>
    </div>
  );
}

export default Card;

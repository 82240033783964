import React, { useEffect, useState } from "react";
import useStore from "../../../utils2/uistore";
import "./productlist.css";
import axios from "axios";
import useShopifyStore from "../../../utils2/shopifyStore";
export default function ProductList() {
  const [productLists, setProductLists] = useState([]);
  const {
    apiURL,
    handleShowProductList,
    showproducts,
    handleItems,
    getData,
    getProductData,
    productList,
    handleCurrentItem,
  } = useStore((state) => state);
  const { setProductList } = useShopifyStore();
  useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await axios.get(
          "https://gabar-prod.onewayx.world/products"
        );
        //added all products to useShopifyStore Object
        setProductList(response.data);
        getProductData(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }

    fetchProducts();
  }, []);

  // useEffect(() => {
  //   setProductLists([...productList]);
  // }, [productList]);
  return (
    <>
      {showproducts ? (
        <div
          className="main-container-parent"
          style={{
            position: "absolute",
            zIndex: "2",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="main-container-products">
            {productList.map((el, index) => {
              return (
                <div
                  key={index}
                  className="product-block"
                  onClick={() => {
                    handleShowProductList(false);
                    handleItems(true);
                    getData("helo");
                    handleCurrentItem(el);
                  }}
                >
                  <div className="listing-product">
                    {el.images && el.images[0] && (
                      <img src={el?.images[0]} className="product-Images" />
                    )}
                    <span className="product-title">{el?.title}</span>
                    <span className="product-descriptionss">
                      {el?.description}
                    </span>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <span className="product-price">
                        {el?.price < (el?.compare_at_price ?? Infinity)
                          ? el?.price
                          : el?.compare_at_price}
                      </span>
                      {/* <span className="product-price">Price</span> */}
                      {/* <span className="product-price">
                        {el?.price < el?.compare_at_price
                          ? el?.price
                          : el?.compare_at_price}
                      </span> */}
                      {/* <span className="product-price">{el?.price}</span> */}
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: 5,
                      }}
                    >
                      <span className="product-price">Available Stock</span>
                      <span className="product-price">
                        {el?.inventory_quantity}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

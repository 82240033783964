import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./thankyou.css";
import useUIStore from "../../../utils2/uistore";
import useStore from "../../../utils2/3dstore";
export default function ThankYou() {
  const { thankyouModal, handleThankyouModal } = useUIStore((state) => state);
  const { setFormOverlay } = useStore();

  const closeOverlay = (e) => {
    e.stopPropagation();
    setFormOverlay(true);
    handleThankyouModal(false);
  };
  return (
    <>
      {thankyouModal ? (
        <div className="parent-form-test" onClick={(e) => closeOverlay(e)}>
          <div className="thankyou-block" onClick={(e) => e.stopPropagation()}>
            <div
              className="form-close-icon"
              onClick={() => {
                setFormOverlay(true);
                handleThankyouModal(false);
              }}
            >
              <CloseIcon style={{ fontSize: 20, color: "#fff" }} />
            </div>
            <span className="thankyou-text">
              Thank you for providing your information!
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

import React, { useEffect } from "react";
import GameOnEnterPopup from "./GameOnEnterPopup/GameOnEnterPopup";
import Won25OffPopup from "./Game25WinPopup/Won25OffPopup";
import WonDiscoverySetPopup from "./GameDiscoveryWinPopup/WonDiscoverySetPopup";
import GameLostPopup from "./GameLostPopup/GameLostPopup";
import GameInstructionsPopup from "./GameInstructionsPopup/GameInstructionsPopup";
import GameWinningLogic from "./utils/useGamePlay";
import GameWaitPopup from "./GameWaitPopup/GameWaitPopup";
import GameBoardAttemptedPopup from "./GameBoardAttemptedPopup/GameBoardAttemptedPopup";
import useGameStore from "./utils/gameStore";
import GameThankYouPopup from "./GameThankYouPopup/GameThankYouPopup";
import useStore from "../../utils2/uistore";
import GameNextQuestionPopup from "./GameNextQuestionPopup/GameNextQuestionPopup";
import Won40OffPopup from "./Game40WinPopup/Won40OffPopup";

const Game_Popups = () => {
  const {
    showGameOnEnterPopup,
    showGame25WinPopup,
    showGame40WinPopup,
    showGameDiscoveryWinPopup,
    showGameInstructionsPopup,
    showGameLostPopup,
    showGameWaitPopup,
    showGameBoardAttemptedPopup,
    showGameThankYouPopup,
    showGameNextQuestionPopup,
    handleShowGameUIPopup,
    handleShowGame25WinPopup,
    handleShowGame40WinPopup,
    handleShowGameDiscoveryWinPopup,
    handleShowGameWaitPopup,
  } = useGameStore();

  const {
    handleShowCart,
    handleShowMenu,
    handleItems,
    // showCart,
    // showMenu,
    // showItems,
  } = useStore();

  useEffect(() => {
    if (
      showGameOnEnterPopup ||
      showGame25WinPopup ||
      showGame40WinPopup ||
      showGameDiscoveryWinPopup ||
      showGameInstructionsPopup ||
      showGameLostPopup ||
      showGameWaitPopup ||
      showGameBoardAttemptedPopup ||
      showGameThankYouPopup ||
      showGameNextQuestionPopup
    )
      handleShowGameUIPopup(true);
    else handleShowGameUIPopup(false);

    if (
      showGameLostPopup ||
      showGameDiscoveryWinPopup ||
      showGame25WinPopup ||
      showGame40WinPopup ||
      showGameNextQuestionPopup
    ) {
      handleShowCart(false);
      handleShowMenu(false);
      handleItems(false);
    }

    if (showGameWaitPopup) {
      handleShowGame25WinPopup(false);
      handleShowGame40WinPopup(false);
      handleShowGameDiscoveryWinPopup(false);
    }
    
    if (showGame25WinPopup || showGameDiscoveryWinPopup || showGame40WinPopup) {
      handleShowGameWaitPopup(false);
    }
    // else if (showCart || showMenu || showItems) { //thought should not possible
    //   handleShowGameLostPopup(false);
    //   handleShowGameDiscoveryWinPopup(false);
    //   handleShowGame25WinPopup(false);
    // }
  }, [
    showGameOnEnterPopup,
    showGame25WinPopup,
    showGame40WinPopup,
    showGameDiscoveryWinPopup,
    showGameInstructionsPopup,
    showGameLostPopup,
    showGameWaitPopup,
    showGameBoardAttemptedPopup,
    showGameThankYouPopup,
    // showCart,
    // showMenu,
    // showItems,
  ]);

  return (
    <>
      {/* <div className="no-text-select"> */}
      <GameWinningLogic />
      <GameOnEnterPopup />
      {/* <WonDiscoverySetPopup /> */}
      <GameLostPopup />
      <GameInstructionsPopup />
      <GameThankYouPopup />
      <GameBoardAttemptedPopup />
      <GameWaitPopup />
      {/* <GameNextQuestionPopup /> */}
      {/* </div> */}
      <Won25OffPopup />
      <Won40OffPopup />
    </>
  );
};

export default Game_Popups;

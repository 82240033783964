import React, { useState, useLayoutEffect } from "react";
import "./index.css";
import { useProgress } from "@react-three/drei";
import useStore from "../../util/3dstore";
import Spinner from "../../../../helper/Spinner";
export default function Loader() {
  const { setLoadingDone } = useStore();
  const [width, setWidth] = useState(0);
  const [finished, set] = useState(false);
  const [loadingClassName, setLoad] = useState("loading landing-contain");
  const { progress, loaded, total } = useProgress();

  useLayoutEffect(() => {
    setWidth((loaded / total) * 100);
    if (progress === 100 && loaded === total && !finished) {
      setTimeout(() => {
        set(true);
        setLoad("done-loading");
        setLoadingDone(true);
      }, 4000);
    }
  }, [width, progress, loaded, total, finished]);
  return (
    <div className={loadingClassName}>
      <div className="main-bar-container">
        <div style={{height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#fff"}}>
          <Spinner/>
          </div>
      </div>
    </div>
  );
}

import { useRef, useEffect, useState } from "react";
import "./sound.css";
import useSoundStore from "./soundStore";
import { ReactComponent as Pause } from "../../../../assets/svg/mute-svg.svg";
import { ReactComponent as Play } from "../../../../assets/svg/play-svg.svg";

const Player = ({ musicUrl, finalScreen }) => {
  const audioElement = useRef(null);
  const { isPlaying, isMuted } = useSoundStore();

  useEffect(() => {
    if (!finalScreen) {
      if (audioElement.current) {
        if (isPlaying) {
          audioElement.current.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
        } else {
          audioElement.current.pause();
        }
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (audioElement.current) {
      audioElement.current.muted = isMuted;
    }
  }, [isMuted]);

  useEffect(() => {
    if (audioElement.current) {
      audioElement.current.pause();
      audioElement.current.load();
      audioElement.current.play().catch((error) => {
        console.error("Error playing new audio:", error);
      });
    }
  }, [musicUrl]);

  return (
    <div className="sound-control">
      <audio loop ref={audioElement}>
        <source src={musicUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

const SoundOnOff = () => {
  const { isMuted, setMuted } = useSoundStore();
  const [isMuteVisible, setMuteVisibity] = useState(0);
  const [isUnmuteVisible, setUnMuteVisibility] = useState(1);

  useEffect(() => {
    if (isMuted) {
      setUnMuteVisibility(0);
      setMuteVisibity(1);
    } else {
      setUnMuteVisibility(1);
      setMuteVisibity(0);
    }
  }, [isMuted]);
  return (
    <div
      className="music_icon"
      onClick={() => {
        setMuted(!isMuted);
      }}
    >
      <Pause
        className="sound-icon-img hide-selection"
        style={{
          width: 20,
          opacity: isUnmuteVisible,
          position: "absolute",
          zIndex: 10,
        }}
      />
      <Play
        className="sound-icon-img hide-selection"
        style={{
          width: 20,
          opacity: isMuteVisible,
          position: "absolute",
          zIndex: 11,
        }}
      />
    </div>
  );
};

export { SoundOnOff, Player };

import { create } from "zustand";

const useAudioContainer = create((set) => ({
  isProductCartOpen: false,
  audioUrl: null,
  productDatas: {},
  playAudio: false, 
  brandId: null,
  varID: null,
  checkoutURL: null,
  isCartOpen: false,
  openProductCartWithAudio: (url, productVal) => set({ isProductCartOpen: true, audioUrl: url, productDatas: productVal }),
  closeProductCart: () => set({ isProductCartOpen: false, audioUrl: null, productDatas: {} }),
  triggerAudioPlayback: () => set({ playAudio: true }), 
  stopAudioPlayback: () => set({ playAudio: false }) ,
  setBrandId : (val) => set({brandId : val}),
  setVarID: (val) => set({varID : val}),
  setCheckoutURL: (val) => set({checkoutURL : val}),
  closeCart: () => set({ isCartOpen : false }),
  openCart: () => set({ isCartOpen : true }),
}));

export default useAudioContainer;
import { create } from "zustand";
const useStore = create((set) => ({
  cartItem: [],
  activeImageIndex:0,
  cartItemId:null,
  isNullCheckoutId:false,
  counter:1,
  currentProduct:{},
  activeImages:[],
  cartLoaded:false,
  cartChecked:false,
  cartOpened:false,
  showMenu: false,
  instructionModal: false,
  formModal: false,
  showCart: false,
  showActiveImage:true,
  count: 0,
  counter: 0,
  landing: true,
  showItems: false,
  showproducts: false,
  data: null,
  productList: [],
  currentItem: {},
  cartItems: [],
  thankyouModal: false,
  currentDEBUG: false,
  showSubParentContainer:true,
  activeImage:"",
  setActiveImageIndex: (val) => set((state) => ({ activeImageIndex: val })),
  handleActiveImage: (val) => set((state) => ({ activeImages: val })),
  setShowActiveImage: (val) => set((state) => ({ showActiveImage: val })),
  setNullCheckoutId: (val) => set((state) => ({ isNullCheckoutId: val })),
  setCartItemId: (val) => set((state) => ({ cartItemId: val })),
  setCounter: (val) => set((state) => ({ counter: val })),
  setActiveImage: (val) => set((state) => ({ activeImage: val })),
  setShowSubParentContainer: (val) => set((state) => ({ showSubParentContainer: val })),
  setCurrentProduct: (val) => set((state) => ({ currentProduct: val })),
  setCartOpened: (val) => set((state) => ({ cartOpened: val })),
  setCartChecked: (val) => set((state) => ({ cartChecked: val })),
  setCartLoaded: (val) => set((state) => ({ cartLoaded: val })),
  setCounter: (val) => set((state) => ({ counter: val })),
  setCartItems: (val) => set((state) => ({ cartItem: val })),
  increase: (val) => set((state) => ({ count: val })),
  handleShowMenu: (val) => set((state) => ({ showMenu: val })),
  handleInstructionModal: (val) => set((state) => ({ instructionModal: val })),
  handleFormModal: (val) => set((state) => ({ formModal: val })),
  handleShowCart: (val) => set((state) => ({ showCart: val })),
  handleLanding: (val) => set((state) => ({ landing: val })),
  handleItems: (val) => set((state) => ({ showItems: val })),
  handleShowProductList: (val) => set((state) => ({ showproducts: val })),
  getData: (val) => set((state) => ({ data: val })),
  getProductData: (val) => set((state) => ({ productList: [...val] })),
  handleCurrentItem: (val) => set((state) => ({ currentItem: { ...val } })),
  handleItemsInCart: (val) =>
    set((state) => ({ cartItems: [...state.cartItems, val] })),
  handleThankyouModal: (val) => set((state) => ({ thankyouModal: val })),
}));



export default useStore;

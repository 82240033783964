import React, { useState, useEffect } from "react";
import "./PlayAround2.scss";
import ThreeTemplates from "../components/ThreeTemplates";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { initGA } from "../components/analytic/Analytic";
import ComingSoon from "../components/ComingSoon";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";
function PlayAround2() {
  const [products, setProducts] = useState([]);
  const [gameData, setGameData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageActive, setPageActive] = useState(false);
  const [commingSoon,setCommingSoon] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    initGA();
    async function getProductList() {
      setLoading(true);
      const url = window.location.href;
      const parts = url.split("/");
      const data = parts[parts.length - 1];
      const response = await fetchData(
        `${ApiConfig.commonEnv}?brandParam=${data}`,
        setLoading,
        "get",
        {},
        {}
      );

     

      if (response?.status === 200) {
        setProducts(response.data.data);
        const targetDate = new Date(response.data.data.next_payment_date);
        const currentDate = new Date();
        const isDateGreater = targetDate >= currentDate;
        if (isDateGreater) {
          setPageActive(true);
        } else {
          setPageActive(false);
        }

        if (response.data.data.retain_trail) {
          setPageActive(true);
        }


        const response2 = await fetchData(
          `${ApiConfig.gameSelection}?brandParam=${data}`,
          setLoading,
          "get",
          {},
          {}
        );


        if (response2?.status === 200) {
          setGameData(response2.data.data);
        } else {
          console.log("Error");
        }


      } else {
        setCommingSoon(true);
        console.log("Error");
      }
      setDataFetched(true);

      setLoading(false);
    }
    getProductList();
  }, []);

  return (
    <>
      {(!loading && dataFetched ) ? (
        <>
          {pageActive? (
            <>
              {dataFetched && (
                <ThreeTemplates products={products} gameData={gameData} />
              )}
            </>
          ) : (
            <ComingSoon />
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
}

export default PlayAround2;

export const formatTime = (time, timeLimitPassed, noTimeRun) => {
  if (timeLimitPassed) return "00:00";
  // console.log(noTimeRun)
  // if (noTimeRun) return "01:00";
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const minutesString = minutes.toString().padStart(2, "0");
  const secondsString = seconds.toString().padStart(2, "0");

  return `${minutesString}:${secondsString}`;
};

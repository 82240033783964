import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import ReCAPTCHA from "react-google-recaptcha";
import { Tooltip } from "@mui/material";

import countryCodes from "../../helper/CountryCodeInput";
import Spinner from "../../helper/Spinner";

import Logo from "../../assets/images/white-logo.svg";
import "../../style/components/LoginSignup.scss";
import useAuthStore from "../../store/authStore";
import { fetchData } from "../../fetcher/fetchData";
import ApiConfig from "../../helper/ApiConfig";



const Signup = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading ] = useState(false);

  const formik = useFormik({
    initialValues: {
      brand_email: "",
      first_name: "",
      last_name: "",
      company: "",
      country_code: "+44",
      phone_number: "",
      platform: "0",
      websiteurl: "",
      type: 2,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      brand_email: Yup.string()
        .email("Please Enter valid Email")
        .matches(/@[^.]*\./, "Please Enter valid Email")
        .required("Email is required"),
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      company: Yup.string().required("Company Name is required"),
      country_code: Yup.string().required("Country Code is required"),
      phone_number: Yup.string()
        .required("Contact Number is required")
        .min(6, "Phone number must be at least 6 digits")
        .max(12, "Phone number must be at most 12 digits"),
      websiteurl: Yup.string().required("Website Url is required"),
    }),
    onSubmit: handleSignup,
  });

  async function handleSignup() {
    setLoading(true);
    const res = await fetchData(
      ApiConfig.signup,
      setLoading,
      "POST",
      formik.values
    );
    setLoading(false);
    if (res) {
      if (res.statusCode === 400) {
        setEmailAlreadyExist(true);
        setErrorMessage(res.message);
      } else {
        localStorage.setItem(
          "brand_email",
          JSON.stringify(formik.values.brand_email)
        );
        localStorage.setItem("registered", JSON.stringify(true));
        navigate("/Email-verification");
      }
    }
  }

  const handleRecaptchaChange = (value) => {
    setIsVerified(!!value);
  };

  return (
    <div
      className="main-container"
      style={{
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(74.69deg, #9747FF 0.33%, #EE3A24 97.52%)",
      }}
    >
      <header className="sigupHeader">
        <nav>
          <ul>
            <li>
              <Tooltip title="Back to Website">
                <Link to="https://www.onewayx.com/" target="_blank">
                  <img src={Logo} alt="logo" />
                </Link>
              </Tooltip>
            </li>
          </ul>
        </nav>
      </header>

      {emailAlreadyExist && (
        <div className="email-exists-section">
          <div className="email-exists-container ">
            <h1 className="heading">{errorMessage}</h1>
            <div className="dialog-submit-container">
              <button
                className="dialog-input-submit login-btn button2"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
      {!emailAlreadyExist && (
        <div className="signup-section">
          <div className="signup-container">
            <h1 className="heading">Sign Up for a ONEWAYX Account</h1>
            <p className="sub-heading">
              Already have an account?{" "}
              <span>
                <Link className="link" to="/login">
                  Login here
                </Link>
              </span>{" "}
            </p>
            {loading && (
              <div className="spinner-wrapper">
                <Spinner />{" "}
              </div>
            )}

            <form onSubmit={formik.handleSubmit}>
              <div className="dialog-input-container email-input-container">
                <div className="row inputDivContainer">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      sx={{ margin: "20px 0" }}
                      className="my-input"
                      placeholder="Your First Name*"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      name="first_name"
                      id="first_name"
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <small className="error">
                        {formik.errors.first_name}
                      </small>
                    ) : null}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      sx={{ margin: "20px 0" }}
                      className="my-input"
                      placeholder="Your Last Name*"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      name="last_name"
                      id="last_name"
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <small className="error">{formik.errors.last_name}</small>
                    ) : null}
                  </div>
                </div>
                <div
                  className="inputDivContainer"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    sx={{ margin: "20px 0" }}
                    className="my-input"
                    placeholder="Your Email*"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brand_email}
                    name="brand_email"
                    id="brand_email"
                  />
                  {formik.touched.brand_email && formik.errors.brand_email ? (
                    <small className="error">{formik.errors.brand_email}</small>
                  ) : null}
                </div>
                <div className="inputDivContainer contact_container ">
                  <div className="contact country-code">
                    <select
                      style={{ cursor: "pointer" }}
                      name="country_code"
                      id="country_code"
                      className="my-input"
                      value={formik.values.country_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {countryCodes.map((code, i) => {
                        return (
                          <option key={i} value={code.dial_code}>
                            {code.dial_code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contact phn-no">
                    <input
                      sx={{ margin: "20px 0" }}
                      className="my-input"
                      placeholder="Your Contact No.*"
                      type="number"
                      name="phone_number"
                      id="phone_number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_number}
                    />
                    {formik.touched.phone_number &&
                    formik.errors.phone_number ? (
                      <small className="error">
                        {formik.errors.phone_number}
                      </small>
                    ) : null}
                  </div>
                </div>
                <div
                  className="inputDivContainer"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    sx={{ margin: "20px 0" }}
                    className="my-input"
                    placeholder="Your Company Name*"
                    type="text"
                    name="company"
                    id="company"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company}
                  />
                  {formik.touched.company && formik.errors.company ? (
                    <small className="error">{formik.errors.company}</small>
                  ) : null}
                </div>
                <div
                  className="inputDivContainer"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    sx={{ margin: "20px 0" }}
                    className="my-input"
                    placeholder="Website URL*"
                    type="text"
                    name="websiteurl"
                    id="websiteurl"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.websiteurl}
                  />
                  {formik.touched.websiteurl && formik.errors.websiteurl ? (
                    <small className="error">{formik.errors.websiteurl}</small>
                  ) : null}
                </div>
                <div className="inputDivContainer platform">
                  <select
                    style={{ cursor: "pointer" }}
                    name="platform"
                    id="platform"
                    className="my-input"
                    value={formik.values.platform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{ margin: "20px 0" }}
                  >
                    <option value="0" disabled hidden>
                      eCommerce Platform
                    </option>
                    <option value="1">Shopify</option>
                    <option value="2">Other</option>
                  </select>
                </div>
              </div>
              <p className="privacy-policy">
                By proceeding, you agree to ONEWAYX{" "}
                <span>
                  {" "}
                  <a
                    href="https://www.onewayx.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              </p>
              {process.env.REACT_APP_RECAPTCHA_MODE === "true" && (
                <div className="dialog-recaptcha">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </div>
              )}
              <div className="dialog-submit-container">
                <button
                  type="submit"
                  disabled={
                    !isVerified &&
                    process.env.REACT_APP_RECAPTCHA_MODE === "true"
                  }
                  className="dialog-input-submit button2"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;

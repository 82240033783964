import React, { useCallback, useEffect, useState } from "react";
import "./won25-styles.css";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
// import FormOverlay from "./Backdrop";
import useGameStore from "../utils/gameStore";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Won25OffPopup() {
  const [copied, setCopied] = useState(false);

  const {
    showGame25WinPopup,
    handleShowGame25WinPopup,
    isGameLocked,
    nextPlayableTime,
  } = useGameStore();

  const onCopyClick = useCallback(() => {
    setCopied(true);
  }, []);
  const parentFormClassName = `parent-form-test ${
    showGame25WinPopup ? "show" : "noshow"
  }`;

  useEffect(() => {
    if (!isGameLocked && nextPlayableTime !== null) {
      setCopied(false);
    }
  }, [isGameLocked]);
  return (
    <>
      {/* {!isFormOverlay && <FormOverlay />} */}
      {showGame25WinPopup ? (
        <>
          {/* <FormOverlay /> */}
          {/* <div className="parent-form-test"> */}
          <div className={parentFormClassName}>
            <div className="sub-parent-form">
              <div
                className="form-close-icon"
                onClick={() => handleShowGame25WinPopup(false)}
              >
                <CloseIcon style={{ fontSize: 20, color: "#fff" }} />
              </div>
              <div className="form-item-container_32">
                <div className="heading-container no-text-select">
                  <span className="heading-test-form_30">CONGRATULATIONS</span>
                  <div className="sub-class-container_32">
                    <span className="heading-test-form_31">
                      You have won 25% off your next
                    </span>
                    <span className="heading-test-form_31">
                      Gabar purchase! It pays to be mindful.
                    </span>
                  </div>
                  <span className="form-input-container_31 no-text-select">
                    Use the discount code below at checkout.{" "}
                  </span>
                </div>
             
                <CopyToClipboard
                  onCopy={onCopyClick}
                  options={{ message: "Whoa!" }}
                  text={"GABARWINNER25"}
                >
                <div className="coupon" onClick={onCopyClick}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ContentCopyIcon
                      style={{ fontSize: 20, color: "#fff", opacity: 0 }}
                      onClick={onCopyClick}
                    />
                    <span className="coupon-text">GABARWINNER25</span>

                    {copied ? (
                      <DoneIcon
                        style={{ fontSize: 20, color: "#fff" }}
                        onClick={onCopyClick}
                      />
                    ) : (
                      <ContentCopyIcon
                        style={{ fontSize: 20, color: "#fff" }}
                        onClick={onCopyClick}
                      />
                    )}
                  </div>
                </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

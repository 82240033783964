// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-customization {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
}
.game-customization .inner-content {
  position: absolute;
  top: 50%;
  background: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2vw 3vw;
  border-radius: 1rem;
  border: 1px solid #ddd;
  width: 95%;
  max-width: 480px;
}
.game-customization .inner-content form > div {
  margin: 1rem 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.game-customization .inner-content form > div p.error {
  width: 100%;
  font-size: 0.8rem;
  color: red;
}
.game-customization .inner-content svg {
  position: absolute;
  top: -5%;
  right: -5%;
  transform: translate(100%);
}
.game-customization .inner-content svg path {
  fill: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/games/GameCustomize.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;AACJ;AAAI;EACI,kBAAA;EACA,QAAA;EACA,gBAAA;EACA,SAAA;EACA,gCAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,gBAAA;AAER;AADQ;EACI,mBAAA;EACA,aAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;AAGZ;AAFY;EACI,WAAA;EACA,iBAAA;EACA,UAAA;AAIhB;AADQ;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,0BAAA;AAGZ;AAFY;EACI,UAAA;AAIhB","sourcesContent":[".game-customization{\n    position: absolute;\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    .inner-content{\n        position: absolute;\n        top: 50%;\n        background: #fff;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        padding: 2vw 3vw;\n        border-radius: 1rem;\n        border: 1px solid #ddd;\n        width: 95%;\n        max-width: 480px;    \n        form>div{\n            margin: 1rem 0 1rem;\n            display: flex;\n            flex-wrap: wrap;\n            justify-content: space-between;\n            align-items: center;\n            p.error {\n                width: 100%;\n                font-size: 0.8rem;\n                color: red;\n            }\n        }\n        svg{\n            position: absolute;\n            top: -5%;\n            right: -5%;\n            transform: translate(100%);\n            path{\n                fill: #000;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

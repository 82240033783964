// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz {
  margin: 0rem auto 1rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
}
.quiz ul {
  flex-direction: column;
  gap: 0.1vw;
  margin-top: 1rem;
}
.quiz ul li {
  text-align: center;
  gap: 1%;
}
.quiz ul li button {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0.55vw 0;
  border-radius: 2.775vw;
  gap: 0.6938vw;
  padding: 0.55vw 3.33vw;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
}

.quiz-instruction {
  color: #fff;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.49vw;
  margin: 1.5vw 0 1.2vw;
}

.quiz-question {
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.49vw;
  text-align: center;
  letter-spacing: 2%;
  color: #FFFFFF;
}

.try-again {
  font-size: 1.39vw;
  line-height: 1.91vw;
  color: #FFFFFF;
  font-weight: 400;
  text-align: center;
  margin: 0.5vw 0 0.75vw;
}`, "",{"version":3,"sources":["webpack://./src/components/games/Quiz.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,sBAAA;AACJ;AACI;EACI,sBAAA;EACA,UAAA;EACA,gBAAA;AACR;AAAQ;EAEI,kBAAA;EACA,OAAA;AACZ;AAAY;EACI,WAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,mBAAA;AAEhB;;AAGA;EACI,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;AAAJ;;AAEA;EACI,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;AACJ;;AACA;EACI,iBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;AAEJ","sourcesContent":[".quiz {\n    margin: 0rem auto 1rem;\n    padding: 1rem 2rem;\n    box-sizing: border-box;\n\n    ul{\n        flex-direction: column;\n        gap: .1vw;\n        margin-top:1rem;\n        li{\n            // width: 100px;\n            text-align: center;\n            gap: 1%;\n            button{\n                width:100%;\n                cursor:pointer;\n                box-sizing: border-box;\n                margin: 0.55vw 0;\n                border-radius: 2.775vw;\n                gap: 0.6938vw;\n                padding: 0.55vw 3.33vw;\n                border: 1px solid #FFFFFF;\n                background: #FFFFFF;\n            }\n        }\n    }\n}\n.quiz-instruction{\n    color: #fff;\n    font-size: 1.25vw;\n    font-weight: 500;\n    line-height: 1.49vw;\n    margin: 1.5vw 0 1.2vw;\n}\n.quiz-question{\n    font-size: 1.25vw;\n    font-weight: 500;\n    line-height: 1.49vw;\n    text-align: center;\n    letter-spacing: 2%;\n    color: #FFFFFF;\n}\n.try-again{\n    font-size: 1.39vw;\n    line-height: 1.91vw;\n    color: #FFFFFF;\n    font-weight: 400;\n    text-align: center;\n    margin: .5vw 0 0.75vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import PopupForMobile from "./PopupForMobile";

export default function AuthGuard({ children }) {
    const isAuthenticated = useSelector(state => state.isAuthenticated)

    if (window.screen.width < 769) {
        return <PopupForMobile/>
       
      }
    if (!isAuthenticated) {
        return <Navigate to="/"></Navigate>
    }
    return <>{children}</>;
}
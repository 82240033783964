import { Dialog, IconButton, TextField, Tooltip } from "@mui/material";
import { ReactComponent as ForgotPasswordIcon } from "../../assests/svg/forgot-password-icon.svg";
import { useFormik } from "formik";
import axios from "axios";
import { BASE_URL } from "../../config/ApiConfig";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assests/svg/close-dialog-icon.svg";
import EmailNotFound from "./EmailNotFound";
export default function ForgotPassword({ open, close }) {
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [openEmailNotFoundPopup, setOpenEmailNotFoundPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
       borderColor: "#EE3A24CC"
      }
    }
  } 

  async function handleSendLink() {
    const response = await axios
      .post(`${BASE_URL}brand/forgetPassword`, formik.values, {
        authorization: `Bearer ${token}`,
      })

      // const response = await fetchData(ApiConfig.forgetPasword,setLoading,'post',formik.values,{ authorization: `Bearer ${token}` })
      .then((result) => {
       
        if (result?.data?.statusCode === 200) {
          localStorage.setItem("brand_email", formik.values.brand_email);
          navigate("/password-verification");
          close();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.statusCode === 400) {
          setOpenEmailNotFoundPopup(true);
         
     setErrorMessage(err?.response?.data?.message)
        }
      });
      if(response){
        console.log('');
      }
  }
  
  const formik = useFormik({
    initialValues: {
      brand_email: "",
      type:2
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
        brand_email: Yup.string()
            .email("Please enter valid Email")
            .matches(/@[^.]*\./, "Please enter valid Email")
            .required("Email is required")
    }),
    onSubmit: handleSendLink,
  });

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
       
        open={open}
        className="common-dialog-container forgot-pwd-container" 
        style={{display: openEmailNotFoundPopup ? 'none' : 'block'}}
      >
        <div className="account-created-container">
          <IconButton className="close-dialog-icon-button" onClick={close}>
            <Tooltip title="Close Dialog">
              <CloseIcon className="close-dialog-icon" />
            </Tooltip>
          </IconButton>
          <div className="header-container">
            <form className="form" onSubmit={formik.handleSubmit}>
              <ForgotPasswordIcon></ForgotPasswordIcon>
              <h2>Forgot Your Password</h2>
              <div className=""  style={{ margin: "20px 0", textAlign:'left' }}>
                <TextField sx={style}
                 
                  className="my-input set-pwd"
                  placeholder="Your Email*"
                  type="email"
                  name="brand_email"
                  id="brand_email"
                  onChange={formik.handleChange}
                  value={formik.values.brand_email}
                 
                />
                 {formik.touched.brand_email && formik.errors.brand_email ? (
                  <small className="error" style={{margin:' 0.25rem 0'}}>{formik.errors.brand_email}</small>
                ) : null}
              </div>
              <div className="footer-container">
                <button className="button2" type="submit">
                  Reset Password Link
                </button>
              </div>
              <div className="return-login">
                <Link onClick={close}>&larr;Return to Login</Link>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      {(openEmailNotFoundPopup ) && (
        <EmailNotFound 
          errorMessage={errorMessage}        
          openEmailNotFoundPopup={openEmailNotFoundPopup}
          setOpenEmailNotFoundPopup={setOpenEmailNotFoundPopup}
          close={close}
        />
      )}
    </>
  );
}
    
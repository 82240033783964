import { create } from "zustand";

const useStore = create((set) => ({
  isInstructionDone: false,
  isReady: false,
  isHovered: false,
  isLoadingDone: false,
  isZeroRequiredDistance: false,
  totalDistance: 0,
  isFormOverlay: false,
  isHeaderOverlay: false,
  isRotateProducts: false,
  isCameraPanning: false,
  isJoystickVisible: true,
  isShowForm: false,
  isOtherModalVisible: false,
  isProductModalOpen: false,
  hitPoint: null,
  isProductClick: false,
  isDoubleClick: false,
  joystickFoward: 0,
  joystickTurn: 0,
  isMovingWithJoystick: false,
  isEntryAnimationDone: undefined,
  isRotateCamera: false,
  isInitialHeightView: false,
  joystickSpeed: 0,
  joystickRotation: 0,
  isPanEnabled: true,
  isShowBag: false,
  isLcdCapsuleHit: false,
  isMovingToTarget: false,
  isGamePopupOpen: false,
  instructionModal: false,
  t:false,
  uT: () => set({ t: prev=> !prev }),
  handleInstructionModal: (val) => set((state) => ({ instructionModal: val })),
  setGamePopupOpen: () => set({ isGamePopupOpen: true }),
  setGamePopupClose: () => set({ isGamePopupOpen: false }),
  setInstructionDone: () => set({ isInstructionDone: true }),
  setHovered: (value) => set({ isHovered: value }),
  setLoadingDone: (value) => set({ isLoadingDone: value }),
  setTotalDistance: (value) => set({ totalDistance: value }),
  setZeroRequiredDistance: (value) => set({ isZeroRequiredDistance: value }),
  setFormOverlay: (value) => set({ isFormOverlay: value }),
  setHeaderOverlay: (value) => set({ isHeaderOverlay: value }),
  setJoystickVisible: (value) => set({ isJoystickVisible: value }),
  setRotateProducts: (value) => set({ isRotateProducts: value }),
  setLcdCapsuleHit: (value) => set({ isLcdCapsuleHit: value }),
  setCameraPanning: (value) => set({ isCameraPanning: value }),
  setProductModalOpen: (value) => set({ isProductModalOpen: value }),
  setShowForm: (value) => set({ isShowForm: value }),
  setOtherModalVisible: (value) => set({ isOtherModalVisible: value }),
  setReady: (value) => set({ isReady: value }),
  setHitPoint: (value) => set({ hitPoint: value }),
  setProductClick: (value) => set({ isProductClick: value }),
  setDoubleClick: (value) => set({ isDoubleClick: value }),
  setJoystickForward: (value) => set({ joystickFoward: value }),
  setJoystickTurn: (value) => set({ joystickTurn: value }),
  setMovingWithJoystick: (value) => set({ isMovingWithJoystick: value }),
  setDoneEntryAnimation: (value) => set({ isEntryAnimationDone: value }),
  setRotateCamera: (value) => set({ isRotateCamera: value }),
  setInitialHeightView: (value) => set({ isInitialHeightView: value }),
  setJoystickSpeed: (value) => set({ joystickSpeed: value }),
  setJoystickRotation: (value) => set({ joystickRotation: value }),
  setPanEnabled: (value) => set({ isPanEnabled: value }),
  setShowBag: (value) => set({ isShowBag: value }),
  setIsMovingToTarget: (value) => set({ isMovingToTarget: value }),
  handleReset: () =>
    set({
      isInstructionDone: false,
      isReady: false,
      isHovered: false,
      isLoadingDone: false,
      isZeroRequiredDistance: false,
      totalDistance: 0,
      isFormOverlay: false,
      isHeaderOverlay: false,
      isRotateProducts: false,
      isCameraPanning: false,
      isJoystickVisible: true,
      isShowForm: false,
      isOtherModalVisible: false,
      isProductModalOpen: false,
      hitPoint: null,
      isProductClick: false,
      isDoubleClick: false,
      joystickFoward: 0,
      joystickTurn: 0,
      isMovingWithJoystick: false,
      isEntryAnimationDone: undefined,
      isRotateCamera: false,
      isInitialHeightView: false,
      joystickSpeed: 0,
      joystickRotation: 0,
      isPanEnabled: true,
      isShowBag: false,
      isLcdCapsuleHit: false,
      isMovingToTarget: false,
      isGamePopupOpen: false,
      instructionModal: false,
    }),
}));

export default useStore;

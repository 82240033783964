import React from "react";
function Landscape() {
  return (
    <>
      <div className="rotate-warning-container">
        <div className="phone"></div>
        <div className="message">
          <p className="first">Please rotate your device and ensure your rotation lock is disabled.</p>
          
          {/* <p className="second">Kindly unlock the orientation on your device.</p> */}
        </div>
      </div>
    </>
  );
}

export default Landscape;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-btn, .subscription-btn {
  border: none;
  font-size: 1.05vw;
  line-height: 1.25vw;
  font-weight: 400;
  color: #3A3A3A;
  cursor: pointer;
  background-color: transparent;
  padding: 0.555vw;
}

.profile-btn.active, .subscription-btn.active {
  top: 0;
  text-decoration: underline;
  text-underline-offset: 0.555vw;
  text-decoration-color: #EE3A24;
  text-decoration-thickness: 0.13875vw;
  text-decoration-style: solid;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyProfile.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,gBAAA;AAAF;;AAIA;EACE,MAAA;EACD,0BAAA;EACA,8BAAA;EACA,8BAAA;EACA,oCAAA;EACA,4BAAA;AADD","sourcesContent":["\n.profile-btn, .subscription-btn {\n  border: none;\n  font-size: 1.05vw;\n  line-height: 1.25vw;\n  font-weight: 400;\n  color: #3A3A3A;\n  cursor: pointer;\n  background-color: transparent;\n  padding: 0.555vw;\n}\n\n\n.profile-btn.active, .subscription-btn.active{\n  top: 0;\n text-decoration: underline;\n text-underline-offset:0.555vw;\n text-decoration-color: #EE3A24;\n text-decoration-thickness: 0.13875vw;\n text-decoration-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import "./Card.scss";
import ComingSoonImage from "../assests/images/coming-soon.png";
export default function ComingSoon() {
  return (
    <>
      <div className="coming-soon-container">
        <img src={ComingSoonImage} width="100%" alt="coming soon" />
        <div className="coming-soon-content">
          <p>Coming Soon</p>
          <p>PAGE UNDER CONSTRUCTION</p>
        </div>
      </div>
    </>
  );
}

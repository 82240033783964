import React, { useEffect, useState } from "react";
import GameCustomize from "./GameCustomize";
import { useNavigate } from "react-router";
import VideoPopup from "../../popup/VideoPopup";
import GameLimitExceed from "./GameLimitExceed";
import PopupPage from "../../popup/PopupPage";
import PopupPage2 from "../../popup/PopupPage2";
import ApiConfig from "../../../helper/ApiConfig";
import { fetchData } from "../../../fetcher/fetchData";
import useAuthStore from "../../../store/authStore";
import { ReactComponent as PlayIcon } from "../../../assets/svg/playIcon.svg";
import { ReactComponent as CustomizeIcon } from "../../../assets/svg/customize-icon.svg";
import "../../../style/components/game/Game.scss";
import "../../../style/components/store/SelectEnv.scss";

function Game() {
  const navigate = useNavigate();
  const [gameList, setGameList] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [openGamePopup, setOpenGamePopup] = useState(false);
  const [openLaunchPopup, setOpenLaunchPopup] = useState(false);
  const [openPopup2, setPopupOpen2] = useState(false);
  const { token} = useAuthStore();
  const [loading, setLoading ] = useState(false);
  const [gameSelectData, setGameSelectData] = useState("");
  const [playVideoPopup, setVideoPopup] = useState(false);
  const [selectedGameInfo, setSelectedGameInfo] = useState({});
  const [enableStep, setStep] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [openPopup, setPopupOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  // const [brand_param,setBrand_param] = useState("");
  const [gameID, setGameID] = useState(null);

  useEffect(() => {
    async function getProductList() {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.gameList}?page=1&limit=10'`,
        setLoading,
        "get",
        {},
        {}
      );
      if (response.status === 401 || response.status === 500) {
        navigate("/login");
      } else if (response.status === 200) {
        setGameList(response.data.data);
      }
      const info = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info.status === 401 || info.status === 500) {
        navigate("/login");
      }
      if (info.status === 200) {
        setStep(
          info.data.data.completed_step >= 4 ||
            info.data.data.brand_param !== ""
        );
        setCurrentStep(info.data.data.completed_step);
        //  setBrand_param(info.data.data.brand_param);
        setGameID(info.data.data.gameMapping?.game_id ?? -1);
      }
      setLoading(false);
    }
    getProductList();
  }, []);

  const handlePopup2 = (data) => {
    setPopupOpen(false);
  };

  const handlePopup3 = (data) => {
    setPopupOpen2(false);
  };

  const handlePopup = (data) => {
    setVideoPopup(data);
  };
  const handleCloseLaunchPopup = () => {
    setOpenLaunchPopup(false);
  };
  const customizeGame = (val) => {
    if (val.game_id === 1) {
      navigate("/dashboard/add-coupon-code");
    } else {
      navigate("/dashboard/spin");
    }
    setGameData(val);
    setOpenGamePopup(true);
  };

  const handleGamePopup = (data) => {
    setOpenGamePopup(false);
  };

  const handleLaunch = async () => {
    const info = await fetchData(
      ApiConfig.launch,
      setLoading,
      "post",
      { game_ids: [] },
      { authorization: `Bearer ${token}` }
    );
    if (info.status === 200) {
      navigate("/launch");
    } else if (info.status === 401 || info.response === 500) {
      navigate("/login");
    } else {
      setOpenLaunchPopup(true);
      setErrorMessage(info.message);
    }
  };

  const handleCheck = () => {
    // if(brand_param === ''){
    //   handleLaunch();
    // }
    // else{
    //   setPopupOpen2(true);
    // }
    updateStep();
  };

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 5 },
      { authorization: `Bearer ${token}` }
    );
    if (response.data.statusCode === 200) {
      navigate("/dashboard");
    }
  }

  const handleLaunch2 = (data) => {
    handleLaunch();
  };

  const playPopup = (data) => {
    setGameSelectData(data.game_play_video_url);
    setSelectedGameInfo({
      description: data.game_desc,
    });
    setVideoPopup(!playVideoPopup);
  };
  return (
    <>
      <div
        style={{ position: "relative" }}
        className="env-store-add-product-heading"
      >
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
        <h2>Add Gamification</h2>
        {/* <button
          className="button2"
          type="button"
          style={{
            margin: "0rem 4rem",
            backgroundColor: "#EE3A24",
            borderRadius: "0.21vw",
            height: "3.25vw",
          }}
          disabled={!enableStep}
          onClick={handleCheck}
        >
          Launch
        </button> */}
        </div>
      
        <div className="stepper-container">
          <div className="stepper">
            <div
              className={`round ${
                currentStep >= 1 ? "round" : "step-not-active"
              }`}
              onClick={() => navigate("/dashboard/select-enviroment")}
            >
              1
            </div>
            <div
              className={`round ${
                currentStep >= 2 ? "round" : "step-not-active"
              }`}
              onClick={() => navigate("/dashboard/select-store")}
            >
              2
            </div>
            <div
              className={`round ${
                currentStep >= 3 ? "round" : "step-not-active"
              }`}
              onClick={() => {
                if (currentStep < 3) {
                  setPopupOpen(true);
                } else {
                  navigate("/dashboard/customize-store");
                }
              }}
            >
              3
            </div>
            <div className="round active">4</div>
            <div className="round step-not-active">5</div>
          </div>
        </div>
      </div>
      <div className="gamification">
        <div className="game-list">
          {loading ? (
            <p>loading...</p>
          ) : (
            gameList.map((game, index) => (
              <div key={index} className="details-container">
                <div className="img-container" onClick={() => playPopup(game)}>
                  <img
                    src={game.game_thumbnail_url}
                    alt={game.game_name}
                    className="game-img"
                  />
                  <span className="play-icon">
                    <PlayIcon />
                  </span>
                  <span className="title"> {game.game_name}</span>
                </div>

                <div className="game-detail">
                  <h4 className="game-title">{game.game_name}</h4>
                  <div>
                    <p className="game-description">{game.game_desc}</p>
                    <button
                      className="customize"
                      type="button"
                      disabled={!enableStep}
                      onClick={() => customizeGame(game)}
                    >
                      Customise {gameID === index + 1 ? "Live Game" : ""}
                      <span>
                        {" "}
                        <CustomizeIcon />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        

        {openGamePopup && (
          <GameCustomize
            gameData={gameData}
            closeGameCustomize={handleGamePopup}
          />
        )}
      </div>

      {playVideoPopup && (
        <VideoPopup
          open={playPopup}
          videoUrl={gameSelectData}
          closePopup={handlePopup}
          data={selectedGameInfo}
        />
      )}
      {openLaunchPopup && (
        <GameLimitExceed
          open={openLaunchPopup}
          close={handleCloseLaunchPopup}
          message={errorMessage}
        />
      )}
      {openPopup && <PopupPage closePopup={handlePopup2} />}
      {openPopup2 && (
        <PopupPage2 closePopup={handlePopup3} launch={handleLaunch2} />
      )}
    </>
  );
}

export default Game;

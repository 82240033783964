import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import useAuthStore from "../../../store/authStore";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import { ReactComponent as BackwardArrowIcon } from "../../../assets/svg/backwardArrow.svg";
import "../../../style/components/store/EnvStore.scss";
import "../../../style/components/store/SelectEnv.scss";
import "../../../style/components/game/Game.scss";

export default function CouponCodeQuiz() {
  const navigate = useNavigate();
  const [gameData, setGameData] = useState(null);
  const { token, setLoading } = useAuthStore();
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#EE3A24CC",
      },
    },
  };

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response?.statusCode === 401 || response?.statusCode === 500) {
        navigate("/login");
      } else if (response?.data.statusCode === 200) {
        setGameData(response.data.data.gameMapping);
      }
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  useEffect(() => {
    if (gameData !== null && gameData.game_id === 1) {
      formik.setFieldValue("trigger_limit", gameData.trigger_limit || "");
      formik.setFieldValue("coupon_code", gameData.coupon_code || "");
      formik.setFieldValue("is_active", gameData.is_active || "");
    }
  }, [gameData]);

  const formik = useFormik({
    initialValues: {
      trigger_limit: "",
      coupon_code: "",
    },
    validationSchema: Yup.object({
      trigger_limit: Yup.string().required("Trigger time is required"),
      coupon_code: Yup.string().required("coupon is required"),
      is_active: Yup.string(),
    }),
    onSubmit: handleFormSubmit,
  });
  function handleFormSubmit() {
    localStorage.setItem(
      "trigger_limit",
      JSON.stringify(formik.values.trigger_limit)
    );
    localStorage.setItem(
      "coupon_code",
      JSON.stringify(formik.values.coupon_code)
    );
    navigate("/dashboard/quiz");
  }

  return (
    <>
      <div
        style={{ position: "relative" }}
        className="env-store-add-product-heading"
      >
        <h2>Add Gamification</h2>
        <div className="stepper-container">
          <div className="stepper">
            <div className="round ">
              <Link to="/dashboard/select-enviroment">1</Link>
            </div>
            <div className="round">
              <Link to="/dashboard/select-store">2</Link>
            </div> 
            <div className="round ">
            <Link to="/dashboard/customize-store">3</Link>
            </div>
            <div className="round active">4</div>
            <div className="round step-not-active ">5</div>
          </div>
          <p
            className="select-gamification"
            onClick={() => navigate("/dashboard/add-game")}
          >
            {" "}
            <span style={{ position: "relative", top: "0.14vw" }}>
              <BackwardArrowIcon style={{ margin: "0 0.15vw" }} />
            </span>
            <span>Select Gamification</span>
          </p>
        </div>
      </div>
      <div className="quiz_coupon_code_container">
        <div className="inner-game-container">
          <div className="store-heading">
            <h2>Quiz</h2>
          </div>

          <form>
            <div className="trigger_limit">
              <Grid container>
                <Grid item lg={6} alignContent={"center"}>
                  <Typography className="trigger-time">Trigger Time</Typography>
                </Grid>
                <Grid item lg={6}>
                  <select
                    style={{ cursor: "pointer" }}
                    onChange={formik.handleChange}
                    name="trigger_limit"
                    id="trigger_limit"
                    value={formik.values.trigger_limit}
                    required
                  >
                    <option value="" disabled hidden>
                      - Select -
                    </option>
                    <option value="10">10 seconds</option>
                    <option value="30">30 seconds</option>
                    <option value="60">1 Minute</option>
                    <option value="300">5 Minutes</option>
                  </select>
                  {formik.touched.trigger_limit &&
                  formik.errors.trigger_limit ? (
                    <small
                      style={{
                        color: "#EE3A24CC",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      {formik.errors.trigger_limit}
                    </small>
                  ) : null}
                </Grid>
              </Grid>
            </div>
            <div className="coupon_code">
              <Grid container>
                <Grid item lg={6} alignContent={"center"}>
                  <Typography className="trigger-time">Coupon Code</Typography>
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    sx={style}
                    value={formik.values.coupon_code}
                    placeholder="Enter Code"
                    name="coupon_code"
                    className="coupon-quiz-input"
                    id="coupon_code"
                    variant="outlined"
                    required
                    onChange={formik.handleChange}
                    error={
                      formik.touched.coupon_code &&
                      Boolean(formik.errors.coupon_code)
                    }
                    helperText={
                      formik.touched.coupon_code && formik.errors.coupon_code
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <div className="is_active">
              <Grid container>
                <Grid item lg={6} alignContent={"center"}>
                  <Typography className="active-time">Is this the active game?</Typography>
                </Grid>
                <Grid item lg={6}>
                  <select
                    style={{ cursor: "pointer" }}
                    onChange={formik.handleChange}
                    name="is_active"
                    id="is_active"
                    value={formik.values.is_active}
                    required
                  >
                    <option value="" disabled hidden>
                      - Select -
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  {formik.touched.is_active &&
                  formik.errors.is_active ? (
                    <small
                      style={{
                        color: "#EE3A24CC",
                        fontSize: "11px",
                        fontWeight: "500",
                      }}
                    >
                      {formik.errors.is_active}
                    </small>
                  ) : null}
                </Grid>
              </Grid>
            </div>
            <div className="spin-wheel-submit submit-btn">
              <button type="button" onClick={formik.handleSubmit}>
                Next
                <span>
                  <ArrowIcon />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { Color } from "three";
import { RigidBody } from "@react-three/rapier";

let moleculeNodes, moleculeMaterials, moleculeAnimations;
// function Molecules_1(props) {
//   // console.log("RERENDERED MOLECULES");
//   const group = useRef();
//   const { nodes, materials, animations } = useGLTF(
//     "/assets/models/molecules.glb",
//   );
//   moleculeNodes = nodes;
//   moleculeMaterials = materials;
//   moleculeAnimations = animations;
//   const { actions } = useAnimations(animations, group);
//   useEffect(() => {
//     actions.Animation.timeScale = 3.5;
//     actions.Animation.play();
//   }, [actions]);
//   return (
//     // <RigidBody type="fixed" colliders="cuboid">
//     <group ref={group} {...props} dispose={null}>
//       <group name="Scene">
//         <group name="Molecules_CTRL" position={[-15.097, 2.351, -9.58]}>
//           <mesh
//             name="Molecule_1"
//             geometry={nodes.Molecule_1.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[5.351, 4.855, 18.284]}
//             scale={0.9}
//           />
//           <mesh
//             name="Molecule_2"
//             geometry={nodes.Molecule_2.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[-0.463, 2.204, 17.518]}
//             scale={1.05}
//             visible={false}
//           />
//           <mesh
//             name="Molecule_3"
//             geometry={nodes.Molecule_3.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[2.656, 4.949, 9.549]}
//             // scale={1}
//           />
//           <mesh
//             name="Molecule_4"
//             geometry={nodes.Molecule_4.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[6.856, 1.5, 13.914]}
//             visible={false}
//             // scale={1.12}
//           />
//           <mesh
//             name="Molecule_5"
//             geometry={nodes.Molecule_5.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[1.744, 7.448, 13.204]}
//             scale={1.3}
//             visible={false}
//           />
//           <mesh
//             name="Molecule_6"
//             geometry={nodes.Molecule_6.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[6.464, 2.759, 10.164]}
//             // visible={false}
//           />

//           <mesh
//             name="Molecule_7"
//             geometry={nodes.Molecule_7.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[-0.86, 0.197, 12.146]}
//             transparent
//             material-roughness={0.2}
//             material-opacity={0.6}
//             scale={1.3}
//           />
//           <mesh
//             name="Molecule_8"
//             geometry={nodes.Molecule_8.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             transparent
//             material-roughness={0.2}
//             material-opacity={0.6}
//             position={[2.746, 2.228, 16.313]}
//             scale={1.3}
//             visible={false}
//           />
//         </group>
//       </group>
//     </group>
//     // </RigidBody>
//   );
// }

// export function Molecules_2(props) {
//   // console.log("RERENDERED MOLECULES");
//   const group = useRef();
//   const { nodes, materials, animations } = useGLTF(
//     "/assets/models/molecules.glb",
//   );
//   const { actions } = useAnimations(animations, group);
//   useEffect(() => {
//     actions.Animation.timeScale = 3.5;
//     actions.Animation.play();
//   }, [actions]);
//   return (
//     // <RigidBody type="fixed" colliders="cuboid">
//     <group ref={group} {...props} dispose={null}>
//       <group name="Scene">
//         <group name="Molecules_CTRL" position={[-5, 2.351, -9.58]}>
//           <mesh
//             name="Molecule_1"
//             geometry={nodes.Molecule_1.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[-2, 6, 18.5]}
//             visible={false}
//             scale={1.05}
//           />
//           <mesh
//             name="Molecule_2"
//             geometry={nodes.Molecule_2.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             transparent
//             material-roughness={0.2}
//             material-opacity={0.6}
//             position={[1.7, 2.5, 16.313]}
//             scale={1.3}
//           />
//           <mesh
//             name="Molecule_3"
//             geometry={nodes.Molecule_3.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[3, 4, 17]}
//             scale={0.9}
//           />
//         </group>
//         <group name="Molecules_CTRL" position={[-27, 2.351, -9.58]}>
//           <mesh
//             name="Molecule_4"
//             geometry={nodes.Molecule_4.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[0.5, 4, 17.518]}
//             // visible={false}
//             scale={1.05}
//           />
//           <mesh
//             name="Molecule_5"
//             geometry={nodes.Molecule_5.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[8, 4.855, 18.284]}
//             visible={false}
//             scale={0.9}
//           />
//           <mesh
//             name="Molecule_6"
//             geometry={nodes.Molecule_6.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             position={[1.5, 4.949, 9.549]}
//             // visible={false}
//             // scale={1}
//           />
//           <mesh
//             name="Molecule_7"
//             geometry={nodes.Molecule_7.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             transparent
//             material-roughness={0.2}
//             material-opacity={0.6}
//             position={[1, 1.5, 16.313]}
//             scale={1.3}
//           />
//           <mesh
//             name="Molecule_8"
//             geometry={nodes.Molecule_8.geometry}
//             material={materials.SilverLight_Molecule_Material}
//             transparent
//             material-roughness={0.2}
//             material-opacity={0.6}
//             position={[2.746, 2.228, 16.313]}
//             scale={1.3}
//             visible={false}
//           />
//         </group>
//       </group>
//     </group>
//     // </RigidBody>
//   );
// }

function Molecules() {
  return (
    <>
      {/* <Molecules_1 />
      <Molecules_2 /> */}
    </>
  );
}

// useGLTF.preload("/assets/models/molecules.glb");

export { Molecules, moleculeNodes, moleculeMaterials, moleculeAnimations };

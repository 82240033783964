import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import useStore from "../../../utils2/uistore";
import ShoppingCart from "./Shop";
import ReactGA from "react-ga4";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import "./shoppingcart.css";
import useShopifyStore from "../../../utils2/shopifyStore";
import useAnalytics from "../../../utils2/google-analytics/useGTAGTracker";
const Cart = ({ getCartItem }) => {
  const {
    apiURL,
    cartLoaded,
    cartChecked,
    cartOpened,
    setCartChecked,
    setCartOpened,
    setCartLoaded,
    handleItems,
    showCart,
    formModal,
    handleShowCart,
    increase,
    count,
    updateSavings,
  } = useStore((state) => state);
  const { trackCartCheckOut } = useAnalytics();
  const { cartItems, setCartItems } = useShopifyStore();

  useEffect(() => {
    if (showCart) {
      setCartLoaded(false);
      setCartChecked(false);
      setCartOpened(false);
      handleItems(false)
    }
    getCartItem();
  }, [showCart]);

  const calculateSavings = () => {
    if (
      !cartItems ||
      !cartItems.cartItems ||
      cartItems.cartItems.length === 0
    ) {
      return 0;
    }

    const totalItemCost = cartItems.cartItems.reduce((total, cartItem) => {
      return (
        total + parseFloat(cartItem.variant.priceV2?.amount) * cartItem.quantity
      );
    }, 0);

    const totalCostWithoutSavings = cartItems.cartItems.reduce(
      (total, cartItem) => {
        return (
          total +
          parseFloat(cartItem.variant.compareAtPriceV2?.amount) *
            cartItem.quantity
        );
      },
      0
    );

    const savings = totalCostWithoutSavings - totalItemCost;
    return savings;
  };
 
  const savings = calculateSavings();
  const handleCheckOut = () => {
    const cartItemNames = cartItems?.cartItems?.map((el) => {
      return el.title;
    });
    cartItemNames.map((el) => {
      trackCartCheckOut(el);
    });
  };

  return (
    <>
      {/* showCart && !formModal && */}
      {showCart && !formModal ? (
        <div className="main-containerss">
          <div className="cart-header">
            <span className="cart-txt">Your Cart</span>
            <div
              className="form-close-icon"
              onClick={() => handleShowCart(!showCart)}
            >
              <CloseIcon style={{ fontSize: 20, color: "#fff" }} />
            </div>
          </div>
          {!cartLoaded && !cartChecked && (
            <div className="loader-container">
              <div className="loader_cart">
                <ReactLoading
                  type={"balls"}
                  color={"#000"}
                  height={50}
                  width={50}
                />
                {/* <h1 className="text-2xl xl:text-3xl log-in-text">
                      Please Wait
                    </h1> */}
              </div>
            </div>
          )}
          {cartChecked && cartItems?.cartItems?.length > 0 && (
            <div>
              {cartLoaded && (
                <>
                  <div className="scroll-container">
                    {cartItems?.cartItems?.map((cartItem, index) => (
                      <ShoppingCart
                        key={index}
                        name={cartItem.title}
                        image={cartItem.variant.product.images[0].originalSrc}
                        price={cartItem.variant.priceV2.amount}
                        total={cartItem.variant.compareAtPriceV2?.amount || 0}
                        count={cartItem.quantity}
                        id={cartItem.variant.id}
                        nodeId={cartItem.id}
                        checkoutId={cartItems.cartData.id}
                        // fetchCart={fetchCart}
                        increase={increase}
                        getCartItem={getCartItem}
                      />
                    ))}
                    {/* </>} */}

                    <div className="total-amount-block">
                      <div className="total-amount">
                        <div className="divider" />
                      </div>
                      {cartItems?.cartItems?.some(
                        (item) =>
                          item.variant.compareAtPriceV2 &&
                          item.variant.compareAtPriceV2.amount >
                            item.variant.priceV2.amount &&
                          (item.variant.compareAtPriceV2.amount == null ||
                            item.variant.compareAtPriceV2.amount ===
                              item.variant.priceV2.amount)
                      ) && (
                        <div className="total-amount">
                          <span className="subtotal-amount">Savings</span>
                          <span className="subtotal-amount">
                            - {"£ "}
                            {savings}
                          </span>
                        </div>
                      )}

                      <div className="total-amount">
                        <span className="subtotal-amount">Subtotal</span>
                        <span className="subtotal-amount">
                          {"£ "}{" "}
                          {parseFloat(
                            cartItems?.cartData?.totalPriceV2?.amount
                          ).toFixed(2)}
                          {/* {cartItems?.cartData?.totalPriceV2?.amount} */}
                        </span>
                      </div>
                    </div>
                    <div className="btn-root-container">
                      <span
                        className="bottom-btn"
                        style={{ backgroundColor: "#fff", cursor: "pointer" }}
                      >
                        <a
                          onClick={handleCheckOut}
                          href={`${localStorage.getItem("webUrl")}?utm_source=onewayx&utm_medium=onewayx&utm_campaign=onewayx`}
                          className="checkout-txt"
                        >
                          CHECK OUT
                        </a>
                      </span>
                      {/* </button> */}
                      <div className="fake-border" />
                      <span className="disclaimer-txt">
                        By pressing Checkout, you agree to our Store Policy.
                        Discounts are unavailable with Apple Pay, Google Pay, or
                        other express payment options.
                      </span>
                      <div className="fake-border" />
                    </div>
                  </div>{" "}
                </>
              )}
            </div>
          )}
          {cartChecked &&
            (cartItems?.cartItems?.length <= 0 ||
              cartItems?.cartItems === undefined) && (
              <p style={{ textAlign: "center",color:"white" }}>
                Your cart is currently empty
              </p>
            )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Cart;

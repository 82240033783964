import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import ApiConfig from "../../config/ApiConfig";
import { fetchData } from "../../service/http.service";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assests/svg/arrow.svg";
import { ReactComponent as QuestionIcon } from "../../assests/svg/question.svg";
import { ReactComponent as ErrorIcon } from "../../assests/svg/error.svg";
import { ReactComponent as LinkIcon } from "../../assests/svg/link.svg";
import "./EnvStore.scss";
import FailedToRetrieve from "../FailedToRetrieve";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import Spinner from "../Spinner";
import PopupPage from "../PopupPage";
import PopupStore from "../PopupStore";

function EnvStore() {
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
       borderColor: "#EE3A24CC",
      },
    },
  };

  const [isChecked, setIsChecked] = useState(false);
  const [failedToRetrieve, setFailedToRetrieve] = useState(false);
  const [currentShopURL,setCurrentShopURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [enableStep,setStep] =useState(true);
  const [currentStep,setCurrentStep] = useState(0);
  const [openPopup, setPopupOpen] = useState(false);
  const [openPopupStore, setPopupStore] = useState(false);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();


  useEffect(()=>{
    async function getEnv() {
      setLoading(true);
      const info = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info.status === 200) {
         setStep((info.data.data.completed_step >= 2) );
         setCurrentStep(info.data.data.completed_step);
         setCurrentShopURL(info.data.data.product_url)
      }
      else if (info.status === 401 ||info.status === 500) {
        navigate("/login");
      }
      setLoading(false);
    }
    getEnv();
  }, []);

  const handlePopup = (data) => {
    setPopupOpen(false);
  };

  
  const formik = useFormik({    
    enableReinitialize: true,
    initialValues: {
      url: currentShopURL ?? '',
    },
    validationSchema: Yup.object({
      url: Yup.string().required("This field is required"),
    }),
    onSubmit: handleSubmit,
  });

  async function handleSubmit() {    
    if(currentShopURL === formik.values.url || currentShopURL === null)
    {
      handleData();
    }
    else{
      setPopupStore(true);
    }

  }

  const handleData = async () => {
    setLoading(true);
    const response = await fetchData(
      `${ApiConfig.storeProducts}?store_url=${formik.values.url}`,
      setLoading,
      "get",
      {},
      { authorization: `Bearer ${token}` }
    );

    const response2 = await fetchData(
      ApiConfig.storeData,
      setLoading,
      "POST",
      { product_url:formik.values.url },
      { authorization: `Bearer ${token}` }
    );
    if (response2.statusCode === 401 ||response.statusCode === 500) {
      navigate("/login");
    }
    if (response2.status !== 200) {
      throw new Error(response.error);
    }
    if (response.status === 200) {
      sessionStorage.setItem("shopifyURL", formik.values.url);
      navigate("/dashboard/add-products");
    } else if (response.status === 401 || response.status === 500) {
      navigate("/login");
    } else {
      setFailedToRetrieve(true);
    }
    setLoading(false);
  }

  const handleCloseDialog = () => {
    setFailedToRetrieve(false);
  };

  const handlePopup3 = (data) => {
    setPopupStore(false);
  };

  const handleLaunch2 = (data) => {
    handleData();
  }

  return (
    <>
      <div className="env-store-add-product-heading">
        <h2>Add Products</h2>
        <div className="stepper-container">
          <div className="stepper">
                  <div 
                  className={`round ${
                    currentStep > 1 ? "round"  : "step-not-active"
                  }`}
                  
                  onClick={() => navigate("/select-enviroment")}>1</div>
                  <div className="round active">2</div>
                  <div
                   className={`round ${
                    currentStep > 3 ? "round"  : "step-not-active"
                  }`}
                   
                   onClick={() => {
                    if(currentStep < 3){
                      setPopupOpen(true);
                    }
                    else{
                      navigate("/customize-env")
                    }
                   }}>3</div>
                  <div  className={`round ${
                    currentStep > 4 ? "round"  : "step-not-active"
                  }`}
                   onClick={() => navigate("/dashboard/add-game")} >4</div>
                  <div className="round step-not-active">5</div>
          </div>
        </div>
      </div>
     
    
      <div className="store-link-container">
        <div className="store-wrapper">
          <div className="inner-container">
            <div className="store-heading">
              <h2>Enter Store Link</h2>
            </div>
            <div className="store-text">
              <p>
                Enter your Website URL, we will automatically import the product
                details into ONEWAYX
              </p>
            </div>
            <div className="spinner-wrapper">
      {loading && <Spinner />}
      </div>
            <form onSubmit={formik.handleSubmit}>
            
              <span
                style={{
                  position: "relative",
                  left: "3.33vw",
                  bottom: "0.2775vw",
                }}
              >
                {formik.touched.url && formik.errors.url ? (
                  <small
                    className="error-bg-white"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <ErrorIcon style={{ marginRight: "0.25vw" }} />
                    {formik.errors.url}
                  </small>
                ) : null}
              </span>
              <div className="env-url-wrapper">
                <label htmlFor="url">URL: </label>
                <TextField
                  sx={style}
                  placeholder="Add link to import"
                  className="my-input"
                  type="text"
                  id="url"
                  variant="outlined"
                  name="url"
                  onChange={formik.handleChange}
                  value={formik.values.url}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <QuestionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div>
                <div className="agree-check">
                  <input
                    type="checkbox"
                    name="terms"
                    id="terms"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />{" "}
                  <span>
                    I agree for ONEWAYX to import product information from our
                    website
                  </span>
                </div>
              </div>

              <div className="submit-btn">
                <button type="submit" disabled={!(isChecked && enableStep)}>
                  Import Products
                  <span>
                    <ArrowIcon />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
        {failedToRetrieve && (
          <FailedToRetrieve
            failedToRetrieve={failedToRetrieve}
            setFailedToRetrieve={setFailedToRetrieve}
            close={handleCloseDialog}
          />
        )}
      </div>
      {openPopup && <PopupPage  closePopup={handlePopup}/>}
      {openPopupStore && <PopupStore  closePopup={handlePopup3}  launch={handleLaunch2}/>}
    </>
  );
}

export default EnvStore;

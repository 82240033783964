import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import useModelStore from "../store";
import { RigidBody } from "@react-three/rapier";
import { fetchData } from "../../../service/http.service";
import ApiConfig from "../../../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAudioContainer from "../../utils2/useAudioContainer";
import useStore from "../../utils2/3dstore";


function Moleculers({ url, scale, name, showProduct, hasToken , productData, cartData}) {
  const group = useRef();
  const { scene } = useGLTF(url);
  const [isHovered, setIsHovered] = useState(false);
  const [loading,setLoading] =  useState(false);

  const position = useModelStore((state) => state.models[name] || [1000, 1000, 1000]);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const  {openProductCartWithAudio, setBrandId} = useAudioContainer();
  const { isGamePopupOpen } = useStore();

  useEffect(() => {
    if(hasToken){      
      const getEnvDetails = async () => {
        setLoading(false);
        const response = await fetchData(
          `${ApiConfig.env}`,
          setLoading,
          "post",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (response.statusCode === 401 || response.statusCode === 500) {
          navigate('/login');
        }
        else if (response.data.statusCode === 200) {          
          if(response.data.data.env_product_mappings.length){
            response.data.data.env_product_mappings.forEach(model => {
              if(model.product_uid === name){
                useModelStore.setState((state) => ({
                  models: {
                    ...state.models,
                    [model.product_uid]: [model.x_coordinate, model.y_coordinate, model.z_coordinate],
                  },
                }));
              }
            });
          }
        }      
        setLoading(true);
      };
      getEnvDetails();
    }
    else{
      const getEnvDetails = async () => {
        setLoading(false);
      const url = window.location.href;
      const parts = url.split("/");
      const data = parts[parts.length - 1];
      const response = await fetchData(
        `${ApiConfig.commonEnv}?brandParam=${data}`,
        setLoading,
        "get",
        {},
        {}
      );
      
      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate('/login');
      }
      else if (response.data.statusCode === 200) {    
        setBrandId(response.data.data.brand_id);
            
        if(response.data.data.env_product_mappings.length){
          response.data.data.env_product_mappings.forEach(model => {
            if(model.product_uid === name){
              useModelStore.setState((state) => ({
                models: {
                  ...state.models,
                  [model.product_uid]: [model.x_coordinate, model.y_coordinate, model.z_coordinate],
                },
              }));
            }
          });
        }
      }      
      setLoading(true);
    };
    getEnvDetails();
      
    }
  }, []);
  

  useFrame(() => {
    if(!isGamePopupOpen){
      if (group.current) {
        group.current.position.set(position[0], position[1], position[2]);
        if (!isHovered) {
          group.current.rotation.y += 0.01;
        }
      }
    }
  });

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
    setIsHovered(true);
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "default";    
    setIsHovered(false);
  };

  const handleCard = () => { 
    if(cartData === ''){
      openProductCartWithAudio(productData.audio_url,productData);   
    }else{
      openProductCartWithAudio(productData.audio_url,cartData);   
    }
     
  }

  return (
    <>
    <group ref={group} dispose={null} scale={scale} onPointerOver={handlePointerOver} onPointerOut={handlePointerOut}onClick={handleCard}>
      <RigidBody type="fixed">
        <primitive object={scene} />
      </RigidBody>
    </group>
    </>
  );
}

export default Moleculers;
import React from "react";
import "./trademark.css";

function TradeMark() {
  return (
    <>
      <div className="trade-mark">
        <img className="trade-mark-img" src="/assets/images/poweredby_1.svg" />
      </div>
      <div className="trade-mark-icon">
        <img className="trade-mark-logo" src="/assets/images/Symbol.png" style={{width: '25px'}} />
      </div>
    </>
  );
}

export default TradeMark;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  overflow: hidden;
}
.background .textContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 65vh;
  width: 60vw;
  gap: 10px;
  background-image: linear-gradient(180.14deg, rgba(238, 58, 36, 0.8) 39.22%, rgba(151, 71, 255, 0.8) 65.37%);
  background-color: rgb(255, 255, 255);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.background .textContainer h2 {
  color: #fff;
  font-weight: 400;
  font-size: 2.1vw;
}
.background .textContainer p {
  color: #fff;
  margin: 0vw auto 0.5vw;
  font-weight: 500;
  font-size: 1vw;
}
.background .textContainer button {
  background-color: #ee3a24;
  color: #fff;
  font-size: 1vw;
  font-weight: 500;
  padding: 0.75vw 2vw;
}
.background .textContainer button svg {
  position: relative;
  top: 1.4px;
  margin-left: 0.4vw;
}
.background .textContainer .close-dialog-icon {
  position: absolute;
  top: 0.5vw;
  right: 0.5vw;
}`, "",{"version":3,"sources":["webpack://./src/components/PopupPage.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;EACA,0BAAA;EACA,8BAAA;EACA,YAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,2GAAA;EAmCA,oCAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAjCJ;AAAI;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;AAEN;AAAI;EACE,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;AAEN;AAAI;EACE,yBAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AAEN;AADM;EACE,kBAAA;EACA,UAAA;EACA,kBAAA;AAGR;AACI;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AACR","sourcesContent":[".background {\n  position: fixed;\n  top: 50px;\n  left: 0;\n  width: 100vw;\n  height: calc(100vh - 50px);\n  background: rgba(0, 0, 0, 0.4);\n  z-index: 999;\n  overflow: hidden;\n\n  .textContainer {\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    height: 65vh;\n    width: 60vw;\n    gap: 10px;\n    background-image: linear-gradient(\n      180.14deg,\n      rgba(238, 58, 36, 0.8) 39.22%,\n      rgba(151, 71, 255, 0.8) 65.37%\n    );\n    h2 {\n      color: #fff;\n      font-weight: 400;\n      font-size: 2.1vw;\n    }\n    p {\n      color: #fff;\n      margin: 0vw auto 0.5vw;\n      font-weight: 500;\n      font-size: 1vw;\n    }\n    button {\n      background-color: #ee3a24;\n      color: #fff;\n      font-size: 1vw;\n      font-weight: 500;\n      padding: 0.75vw 2vw;\n      svg {\n        position: relative;\n        top: 1.4px;\n        margin-left: 0.4vw;\n      }\n    }\n\n    .close-dialog-icon {\n        position: absolute;\n        top: .5vw;\n        right: .5vw;\n    }\n\n    background-color: rgba(255, 255, 255, 1);\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

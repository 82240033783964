import { useContext, useState,useEffect } from 'react';
import { ReactComponent as LockIcon } from '../assests/svg/lock-icon.svg'
import { ReactComponent as AccountCreatedIcon } from '../assests/svg/account-created.svg';
// import { ReactComponent as VisibilityOff } from '../assests/svg/visibility.svg';
import { ReactComponent as CheckIcon } from '../assests/svg/check-circle.svg'
import Logo from '../assests/images/white-logo.svg'
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { hasSpecialCharacter, hasNumber } from '../utils/validation';
import { useFormik } from 'formik';
import { fetchData } from '../service/http.service';
import { HeaderContext } from '../store/Header-context';
import { Link,  Navigate } from 'react-router-dom';
import ApiConfig from '../config/ApiConfig';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
export default function AccountCreated() {
    const token=useSelector(state=>state.token);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [nav,setNav] = useState(false);
    const [passmismatch, checkPassmismatch] = useState(false);
    const { setLoading } = useContext(HeaderContext);
    

    return <div className="main-container clearfix" style={{background: "linear-gradient(74.69deg, #9747FF 0.33%, #EE3A24 97.52%)"}}>
    <header className='header-absolute2 sigupHeader'>
            <nav>
                <ul>
                    <li>
                        <Tooltip title="Back to Website">
                            <Link to="https://www.onewayx.com/" target="_blank">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </Tooltip>
                    </li>
                </ul>
            </nav>
        </header>
<div className="account-created-section">
<div className="account-created-content" >
       
       <div className="header-container account-created-header" >
       <div className='icon'> <AccountCreatedIcon/></div>
             <div className='account-created-heading'>
             <h3>Your Account is Created </h3>
               <p>Our team will be in touch with you shortly</p>
             </div>
            <div className="return-login" style={{margin:'0.5rem 0 0'}}><Link  to='/login'>&larr;Return to Login</Link></div>    
           </div>
           
          
          
       </div>
</div>
       
    </div>
}
import { Outlet } from "react-router-dom";
import DashboardLayout from "./DashboardLayout";
import "../../style/components/dashboard/Dashboard.scss";

export default function Dashboard() {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.three-template .loadingTab:after {
  z-index: 1;
}
.three-template .loadingTab div {
  z-index: 2;
}

.header-cart-container {
  position: absolute;
  z-index: 3;
  right: 2.22vw;
  top: 2.22vw;
  display: flex;
}

.icon-wrapper {
  width: 3.47vw;
  height: 3.47vw;
  border-radius: 50%;
  background: radial-gradient(100% 100% at 0% 0%, rgba(53, 38, 38, 0.4) 0%, rgba(38, 26, 26, 0.1) 100%);
  box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.0196078431) inset;
  margin: 0 0.55vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.poweredby-wrapper {
  display: flex;
  position: absolute;
  justify-content: space-between;
  z-index: 3;
  align-items: center;
  width: 100%;
  left: 2.22vw;
  bottom: 2.22vw;
}

.poweredby-icon {
  position: relative;
  right: 4.44vw;
  top: 0.98vw;
}`, "",{"version":3,"sources":["webpack://./src/components/ThreeTemplates.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;AAEE;EACE,UAAA;AAAJ;;AAGA;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;AAAF;;AAEA;EACE,aAAA;EACA,cAAA;EACA,kBAAA;EACA,qGAAA;EACA,uEAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACF;;AACA;EACE,aAAA;EACA,kBAAA;EAEA,8BAAA;EACA,UAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EAEA,cAAA;AAAF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,WAAA;AACF","sourcesContent":[".three-template {\n  .loadingTab:after {\n    z-index: 1;\n  }\n  .loadingTab div {\n    z-index: 2;\n  }\n}\n.header-cart-container{\n  position: absolute;\n  z-index: 3;\n  right: 2.22vw;\n  top: 2.22vw;\n  display: flex;\n}\n.icon-wrapper{\n  width: 3.47vw;\n  height: 3.47vw;\n  border-radius: 50%;\n  background: radial-gradient(100% 100% at 0% 0%, rgba(53, 38, 38, 0.4) 0%, rgba(38, 26, 26, 0.1) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;\n  box-shadow: -5px -5px 250px 0px #FFFFFF05 inset;\n  margin: 0 0.55vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n.poweredby-wrapper{\n  display: flex;\n  position: absolute;\n  \n  justify-content: space-between;\n  z-index: 3;\n  align-items: center;\n  width: 100%;\n  left: 2.22vw;\n \n  bottom: 2.22vw;\n}\n.poweredby-icon{\n  position: relative;\n  right: 4.44vw;\n  top: 0.98vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { ReactComponent as AccountCreatedIcon } from "../../assets/svg/account-created.svg";
import Logo from "../../assets/images/white-logo.svg";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
export default function AccountCreated() {
  return (
    <div
      className="main-container clearfix"
      style={{
        background: "linear-gradient(74.69deg, #9747FF 0.33%, #EE3A24 97.52%)",
      }}
    >
      <header className="header-absolute2 sigupHeader">
        <nav>
          <ul>
            <li>
              <Tooltip title="Back to Website">
                <Link to="https://www.onewayx.com/" target="_blank">
                  <img src={Logo} alt="logo" />
                </Link>
              </Tooltip>
            </li>
          </ul>
        </nav>
      </header>
      <div className="account-created-section">
        <div className="account-created-content">
          <div className="header-container account-created-header">
            <div className="icon">
              {" "}
              <AccountCreatedIcon />
            </div>
            <div className="account-created-heading">
              <h3>Your Account is Created </h3>
              <p>Our team will be in touch with you shortly</p>
            </div>
            <div className="return-login" style={{ margin: "0.5rem 0 0" }}>
              <Link to="/login">&larr;Return to Login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import { useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import { ReactComponent as UploadIcon } from "../../assests/svg/upload-icon.svg";
import { ReactComponent as CloseIcon } from "../../assests/svg/closeIcon.svg";
import { ReactComponent as SearchIcon } from "../../assests/svg/carbon_search.svg";
import Spinner from ".././Spinner";
import "./AddProduct.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDropzone } from "react-dropzone";
import { ReactComponent as ArrowIcon } from "../../assests/svg/arrow.svg";
import { ReactComponent as DownArrowIcon } from "../../assests/svg/select-arrow.svg";
import ProductLimitExceed from "../ProductLimitExceed";
import DefaultImage from "../../assests/images/default.png";
function AddProducts() {
  const [selectedProduct, setSelectedProduct] = useState({
    id: "",
    selectedVariant: { title: "", id: "" },
    body_html: "",
  });
  const [loading, setLoading] = useState(false);
  const [productLoading, setproductLoading] = useState(false);
  const [productListLoading, setproductListLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [inputOnHover, setInputOnHover] = useState(false);
  const [shopifyProducts, setShopifyProducts] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [productsList, setProductLists] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showList, setShowList] = useState(true); // State to control list visibility
  const token = useSelector((state) => state.token);
  const [productCounter, setProductCounter] = useState(0);
  const [isFileRequired, setIsFileRequired] = useState(false);
  const [productLimitExceed, setProductLimitExceed] = useState(false);
  const [openDropDown,setDropDown] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();
  const [selectedModel, setSelectedModel] = useState(null);
  const [fileName, setFileName] = useState("");
  const [mp3File, setMp3File] = useState(null);
  const [mp3FileName, setMp3FileName] = useState("");
  const [subVal, setSubVal] = useState(0);
  const [productShopURL, setProductShopURL] = useState("");
  const [isMusicFileRequired, setIsMusicFileRequired] = useState(false)
  const [getDetails, setDetails] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!getDetails) {
      const getEnvDetails = async () => {
        setLoading(true);
        const info = await fetchData(
          ApiConfig.info,
          setLoading,
          "post",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (info.statusCode === 401 || info.statusCode === 500) {
          navigate("/login");
        } else if (info.data.statusCode === 200) {
          setSubVal(info.data.data.brandProfile.subscription_id);
          setProductShopURL(info.data.data.envDetail.product_url);
        }

        setLoading(false);
      };
      getEnvDetails();
    }
    setDetails(true);
    if (getDetails) {
      getProductList();
      getProducts();
    }
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [productShopURL]);

  const handleClickOutside = (event) => {
    const target = event.target;
    if (!target.closest(".filter-container")) {
      setInputOnHover(false);
    }
  };
  document.body.addEventListener("click", handleClickOutside);

  const handleDrop1 = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setSelectedModel(acceptedFiles[0]);
      setIsFileRequired(false);
      const file = acceptedFiles[0];
      var fileN = file.name;
      let lastIndex = fileN.lastIndexOf(".");
      let fileExtension = fileN.slice(lastIndex + 1);
      let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
      let newFile;
      if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
        newFile = shortenedName + "..." + fileExtension;
      } else {
        newFile = shortenedName + "." + fileExtension;
      }
      setFileName(newFile);
    }
  };

  const handleMp3Drop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setMp3File(acceptedFiles[0]);
      setIsMusicFileRequired(false)
      const file = acceptedFiles[0];
      var fileN = file.name;
      let lastIndex = fileN.lastIndexOf(".");
      let fileExtension = fileN.slice(lastIndex + 1);
      let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
      let newFile;
      if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
        newFile = shortenedName + "..." + fileExtension;
      } else {
        newFile = shortenedName + "." + fileExtension;
      }
      setMp3FileName(acceptedFiles[0].name);
    }
  };

  const dropzone1 = useDropzone({
    onDrop: handleDrop1,
    accept: {
      "model/gltf-binary": [".glb"],
    },
    multiple: false,
  });

  const mp3Dropzone = useDropzone({
    onDrop: handleMp3Drop,
    accept: {
      "audio/mpeg": [".mp3"],
    },
    multiple: false,
  });

  async function getProducts() {
    setproductLoading(true);
    const response = await fetchData(
      `${ApiConfig.storeProducts}?store_url=${productShopURL}`,
      setproductLoading,
      "get",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (response.status === 200) {
      setShopifyProducts(response.data.data);
    } else {
      navigate("/dashboard/select-store");
    }
    setproductLoading(false);
  }

  async function getProductList() {
    const response = await fetchData(
      `${ApiConfig.productList}?page=1&limit=100`,
      setLoading,
      "get",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (response.status === 401 || response.status === 500) {
      navigate("/login");
    }
    if (response.status === 200) {
      setProductLists(response.data.data);
      setProductCounter(response.data.data.length);
    }
    setLoading(false);
  }

  const handleTitleChange = (e) => {
    const selectedTitle = e.target.value;
    setShowList(true);
    setSearchText(selectedTitle);
    const selectedItem = shopifyProducts.find(
      (item) => item.title === selectedTitle
    );
    setSelectedProduct(
      selectedItem || {
        id: "",
        selectedVariant: { title: "", id: "" },
        body_html: "",
      }
    );
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!searchText.trim()) {
      setTitleError("Product title is required");
      return;
    }
    const selectedItem = shopifyProducts.find(
      (item) => item.title === searchText
    );
    if (!selectedItem) {
      setTitleError("Please select a valid product from the list");
      return;
    }
    if (!fileName) {
      setIsFileRequired(true);
      return;
    }
    if(!mp3FileName){
      setIsMusicFileRequired(true);
      return;
    }
    let value = subVal === 3 ? 20 : 10;
    if (productCounter < value) {
      setLoading(true);
      var temp = "";
      if (selectedProduct.images[0]) {
        temp = selectedProduct.images[0].src ?? DefaultImage;
      }
      let values = {
        product_title: selectedProduct.title,
        product_id: selectedProduct.id,
        product_description: selectedProduct.body_html ?  selectedProduct.body_html :  'NA',
        product_img: temp ? temp : 'https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/placeholder-image.png',
        product_glb_model: selectedModel,
        audio_url: mp3File,
      };
      
      const response = await fetchData(
        ApiConfig.addProduct,
        setLoading,
        "media",
        values,
        { authorization: `Bearer ${token}` }
      );
      if (response.status === 401 || response.status === 500) {
        navigate("/login");
      }
      if (response) {
        setSearchText("");
        setSelectedModel(null);
        setFileName("");
        setSelectedProduct({
          id: "",
          selectedVariant: { title: "", id: "" },
          body_html: "",
        });
        setMp3File(null);
        setMp3FileName('')
      }
      setLoading(false);
      if (selectedModel !== null && Object.keys(selectedModel).length !== 0) {
        getProductList();
      }
    } else {
      setProductLimitExceed(true);
    }
  }

  const filteredProducts = shopifyProducts.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  async function removeProduct(val) {
    setRemoveLoading(true);
    const response = await fetchData(
      `${ApiConfig.deleteProduct}?product_id=${val}`,
      setLoading,
      "delete",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (response.status === 401 || response.status === 500) {
      navigate("/login");
    }
    getProductList();
    setRemoveLoading(false);
  }

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 3 },
      { authorization: `Bearer ${token}` }
    );
    if (response.status === 401 || response.status === 500) {
      navigate("/login");
    }
    if (response.data.statusCode === 200) {
      // navigate("/customize-env");
      navigate("/customize-gabar-product");
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  if (loading) {
    return (
      <div className="spinner-loader">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="add-product-container">
        <div className="add-prod-heading">
          <h2>Add Products</h2>
        </div>
        <div className="new_product_data">
          <form onSubmit={handleSubmit}>
            <div className="new-product-container ">
              <div className="new-product-wrapper">
                <div className="new-product-wrapper" style={{ marginTop: "0" }}>
                  <label>
                    Product Title <span style={{ color: "#EE3A24" }}>*</span>
                  </label>
                  <div className="filter-container" style={{ margin: "0" }}>
                    <div style={{ position: "relative", margin: "0" }}>                      
                      {searchText.length > 0 &&
                        (selectedProduct.images &&
                        //   &&
                        // selectedProduct.images[0].src 
                        selectedProduct.images !== undefined ? (
                          <div className="image-container">
                            <img
                              src={selectedProduct.images[0]?.src ?? DefaultImage  }
                              alt="product_image"
                              style={{
                                width: "2.78vw",
                                height: "2.78vw",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ) : (
                          <div className="image-container">
                            <img
                              src={DefaultImage}
                              alt="product_image"
                              style={{
                                width: "2.78vw",
                                height: "2.78vw",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ))}
                      <span
                        className="search-icon"
                        style={{ opacity: searchText.length > 0 ? "0" : "1" }}
                      >
                        <SearchIcon />
                      </span>
                      <span className="down-arrow" onClick={() => {
                        setDropDown(prev => !prev);
                      }}>
                        <DownArrowIcon/>
                      </span>
                      <input
                        type="text"
                        className="productTitle"
                        value={searchText}
                        placeholder="Search"
                        onChange={handleTitleChange}
                        onClick={() => {setInputOnHover(true);
                          setDropDown(false);
                        }}
                        onFocus={() => setInputOnHover(true)}
                      />
                    </div>

                    {titleError && (
                      <label
                        className="error-message"
                        style={{ color: "#EE3A24", marginTop: ".2vw" }}
                      >
                        {titleError}
                      </label>
                    )}

                    {searchText.length === 0 &&
                      inputOnHover && !openDropDown &&
                      filteredProducts.length > 0 && (
                        <div className="productsList">
                          <ul>
                            {filteredProducts.map((item) => (
                              <li
                                key={item.id}
                                onClick={() => {                                  
                                  setSelectedProduct(item);                                  
                                  setSearchText(item.title);
                                  setShowList(false);
                                  setTitleError("");
                                }}
                              >
                                {item.title}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                    {openDropDown
                       &&
                       shopifyProducts.length > 0 && (
                        <div className="productsList">
                          <ul>
                            {shopifyProducts.map((item) => (
                              <li
                                key={item.id}
                                onClick={() => {
                                 
                                  setSelectedProduct(item);
                                  setSearchText(item.title);
                                  setShowList(false);
                                  setTitleError(""); // Clear error when a product is selected
                                  setDropDown(false);
                                }}
                              >
                                {item.title}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                    {searchText  && !openDropDown &&
                      inputOnHover &&
                      filteredProducts.length > 0 &&
                      showList && (
                        <div className="productsList">
                          <ul>
                            {filteredProducts.map((item) => (
                              <li
                                onClick={() => {
                                  setSelectedProduct(item);
                                  setSearchText(item.title);
                                  setShowList(false);
                                  setTitleError(""); // Clear error when a product is selected
                                }}
                                key={item.id}
                                value={item.id}
                              >
                                {item.title}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>
                </div>
                <div>
                  <label>Product ID </label>
                  <input
                    type="text"
                    value={selectedProduct.id || ""}
                    readOnly
                    placeholder="Select Product ID"
                  />
                </div>
              </div>

              <div className="product_desc">
                <label htmlFor="prod_description">Product Description</label>
                <textarea
                  id="prod_description"
                  name="prod_description"
                  rows="5"
                  cols="50"
                  placeholder="Write Product Description..."
                  defaultValue={selectedProduct.body_html && selectedProduct.body_html.replace(
                    /<[^>]*>/g,
                    ""
                  )}
                />
                <p className="min_char" style={{ right: "auto" }}>
                  Minimum 30 characters
                </p>
              </div>

              <div className="product-3d-model-container">
                <div>
                  <label>
                    Product 3D Model <span style={{ color: "#EE3A24" }}>*</span>
                  </label>
                  <div className="upload_3d_model">
                    <div
                      className={`custom-drag-box ${
                        isDragActive ? "active" : ""
                      }`}
                      {...dropzone1.getRootProps()}
                    >
                      <input {...dropzone1.getInputProps()} accept="image/*" />
                      <div className="custom-drag-box-title">
                        Upload 3D Model{" "}
                        <span>
                          <UploadIcon />
                        </span>
                      </div>
                      <div className="custom-drag-box-subTitle">
                        Upload GLB or GLTF file only. 50 MB max
                      </div>
                      {fileName && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="custom-drag-box-title input-box"
                        >
                          {fileName}
                        </div>
                      )}
                    </div>
                  </div>
                  {isFileRequired && (
                    <label
                      className="error-message"
                      style={{ color: "#EE3A24", marginTop: ".2vw" }}
                    >
                      A file is required to proceed.
                    </label>
                  )}
                </div>
              </div>
              <div>
              <div className="product-3d-model-container">
                <div>
                  <label>
                    Product Mp3 file <span style={{ color: "#EE3A24" }}>*</span>
                  </label>
                  <div className="upload_3d_model">
                    <div
                      className={`custom-drag-box ${
                        isDragActive ? "active" : ""
                      }`}
                      {...mp3Dropzone.getRootProps()}
                    >
                      <input {...mp3Dropzone.getInputProps()} accept="mp3/*" />
                      <div className="custom-drag-box-title">
                        Upload MP3{" "}
                        <span>
                          <UploadIcon />
                        </span>
                      </div>
                      <div className="custom-drag-box-subTitle">
                        Upload MP3. 50 MB max
                      </div>
                      {mp3FileName && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="custom-drag-box-title input-box"
                        >
                          {mp3FileName}
                        </div>
                      )}
                    </div>
                  </div>
                  {isMusicFileRequired && (
                    <label
                      className="error-message"
                      style={{ color: "#EE3A24", marginTop: ".2vw" }}
                    >
                      A file is required to proceed.
                    </label>
                  )}
                </div>
              </div>
              <div className="save-btn">
                <button className="button" type="submit">
                  Save & Next
                </button>
              </div>
              </div>
             
            </div>
          </form>

          <div className="slider-container">
            {removeLoading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner />
              </div>
            )}
            {!removeLoading && (
              <Slider {...settings}>
                {productsList.map((product, index) => (
                  <div className="productsImage" key={index}>
                    <div className="container">
                      <span onClick={() => removeProduct(product.product_id)}>
                        <CloseIcon />
                      </span>
                      <img
                        src={product.product_image ?? DefaultImage}
                        alt={product.product_owner_brand ?? "image"}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div className="merchandise-Store-button">
            <button type="button" onClick={() => updateStep()}>
              Merchandise Store{" "}
              <span>
                <ArrowIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
      {productLimitExceed && (
        <ProductLimitExceed
          productLimitExceed={productLimitExceed}
          setProductLimitExceed={setProductLimitExceed}
        />
      )}
    </>
  );
}

export default AddProducts;

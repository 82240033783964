import React, { Suspense, useEffect, useCallback, useRef } from "react";
import { useGLTF, useTexture, useVideoTexture } from "@react-three/drei";
import { Color, DoubleSide, SRGBColorSpace, Raycaster, Vector2 } from "three";
import ReactGA from "react-ga4";
import { RigidBody } from "@react-three/rapier";
import useUIStore from "../../utils2/uistore";
import { Info_Click } from "./Info_Click";
import useStore from "../../utils2/3dstore";
import { useFrame, useThree } from "@react-three/fiber";
import useAnalytics from "../../utils2/google-analytics/useGTAGTracker";
import { Product_Click } from "./Product_Click";

export function Building_Products(props) {
  const { camera } = useThree();
  const liquidColor = new Color("#927947");
  const newColor = new Color("#AAAAAB");
  const pointColor = new Color("#8EA992");
  const { nodes, materials } = useGLTF("/assets/models/building_product.glb");

  // console.log(camera.position)
  const {
    setHitPoint,
    setDoubleClick,
    isProductClick,
    setProductClick,
    isCameraPanning,
    setLcdCapsuleHit,
    isEntryAnimationDone,
    isRotateProducts,
    setRotateProducts,
  } = useStore();
  const floatBottleRef = useRef();
  const groundBottleRef = useRef();
  const bodyOilBottleRef = useRef();
  const faceOilBottleRef = useRef();
  const swimBottleRef = useRef();
  const {
    handleShowProductList,
    handleItems,
    getData,
    productList,
    handleCurrentItem,
    handleActiveImage,
  } = useUIStore();

  const { track3DCapsuleView } = useAnalytics();

  const actionMap = {
    floatBottleClick: {
      productClick: true,
      currentItem: productList[2],
    },
    swimBottleClick: {
      productClick: true,
      currentItem: productList[6],
    },
    bodyOilBottleClick: {
      productClick: true,
      currentItem: productList[0],
    },
    groundBottleClick: {
      productClick: true,
      currentItem: productList[4],
    },
    faceOilBottleClick: {
      productClick: true,
      currentItem: productList[1],
    },
    main_wall: {
      doubleClick: true,
    },
    lcd_capsule: {
      doubleClick: true,
      lcdCapsuleHit: true,
    },
    Lightbox_Long: {
      doubleClick: true,
    },
    Lightbox_Long001: {
      doubleClick: true,
    },
    Lightbox_Long002: {
      doubleClick: true,
    },
    Lightbox_Long003: {
      doubleClick: true,
    },
    Lightbox_Long004: {
      doubleClick: true,
    },
    Lightbox_Long005: {
      doubleClick: true,
    },
  };

  // const sendDropLocationToParent = useCallback(
  //   (location) => {

  //     if (props.onDropLocation) {
  //       props.onDropLocation(location);
  //     }
  //   },
  //   [props.onDropLocation] // Dependencies
  // );

  // useEffect(() => {
  //   const handleMouseMove = (e) => {
  //     mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
  //     mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

  //     raycaster.setFromCamera(mouse, camera);
  //     const intersects = raycaster.intersectObject(nodes.Floor, true);
  //     intersects.map((intersect) => (
  //       console.log(intersect)

  //     ))
  //     const abcIntersects = intersects.filter((intersect) =>
  //       (intersect.object.name.includes("product-drop") || intersect.object.material.name.includes("blue-seats") || intersect.object.material.name.includes("tables"))
  //     );
  //     // console.log(nodes);
  //     console.log(abcIntersects?'yes':'no');

  //     if (intersects.length > 0) {
  //       const point = intersects[intersects.length -1].point;
  //       // console.log(point);
  //       sendDropLocationToParent(point);
  //     }
  //   };

  //   // Add mouse move event listener
  //   window.addEventListener("dragend", handleMouseMove);

  //   return () => {
  //     window.removeEventListener("dragend", handleMouseMove);
  //   };
  // }, [camera, nodes.Floor]);

  const animateCamera = (e) => {
    e.stopPropagation();

    const action = actionMap[e.object.name];
    if (action) {
      setHitPoint(e.point);
      console.log(e.object.name);
      if (!isCameraPanning) {
        if (action.customAction) action.customAction();

        if (action.productClick) {
          setProductClick(true);
          track3DCapsuleView(action.currentItem.title);
          handleCurrentItem(action.currentItem);
          handleActiveImage(
            action.currentItem?.images?.map((el, index) => (
              <div className="zoomed-image" key={index}>
                <img
                  src={el}
                  className="main-image"
                  alt={`Zoomed Image ${index}`}
                />
              </div>
            ))
          );
        }

        if (action.doubleClick) setDoubleClick(true);

        if (action.lcdCapsuleHit) setLcdCapsuleHit(true);
      }
    }
  };

  useEffect(() => {
    if (isEntryAnimationDone)
      setTimeout(() => {
        setRotateProducts(true);
      }, 5000);
  }, [isEntryAnimationDone]);

  const GTAG3DCapsuleView = (item_name) => {
    // console.log("Product Capsule View")
    ReactGA.gtag("event", "Product Capsule View", {
      product_capsule_view: item_name,
    });
  };
  return (
    <>
      <RigidBody type="fixed" colliders="trimesh">
        <group {...props} dispose={null}>
          <mesh
            geometry={nodes.LCD_Capsule_Holder.geometry}
            material={materials.LCD_Holder_Material}
            position={[-15.122, 14.528, -9.585]}
          />

          {/* CHANGES -> Mesh003 */}
          <group
            position={[-15.039, 2.351, -4.902]}
            name="lcd_capsule"
            onClick={(e) => animateCamera(e)}
            onDoubleClick={(e) => animateCamera(e)}
          >
            <mesh
              geometry={nodes.Mesh011.geometry}
              material={materials.LCD_Capsule_Material}
            />
            <mesh
              geometry={nodes.Mesh011_1.geometry}
              material={materials.LCD_Capsule_Belt_Material}
            />
            <mesh
              geometry={nodes.Mesh011_2.geometry}
              material={materials.LCD_FrontTop_Material}
              position={[0, 1.58, 0]}
              scale={[1, 0.75, 1]}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_2_Top.jpg" />
                }
              >
                <VideoMaterial
                  url={
                    props.videoUrl ||
                    "/assets/videos/Metaverse_2 _Top_Optimised_1.mp4"
                  }
                />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_3.geometry}
              material={materials.LCD_FrontTop_Material}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_1_Bottom.jpg" />
                }
              >
                <VideoMaterial
                  url={
                    props.videoUrl ||
                    "/assets/videos/Metaverse_2 _Top_Optimised_1.mp4"
                  }
                />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_4.geometry}
              material={materials.LCD_FrontTop_Material}
              position={[0, 1.58, 0]}
              scale={[1, 0.75, 1]}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_2_Top.jpg" />
                }
              >
                <VideoMaterial
                  url={
                    props.videoUrl ||
                    "/assets/videos/Metaverse_2 _Top_Optimised_1.mp4"
                  }
                />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_5.geometry}
              material={materials.LCD_FrontTop_Material}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url="/assets/videos/metaverse_1_Bottom.jpg" />
                }
              >
                {props.videoUrl !== null ? (
                  <VideoMaterial
                    url={
                      props.videoUrl ||
                      "/assets/videos/Metaverse_2 _Top_Optimised_1.mp4"
                    }
                  />
                ) : (
                  <VideoMaterial url="/assets/videos/Metaverse_2 _Top_Optimised_1.mp4" />
                )}
              </Suspense>
            </mesh>
          </group>
          {/* CHANGES -> The building and floor were separated into 2 meshes, Should be joined */}
          <mesh
            name="main_wall"
            onDoubleClick={(e) => animateCamera(e)}
            geometry={nodes.Floor.geometry}
            material={materials.Metallic_Silver_Material}
            position={[-14.04, -0.001, -4.603]}
            material-roughness={0.35}
            material-metalness={0.75}
            material-envMapIntensity={1.5}
            material-color={newColor}
          />

          <group>
            <mesh
              name="Lightbox_Long"
              geometry={nodes.Lightbox_Long.geometry}
              materials={materials.Lighbox_Material}
              position={[-1.471, 0, 7.527]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long001"
              geometry={nodes.Lightbox_Long001.geometry}
              materials={materials.Lighbox_Material}
              position={[-31.52, 0, -10.701]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long002"
              geometry={nodes.Lightbox_Long002.geometry}
              materials={materials.Lighbox_Material}
              position={[-24.197, 0, -19.356]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long003"
              geometry={nodes.Lightbox_Long003.geometry}
              materials={materials.Lighbox_Material}
              position={[0.765, 0, -13.701]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long004"
              geometry={nodes.Lightbox_Long004.geometry}
              materials={materials.Lighbox_Material}
              position={[1.692, 0, -1.246]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long005"
              geometry={nodes.Lightbox_Long005.geometry}
              materials={materials.Lighbox_Material}
              position={[-22.757, 0, 8.039]}
              onDoubleClick={(e) => animateCamera(e)}
            />
          </group>
        </group>
      </RigidBody>
      <group dispose={null}>
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[1.692, 0.532, -1.246]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[0.765, 0.532, -13.701]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-24.197, 0.54, -19.356]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-31.52, 0.54, -10.701]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-1.471, 0.54, 7.527]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-22.757, 0.54, 8.039]}
        />
      </group>

      <mesh
        geometry={nodes.Walkway_Path.geometry}
        material={materials.Walkway_Material}
        position={[-14.763, 0.288, -4.851]}
        material-roughness={0.1}
        material-metalness={1}
        material-envMapIntensity={1}
      />
    </>
  );
}

function VideoMaterial({ url }) {
  const texture = useVideoTexture(url);
  texture.flipY = false;
  texture.colorSpace = SRGBColorSpace;
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url);
  texture.flipY = false;
  texture.colorSpace = SRGBColorSpace;
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

useGLTF.preload("/assets/models/building_product.glb");

import React, { useEffect, useState } from "react";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./CustomProduct.scss";
import CustomThreeScene from "../components/addProducts/CustomThreeScene";
import Spinner from "../components/Spinner";

const CustomizeEnv = () => {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [envData, setEnvData] = useState({});
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  useEffect(() => {
    const getEnvDetail = async () => {
      setLoading(true);
      setLoading2(true);
      let envlist = window.location.href.split("/");
      let currentEnv = envlist[envlist.length - 1];
      try {
        const [selectedEnv, envResponse] = await Promise.all([
          fetchData(
            `${ApiConfig.environmentTemplate}?page=1&limit=10&env_template_id=${currentEnv}`,
            setLoading,
            "get",
            {},
            { authorization: `Bearer ${token}` }
          ),
          fetchData(
            `${ApiConfig.env}`,
            setLoading,
            "post",
            {},
            { authorization: `Bearer ${token}` }
          ),
        ]);     

        if (envResponse.status === 200 && selectedEnv.status === 200) {          
          const data = envResponse.data.data;
          const data2 = selectedEnv.data.data[0];
          const color =
            data.selected_colour !== null
              ? getColor(data.selected_colour)
              : getColor(data2.env_default_colour);
          setEnvData({
            logo: data.logo_image_url,
            campaign: data.campaign_link_url,
            campaignType: data.campaign_type,
            light: data.selected_lighting ?? data2.env_default_lighting,
            color: color,
            texture: data.selected_texture ?? data2.env_default_texture,
            backgroundEnv: data2.env_thumbnail_url,
            env: data2.env_buildfile_url,
            templateNumber: currentEnv,
            sameEnv: parseInt(data.env_template_id) === parseInt(currentEnv),
            productData: data.env_product_mappings,
            hide: false,
          });
        } else if (envResponse.statusCode === 401 || envResponse.statusCode === 500) {
          navigate("../login");
        } else {
          console.log(envResponse.status);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }

      setLoading(false);
      setLoading2(false);
    };

    getEnvDetail();
  }, [token, navigate]);

  const getColor = (selectedColour) => {
    switch (selectedColour) {
      case "White":
        return "#FFFFFF";
      case "Cappuccino":
        return "#A59385";
      case "Red":
        return "#FF0000";
      case "Gold":
        return "#FFD700";
      case "Rose Pink":
        return "#F5D7D5";
      default:
        return "";
    }
  };

  return (
    <div className="customProduct">
      {!loading2  ? (
        <CustomThreeScene envData={envData} />           
      ) : (
        <div
          style={{
            width: "100",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CustomizeEnv;

import React, { useEffect, useState } from "react";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThreeCust from "../components/ThreeCust";
import Header from "../components/Header";
import lightIcon from "../assests/images/light_error.png";
import "./CustomProduct.scss";

function CustomProduct2() {
  const [loading, setLoading] = useState(true);
  const [envData, setEnvData] = useState({});
  const [modelPaths, setModelPaths] = useState([]);
  const [productData, setProductData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);


  useEffect(() => {
      setLoading(true);




            setEnvData({
              logo: null,
              campaign: null,
              campaignType: null,
              light: "Warm",
              color: "",
              texture: "default",
              backgroundEnv:
                "https://conceptstorebucket.s3.amazonaws.com/appzlogic/sandbox-environment/thumbnail/Futuristic+Environment+-+Thumbnail+Image.png",
              env: "https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/metaverse_office.glb",
              templateNumber: 400,
              envProductMappings: [
                {
                  created_at: "2024-08-16 07:02:52",
                  updated_at: "2024-08-16 07:02:52",
                  product_mapping_id: 766,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 516,
                  product_uid: 9238496641371,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: -0.4802524599543716,
                  y_coordinate: 0.14418727597474496,
                  z_coordinate: 1.092810253605796,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-16 07:02:37",
                      updated_at: "2024-08-16 07:02:37",
                      product_id: 516,
                      brand_id: 187,
                      product_uid: 9238496641371,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model4.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-hergafi-8502482.jpg?v=1717408426",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Sporty and Stylish",
                      product_description:
                        "<p>Designed for the active individual, this bag is both functional and fashionable. Its durable, lightweight material, ample storage space, and convenient exterior pockets make it perfect for gym trips, outdoor adventures, or casual outings. Stay organized and stylish on the move.</p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:29:59",
                  updated_at: "2024-08-19 18:29:59",
                  product_mapping_id: 771,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 521,
                  product_uid: 9313778762075,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: 0.04347243041964999,
                  y_coordinate: 0.16322276459578058,
                  z_coordinate: 0.8132767067535347,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:26:49",
                      updated_at: "2024-08-19 18:26:49",
                      product_id: 521,
                      brand_id: 187,
                      product_uid: 9313778762075,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model1.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-murilo-papini-2682930-6215974.jpg?v=1720015997",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Watch",
                      product_description: "<p>New watch</p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:30:02",
                  updated_at: "2024-08-19 18:30:02",
                  product_mapping_id: 772,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 522,
                  product_uid: 9259473502555,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: 0.04347243041964999,
                  y_coordinate: 0.16322276459578058,
                  z_coordinate: 0.8132767067535347,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:27:36",
                      updated_at: "2024-08-19 18:27:36",
                      product_id: 522,
                      brand_id: 187,
                      product_uid: 9259473502555,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/gabar1.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/Screenshot2024-06-10131659.png?v=1718005772",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Gold & Black",
                      product_description:
                        "<p>High-heeled shoes in black and gold exude opulence and sophistication. The black provides a sleek, versatile base, while gold accents add a touch of luxury and glamour. Perfect for evening events or upscale gatherings, these shoes make a bold statement, enhancing any outfit with their striking contrast and elegant design, ensuring the wearer stands out with confidence and style.</p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:30:12",
                  updated_at: "2024-08-19 18:30:12",
                  product_mapping_id: 773,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 523,
                  product_uid: 9259313758555,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: 0.061006051407815134,
                  y_coordinate: 0.16616297572014763,
                  z_coordinate: 0.737292034075968,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:27:51",
                      updated_at: "2024-08-19 18:27:51",
                      product_id: 523,
                      brand_id: 187,
                      product_uid: 9259313758555,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model3.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/Screenshot2024-06-10115437.png?v=1718000759",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Gladiator Heels",
                      product_description:
                        "<p>High-heeled gladiators in black are a bold fashion statement, merging edgy style with elegance. Their strappy design and towering heels create a dramatic look, perfect for nights out or chic daytime wear. The black color adds versatility, easily pairing with various outfits, while the gladiator style infuses a touch of historical flair and modern sophistication.</p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:30:23",
                  updated_at: "2024-08-19 18:30:23",
                  product_mapping_id: 774,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 524,
                  product_uid: 9238492578139,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: 0.122747878182098,
                  y_coordinate: 0.13229121474371552,
                  z_coordinate: 0.5339153935622327,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:28:04",
                      updated_at: "2024-08-19 18:28:04",
                      product_id: 524,
                      brand_id: 187,
                      product_uid: 9238492578139,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model5.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-geyonk-1152077_88930559-3c82-453b-8ce7-29f700d0a427.jpg?v=1717408341",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Travel Companion:",
                      product_description:
                        "<p>Ideal for travelers, this bag is designed to keep your belongings secure and organized. It features a robust, water-resistant exterior, lockable zippers, and multiple compartments for easy access to your essentials. Travel in style and comfort with this reliable bag.</p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:30:29",
                  updated_at: "2024-08-19 18:30:29",
                  product_mapping_id: 775,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 525,
                  product_uid: 9238484615515,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: -0.14054383218183464,
                  y_coordinate: 0.1243255658744659,
                  z_coordinate: 0.02004144222702898,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:28:18",
                      updated_at: "2024-08-19 18:28:18",
                      product_id: 525,
                      brand_id: 187,
                      product_uid: 9238484615515,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model6.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-malaydi-9047344.jpg?v=1717408112",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Professional Chic",
                      product_description:
                        "<p>Elevate your business attire with this sophisticated bag. Designed for the modern professional, it features a padded laptop compartment, pen holders, and ample space for documents. The classic silhouette and polished finish make it a perfect addition to your work wardrobe.</p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:30:32",
                  updated_at: "2024-08-19 18:30:32",
                  product_mapping_id: 776,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 526,
                  product_uid: 9096773534043,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: -0.10863178788254858,
                  y_coordinate: 0.12247898112306922,
                  z_coordinate: 1.006058099161124,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:28:37",
                      updated_at: "2024-08-19 18:28:37",
                      product_id: 526,
                      brand_id: 187,
                      product_uid: 9096773534043,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model7.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-photo.jpg?v=1712577216",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Shoes",
                      product_description: "New Shoes",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:30:52",
                  updated_at: "2024-08-19 18:30:52",
                  product_mapping_id: 777,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 529,
                  product_uid: 9238471213403,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: 0.5941464985150646,
                  y_coordinate: 0.2609590959691579,
                  z_coordinate: 0.1323222744593757,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:29:27",
                      updated_at: "2024-08-19 18:29:27",
                      product_id: 529,
                      brand_id: 187,
                      product_uid: 9238471213403,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model4.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-godisable-jacob-226636-934673.jpg?v=1717407693",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Functional Fashion",
                      product_description:
                        "<p><span>Our bag is crafted from high-quality, durable materials, ensuring it withstands daily wear and tear. The elegant design includes multiple pockets for organized storage, a padded shoulder strap for comfort, and stylish hardware accents, making it both practical and chic.</span></p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:30:55",
                  updated_at: "2024-08-19 18:30:55",
                  product_mapping_id: 778,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 528,
                  product_uid: 9238464790875,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: 0.6026497422299631,
                  y_coordinate: 0.2609590959691579,
                  z_coordinate: -0.029362465550042925,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:29:12",
                      updated_at: "2024-08-19 18:29:12",
                      product_id: 528,
                      brand_id: 187,
                      product_uid: 9238464790875,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model5.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-punchbrandstock-5462562.jpg?v=1717407041",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Classic Elegance",
                      product_description:
                        "<p>This sleek leather bag combines timeless design with modern functionality. Perfect for daily use, it features spacious compartments, secure zippers, and a comfortable strap. Its versatile style complements any outfit, making it a must-have accessory for both casual and professional settings.</p>\r\n<!---->",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
                {
                  created_at: "2024-08-19 18:31:11",
                  updated_at: "2024-08-19 18:31:11",
                  product_mapping_id: 779,
                  brand_id: 187,
                  env_selection_id: 59,
                  product_id: 527,
                  product_uid: 9099322294619,
                  product_url: "https://389b44-ac.myshopify.com",
                  mapping_status: 1,
                  x_coordinate: -0.4860818089885162,
                  y_coordinate: 0.26099723796069696,
                  z_coordinate: 0.16521562634211262,
                  brand_product_masters: [
                    {
                      created_at: "2024-08-19 18:28:54",
                      updated_at: "2024-08-19 18:28:54",
                      product_id: 527,
                      brand_id: 187,
                      product_uid: 9099322294619,
                      product_3D_model_url:
                        "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model7.glb",
                      product_image:
                        "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-pnw-production-8490222.jpg?v=1712644256",
                      product_url: "https://389b44-ac.myshopify.com",
                      name: "Serum A",
                      product_description: "New Serum",
                      in_metaverse: null,
                      product_owner_brand: "Shopify",
                    },
                  ],
                },
              ],
            });
          
       

       
         const TempData = [
            {
                "created_at": "2024-08-16 07:02:37",
                "updated_at": "2024-08-16 07:02:37",
                "product_id": 516,
                "brand_id": 187,
                "product_uid": 9238496641371,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model4.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-hergafi-8502482.jpg?v=1717408426",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Sporty and Stylish",
                "product_description": "<p>Designed for the active individual, this bag is both functional and fashionable. Its durable, lightweight material, ample storage space, and convenient exterior pockets make it perfect for gym trips, outdoor adventures, or casual outings. Stay organized and stylish on the move.</p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:26:49",
                "updated_at": "2024-08-19 18:26:49",
                "product_id": 521,
                "brand_id": 187,
                "product_uid": 9313778762075,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model1.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-murilo-papini-2682930-6215974.jpg?v=1720015997",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Watch",
                "product_description": "<p>New watch</p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:27:36",
                "updated_at": "2024-08-19 18:27:36",
                "product_id": 522,
                "brand_id": 187,
                "product_uid": 9259473502555,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/gabar1.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/Screenshot2024-06-10131659.png?v=1718005772",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Gold & Black",
                "product_description": "<p>High-heeled shoes in black and gold exude opulence and sophistication. The black provides a sleek, versatile base, while gold accents add a touch of luxury and glamour. Perfect for evening events or upscale gatherings, these shoes make a bold statement, enhancing any outfit with their striking contrast and elegant design, ensuring the wearer stands out with confidence and style.</p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:27:51",
                "updated_at": "2024-08-19 18:27:51",
                "product_id": 523,
                "brand_id": 187,
                "product_uid": 9259313758555,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model3.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/Screenshot2024-06-10115437.png?v=1718000759",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Gladiator Heels",
                "product_description": "<p>High-heeled gladiators in black are a bold fashion statement, merging edgy style with elegance. Their strappy design and towering heels create a dramatic look, perfect for nights out or chic daytime wear. The black color adds versatility, easily pairing with various outfits, while the gladiator style infuses a touch of historical flair and modern sophistication.</p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:28:04",
                "updated_at": "2024-08-19 18:28:04",
                "product_id": 524,
                "brand_id": 187,
                "product_uid": 9238492578139,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model5.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-geyonk-1152077_88930559-3c82-453b-8ce7-29f700d0a427.jpg?v=1717408341",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Travel Companion:",
                "product_description": "<p>Ideal for travelers, this bag is designed to keep your belongings secure and organized. It features a robust, water-resistant exterior, lockable zippers, and multiple compartments for easy access to your essentials. Travel in style and comfort with this reliable bag.</p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:28:18",
                "updated_at": "2024-08-19 18:28:18",
                "product_id": 525,
                "brand_id": 187,
                "product_uid": 9238484615515,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model6.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-malaydi-9047344.jpg?v=1717408112",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Professional Chic",
                "product_description": "<p>Elevate your business attire with this sophisticated bag. Designed for the modern professional, it features a padded laptop compartment, pen holders, and ample space for documents. The classic silhouette and polished finish make it a perfect addition to your work wardrobe.</p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:28:37",
                "updated_at": "2024-08-19 18:28:37",
                "product_id": 526,
                "brand_id": 187,
                "product_uid": 9096773534043,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model7.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-photo.jpg?v=1712577216",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Shoes",
                "product_description": "New Shoes",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:28:54",
                "updated_at": "2024-08-19 18:28:54",
                "product_id": 527,
                "brand_id": 187,
                "product_uid": 9099322294619,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model7.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-pnw-production-8490222.jpg?v=1712644256",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Serum A",
                "product_description": "New Serum",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:29:12",
                "updated_at": "2024-08-19 18:29:12",
                "product_id": 528,
                "brand_id": 187,
                "product_uid": 9238464790875,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model5.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-punchbrandstock-5462562.jpg?v=1717407041",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Classic Elegance",
                "product_description": "<p>This sleek leather bag combines timeless design with modern functionality. Perfect for daily use, it features spacious compartments, secure zippers, and a comfortable strap. Its versatile style complements any outfit, making it a must-have accessory for both casual and professional settings.</p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            },
            {
                "created_at": "2024-08-19 18:29:27",
                "updated_at": "2024-08-19 18:29:27",
                "product_id": 529,
                "brand_id": 187,
                "product_uid": 9238471213403,
                "product_3D_model_url": "https://conceptstorebucket.s3.amazonaws.com/appzlogic/brands/187/product/model4.glb",
                "product_image": "https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-godisable-jacob-226636-934673.jpg?v=1717407693",
                "product_url": "https://389b44-ac.myshopify.com",
                "name": "Functional Fashion",
                "product_description": "<p><span>Our bag is crafted from high-quality, durable materials, ensuring it withstands daily wear and tear. The elegant design includes multiple pockets for organized storage, a padded shoulder strap for comfort, and stylish hardware accents, making it both practical and chic.</span></p>\r\n<!---->",
                "in_metaverse": null,
                "product_owner_brand": "Shopify"
            }
        ];
          setProductData(TempData);
          const paths = TempData.map(
            (product) => product.product_3D_model_url
          );
          setModelPaths(paths);
        

      setLoading(false);
   
  }, []);



  return (
    <div className="customProduct">
      {!loading &&
          <ThreeCust
            envData={envData}
            modelPath={modelPaths}
            productData={productData}
          />
        }
    </div>
  );
}

export default CustomProduct2;

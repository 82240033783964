import { RigidBody } from "@react-three/rapier";
import { DoubleSide } from "three";
import { degToRad } from "three/src/math/MathUtils.js";

export const EntryCollider = () => {
  // console.log("RERENDERED COLLIDER")
  const animateCamera = (e) => {
    e.stopPropagation();
    if (e.object.name === "entry_wall") {
      // console.log("Entry Wall HIT");
    }
  };

  return (
    <RigidBody type="fixed" colliders="trimesh">
      <mesh
        name="entry_wall"
        onClick={(e) => animateCamera(e)}
        onDoubleClick={(e) => animateCamera(e)}
        receiveShadow
        position={[-13.082, 10, -2.2]}
        rotation={[degToRad(0.66), degToRad(-22), degToRad(-1.02)]}
      >
        <cylinderGeometry args={[22, 22, 20, 32, 1, true, 0, 1]} />
        <meshBasicMaterial side={DoubleSide} transparent opacity={0} />

        {/* <planeGeometry args={[20, 20]} /> */}
        {/* <meshBasicMaterial side={DoubleSide} transparent opacity={0} /> */}
      </mesh>
      {/* <mesh
        name="entry_wall"
        onClick={(e) => animateCamera(e)}
        onDoubleClick={(e) => animateCamera(e)}
        receiveShadow
        position={[-21, 10, 22]}
        rotation={[0, degToRad(100), 0]}
      >
        <planeGeometry args={[5, 20]} />
        <meshBasicMaterial side={DoubleSide} transparent opacity={0} />
      </mesh>
      <mesh
        name="entry_wall"
        onClick={(e) => animateCamera(e)}
        onDoubleClick={(e) => animateCamera(e)}
        receiveShadow
        position={[-1.5, 10, 20]}
        rotation={[0, degToRad(270), 0]}
      >
        <planeGeometry args={[5, 20]} />
        <meshBasicMaterial side={DoubleSide} transparent opacity={0} />
      </mesh> */}
    </RigidBody>
  );
};

import {create} from "zustand";

const useVideoStore = create ((set) =>({
    videoFileData: null,
    audioFileData: null,

    handleVideoFileData: (val) => set((state) => ({ videoFileData : val })),
    handleAudioFileData: (val) => set((state) => ({ audioFileData : val })),
}))

export default useVideoStore;
import { Dialog, IconButton, Tooltip } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/close-dialog-icon.svg";
import ReactConfetti from "react-confetti";
import { useState, useEffect } from "react";

const CongratulationDialog = ({
  handleCloseDialog,
  openDialog,
  setOpenDialog,
}) => {
  const [startConfetti, setConfetti] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfetti(true);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  
  return (
    <>
      {openDialog && (
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          // onClose={handleCloseDialog}
          open={openDialog}
          className="common-dialog-container congratulation-dialog-container"
        >
          <ReactConfetti
            width={window.innerWidth}
            height={window.innerHeight}
            colors={["#e4c961"]}
            recycle={!startConfetti}
            tweenDuration={1000}
            gravity={.2}
          />
          <form className="congratulation-dialog">
            <IconButton
              className="close-dialog-icon-button"
              onClick={() => setOpenDialog(false)}
            >
              <Tooltip title="Close Dialog">
                <CloseIcon className="close-dialog-icon" />
              </Tooltip>
            </IconButton>
            <div className="dialog-title">Congratulations!</div>
            <div className="dialog-subTitle congratulation-subTitle">
              You’ve successfully finished customising the virtual store.  Are
              you ready to create your brand’s virtual store?
            </div>
            <div className="dialog-submit-container congratulation-sumit-container">
              <button
                onClick={() => {
                  setOpenDialog(false);
                  handleCloseDialog(true);
                }}
                className="dialog-input-submit"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setOpenDialog(false);
                  handleCloseDialog(false);
                }}
                className="dialog-input-submit"
              >
                No
              </button>
            </div>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default CongratulationDialog;

import React from 'react';
import increment from '../../assests/icons/increase.png';
import decrement from '../../assests/svg/decrease-arrow.svg';
import './CounterBox.scss';

function CounterBox(props) {
  return (
    <div className='data-counter'>
      <h6>{props.title}</h6>
      <div>
        <p className='counter'>{props.counter ? props.counter : '0'}</p>
        {props.counter!== '' && <p className={`percent ${props.inc? 'increment' : 'decrement'} `}>{props.percent}<img  src={props.inc? increment : decrement} alt='image-data'/></p>}
      </div>      
    </div>
  )
}

export default CounterBox
import { useState } from 'react';
import { ReactComponent as LockIcon } from '../../assests/svg/Lock.svg'
import { ReactComponent as CheckIcon } from '../../assests/svg/check-circle.svg'
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Header from '../Header';
import { hasSpecialCharacter } from '../../utils/validation';
export default function CreatePassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return <>
        <Header variation={1}></Header>
        <div className="create-password-container">
            <div className="header-container">
                <LockIcon></LockIcon>
                <h1>Set password</h1>
            </div>
            <div className='main-section'>
                <TextField
                    label="Password*"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Confirm Password*"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleConfirmPasswordVisibility}>
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="validation-container"> <span> <CheckIcon className={`check-circle ${password.length >= 8 ? 'checked' : ''}`} ></CheckIcon></span> <span>minimum 8 characters</span></div>
            <div className="validation-container"> <span> <CheckIcon className={`check-circle ${hasSpecialCharacter(password) ? 'checked' : ''}`} ></CheckIcon></span> <span>at least one special character</span></div>
            <div className="CTA-section">
                <button className="button" disabled={!hasSpecialCharacter(password)|| !password.length >= 8}>Proceed</button>
            </div>
        </div>
    </>
}
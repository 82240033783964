import { Navigate } from "react-router-dom";
import PopupForMobile from "./popup/PopupForMobile";
import useAuthStore from "../store/authStore";

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuthStore();

  if (!isAuthenticated) {
    return <Navigate to="/"></Navigate>;
  }
  else  if (window.screen.width < 769) {
    return <PopupForMobile />;
  }
  return <>{children}</>;
}

export const useRandomQuestions = () => {
  const questionsArray = [
    {
      question:
        "Walk slowly around the space. How many scent bubbles do you see?",
      options: {
        option_1: "7",
        option_2: "9",
        option_3: "8",
      },
      correctAnswer: "8",
    },
    {
      question:
        "Stand on the edge of the store and look outside. Is the moon a Full, New or Half Moon?",
      options: {
        option_1: "Full Moon",
        option_2: "New Moon",
        option_3: "Half Moon",
      },
      correctAnswer: "Full Moon",
    },
    {
      question: "Are the products rotating the same way? Check each one.",
      options: {
        option_1: "No",
        option_2: "Yes",
      },
      correctAnswer: "Yes",
    },
    {
      question:
        "Look to the center. What fragrance is featured in the top video?",
      options: {
        option_1: "02 Ground",
        option_2: "03 Swim",
        option_3: "01 Float",
      },
      correctAnswer: "01 Float",
    },
    {
      question:
        "Look to the center. What fragrance is featured in the bottom video?",
      options: {
        option_1: "01 Float",
        option_2: "03 Swim",
        option_3: "02 Ground",
      },
      correctAnswer: "03 Swim",
    },
    {
      question:
        "Get close to the rotating products. What color is the glow of the spotlight?",
      options: {
        option_1: "Blue",
        option_2: "Yellow",
        option_3: "Green",
      },
      correctAnswer: "Green",
    },
    {
      question: "Find the skylight. What shape is it?",
      options: {
        option_1: "Circle",
        option_2: "Square",
        option_3: "Triangular",
      },
      correctAnswer: "Circle",
    },
    {
      question: "At the end of the sound bath, what is the last sound?",
      options: {
        option_1: "The sound of the wind",
        option_2: "The sound of a gong",
        option_3: "The sound of water",
      },
      correctAnswer: "The sound of water",
    },
    {
      question:
        "Follow the black pathway around the room. Is the pathway symmetrical (the same on both sides)?",
      options: {
        option_1: "Yes",
        option_2: "No",
      },
      correctAnswer: "No",
    },
  ];

  const boardProperties = [
    {
      position: [-23, 0.5, 1.438],
      rotation: [0, 0.2, 0],
    },
    {
      position: [-0.5, 0.5, 0],
      rotation: [0, -0.9, 0],
    },
    {
      position: [-11, 0.5, -21],
      rotation: [0, -0.45, 0],
    },
  ];

  // Shuffle the questions array
  const shuffledQuestions = questionsArray.sort(() => Math.random() - 0.5);

  // Select the first 3 shuffled questions
  const selectedQuestions = shuffledQuestions.slice(4, 7);

  // Transform each selected question to the separate structure
  const transformedQuestions = selectedQuestions.map((question, index) => {
    return {
      question: question.question,
      options: question.options || {
        option_1: question.possibleAnswers[0],
        option_2: question.possibleAnswers[1],
        option_3: question.possibleAnswers[2],
      },
      correctAnswer: question.correctAnswer,
      rotation: boardProperties[index].rotation,
      position: boardProperties[index].position,
    };
  });
  return transformedQuestions;
};

/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./product.css";
import "./backdrop.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import useUIStore from "../../../utils2/uistore";
import Backdrop from "../Fade Backdrop";
import ReactLoading from "react-loading";
import ReactGA from "react-ga4";
import {
  isMobile,
  isTablet,
  isAndroid,
  isIOS,
  isMobileOnly,
  isDesktop,
  OsTypes,
} from "react-device-detect";
import useAnalytics from "../../../utils2/google-analytics/useGTAGTracker";
const Product = ({ getCartItem }) => {
  const divRef = useRef();
  useEffect(() => {}, []);
  const {
    currentProduct,
    setCurrentProduct,
    activeImage,
    setActiveImage,
    showSubParentContainer,
    setShowSubParentContainer,
    currentItem,
    showItems,
    handleItems,
    handleShowCart,
    setCartChecked,
    setCartLoaded,
    activeImages,
    setCartOpened,
    showActiveImage,
    setShowActiveImage,
    activeImageIndex,
    setActiveImageIndex,
    productList
    // counter,setCounter,
  } = useUIStore((state) => state);
  const { trackAddToCart } = useAnalytics();
  const [counter, setCounter] = useState(1);

  const handleOnChange = (val) => {
    if (val) {
      if (counter < currentProduct?.inventory_quantity)
        setCounter((e) => e + 1);
    } else {
      if (counter > 1) {
        setCounter((e) => e - 1);
      } else {
        setCounter(1);
      }
    }
  };

  const numReg = /^[0-9]*$/;
  const handleChange = (e) => {
    if (numReg.test(e.target.value)) {
      if (!e.target.value) {
        setCounter(0);
        return;
      }
      if (e.target.value >= 0) {
        if (e.target.value <= currentProduct?.inventory_quantity) {
          setCounter(parseInt(e.target.value));
        }
      }
    }
  };

  const handleIncreaseCount = () => {
    //increase(counter);
  };
  useEffect(() => {
    setCurrentProduct({ ...currentProduct, count: counter });
  }, [counter]);
  useEffect(() => {
    // console.log(currentProduct);
    setCurrentProduct({ ...currentItem });
    if (currentItem?.images) {
      setActiveImage(currentItem?.images?.[0]);
    }

    // console.log(currentProduct)
  }, [currentItem]);

  useEffect(() => {
    // console.log("currentItem", currentItem);
    // console.log("currentProduct", currentProduct);
    // console.log(activeImages);
    // console.log(currentProduct.title);
  }, []);
  const handleActiveImage = (el, index) => {
    setActiveImage(el);
    setActiveImageIndex(index);
  };
  const handleLocalItemsInCart = async () => {
    setCartLoaded(false);
    setCartChecked(false);
    // console.log("CLICKED");
    try {
      let checkoutId = localStorage.getItem("checkoutId");
      const response = await axios.post(
        "https://gabar-prod.onewayx.world/add-to-cart",
        {
          variantId: currentProduct.variant_id,
          quantity: counter,
          checkoutId: checkoutId,
        }
      );
      // console.log(response);
      if (!checkoutId) {
        checkoutId = response.data.checkout.id;
        localStorage.setItem("checkoutId", checkoutId);
        localStorage.setItem("webUrl", response.data.checkout.webUrl);
      }
      handleItems(false);
      handleShowCart(true);
      // getCartItem();
      setCounter(1);
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  const formCloseIconClassName = `form-close-icon ${
    showItems ? "show_products" : "noshow_products"
  }`;

  const itemsMainContainerClassName = `item-main-container no-text-select ${
    showItems && showActiveImage ? "show_products" : "noshow_products"
  }`;

  const itemDescriptionClassName = `item-description-containss ${
    showItems && showActiveImage ? "show_products" : "noshow_products"
  }`;
  const titleClassName = `title-bar ${
    showItems && showActiveImage ? "show_products" : "noshow_products"
  }`;

  const priceContainerClassName = `price-container-cart ${
    showItems && showActiveImage ? "show_products" : "noshow_products"
  }`;

  const bottomBtnContainerClassName = ` bottom-main-container no-text-select ${
    showItems && showActiveImage ? "show_products" : "noshow_products"
  }`;

  const parentContainerClassName = `product-main-container_0 ${
    showItems ? "show_products" : "noshow_products"
  }`;
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  }, [showItems]);
  return (
    <>
      <div
        className={parentContainerClassName}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* <div
          className={parentContainerClassName}
          onClick={(e) => {
            e.stopPropagation();
          }}
        > */}
        <>
          <div
            className={`fullscreen ${
              !showItems ? "ready" : "fullscreen_notready notready"
            } ${!showItems && "clicked"}`}
            onClick={() => {
              setCounter(1);
              handleItems(false);
            }}
          ></div>
          <div
            className="main-container-wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="main-container-child" style={{ width: "85%" }}>
              {!showActiveImage && (
                <div className="loader">
                  <ReactLoading
                    type={"spin"}
                    color={"#fff"}
                    height={50}
                    width={50}
                  />
                </div>
              )}
              {/* <div className={titleClassName}> */}
              <div
                className="product-close-icon"
                onClick={() => {
                  setCounter(1);
                  handleItems(false);
                }}
              >
                <CloseIcon
                  className="no-text-select"
                  style={{ fontSize: 19, color: "#fff" }}
                />
              </div>
              {/* </div> */}
              {/* {showActiveImage && ( */}
              {/* <div className="layout-parent"> */}
              <div className="sub-child-conatiner" ref={divRef}>
                <div className="product-heading">{currentProduct?.title}</div>
                <div className="item-container-wrapper">
                  <div className={itemsMainContainerClassName}>
                    <div className="class-1">
                      <img src={activeImage} className="class-1-img" />
                    </div>
                    <div className="class-2">
                      <div className="image-vertical-divider" />
                    </div>
                    <div className="class-3">
                      {currentItem?.images?.map((el, index) => {
                        return (
                          <div
                            key={index + "index"}
                            style={{
                              marginTop: index == 0 ? "0%" : "5%",
                              borderColor: el.includes(activeImage)
                                ? "#000"
                                : "transparent",
                            }}
                            onClick={() => {
                              handleActiveImage(el, index);
                            }}
                            className={"product-img-container"}
                          >
                            <div className="inner-container">
                              <img src={el} key={index} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* {activeImages[activeImageIndex]}
                    <div className="image-vertical-divider" />
                    <div className="series-image">
                      {currentItem?.images?.map((el, index) => {
                        return (
                          <div className="image-parent" key={index}>
                            <img
                              src={el}
                              className={
                                el.includes(activeImage)
                                  ? "active_product_image"
                                  : "product_image"
                              }
                              onClick={() => handleActiveImage(el, index)}
                              key={index}
                            />
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
                <div className={itemDescriptionClassName}>
                  <p
                    className="product-description"
                    style={{ marginBlockStart: "0px" }}
                  >
                    {currentProduct?.description
                      ?.split(/\n\n|\n/)
                      .map((line, index, array) => (
                        <span key={index}>
                          {line}
                          {index !== array.length - 1 && (
                            <>
                              <br />
                              <br />
                            </>
                          )}
                        </span>
                      ))}
                  </p>
                  <p className="ingredients-heading">Key Ingredients</p>
                  <p className="product-description">
                    {currentProduct?.metafields &&
                      currentProduct.metafields
                        .split("\n\n")
                        .map((line, index, array) => {
                          const [ingredient, description] = line.split(":");
                          const isBold = ingredient && description;

                          return (
                            <React.Fragment key={index}>
                              {isBold ? (
                                <span className="ingredients-heading">
                                  {ingredient}:
                                </span>
                              ) : (
                                ingredient
                              )}
                              {description && ` ${description}`}
                              {index !== array.length - 1 && <br />}
                            </React.Fragment>
                          );
                        })}
                  </p>
                </div>
              </div>

              {/* <div className="bottom-content"> */}

              <div className={bottomBtnContainerClassName}>
                <div className="price-container-cart">
                  {currentProduct?.compare_at_price > currentProduct?.price && (
                    <span className="stroked-text">
                      {"£" + currentProduct?.compare_at_price}
                    </span>
                  )}
                  <div className="bottom-items">
                    <span className="actual-price-text">
                      {"£ "} {currentProduct?.price}
                    </span>
                    {currentProduct?.compare_at_price >
                      currentProduct?.price && (
                      <span className="grad-container">
                        Save{" "}
                        {Math.round(
                          ((currentProduct?.compare_at_price -
                            currentProduct?.price) /
                            currentProduct?.compare_at_price) *
                            100
                        ) + "%"}
                      </span>
                    )}
                  </div>
                </div>
                <div className="bottom-btn-container">
                  <div className="counter-blockss_1">
                    <div className="icon-holders_1">
                      <RemoveIcon
                        fontSize={"24px"}
                        onClick={() => handleOnChange(false)}
                      />
                    </div>
                    <input
                      className="inner-counter-eles_1"
                      value={
                        currentProduct?.inventory_quantity >= 1 ? counter : 0
                      }
                      disabled
                      onChange={handleChange}
                      inputMode="numeric"
                    />
                    <div className="icon-holder2ss_1">
                      <AddIcon
                        fontSize={"24px"}
                        onClick={() => handleOnChange(true)}
                      />
                    </div>
                  </div>
                  <button
                    className="add-cart-btn"
                    onClick={() => {
                      if (currentProduct?.inventory_quantity >= 1) {
                        trackAddToCart(currentProduct?.title);

                        handleIncreaseCount();
                        handleLocalItemsInCart();
                      } else {
                        console.log("Sold Out");
                      }
                    }}
                    disabled={currentProduct?.inventory_quantity < 1}
                  >
                    <span className="btn-txt-cart">
                      {currentProduct?.inventory_quantity >= 1
                        ? "Add to Bag"
                        : "Sold Out"}
                    </span>
                  </button>
                </div>
              </div>
              {/* </div> */}

              {/* </div> */}
              {/* )} */}
            </div>
          </div>
        </>
        {/* </div> */}
      </div>
    </>
  );
};
export default Product;

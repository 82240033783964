import { Dialog, IconButton, TextField, Tooltip } from "@mui/material";
import "../../pages/Dialog.scss"
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as CloseIcon } from "../../assests/svg/close-dialog-icon.svg";
import { HeaderContext } from "../../store/Header-context";
import { useContext, useState } from "react";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import ReCAPTCHA from "react-google-recaptcha";
import {isEmail} from "../../utils/validation.js";
export default function LearnMore({ timerCount }) {
    const [isVerified, setIsVerified] = useState(false);
    const headerContext = useContext(HeaderContext);
    const handlefinalSubmit = async (values, { resetForm }) => {
        headerContext.setLoading(true);
        const data = await fetchData(ApiConfig.addUserDetail, headerContext.setLoading, 'POST', values);
        if (data) {
            headerContext.setCurrentStep(2);
        }
        headerContext.setLoading(false);
    };
    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            company: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            email: Yup.string()
                    .test('email', 'Invalid email', value => isEmail(value))
                    .required('Email is required'),
            name: Yup.string().required("Name is required"),
           
        }),
        onSubmit: handlefinalSubmit,
    });
    const handleRecaptchaChange = (value) => {
        setIsVerified(!!value);
    };
    return <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        onClose={headerContext.handleCloseDialog}
        open={headerContext.openDialog}
        className="common-dialog-container"
    >
        {" "}
        <ToastContainer />
        <form onSubmit={formik.handleSubmit} noValidate>
            <IconButton
                className="close-dialog-icon-button"
                onClick={headerContext.toggleLearnMorePopup}
            >
                <Tooltip title="Close Dialog">
                    <CloseIcon className="close-dialog-icon" />
                </Tooltip>
            </IconButton>
            {headerContext.currentStep == 1 && (
                <div>
                    <div className="dialog-title">Want to Learn More?</div>
                    <div className="dialog-subTitle">Create your virtual store</div>
                    <div className="dialog-input-container">
                        <TextField
                            className="common-input-field"
                            label="Your Full Name*"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            name="name"
                            id="name"
                            error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                            }
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                        />
                        <TextField
                            sx={{ margin: "20px 0" }}
                            className="common-input-field"
                            label="Your Email*"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            name="email"
                            id="email"
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            label="Your Company Name"
                            type="text"
                            className="common-input-field"
                            onChange={formik.handleChange}
                            value={formik.values.company}
                            name="company"
                            id="company"
                            error={
                                formik.touched.company &&
                                Boolean(formik.errors.company)
                            }
                            helperText={
                                formik.touched.company && formik.errors.company
                            }
                        />
                    </div>
                    {process.env.REACT_APP_RECAPTCHA_MODE == "true" && <div className="dialog-recaptcha">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={handleRecaptchaChange}
                        />
                    </div>}
                    <div className="dialog-submit-container">
                        <button className="dialog-input-submit" type="submit" disabled={!isVerified && process.env.REACT_APP_RECAPTCHA_MODE == "true"}>
                            Submit
                        </button>
                    </div>
                </div>
            )}
            {headerContext.currentStep == 2 && (
                <div className="success-dialog">
                    <div className="dialog-title no-line-height">
                        Your information has been successfully submitted.
                    </div>
                    <div className="dialog-subTitle">
                        Our team will reach out to you shortly. Thank you for your
                        interest, and we look forward to connecting with you!
                    </div>
                    <div className="dialog-submit-container">
                        <button
                            onClick={headerContext.handleCloseDialog}
                            className="dialog-input-submit"
                        >
                            Continue
                        </button>
                    </div>
                    <div className="button-redirection-text">
                        Auto close in {timerCount} sec
                    </div>
                </div>
            )}
        </form>
    </Dialog>

}
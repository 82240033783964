import React, { useEffect, useState } from "react";
import "./gameboardattempted-styles.css";
import CloseIcon from "@mui/icons-material/Close";
// import FormOverlay from "../Backdrop";
import useGameStore from "../utils/gameStore";

export default function GameBoardAttemptedPopup() {
  const { showGameBoardAttemptedPopup, handleShowGameBoardAttemptedPopup } =
    useGameStore();

  return (
    <>
      {/* {!isFormOverlay && <FormOverlay />} */}
      {showGameBoardAttemptedPopup ? (
        <>
          {/* <FormOverlay /> */}
          <div className="parent-form-test_4  no-text-select">
            <div className="sub-parent-form_4">
              <div
                className="form-close-icon"
                onClick={() => handleShowGameBoardAttemptedPopup(false)}
              >
                <CloseIcon style={{ fontSize: 20, color: "#fff" }} />
              </div>
              <div className="form-item-container_4">
                <div className="heading-container_4">
                  <span className="heading-test-form_4">
                    Sorry, question attempted.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

import React, { useEffect } from "react";
import {
  isIOS,
  isMobileSafari,
  isSafari,
  isIOS13,
  isMacOs,
} from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDownLeftRight, faExpand } from "@fortawesome/free-solid-svg-icons";
import useUIStore from "../../utils2/uistore";
export function EnterFullScreenButton({ handle }) {
  const { showCart } = useUIStore();
  return (
    <>
      {!isIOS &&
        !isMobileSafari &&
        !isSafari &&
        !isIOS13 &&
        !isIOS13 &&
        !isMacOs &&
        !showCart && (
          <button className="fullscreen_icon" onClick={handle.enter}>
            <FontAwesomeIcon
              icon={faExpand}
              size="2xl"
              style={{ color: "#111212" }}
            />
          </button>
        )}
    </>
  );
}
export function ExitFullScreenButton({ handle }) {
  const { showCart } = useUIStore();
  return (
    <>
      {!isIOS &&
        !isMobileSafari &&
        !isSafari &&
        !isIOS13 &&
        !isIOS13 &&
        !isMacOs &&
        handle.active &&
        !showCart && (
          <button className="fullscreen_icon" onClick={handle.exit}>
            <FontAwesomeIcon
              icon={faUpDownLeftRight}
              size="2xl"
              style={{ color: "#121212" }}
            />
          </button>
        )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import ThreeEnv from "../components/ThreeEnv";

function CustomEnv() {
  const [loading, setLoading] = useState(true);
  const [envData, setEnvData] = useState({});
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  useEffect(() => {
    const getEnvDetail = async () => {
      setLoading(true);
      const currentUrl = window.location.href;
      let envlist = currentUrl.split("/");
      let currentEnv = envlist[envlist.length - 1];
      try {
        const envResponse = await fetchData(
          `${ApiConfig.environmentTemplate}?page=1&limit=10&env_template_id=${currentEnv}`,
          setLoading,
          "get",
          {},
          { authorization: `Bearer ${token}` }
        );

        if (envResponse.status === 200) {
          const data = envResponse.data.data;
          setEnvData({
            model: data[0].env_buildfile_url,
            template: data[0].env_template_id,
          });
        } else if (envResponse.status === 401 || envResponse.status === 500) {
          navigate("/login");
        } else {
          console.log(envResponse.status);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }

      setLoading(false);
    };

    getEnvDetail();
  }, [token, navigate]);

  return (
    <>
      <div className="dashboard-header">
        <Header variation={2}></Header>
      </div>
      <div style={{display:'flex', alignItems:'center', justifyContent: 'center' , height:'100vh'}}>
        {loading ? <Spinner/> :<ThreeEnv env={envData}/>}
      </div>
    </>
  );
}

export default CustomEnv;

import React from 'react'
// import ThreeScene from '../components/three/ThreeScene'
// import SpinWheelGame from '../components/games/SpinWheelGame'
// import Quiz from '../components/games/Quiz'
import ThreeScene from "../components/addProducts/ThreeScene"


// const questionBank = [
//   {
//     question: 'What is 2 + 2?',
//     options: ['3', '4', '5'],
//     correctAnswer: '4'
//   },
//   {
//     question: 'What is the capital of France?',
//     options: ['London', 'Paris', 'Berlin'],
//     correctAnswer: 'Paris'
//   },
//   {
//     question: 'What is 2 + 1?',
//     options: ['3', '4', '7'],
//     correctAnswer: '3'
//   },
//   {
//     question: 'What is 2 + 5?',
//     options: ['3', '4', '7'],
//     correctAnswer: '7'
//   },
// ];

function PlayAround3() {
  return (
    <>
      {/* <ThreeScene/> */}
      {/* <SpinWheelGame/> */}
      {/* <Quiz questionBank={questionBank} /> */}
      <ThreeScene/>
    </>
  )
}

export default PlayAround3

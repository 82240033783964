import "./styles.css";
import useUIStore from "../../../utils2/uistore";
import useStore from "../../../utils2/3dstore";

function Backdrop() {
  const { showItems, handleItems } = useUIStore();
  const {setCounter} = useStore()
  return (
    <>
      <div
        className={`fullscreen ${
          !showItems ? "ready" : "fullscreen_notready notready"
        } ${!showItems && "clicked"}`}
        onClick={() => {
          handleItems(false);
          console.log("BACKDROP CLICKED");
        }}
      ></div>
    </>
  );
}

export default Backdrop;

import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import Movements from "../../modules/movement";
import { useSelector } from "react-redux";
import { getColor } from "./ColorCode";

import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import { useNavigate } from "react-router-dom";
import "./ThreeScene.scss";
import modelEnv2 from "../../assests/images/potsdamer_platz_1k.hdr";
import modelEnv3 from "../../assests/images/Sand_Dunes_high.jpg";
import modelEnv4 from "../../assests/images/studio_small_08_2k.hdr";
import modelEnv5 from "../../assests/images/Sky_Dome_equirectangular-jpg_pink_sky_1773824017_10340800.hdr";
import modelEnv6 from "../../assests/images/3d_image_5.png";
//Not Related to Three.js imports
import Spinner from "../Spinner";
import { useDropzone } from "react-dropzone";
import { ReactComponent as CheckIcon } from "../../assests/svg/check-icon.svg";
import { ReactComponent as LogoIcon } from "../../assests/svg/logo-icon.svg";
import { ReactComponent as ColorIcon } from "../../assests/svg/color-icon.svg";
import { ReactComponent as TextureIcon } from "../../assests/svg/texture-icon.svg";
import { ReactComponent as LightingIcon } from "../../assests/svg/lighting-icon.svg";
import homeIcon from "../../assests/images/refresh.png";
import keyboadButton from "../../assests/images/keyboad-button.png";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Tooltip } from "@mui/material";
import Header from "../Header";

const CustomThreeScene = ({ envData }) => {
  //ref  
  const videoRef = useRef(null);
  const change = useRef(0);
  let modelsRef = useRef([]);
  const logoImg = useRef(envData.logo);
  const campData = useRef({
    file: envData.campaign !== null ? envData.campaign : '',
    type: envData.campaignType !== null ?  envData.campaignType : '',
  });
  const roomData = useRef({
    color: envData.color,
    matTexture:
      envData.texture.length > 0
        ? envData.texture === "Glossy"
          ? "1"
          : "2"
        : "default",
    bright:
      envData.light.length > 0
        ? envData.light === "Bright"
          ? "1"
          : "2"
        : "default",
  });

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEnv, setIsLoadingEnv] = useState(true);
  const [isMinimise, setIsMinimise] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  //api data
  const [logo, setLogo] = useState(null);
  const [camp, setCamp] = useState(null);
  const [campType, setCampType] = useState(null);
  const [customData, setCustomData] = useState({
    light: envData.light,
    colour: getColor(envData.color),
    texture: envData.texture,
  });

  const [logoName, setLogoName] = useState(reName(envData.logo));
  const [fileName, setFileName] = useState(reName(envData.campaign));

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();

  //useState for disabling
  const [isdisable, setDisable] = useState({
    logo: true,
    campaign: true,
    room: true,
    product: true,
  });
  const [isApplied, setApplied] = useState({
    logo: true,
    campaign: true,
  });

  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  const containerRef = useRef();
  let scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.001,
    1000
  );

  camera.fov = 2 * Math.atan(36 / (2 * 50)) * (180 / Math.PI);

  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);

  useEffect(() => {
    var container = containerRef.current;
    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }    

    //custom code to set camera position
    if(envData.templateNumber === "400"){
      camera.position.set(0.0019361698230040427, 0.005, 0.8654263091709458);
      camera.rotation.set(0, 0.06283185307179587, 0);
    }
    else if (envData.templateNumber === "4") {
      camera.position.set(-0.38607296060670965, 0.27, 1.4942321515766563);
      camera.rotation.set(0, 0.06283185307179587, 0);
    } else {
      camera.position.set(0.04, 0.32, 1.5);
    }

    if (envData.templateNumber !== "4") {
      Enviroment(modelEnv4, modelEnv5, setIsLoadingEnv);
    } else {
      Enviroment2(modelEnv2, setIsLoadingEnv);
    }



      let logoMaterial = logoTexture(logoImg.current);
      let campMaterial = VideoTexture(campData.current);
      
      ModelScene(
        envData.env,
        setIsLoading,
        logoMaterial,
        roomData.current,
        campMaterial
      );
      if(envData.sameEnv && envData.productData.length > 0){
        modelsRef.current = loadProducts(envData.productData);
      }

    

    const animate = () => {
      requestAnimationFrame(animate);
      Movement();
      
      if(envData.templateNumber !== "7" && modelsRef.current.length > 0){
        animateProducts(modelsRef.current);
      }
      if (envData.templateNumber === "4") {
        restrictCameraPosition(camera);
      }
      renderer.render(scene, camera);
    };
    animate();
    return () => {
      scene.traverse((obj) => {
        if (obj instanceof THREE.Mesh) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      renderer.dispose();
      setIsLoading(true);
      scene = new THREE.Scene();
      container.removeChild(renderer.domElement);
    };
  }, [change.current]);

  function ModelScene(model, loading, image, room, campaign) {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    loader.load(
      model,
      (gltf) => {
        const desiredSize = 3;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.traverse((child) => {
          if (child.isMesh) {            
            child.castShadow = true;
            if (child.name === "logo-mesh") {
              child.material = image;
            }
            if (child.name.includes("campaign-mesh")) {
              if (campaign !== 0 ) {
                child.material = campaign;
              }
            }
            child.receiveShadow = true;
            if (
              child.userData["texture-changeable"] ||
              child.userData["colour-changeable"]
            ) {
              if (room.color.length > 0) {
                child.material.color.set(room.color);
              }
              if (room.matTexture === "1") {
                child.material.metalness = 0;
                child.material.roughness = 0.4;
              } else if (room.matTexture === "2") {
                child.material.metalness = 0.7;
                child.material.roughness = 0.4;
              }
            }
          }
          if (child.isLight) {
            child.intensity = 1;
          }
        });
        gltf.scene.position.set(0, 0, 0.8);        
        scene.add(gltf.scene);
        loading(false);
      },
      () => {},
      (error) => {
        console.error(error);
      }
    );
  }

  function loadProducts(modelPaths) {
    if (!Array.isArray(modelPaths)) {
      console.error("modelPaths is not an array.");
      return;
    }
    let modelRefer = [];
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    modelPaths.forEach((path, index) => {
      loader.load(path.brand_product_masters[0].product_3D_model_url, (gltf) => {
        const desiredSize = 0.07;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.position.set(path.x_coordinate, path.y_coordinate, path.z_coordinate);
        gltf.scene.userData.name = `${index + 1}`;
        gltf.scene.name = `${index + 1}`;
        modelRefer[index] = gltf.scene;
        scene.add(gltf.scene);
      });
    });
    return modelRefer;
  }

  function animateProducts(models) {
    models.forEach((model) => {
      model.rotation.y += 0.0032;
    });
  }

  function restrictCameraPosition(camera) {
    const radius = 0.86;
    const meshCenter = new THREE.Vector3(-0.55, 0, 0.65);
    const dx = camera.position.x - meshCenter.x;
    const dz = camera.position.z - meshCenter.z;
    const distance = Math.sqrt(dx * dx + dz * dz);

    if (distance > radius) {
      const angle = Math.atan2(dz, dx);
      camera.position.x = meshCenter.x + radius * Math.cos(angle);
      camera.position.z = meshCenter.z + radius * Math.sin(angle);
    }
  }

  function logoTexture(val) {
    let logoMaterial;
    let logoTexture = new THREE.TextureLoader().load(val);
    logoTexture.flipY = false;
    if (val !== null) {
      logoMaterial = new THREE.MeshBasicMaterial({
        map: logoTexture,
        transparent: true,
      });
    } else {
      logoMaterial = new THREE.MeshBasicMaterial({
        color: 0xe6aaa5,
        transparent: true,
        opacity: 0,
        shadowSide: THREE.DoubleSide,
      });
    }
    return logoMaterial;
  }

  function VideoTexture(val) {
    let screenMaterial;
    if (val.file !== "") {
      if (val.type === "video") {
        videoRef.current.src = val.file;
        videoRef.current.loop = true;
        videoRef.current.muted = true;
        videoRef.current.preload = "auto";
        videoRef.current.addEventListener("loadedmetadata", function () {
          videoRef.current.play();
        });
        let video = document.getElementById("video");
        video.setAttribute("crossorigin", "anonymous");
        const vidTexture = new THREE.VideoTexture(video);
        vidTexture.minFilter = THREE.LinearFilter;
        vidTexture.magFilter = THREE.LinearFilter;
        vidTexture.flipY = false;
        screenMaterial = new THREE.MeshBasicMaterial({
          map: vidTexture,
          side: THREE.FrontSide,
          toneMapped: false,
        });
      } else {
        let screenTexture = new THREE.TextureLoader().load(val.file);
        screenTexture.flipY = false;
        screenMaterial = new THREE.MeshBasicMaterial({
          map: screenTexture,
        });
      }
    } else {
      screenMaterial = 0;
    }
    return screenMaterial;
  }

  function Enviroment(lightingEnv, backgroundEnv , loading) {
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;

    const hdrLoader = new RGBELoader();

    hdrLoader.load(lightingEnv, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;

      // envMap.encoding = THREE.sRGBEncoding;
      // envMap.mapping = THREE.EquirectangularReflectionMapping;

      // const envMapIntensity = new THREE.MeshStandardMaterial({
      //   envMap: envMap,
      //   envMapIntensity: 1, // Adjust this value to control intensity
      // });

      scene.environment = envMap;
      // scene.background = envMap;

      pmremGenerator.dispose();
      loading(false);
    });

    // Load and set the HDR image for the background
    hdrLoader.load(backgroundEnv, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.mapping = THREE.EquirectangularReflectionMapping;

      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
        toneMapped: false
      });

      const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      const backgroundMesh = new THREE.Mesh(
        backgroundGeometry,
        backgroundMaterial
      );

      // Rotate the background by 180 degrees
      // backgroundMesh.rotation.y = Math.PI; // 180 degrees in radians

      scene.add(backgroundMesh);
    });
  }

  // function Enviroment2(env, loading) {    
  //   const hdrLoader = new RGBELoader();
  //   hdrLoader.load(env, (texture) => {
  //     const pmremGenerator = new THREE.PMREMGenerator(renderer);
  //     pmremGenerator.compileEquirectangularShader();
  //     const envMap = pmremGenerator.fromEquirectangular(texture).texture;
  //     scene.environment = envMap;
  //     const backgroundMaterial = new THREE.MeshBasicMaterial({
  //       map: texture,
  //       side: THREE.BackSide,
  //     });
  //     if (envData.templateNumber === "4") {
  //       const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
  //       const textureLoader = new THREE.TextureLoader();
  //       const texture2 = textureLoader.load(envData.templateNumber === "400"? modelEnv6 : modelEnv3);
  //       const material = new THREE.MeshBasicMaterial({
  //         map: texture2,
  //         side: THREE.BackSide,
  //       });
  //       const backgroundMesh = new THREE.Mesh(backgroundGeometry, material);
  //       scene.add(backgroundMesh);
  //     } else {
  //       const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
  //       const backgroundMesh = new THREE.Mesh(
  //         backgroundGeometry,
  //         backgroundMaterial
  //       );
  //       scene.add(backgroundMesh);
  //     }
  //     pmremGenerator.dispose();
  //     loading(false);
  //   });
  // }

  function Enviroment2(env, loading) {
    const hdrLoader = new RGBELoader();
  
    hdrLoader.load(
      env,
      (texture) => {
        try {
          const pmremGenerator = new THREE.PMREMGenerator(renderer);
          
          // Introducing a slight delay before shader compilation
          setTimeout(() => {
            pmremGenerator.compileEquirectangularShader();
  
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            scene.environment = envMap;
  
            const backgroundMaterial = new THREE.MeshBasicMaterial({
              map: texture,
              side: THREE.BackSide,
            });
  
            const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
  
            if (envData.templateNumber === "4") {
              const textureLoader = new THREE.TextureLoader();
              const texture2 = textureLoader.load(
                envData.templateNumber === "400" ? modelEnv6 : modelEnv3
              );
              const material = new THREE.MeshBasicMaterial({
                map: texture2,
                side: THREE.BackSide,
              });
              const backgroundMesh = new THREE.Mesh(backgroundGeometry, material);
              scene.add(backgroundMesh);
            } else {
              const backgroundMesh = new THREE.Mesh(
                backgroundGeometry,
                backgroundMaterial
              );
              scene.add(backgroundMesh);
            }
  
            pmremGenerator.dispose();
            loading(false);
          }, 100); // Adjust delay time if necessary
  
        } catch (error) {
          console.error("An error occurred while setting up the environment:", error);
          loading(false);
        }
      },
      undefined,
      (error) => {
        console.error("Failed to load the HDR image:", error);
        loading(false);
      }
    );
  }
  

  function Movement() {
    var player = { speed: 0.01, turnSpeed: Math.PI * 0.01 };
    if (Movements.isPressed(87) || Movements.isPressed(38)) {
      camera.rotation.x = 0;
      camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
      camera.position.z -= Math.cos(camera.rotation.y) * player.speed;
    }
    if (Movements.isPressed(83) || Movements.isPressed(40)) {
      camera.rotation.x = 0;
      camera.position.x += Math.sin(camera.rotation.y) * player.speed;
      camera.position.z += Math.cos(camera.rotation.y) * player.speed;
    }
    if (Movements.isPressed(65) || Movements.isPressed(37)) {
      camera.rotation.y += player.turnSpeed;
    }
    if (Movements.isPressed(68) || Movements.isPressed(39)) {
      camera.rotation.y -= player.turnSpeed;
    }
    if (envData.templateNumber !== "4" && envData.templateNumber !== "400") {
      if (camera.position.z <= 0) {
        camera.position.z = 0;
      }
      if (camera.position.z >= 1.5) {
        camera.position.z = 1.5;
      }
      if (camera.position.x >= 0.5) {
        camera.position.x = 0.5;
      }
      if (camera.position.x <= -0.4) {
        camera.position.x = -0.4;
      }
    }
  }

//   function Movement(){
//     var player = { speed: 0.05, turnSpeed: Math.PI * 0.01 };
// const delta = 0.1; // Time elapsed since last frame
// const collisionThreshold = .01; // Distance to stop before collision
// const raycaster = new THREE.Raycaster();
// const direction = new THREE.Vector3();

// if (Movements.isPressed(87) || Movements.isPressed(38)) { 
//   camera.rotation.x = 0;
//   direction.set(0, 0, -1).applyQuaternion(camera.quaternion);
//   raycaster.ray.origin.copy(camera.position);
//   raycaster.ray.direction.copy(direction);

//   const intersects = raycaster.intersectObject(scene, true);
//   if (intersects.length > 0 && intersects[0].distance < collisionThreshold) {
//     camera.position.addScaledVector(direction, Math.max(intersects[0].distance - collisionThreshold, 0));
//   } else {
//     camera.position.addScaledVector(direction, player.speed * delta);
//   }
// }

// if (Movements.isPressed(83) || Movements.isPressed(40)) { 
//   camera.rotation.x = 0;
//   direction.set(0, 0, 1).applyQuaternion(camera.quaternion);
//   raycaster.ray.origin.copy(camera.position);
//   raycaster.ray.direction.copy(direction);

//   const intersects = raycaster.intersectObject(scene, true);
//   if (intersects.length > 0 && intersects[0].distance < collisionThreshold) {
//     camera.position.addScaledVector(direction, -Math.max(intersects[0].distance - collisionThreshold, 0));
//   } else {
//     camera.position.addScaledVector(direction, player.speed * delta);
//   }
// }

// if (Movements.isPressed(65) || Movements.isPressed(37)) { 
//   camera.rotation.y += player.turnSpeed;
// }

// if (Movements.isPressed(68) || Movements.isPressed(39)) { 
//   camera.rotation.y -= player.turnSpeed;
// }

// // Restrict movement within certain boundaries
// // if (envData.templateNumber !== "4") {
// //   if (camera.position.z <= 0) {
// //     camera.position.z = 0;
// //   }
// //   if (camera.position.z >= 1.5) {
// //     camera.position.z = 1.5;
// //   }
// //   if (camera.position.x >= 0.5) {
// //     camera.position.x = 0.5;
// //   }
// //   if (camera.position.x <= -0.4) {
// //     camera.position.x = -0.4;
// //   }
// // }

//   }

  const handleRoom = (event) => {
    setDisable((prevState) => ({
      ...prevState,
      room: false,
    }));
    const { name, value } = event.target;
    roomData.current = {
      ...roomData.current,
      [name]: value,
    };
  };

  async function handleData(val) {
    // Ensure token is available before proceeding
    if (token === null) {
      return;
    }

    try {
      setLoading(true); // Set loading state to true

      const formData = new FormData();
      if (val === null) {
        formData.append("logo", "");
      } else {
        formData.append("logo", val, val.name);
      }

      // Make the API call
      const response = await fetchData(
        ApiConfig.addLogo,
        setLoading,
        "media",
        formData,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.error("Error occurred while handling data:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCampaign(val, type) {
    setLoading(true);
    const response = await fetchData(
      ApiConfig.addCampaign,
      setLoading,
      "media",
      { campaign: val, campaign_type: type },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleCustomData() {
    setLoading(true);
    const response = await fetchData(
      ApiConfig.addCustom,
      setLoading,
      "post",
      customData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function updateStep(val) {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: val },
      { authorization: `Bearer ${token}` }
    );
  }

  const handleStep = (event) => {
    change.current = change.current + 1;
    event.preventDefault();
    setIsLoading(true);
    setCompletedSteps((prevValue) => {
      return [currentStep, ...prevValue];
    });
    if (currentStep === 1) {
      isApplied.logo = false;
    }
    if (currentStep === 2) {
      isApplied.campaign = false;
    }
    if (currentStep !== 4) {
      const { color, matTexture, bright } = roomData.current;
      var currentColour;
      if (color === "#FFFFFF") {
        currentColour = "White";
      } else if (color === "#FF0000") {
        currentColour = "Red";
      } else if (color === "#FFD700") {
        currentColour = "Gold";
      } else if (color === "#F5D7D5") {
        currentColour = "Rose Pink";
      } else {
        currentColour = "default";
      }
      setCustomData({
        light:
          bright.length > 0 ? (bright === "1" ? "Bright" : "Warm") : "default",
        colour: currentColour,
        texture:
          matTexture.length > 0
            ? matTexture === "1"
              ? "Glossy"
              : "Matt"
            : "default",
      });
    } else {
      setIsLoading(false);
    }
  };

  function handleNext() {
    if (currentStep === 1) {
      handleData(logo);
    }
    if (currentStep === 2) {
      handleCampaign(camp, campType);
    }
    if (currentStep === 3) {
      if (customData !== null && Object.keys(customData).length !== 0) {
        handleCustomData();
        updateStep(2);
        navigate(`/dashboard/select-store`);
      }
    }
    if (currentStep !== 3) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(1);
    }
  }

  function returnToHomePosition() {
    if(envData.templateNumber === "400"){
      camera.position.set(0.0019361698230040427, 0.005, 0.8654263091709458);
      camera.rotation.set(0, 0.06283185307179587, 0);
    }
    else if (envData.templateNumber === 4) {
      camera.position.set(-0.38607296060670965, 0.27, 1.4942321515766563);
      camera.rotation.set(0, 0.06283185307179587, 0);
    } else {
      camera.position.set(0.04, 0.32, 1.5);
      camera.rotation.x = 0;
      camera.rotation.y = 0;
      camera.rotation.z = 0;
    }
  }
  const homeDiv = document.getElementById("home");
  if (homeDiv) {
    homeDiv.addEventListener("click", returnToHomePosition);
  }

  function onToggleMinimise() {
    setIsMinimise((prevValue) => !prevValue);
  }

  const handleDrop1 = (acceptedFiles) => {
    setLogo(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setLogoName(newFile);
    const imageUrl = URL.createObjectURL(file);
    logoImg.current = imageUrl;
    setDisable((prevState) => ({
      ...prevState,
      logo: false,
    }));
  };
  const handleDrop2 = (acceptedFiles) => {
    setCamp(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setFileName(newFile);
    const fileType = file.type.startsWith("image/") ? "image" : "video";
    const fileUrl = URL.createObjectURL(file);
    campData.current.file = fileUrl;
    campData.current.type = fileType;
    setCampType(fileType);
    setDisable((prevState) => ({
      ...prevState,
      campaign: false,
    }));
  };
  const dropzone1 = useDropzone({
    onDrop: handleDrop1,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    multiple: false,
  });

  const dropzone2 = useDropzone({
    onDrop: handleDrop2,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
      "video/*": [
        ".mp4",
        ".mpeg",
        ".flv",
        ".ts",
        ".3gp",
        ".mov",
        ".avi",
        ".wmv",
      ],
    },
    multiple: false,
  });

  const clearLogo = (event) => {
    logoImg.current = "";
    event.stopPropagation();
    setLogo(null);
    setLogoName("");
  };

  const clearVideo = (event) => {
    campData.current.file = "";
    campData.current.type = "";
    event.stopPropagation();
    setFileName("");
    setCamp(null);
    setCampType(null);
  };

  function reName(val) {
    if (val === null) {
      return;
    }
    var fileN = val.split("/").pop();
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    return newFile;
  }

  return (
    <>
      <div className="dashboard-header">
        <Header variation={5}></Header>
      </div>
      <div className="three-customize">
        <div
          className={(isLoading || isLoadingEnv)? `loadingTab` : ``}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {(isLoading || isLoadingEnv)  && (
            <div
              style={{
                position: "absolute",
                top: "44%",
                left: "47%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner />
            </div>
          )}
          <div
            ref={containerRef}
            style={{
              height: "100%",
              overflow: "hidden",
            }}
          ></div>
        </div>
        <video
          id="video"
          ref={videoRef}
          style={{ display: "none" }}
          muted
          loop
          autoPlay
        />
        <div className="homeIcon" id="home">
          <Tooltip title="Refresh">
            <img src={homeIcon} alt="home" />
          </Tooltip>
        </div>
        <div className="keyboadButton">
          <Tooltip title="Use your arrow keys to move around">
            <img src={keyboadButton} alt="button" />
          </Tooltip>
        </div>
        <div
          className={`custom-create-section`}
          style={{
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            overflow: "hidden",
            height: `${isMinimise ? "3.4rem" : "auto"} `,
            width: "max-content",
            padding: " 0",
          }}
        >
          <div className="minimise-tray">
            <div>
              {isMinimise ? (
                <KeyboardArrowUpIcon
                  style={{ fill: "#ee3a24" }}
                  onClick={onToggleMinimise}
                ></KeyboardArrowUpIcon>
              ) : (
                <KeyboardArrowDownIcon
                  style={{ fill: "#ee3a24" }}
                  onClick={onToggleMinimise}
                ></KeyboardArrowDownIcon>
              )}
            </div>
          </div>
          <div
            className="custom-create-container"
            style={{ justifyContent: "end" }}
          >
            <div className="create-stepper-container">
              <button
                className={`${
                  currentStep === 1
                    ? `active-stepper`
                    : completedSteps.includes(1)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(1);
                }}
              >
                <div className="stepper-button-title">
                  Add Your Logo
                  {completedSteps.includes(1) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
              <button
                className={`${
                  currentStep === 2
                    ? `active-stepper`
                    : completedSteps.includes(2)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(2);
                }}
                style={{
                  flexDirection: "row",
                }}
              >
                <div>
                  <div className="stepper-button-title">Add Your Campaign</div>
                  <div className="stepper-button-subTitle">Image or Video</div>
                </div>
                {completedSteps.includes(2) && (
                  <CheckIcon className="stepper-check-icon" />
                )}
              </button>
              <button
                className={`${
                  currentStep === 3
                    ? `active-stepper`
                    : completedSteps.includes(3)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(3);
                }}
              >
                <div className="stepper-button-title">
                  Customise The Environment
                  {completedSteps.includes(3) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
            </div>
            {currentStep === 1 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">Add Your Logo</div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone1.getRootProps()}
                >
                  <input {...dropzone1.getInputProps()} accept="image/*" />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {logoName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {logoName}
                      <div onClick={clearLogo}>
                        <CloseIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">
                    Add Campaign Image or Video
                  </div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone2.getRootProps()}
                >
                  <input
                    {...dropzone2.getInputProps()}
                    accept="image/*,video/*"
                  />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {fileName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {fileName}
                      <CloseIcon onClick={clearVideo} />
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="custom-create-input-container">
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <LightingIcon />
                    <div className="custom-create-input-title">Lighting</div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="bright"
                      value={roomData.current.bright}
                      onChange={handleRoom}
                    >
                      <option>Choose Light</option>
                      <option value="1">Bright</option>
                      <option value="2">Warm</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <ColorIcon />
                    <div className="custom-create-input-title">
                      Counter Colour
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="color"
                      value={roomData.current.color}
                      onChange={handleRoom}
                    >
                      <option>Choose Color</option>
                      <option value="#FFFFFF">White</option>
                      <option value="#F5D7D5">Rose Pink</option>
                      <option value="#FF0000">Red</option>
                      <option value="#FFD700">Gold</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <TextureIcon />
                    <div className="custom-create-input-title">
                      Counter Texture
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="matTexture"
                      value={roomData.current.matTexture}
                      onChange={handleRoom}
                    >
                      <option value="">Choose Texture</option>
                      <option value="1">Glossy</option>
                      <option value="2">Matt</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className="custom-create-cta-group">
              <button className={`common-cta-button `} onClick={handleStep}>
                <span className="common-cta-text">Save</span>
              </button>
              <button
                className={`common-cta-button`}
                onClick={() => handleNext()}
                style={{
                  display: `${currentStep === 4 ? `none` : `initial`}`,
                }}
              >
                <span className="common-cta-text">
                  {" "}
                  {currentStep === 3 ? `Add products` : `Next `}
                </span>
                <svg
                  className="right-arrow"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8877 6.22237H10.1734M10.1734 6.22237L6.85912 1.81866M10.1734 6.22237L6.85912 10.6261"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div> 
      </div>
    </>
  );
};

export default CustomThreeScene;

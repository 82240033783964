import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Avatar from "react-avatar";
import * as Yup from "yup";
import countryCodes from "../../helper/CountryCodeInput";
import { useNavigate } from "react-router-dom";
import Spinner from "../../helper/Spinner";
import useAuthStore from "../../store/authStore";
import { ReactComponent as EditIcon } from "../../assets/svg/edit.svg";
import "../../style/components/dashboard/Profile.scss";
import ApiConfig from "../../helper/ApiConfig";
import { fetchData } from "../../fetcher/fetchData";

export default function Profile() {
  const navigate = useNavigate();
  const { user, token, updateUser } = useAuthStore();
  const [loading, setLoading ] = useState(false);
  const [userData, setUserData] = useState({});
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    updateProfile();
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: userData.first_name || "",
      last_name: userData.last_name || "",
      company: userData.company || "",
      country_code: userData.country_code || "",
      phone_number: userData.phone_number || "",
      website_url: userData.website_url || "",
      platform: userData.platform || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      company: Yup.string().required("Company Name is required"),
      country_code: Yup.string().required("Country Code is required"),
      phone_number: Yup.string()
        .required("Contact Number is required")
        .min(6, "Phone number must be at least 6 digits")
        .max(12, "Phone number must be at most 12 digits"),
      website_url: Yup.string(),
      platform: Yup.string(),
    }),
    onSubmit: handleUpdateInfo,
  });

  const updateProfile = async () => {
    setLoading(true);
    const info = await fetchData(
      ApiConfig.info,
      setLoading,
      "post",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (info?.data?.statusCode === 200 || info?.statusCode === 200) {
      setUserData(info.data.data.brandProfile);
      formik.setValues({
        first_name: info.data.data.brandProfile.first_name,
        last_name: info.data.data.brandProfile.last_name,
        company: info.data.data.brandProfile.company,
        country_code: info.data.data.brandProfile.country_code,
        phone_number: info.data.data.brandProfile.phone_number,
        website_url: info.data.data.brandProfile.website_url || "",
        platform: info.data.data.brandProfile.platform || "",
      });
    } else if (
      info?.data?.statusCode === 401 ||
      info?.statusCode === 401 ||
      info?.data?.statusCode === 500 ||
      info?.statusCode === 500
    ) {
      navigate("/login");
    } else {
      console.error(info);
    }
    setLoading(false);
    setDisabled(true);
  };

  async function handleUpdateInfo() {
    const data = { ...formik.values, brand_email: user.brand_email };
    setLoading(true);
    const response = await fetchData(
      ApiConfig.updateProfile,
      setLoading,
      "PUT",
      formik.values,
      { authorization: `Bearer ${token}` }
    );
    if (response?.statusCode === 401 || response?.statusCode === 500) {
      navigate("/login");
    }
    setLoading(false);
    updateUser(data);

    setLoading(false);
    setDisabled(true);
    updateProfile();
  }

  const cancelHandler = () => {
    setDisabled(true);
    updateProfile();
  };

  return (
    <div className="profile-info-form">
      {!loading? (
        <form onSubmit={formik.handleSubmit}>
          <div className="profile-detail-container">
            <div className="profile-details">
              <div className="profile-img">
                <Avatar
                  name={`${user.first_name} ${user.last_name}`}
                  size="72.5"
                  round={true}
                />
              </div>
              <div className="company-name">
                <p>{formik.values.company}</p>
                <p>{user.website_url}</p>
              </div>
            </div>
            <div className="edit" style={{ cursor: "pointer" }}>
              <p onClick={() => setDisabled(false)}>
                Edit{" "}
                <span>
                  <EditIcon />
                </span>
              </p>
            </div>
          </div>

          <div className="profile-info-container">
            <div className="firstname">
              <label>First Name</label>
              <input
                type="text"
                placeholder="Your First Name*"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                name="first_name"
                id="first_name"
                disabled={disabled}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <small className="error">{formik.errors.first_name}</small>
              ) : null}
            </div>
            <div className="lastname">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Your Last Name*"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                name="last_name"
                id="last_name"
                disabled={disabled}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <small className="error">{formik.errors.last_name}</small>
              ) : null}
            </div>
          </div>
          <div className="profile-info-container">
            <div className="email">
              <label>Email</label>
              <input
                type="email"
                disabled
                value={user.brand_email}
                id="brand_email"
              />
            </div>
            <div className="contact-info-container">
              <label>Contact Number</label>
              <div className="contact-info">
                <div className="country-code">
                  <select
                    name="country_code"
                    id="country_code"
                    className="my-input"
                    value={formik.values.country_code}
                    onChange={formik.handleChange}
                    disabled={disabled}
                  >
                    {countryCodes.map((code, i) => (
                      <option key={i} value={code.dial_code}>
                        {code.dial_code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mobile-number">
                  <input
                    type="text"
                    placeholder="Your Contact No.*"
                    name="phone_number"
                    id="phone_number"
                    onChange={formik.handleChange}
                    value={formik.values.phone_number}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="profile-info-container">
            <div className="website-url">
              <label>Website URL</label>
              <input
                placeholder="Website URL*"
                type="text"
                name="website_url"
                id="website_url"
                onChange={formik.handleChange}
                value={formik.values.website_url}
                disabled={disabled}
              />
              {formik.touched.website_url && formik.errors.website_url ? (
                <small className="error">{formik.errors.website_url}</small>
              ) : null}
            </div>
            <div className="platform">
              <label>Platform</label>
              <select
                name="platform"
                id="platform"
                value={formik.values.platform}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
              >
                <option value="0" hidden>
                  eCommerce Platform
                </option>
                <option value="1">Shopify</option>
                <option value="2">Other</option>
              </select>
            </div>
          </div>
          {!disabled && (
            <div className="button-wrapper">
              <button type="button" onClick={cancelHandler}>
                Cancel
              </button>
              <button type="submit">Save Changes</button>
            </div>
          )}
        </form>
      ) : (
        <div style={{width: '100%', height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Spinner />
        </div>
      )}
    </div>
  );
}
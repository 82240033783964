import React, { useRef, useEffect, useState } from "react";
import "./ThreeScreens.scss";
import CloseIcon from "@mui/icons-material/Close";
import homeIcon from "../assests/images/home.svg";
import { ReactComponent as LogoIcon } from "../assests/svg/logo-icon.svg";
import { ReactComponent as ColorIcon } from "../assests/svg/color-icon.svg";
import { ReactComponent as TextureIcon } from "../assests/svg/texture-icon.svg";
import { ReactComponent as LightingIcon } from "../assests/svg/lighting-icon.svg";
import { ReactComponent as CheckIcon } from "../assests/svg/check-icon.svg";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { useDropzone } from "react-dropzone";
import CongratulationDialog from "./dialog/CongratulationDialog.jsx";
import Product1 from "../assests/images/product-1.png";
import Product2 from "../assests/images/product-2.png";
import Product3 from "../assests/images/product-3.png";
import Product4 from "../assests/images/product-4.png";
import Product5 from "../assests/images/product-5.png";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import Movements from "../modules/movement.js";
import modelUrl from "../assests/model/Store_OneWayX_3DEvn_v2.glb";
import env1 from "../assests/images/Sky_Dome_equirectangular-jpg_pink_sky_1773824017_10340800.hdr";
import model1 from "../assests/model/model1.glb";
import model2 from "../assests/model/model2.glb";
import model3 from "../assests/model/model3.glb";
import model4 from "../assests/model/model4.glb";
import model5 from "../assests/model/model5.glb";
import EmailDialog from "./dialog/EmailDialog.jsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BackgroundMusic from "./BackgroundMusic.js";
import Header from "./Header.jsx";
import Spinner from "./Spinner.js";
function ThreeScreens() {
  const containerRef = useRef();
  const nameRef = useRef(null);
  const modelsRef = useRef([]);
  const modelsLengthRef = useRef([]);
  const videoRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [logoName, setLogoName] = useState("");
  const [fileName, setFileName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [logoloading, setLogoLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [camp, setCamp] = useState({});
  const [customData, setCustomData] = useState({
    light: "Bright",
    colour: "White",
    texture: "Glossy",
  });

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();
  const [roomComponents, setRoomComponents] = useState({
    imgSrc: "",
    fileSrc: "",
    fileType: "",
    lightIntensity: "50",
    color: "#FFFFFF",
    matTexture: "1",
    bright: "1",
  });
  const [selectComponents, setSelectComponents] = useState({
    imgSrc: "",
    fileSrc: "",
    fileType: "",
    lightIntensity: "50",
    color: "#FFFFFF",
    matTexture: "1",
    bright: "1",
  });
  const [isMinimise, setIsMinimise] = useState(false);
  const [isdisable, setDisable] = useState(true);
  const [isproductDragged, setPRoductDragged] = useState(true);
  const token = useSelector((state) => state.token);
  var data = [];
  useEffect(() => {
    if (!window.WebGLRenderingContext) {
      containerRef.current.innerHTML =
        "WebGL is not supported on this device/browser.";
      setIsLoading(false);
      return;
    }
    // Create the scene, camera, and renderer
    data = [];
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );
    const renderer = new THREE.WebGLRenderer();
    var container = containerRef.current;

    // Set up camera position
    camera.position.set(0.04, 0.27, 1.5);
    camera.fov = 2 * Math.atan(36 / (2 * 50)) * (180 / Math.PI);

    // Set up enviroment
    const hdrLoader = new RGBELoader();
    hdrLoader.load(env1, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.environment = envMap;
      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
      });
      const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      const backgroundMesh = new THREE.Mesh(
        backgroundGeometry,
        backgroundMaterial
      );
      scene.add(backgroundMesh);
      pmremGenerator.dispose();
    });

    //point Light
    //#fcb69f
    var lightColor = roomComponents.bright === "2" ? "#D4AF37" : "#ffffff";
    if (lightColor !== "#ffffff") {
      var pointLight = new THREE.PointLight(lightColor, 5);
      pointLight.position.set(2, 0.1, 4);
      scene.add(pointLight);
      data.push(pointLight);
      var pointLight2 = new THREE.PointLight(lightColor, 5);
      pointLight2.position.set(-1.5, 0.1, 4);
      scene.add(pointLight2);
      data.push(pointLight2);
    }

    var pointLight3 = new THREE.DirectionalLight(lightColor, 2);
    pointLight3.position.set(400, 10, 400);
    scene.add(pointLight3);
    data.push(pointLight3);

    const ambientLight = new THREE.AmbientLight(lightColor, 2);
    scene.add(ambientLight);
    data.push(ambientLight);

    // Setting up the main model
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);

    loader.load(
      modelUrl,
      (gltf) => {
        const desiredSize = 3;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
            if (child.name === "Ground_Stand_LOGO_Plane") {
              child.visible = false;
            }
            child.receiveShadow = true;
            if (child.name === "Mesh010_2" || child.name === "Mesh010") {
              child.material.map = null;
              child.material.color.set(roomComponents.color);
              if (roomComponents.matTexture === "1") {
                child.material.metalness = 0;
                child.material.roughness = 0.4;
              } else if (roomComponents.matTexture === "2") {
                child.material.metalness = 0.7;
                child.material.roughness = 0.4;
              }
            }
          }
        });
        gltf.scene.position.set(0, 0, 0.8);
        scene.add(gltf.scene);
        data.push(gltf.scene);
        setIsLoading(false);
      },
      () => {},
      (error) => {
        console.error(error);
      }
    );

    //Set up video/Image screen
    const screeenGeometry = new THREE.BoxGeometry(0.45, 0.23, 0.001);
    let screenMaterial;
    if (roomComponents.fileSrc !== "") {
      if (roomComponents.fileType === "video") {
        videoRef.current.src = roomComponents.fileSrc;
        videoRef.current.loop = true;
        videoRef.current.mute = true;
        videoRef.current.play();
        let video = document.getElementById("video");
        const vidTexture = new THREE.VideoTexture(video);
        vidTexture.minFilter = THREE.LinearFilter;
        vidTexture.magFilter = THREE.LinearFilter;
        screenMaterial = new THREE.MeshBasicMaterial({
          map: vidTexture,
          side: THREE.FrontSide,
          toneMapped: false,
        });
      } else {
        let screenTexture = new THREE.TextureLoader().load(
          roomComponents.fileSrc
        );
        screenMaterial = new THREE.MeshBasicMaterial({
          map: screenTexture,
        });
      }
    } else {
      screenMaterial = new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0,
      });
    }
    const screenMesh = new THREE.Mesh(screeenGeometry, screenMaterial);
    scene.add(screenMesh);
    screenMesh.position.set(0.057, 0.301, -0.023);

    // //Set screen for logo
    const logoGeometry = new THREE.BoxGeometry(0.23, 0.05, 0.005);
    let logoMaterial;
    let logoTexture = new THREE.TextureLoader().load(roomComponents.imgSrc);
    if (roomComponents.imgSrc !== "") {
      logoMaterial = new THREE.MeshBasicMaterial({
        map: logoTexture,
        transparent: true,
      });
    } else {
      logoMaterial = new THREE.MeshBasicMaterial({
        color: 0xe6aaa5,
        transparent: true,
        opacity: 0,
        shadowSide: THREE.DoubleSide,
      });
    }
    const logoMesh = new THREE.Mesh(logoGeometry, logoMaterial);
    scene.add(logoMesh);
    logoMesh.name = "logo";
    logoMesh.receiveShadow = true;
    logoMesh.castShadow = true;
    logoMesh.position.set(0.055, 0.4625, -0.022);
    data.push(logoMesh);

    //Set up product models
    const modelPaths = [model1, model2, model3, model4, model5];
    modelPaths.forEach((path, index) => {
      loader.load(path, (gltf) => {
        const desiredSize = 0.07;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        var length = boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.position.set(100, 100, 100);
        gltf.scene.userData.draggable = true;
        gltf.scene.userData.name = `model${index + 1}`;
        modelsLengthRef.current[index] = length;
        modelsRef.current[index] = gltf.scene;
        scene.add(gltf.scene);
      });
    });

    // Set up renderer and add it to the container
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    containerRef.current.appendChild(renderer.domElement);

    //raycasting
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();
    let xDrag, yDrag, zDrag;

    const onMouseMove = (e) => {
      pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
      pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(pointer, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      const abcIntersects = intersects.filter(
        (intersect) => intersect.object.name === "Table_Top"
      );
      if (abcIntersects.length > 0) {
        [xDrag, yDrag, zDrag] = [
          intersects[0].point.x,
          0.163,
          intersects[0].point.z,
        ];
        //intersects[0].object.material.color.set(0xff0000);
      } else {
        [xDrag, yDrag, zDrag] = [
          0.04347243041964999, 0.16322276459578058, 0.8132767067535347,
        ];
      }
    };

    // const hoverOver = (e) => {
    //   pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
    //   pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
    //   raycaster.setFromCamera(pointer, camera);
    //   const intersects = raycaster.intersectObjects(scene.children);
    //   const abcIntersects = intersects.filter(
    //     (intersect) => intersect.object.name === "Table_Top"
    //   );
    //   if (abcIntersects.length > 0) {
    //     if (intersects[0].object.name === "Table_Top") {
    //       intersects[0].object.material.color.set(0xff0000);
    //       return true;
    //     } 
    //     const meshWithNameAbc = findMeshByName(scene, "Table_Top");

    //     if (meshWithNameAbc) {
    //       // Change the color of the mesh
    //       meshWithNameAbc.material.color.set(0x010101); // Set to red (change the color as needed)
    //     }
    //     //intersects[0].object.material.color.set(0xff0000);
    //   }
    // };
    // const findMeshByName = (object, name) => {
    //   if (object.name === name) {
    //     return object;
    //   }
    //   if (object.children !== undefined && object.children.length > 0) {
    //     for (let child of object.children) {
    //       const found = findMeshByName(child, name);
    //       if (found !== undefined) {
    //         return found;
    //       }
    //     }
    //   }
    //   return undefined;
    // };

    // const mouseOut = (e) => {
    //   pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
    //   pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
    //   raycaster.setFromCamera(pointer, camera);
    //   const intersects = raycaster.intersectObjects(scene.children);
    //   const abcIntersects = intersects.filter(
    //     (intersect) => intersect.object.name === "Table_Top"
    //   );
    //   if (abcIntersects.length > 0) {
    //     intersects[0].object.material.color.color.set(null);
    //     //intersects[0].object.material.color.set(0xff0000);
    //   }
    // };

    // window.addEventListener("mousemove", hoverOver, false);
    // window.addEventListener("mouseout", mouseOut, false);

    const onMouseUp = () => {
      setTimeout(function () {
        xDrag = 100;
        yDrag = 100;
        zDrag = 100;
      }, 2);
    };
    window.addEventListener("mousedown", onMouseUp, false);
    window.addEventListener("dragend", onMouseMove, false);

    //handle the resize of the screen
    // const handleResize = () => {
    //   const width = containerRef.current.offsetWidth;
    //   const height = containerRef.current.offsetHeight;
    //   camera.aspect = width / height;
    //   camera.updateProjectionMatrix();
    //   renderer.setSize(width, height);
    // };
    // window.addEventListener("resize", handleResize);

    function returnToHomePosition() {
      camera.position.set(0.04, 0.27, 1.5); // Set the camera's position to its home position
      camera.rotation.x = 0;
      camera.rotation.y = 0;
      camera.rotation.z = 0;
    }

    // Attach an event listener to the div with id "home"
    const homeDiv = document.getElementById("home");
    if (homeDiv) {
      homeDiv.addEventListener("click", returnToHomePosition);
    }

    function download() {
      const exporter = new GLTFExporter();
      exporter.parse(
        data,
        function (gltfJson) {
          const jsonString = JSON.stringify(gltfJson);
          const blob = new Blob([jsonString], { type: "application/json" });
          //saveAs(blob, "scene.gltf");
          const fileName = "scene.gltf";
          const blobWithFileName = new Blob([blob], {
            type: blob.type,
            endings: "native",
          });
          blobWithFileName.name = fileName;
          exportEnviroment(blobWithFileName);
        },
        { binary: true }
      );
    }

    // Add click event listener to the export button
    //document.getElementById("exportButton").addEventListener("click", download);

    // Animation function
    const animate = () => {
      var player = { speed: 0.01, turnSpeed: Math.PI * 0.01 };
      requestAnimationFrame(animate);
      //Up Movemet
      if (Movements.isPressed(38)) {
        camera.rotation.x = 0;
        camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
        camera.position.z -= Math.cos(camera.rotation.y) * player.speed;
      }
      //Movement updated code
      if (Movements.isPressed(40)) {
        camera.rotation.x = 0;
        camera.position.x += Math.sin(camera.rotation.y) * player.speed;
        camera.position.z += Math.cos(camera.rotation.y) * player.speed;
      }

      //right Movement
      if (Movements.isPressed(37)) {
        camera.rotation.y += player.turnSpeed;
      }
      //left Movement
      if (Movements.isPressed(39)) {
        camera.rotation.y -= player.turnSpeed;
      }
      if (camera.position.z <= 0) {
        camera.position.z = 0;
      }
      if (camera.position.z >= 1.5) {
        camera.position.z = 1.5;
      }
      if (camera.position.x >= 0.53) {
        camera.position.x = 0.53;
      }
      if (camera.position.x <= -0.4) {
        camera.position.x = -0.4;
      }

      const name = nameRef.current;
      let models;
      if (modelsRef.current.length > 0) {
        models = modelsRef.current;
      }
      if (modelsLengthRef.current.length > 0) {
        models.forEach((model, index) => {
          if (name === `model${index + 1}`) {
            if (xDrag !== 100) {
              model.position.set(xDrag, yDrag, zDrag);
              xDrag = 100;
            }
          }
          model.rotation.y += 0.0032;
        });
      }
      renderer.render(scene, camera);
    };
    animate();
    return () => {
      scene.traverse((obj) => {
        if (obj instanceof THREE.Mesh) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      renderer.dispose();
      setIsLoading(true);
      scene = new THREE.Scene();
      container.removeChild(renderer.domElement);
    };
  }, [roomComponents]);

  const handleDrop1 = (acceptedFiles) => {
    setLogo(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setLogoName(newFile);
    const imageUrl = URL.createObjectURL(file);
    selectComponents.imgSrc = imageUrl;
  };
  const handleDrop2 = (acceptedFiles) => {
    setCamp(acceptedFiles[0]);
    const file = acceptedFiles[0];
    var fileN = file.name;
    let lastIndex = fileN.lastIndexOf(".");
    let fileExtension = fileN.slice(lastIndex + 1);
    let shortenedName = fileN.slice(0, Math.min(3, fileN.length));
    let newFile;
    if (Math.min(4, fileN.length - fileExtension.length - 1) > 3) {
      newFile = shortenedName + "..." + fileExtension;
    } else {
      newFile = shortenedName + "." + fileExtension;
    }
    setFileName(newFile);
    const fileType = file.type.startsWith("image/") ? "image" : "video";
    const fileUrl = URL.createObjectURL(file);
    selectComponents.fileSrc = fileUrl;
    selectComponents.fileType = fileType;
  };
  const dropzone1 = useDropzone({
    onDrop: handleDrop1,
    accept: "image/*",
    multiple: false,
  });

  const dropzone2 = useDropzone({
    onDrop: handleDrop2,
    accept: "image/*,video/*",
    multiple: false,
  });

  const handleStep = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setCompletedSteps((prevValue) => {
      return [currentStep, ...prevValue];
    });
    if (currentStep !== 4) {
      const {
        imgSrc,
        fileSrc,
        fileType,
        lightIntensity,
        color,
        matTexture,
        bright,
      } = selectComponents;
      var currentColour;
      if (color === "#FFFFFF") {
        currentColour = "White";
      } else if (color === "#A59385") {
        currentColour = "Cappuccino";
      } else {
        currentColour = "Rose Pink";
      }
      setCustomData({
        light: bright === 1 ? "Bright" : "Warm",
        colour: currentColour,
        texture: matTexture === 1 ? "Glossy" : "Matt",
      });

      setRoomComponents({
        imgSrc,
        fileSrc,
        fileType,
        lightIntensity,
        color,
        matTexture,
        bright,
      });
    } else {
      setIsLoading(false);
      setOpenDialog(true);
    }
  };

  function handleNext() {
    if (currentStep === 1) {
      if (logo !== null && Object.keys(logo).length !== 0) {
        handleData(logo);
      }
    }
    if (currentStep === 2) {
      if (camp !== null && Object.keys(camp).length !== 0) {
        handleCampaign(camp);
      }
    }
    if (currentStep === 3) {
      if (customData !== null && Object.keys(customData).length !== 0) {
        handleCustomData();
      }
    }
    if (currentStep !== 4) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(1);
    }
  }

  const handleRoom = (event) => {
    setDisable(false);
    const { name, value } = event.target;
    setSelectComponents({ ...selectComponents, [name]: value });
  };

  const clearLogo = (event) => {
    selectComponents.imgSrc = "";
    event.stopPropagation();
    setLogoName("");
  };

  const clearVideo = (event) => {
    selectComponents.fileSrc = "";
    selectComponents.fileType = "";
    event.stopPropagation();
    setFileName("");
  };

  const handleImageClick = (modelName) => {
    setPRoductDragged(false);
    nameRef.current = modelName;
  };
  var rootElement = document.getElementById("root");
  if (openDialog || openEmailDialog) {
    rootElement.classList.add("blurred-background");
  } else {
    rootElement.classList.remove("blurred-background");
  }

  function handleClose(event) {
    if (event) {
      setOpenEmailDialog(true);
    }
  }

  async function handleData(val) {
    if(token === null){
      return;
    }
    setLogoLoading(true);
    const formData = new FormData();
    formData.append("logo", val, val.name);
    const response = await fetchData(
      ApiConfig.addLogo,
      setLogoLoading,
      "media",
      formData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLogoLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleCampaign(val) {
    setCampaignLoading(true);
    const formData = new FormData();
    formData.append("campaign", val, val.name);
    const response = await fetchData(
      ApiConfig.addCampaign,
      setCampaignLoading,
      "media",
      formData,      
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setCampaignLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleCustomData() {
    setCampaignLoading(true);
    const response = await fetchData(
      ApiConfig.addCustom,
      setCampaignLoading,
      "post",
      customData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setCampaignLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function exportEnviroment(val) {
    // setLogoLoading(true);
    // const formData = new FormData();
    // formData.append("env", val, val.name);
    // try {
    //   const response = await fetchData(
    //     ApiConfig.exportCustomEnv,
    //     "media",
    //     formData,
    //     setLogoLoading,
    //     false
    //   );
    //   setLogoLoading(false);
    //   if (response) {
    //     console.log(response);
    //   }
    // } catch (error) {
    //   console.error("Error saving GLTF file in the database:", error);
    //   setLogoLoading(false);
    // }
  }

  let toggleButton = (
    <KeyboardArrowDownIcon
      style={{ fill: "#ee3a24" }}
      onClick={onToggleMinimise}
    ></KeyboardArrowDownIcon>
  );

  if (isMinimise) {
    toggleButton = (
      <KeyboardArrowUpIcon
        style={{ fill: "#ee3a24" }}
        onClick={onToggleMinimise}
      ></KeyboardArrowUpIcon>
    );
  }
  function onToggleMinimise() {
    setIsMinimise((prevValue) => !prevValue);
  }
  return (
    <>
      <Header variation={2}></Header>
      <div>
        <div
          className={isLoading ? `loadingTab` : ``}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner />
            </div>
          )}
          <div
            ref={containerRef}
            style={{ height: "100%", overflow: "hidden" }}
          ></div>
        </div>
        <video
          id="video"
          ref={videoRef}
          style={{ display: "none" }}
          muted
          loop
          autoPlay
        />
        <div className="homeIcon" id="home">
          <img src={homeIcon} alt="home" />
        </div>

        <BackgroundMusic />
        <div
          className={`custom-create-section`}
          style={{
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            overflow: "hidden",
            height: `${isMinimise ? "3.4rem" : "auto"} `,
            width: "max-content",
            padding: " 0",
          }}
        >
          <div className="minimise-tray">
            <div>{toggleButton}</div>
          </div>
          <div className="custom-create-container">
            <div className="create-stepper-container">
              <button
                className={`${
                  currentStep === 1
                    ? `active-stepper`
                    : completedSteps.includes(1)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(1);
                }}
              >
                <div className="stepper-button-title">
                  Add Your Logo
                  {completedSteps.includes(1) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
              <button
                className={`${
                  currentStep === 2
                    ? `active-stepper`
                    : completedSteps.includes(2)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(2);
                }}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div>
                  <div className="stepper-button-title">Add Your Campaign</div>
                  <div className="stepper-button-subTitle">Image or Video</div>
                </div>
                {completedSteps.includes(2) && (
                  <CheckIcon className="stepper-check-icon" />
                )}
              </button>
              <button
                className={`${
                  currentStep === 3
                    ? `active-stepper`
                    : completedSteps.includes(3)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(3);
                }}
              >
                <div className="stepper-button-title">
                  Customise The Environment
                  {completedSteps.includes(3) && (
                    <CheckIcon className="stepper-check-icon" />
                  )}
                </div>
              </button>
              <button
                className={`${
                  currentStep === 4
                    ? `active-stepper`
                    : completedSteps.includes(4)
                    ? `complete-stepper`
                    : ``
                } custom-stepper-button `}
                onClick={() => {
                  setCurrentStep(4);
                }}
              >
                <div className="stepper-button-title">Add Products</div>
                <div className="stepper-button-subTitle">
                  Click and drag the product into the environment
                </div>
              </button>
            </div>
            {currentStep === 1 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">Add Your Logo</div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone1.getRootProps()}
                >
                  <input {...dropzone1.getInputProps()} accept="image/*" />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {logoName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {logoName}
                      <div onClick={clearLogo}>
                        <CloseIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="custom-drag-file-container">
                <div className="custom-drag-logo-content">
                  <LogoIcon />
                  <div className="custom-drag-logo-title">
                    Add Campaign Image or Video
                  </div>
                </div>
                <div
                  className={`custom-drag-box ${isDragActive ? "active" : ""}`}
                  {...dropzone2.getRootProps()}
                >
                  <input
                    {...dropzone2.getInputProps()}
                    accept="image/*,video/*"
                  />
                  <div className="custom-drag-box-title">
                    Drag and drop an image file here or
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Upload from computer
                  </div>
                  {fileName && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="custom-drag-box-title input-box"
                    >
                      {fileName}
                      <CloseIcon onClick={clearVideo} />
                    </div>
                  )}
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="custom-create-input-container">
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <LightingIcon />
                    <div className="custom-create-input-title">Lighting</div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="bright"
                      value={selectComponents.bright}
                      onChange={handleRoom}
                    >
                      <option value="1">Bright</option>
                      <option value="2">Warm</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <ColorIcon />
                    <div className="custom-create-input-title">
                      Counter Colour
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="color"
                      value={selectComponents.color}
                      onChange={handleRoom}
                    >
                      <option value="#FFFFFF">White</option>
                      <option value="#F5D7D5">Rose Pink</option>
                      <option value="#A59385">Cappuccino</option>
                    </select>
                  </div>
                </div>
                <div className="custom-create-input-group">
                  <div className="custom-create-inputs">
                    <TextureIcon />
                    <div className="custom-create-input-title">
                      Counter Texture
                    </div>
                  </div>
                  <div className="custom-create-selectBox">
                    <select
                      name="matTexture"
                      value={selectComponents.matTexture}
                      onChange={handleRoom}
                    >
                      <option value="1">Glossy</option>
                      <option value="2">Matt</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 4 && (
              <div className="custom-add-product-container">
                <div className="custom-add-product-group">
                  <div className="product-card-container">
                    <div className="product-card">
                      <img
                        src={Product1}
                        alt="Product 1"
                        onMouseDown={() => handleImageClick("model1")}
                      />
                    </div>
                    <div className="product-card-name">Day Cream</div>
                  </div>
                  <div className="product-card-container">
                    <div className="product-card">
                      <img
                        src={Product2}
                        alt="Product 2"
                        onMouseDown={() => handleImageClick("model2")}
                      />
                    </div>
                    <div className="product-card-name">Night Cream</div>
                  </div>
                  <div className="product-card-container">
                    <div className="product-card">
                      <img
                        src={Product3}
                        alt="Product 3"
                        onMouseDown={() => handleImageClick("model3")}
                      />
                    </div>
                    <div className="product-card-name">Foundation</div>
                  </div>
                  <div className="product-card-container">
                    <div className="product-card">
                      <img
                        src={Product4}
                        alt="Product 4"
                        onMouseDown={() => handleImageClick("model4")}
                      />
                    </div>
                    <div className="product-card-name">Primer</div>
                  </div>
                  <div className="product-card-container">
                    <div className="product-card">
                      <img
                        src={Product5}
                        alt="Product 5"
                        onMouseDown={() => handleImageClick("model5")}
                      />
                    </div>
                    <div className="product-card-name">Lip Gloss</div>
                  </div>
                </div>
              </div>
            )}
            <div className="custom-create-cta-group">
              <button
                className={`common-cta-button `}
                onClick={handleStep}
                disabled={
                  (isdisable && currentStep === 3) ||
                  (currentStep === 2 && selectComponents.fileSrc === "") ||
                  (currentStep === 1 && selectComponents.imgSrc === "") ||
                  (currentStep === 4 && isproductDragged)
                }
              >
                <span className="common-cta-text">Apply</span>
              </button>
              <button
                className={`common-cta-button`}
                onClick={() => handleNext()}                
              >
                <span className="common-cta-text">Next</span>
              </button>
              {/* <button
                className={`common-cta-button`}
                id="exportButton"
                style={{
                  display: `${currentStep === 4 ? `initial` : `none`}`,
                }}
              >
                <span className="common-cta-text">Launch</span>
              </button> */}
            </div>
          </div>
        </div>
        {openDialog && (
          <CongratulationDialog
            handleCloseDialog={handleClose}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />
        )}
        {openEmailDialog && (
          <EmailDialog
            handleCloseDialog={handleClose}
            openEmailDialog={openEmailDialog}
            setOpenEmailDialog={setOpenEmailDialog}
          />
        )}
      </div>
    </>
  );
}

export default ThreeScreens;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../helper/Spinner";
import PopupPage from "../../components/popup/PopupPage";
import Congratulations from "../../components/dashboard/Congratulations";
import useAuthStore from "../../store/authStore";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svg/right-arrow.svg";
import "../../style/pages/dashboard/Homepage.scss";
import { fetchData } from "../../fetcher/fetchData";
import ApiConfig from "../../helper/ApiConfig";

export default function Homepage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPoint, setCurrentPoint] = useState();
  const [subVal, setSubVal] = useState(0);
  const [congratsPage, setCongratsPage] = useState(false);
  const [openPopup, setPopupOpen] = useState(false);
  const { token, user } = useAuthStore();
  const username = user.first_name;

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.data.statusCode === 200) {
        if (response.data.data.completed_step === 5) {
          setCongratsPage(true);
        }
        setCurrentPoint(response.data.data.completed_step);
      }
      const info = await fetchData(
        ApiConfig.info,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info.data.statusCode === 200) {
        setSubVal(info.data.data.brandProfile.subscription_id);
      }
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  const handlePopup = (data) => {
    setPopupOpen(false);
  };
  if (congratsPage) {
    return (
      <>
        <Congratulations />
      </>
    );
  }
  return (
    <>
      <div className="home-container">
        {loading && (
          <>
            <div className="user-name">
              <h1>Hi {username || "username"}, </h1>
              <p>Welcome to your dashboard!</p>
            </div>
            <div>
              <div className="pick-subscription-plan">
                <p>Choose your Subscription plan</p>
                <button
                  className="continue-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/dashboard/subscription")}
                >
                  {loading && (subVal > 0 ? `Update` : `Select`)}{" "}
                  <span>
                    <ArrowIcon />
                  </span>
                </button>
              </div>
            </div>
            <div className="build-virtual-reality">
              <h1>
                Get Started with Your Virtual Retail Experience in 5 Easy Steps
              </h1>
            </div>

            <div className="step-container">
              <div
                className={`step ${currentPoint === 1 ? "step-active" : ""}`}
              >
                <div
                  onClick={() => {
                    if (subVal !== null) {
                      if (1 <= currentPoint) {
                        navigate("/dashboard/select-enviroment");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="circle">1</div>
                </div>

                <div
                  className="first_step step_box"
                  onClick={() => {
                    if (subVal !== null) {
                      if (1 <= currentPoint) {
                        navigate("/dashboard/select-enviroment");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="customise_env">
                    <div className="title">
                      <p>Choose Your Virtual Retail Space</p>
                    </div>
                    <div className="caption">
                      <p>
                        Choose an environment from the marketplace and customise
                        it to suit your brand.
                      </p>
                    </div>
                  </div>
                  <span>
                    <RightArrowIcon />
                  </span>
                </div>
              </div>
              <div
                className={`step ${
                  currentPoint === 2
                    ? "step-active"
                    : currentPoint < 2
                    ? "step-not-active"
                    : ""
                }`}
              >
                <div
                  onClick={() => {
                    if (subVal !== null) {
                      if (currentPoint < 2) {
                        setPopupOpen(true);
                      } else {
                        navigate("/dashboard/select-store");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="circle">2</div>
                </div>
                <div
                  className="second_step step_box"
                  onClick={() => {
                    if (subVal !== null) {
                      if (currentPoint < 2) {
                        setPopupOpen(true);
                      } else {
                        navigate("/dashboard/select-store");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="customise_env">
                    <div className="title">
                      <p>Upload Your Products</p>
                    </div>
                    <div className="caption">
                      <p>
                        Connect with your eCommerce platform and choose the
                        products you want to feature.
                      </p>
                    </div>
                  </div>
                  <span>
                    <RightArrowIcon />
                  </span>
                </div>
              </div>
              <div
                className={`step ${
                  currentPoint === 3
                    ? "step-active"
                    : currentPoint < 3
                    ? "step-not-active"
                    : ""
                }`}
              >
                <div
                  onClick={() => {
                    if (subVal !== null) {
                      if (currentPoint < 3) {
                        setPopupOpen(true);
                      } else {
                        navigate("/dashboard/customize-store");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="circle">3</div>
                </div>
                <div
                  className="third_step step_box"
                  onClick={() => {
                    if (subVal !== null) {
                      if (currentPoint < 3) {
                        setPopupOpen(true);
                      } else {
                        navigate("/dashboard/customize-store");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="customise_env">
                    <div className="title">
                      <p>Merchandise Your Virtual Retail Experience</p>
                    </div>
                    <div className="caption">
                      <p>
                        Curate and style your VR environment for an immersive
                        shopping experience.
                      </p>
                    </div>
                  </div>
                  <span>
                    <RightArrowIcon />
                  </span>
                </div>
              </div>
              <div
                className={`step ${
                  currentPoint === 4
                    ? "step-active"
                    : currentPoint < 4
                    ? "step-not-active"
                    : ""
                }`}
              >
                <div
                  onClick={() => {
                    if (subVal !== null) {
                      if (currentPoint < 4) {
                        setPopupOpen(true);
                      } else {
                        navigate("/dashboard/add-game");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="circle">4</div>
                </div>
                <div
                  className="fourth_step step_box"
                  onClick={() => {
                    if (subVal !== null) {
                      if (currentPoint < 4) {
                        setPopupOpen(true);
                      } else {
                        navigate("/dashboard/add-game");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="customise_env">
                    <div className="title">
                      <p>Add Gamification</p>
                    </div>
                    <div className="caption">
                      <p>
                        Add engaging gamification elements to enhance customer
                        engagement.
                      </p>
                    </div>
                  </div>
                  <span>
                    <RightArrowIcon />
                  </span>
                </div>
              </div>
              <div
                className={`step ${
                  currentPoint === 5
                    ? "step-active"
                    : currentPoint < 5
                    ? "step-not-active"
                    : ""
                }`}
              >
                <div
                  onClick={() => {
                    if (subVal !== null) {
                      if (5 <= currentPoint) {
                        navigate("/launch");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="circle">5</div>
                </div>
                <div
                  className="fifth_step step_box"
                  onClick={() => {
                    if (subVal !== null) {
                      if (5 <= currentPoint) {
                        navigate("/launch");
                      }
                    } else {
                      navigate("/dashboard/subscription");
                    }
                  }}
                >
                  <div className="customise_env">
                    <div className="title">
                      <p>Launch Your VR Experience</p>
                    </div>
                    <div className="caption">
                      <p>Go live with your Virtual Retail environment.</p>
                    </div>
                  </div>
                  <span>
                    <RightArrowIcon />
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {!loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
            }}
          >
            {" "}
            <Spinner />{" "}
          </div>
        )}
      </div>
      {openPopup && <PopupPage closePopup={handlePopup} />}
    </>
  );
}

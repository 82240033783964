// SideNav.js
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../assests/svg/House_01.svg";
import { ReactComponent as EnvironmentIcon } from "../assests/svg/Icon.svg";
import { ReactComponent as ProductIcon } from "../assests/svg/product-icon.svg";
import { ReactComponent as AnalyticsIcon } from "../assests/svg/analytic-icon.svg";
import { ReactComponent as GamificationIcon } from "../assests/svg/gamification.svg";
import { ReactComponent as LeftArrowIcon } from "../assests/svg/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../assests/svg/right-arrow.svg";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import { useSelector } from "react-redux";

export default function SideNav() {
  const currentUrl = useLocation();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setActive] = useState(1);
  const [subVal, setSubVal] = useState(0);
  const [isURL,setURL] = useState('');
  const navigate = useNavigate();
  let path =( currentUrl.pathname.includes("dashboard/") ||!currentUrl.pathname.includes("/dashboard") );
  let isProductAnalytics = currentUrl.pathname.includes("/product-analytics");
  const token = useSelector((state) => state.token);

  useEffect(() => {

    if (window.screen.width < 769) {
      setShow(true);
    }
    if(!path){
      setShow(false)
    }   
    const getEnvDetails = async () => {
      setLoading(false);
      const info = await fetchData(
        ApiConfig.info,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info.statusCode === (401) || info.statusCode === (500)) {
        navigate("/login");
      } else if (info.data.statusCode === 200) {
        setSubVal(info.data.data.envDetail.completed_step);
        setURL(info.data.data.envDetail.brand_param)
      }
      setLoading(true);
    }
    getEnvDetails();
  }, [path]);

  const onToggleExit = () => {
    setShow(!show);
  };

  return (
    <div className={"side-nav " + (path && (show ? " close" : ""))}>
      <ul className="nav-links">
        {(currentUrl.pathname.includes("dashboard/") ||
        !currentUrl.pathname.includes("/dashboard") && !currentUrl.pathname.includes("/congratulation/env") )? (
          <li onClick={onToggleExit}>
            {" "}
            <NavLink className={
      show ? "collapse" : ""}>
              {show ? (
                <>
                  <RightArrowIcon /> 
                </>
              ) : (
                <>
                  <LeftArrowIcon />
                </>
              )}
            </NavLink>
          </li>
        ) : (
          ""
        )}

        <li className={(isActive === 1 && !isProductAnalytics)? 'active_link' : ''}
            onClick={()=> setActive(1)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            exact="true"
            to="/dashboard"
          >
            <HomeIcon /> <span className="link_name">Home</span>{" "}
          </NavLink>
        </li>
        <li className={(isActive === 2 && !isProductAnalytics)? 'active_link' : ''}
            onClick={()=> setActive(2)} 
        >
          <NavLink className={show ? "collapse" : ""}  
          to={(subVal === 5 || isURL !== null )? '/dashboard/enviroment':"/dashboard/coming-soon"}
          >
            <EnvironmentIcon /> <span className="link_name"> Environment </span>{" "}
          </NavLink>
        </li>
        <li className={( isActive === 3 || isProductAnalytics)? 'active_link' : ''}
            >
          <NavLink
            className={show ? "collapse" : ""}
            to={(subVal === 5 || isURL !== null )? '/dashboard/product-analytics':"/dashboard/coming-soon"}
            onClick={()=> setActive(3)} 
          >
            <ProductIcon /> <span className="link_name"> Product </span>
          </NavLink>
        </li>
        <li className={(isActive === 4 && !isProductAnalytics)? 'active_link' : ''}
            onClick={()=> setActive(4)}>
          <NavLink className={show ? "collapse" : ""}  
          to={(subVal === 5 || isURL !== null ) ? '/dashboard/game':"/dashboard/coming-soon"}       
          >
            <GamificationIcon />{" "}
            <span className="link_name"> Gamification </span>
          </NavLink>
        </li>
        <li 
        className={(isActive === 5 && !isProductAnalytics)? 'active_link' : ''}
        onClick={()=> setActive(5)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            to={(subVal === 5 || isURL !== null ) ? "/dashboard/analytics":"/dashboard/coming-soon"}
          >
            <AnalyticsIcon /> <span className="link_name"> Analytics </span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

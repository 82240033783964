import React, { useState, useRef, useEffect } from 'react';
import './BackgroundMusic.scss';
import playArrowIcon from '../assests/images/play.svg';
import pauseIcon from '../assests/images/pause.svg';
import bgMusic from '../assests/songs/music.mp3';

function BackgroundMusic() {
    const [isPlaying, setIsPlaying] = useState(false); 
    const audioRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;
        
        const timeoutId = setTimeout(() => {
            const playPromise = audio.play();
            audio.volume = 0.1;
        
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    setIsPlaying(true);
                }).catch(error => {
                    setIsPlaying(false);
                });
            }
        }, 1000); 

        return () => clearTimeout(timeoutId);
        
    }, []);

    useEffect(() => {
        localStorage.setItem('isPlaying', JSON.stringify(isPlaying));
    }, [isPlaying]);

    const togglePlay = () => {
        const audio = audioRef.current;
        
        if (audio.paused) {
            audio.play();
            setIsPlaying(true);
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    };

    return (
        <>
            <div className='audio-controls' style={{ position: 'absolute', bottom: '1rem', right: '1rem', zIndex: '1' }}>
                {isPlaying ? (
                    <img src={playArrowIcon} alt='play' onClick={togglePlay} style={{ cursor: 'pointer' }}/>
                ) : (
                    <img src={pauseIcon} alt='pause' onClick={togglePlay} style={{ cursor: 'pointer' }}/>
                )}
            </div>
            <audio ref={audioRef} src={bgMusic} loop />
        </>
    );
}

export default BackgroundMusic;

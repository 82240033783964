import "./ui.css";
import "./font/Univers-light-normal.ttf";
import "./font/UniversBlack.ttf";
import "./font/UniversLTStd.otf";
import "./font/UniversLTStd-Bold.otf";
import axios from "axios";
import useUIStore from "../../utils2/uistore";
import Product from "./Product/Product";
import Header from "./Header/Header";
import ProductList from "./ProductList/ProductList";
import ThankYou from "./ThankYou/ThankYou";
import Instruction from "./Instruction/Instruction";
import Cart from "./ShoppingCart/Cart";
import TradeMark from "./TradeMark/TradeMark";
import { useEffect, useState } from "react";
import useShopifyStore from "../../utils2/shopifyStore";
import { Player, SoundOnOff } from "./Audio";
import Game_Popups from "../Game/Game";
import KeyboardIcon from "./KeyboardIcon/KeyboardIcon";
import ViewportHeightSetter from "../../utils2/useViewportHeightSetter";
import Header2 from '../../../components/Header';


export default function UI({musicUrl}) {
  // const {
  //   showCart,
  //   setCartLoaded,
  //   setCartOpened,
  //   setCartChecked,
  //   count,
  //   increase,
  //   getProductData,
  //   showItems,
  //   setActiveImageIndex,
  //   setShowActiveImage,
  // } = useUIStore((state) => state);
  // const { setProductList, cartItems, setCartItems, checkOutId, setCheckOutId } =
  //   useShopifyStore();



  // useEffect(() => {
  //   setCheckOutId(localStorage.getItem("checkoutId"));
  //   getCartItem();
  // }, []);


  // useEffect(() => {
  //   if (!showCart) {
  //     setCartLoaded(false);
  //     setCartChecked(false);
  //     setCartOpened(false);
  //   }
  // }, [showCart]);

  // useEffect(() => {
  //   if (!showItems) {
  //     setActiveImageIndex(0);
  //     setShowActiveImage(false);
  //   }
  // }, [showItems]);

  // const getCartItem = async () => {
  //   try {
  //     let totalCount = 0;
  //     let checkoutid = localStorage.getItem("checkoutId");
  //     if (checkoutid !== null) {
  //       const response = await axios.post(
  //         "https://gabar-prod.onewayx.world/cart",
  //         {
  //           checkoutId: checkoutid,
  //         }
  //       );
  //       // console.log("RAN");
  //       const cartList = response.data.cartItems;
  //       cartList?.forEach((cartItem) => {
  //         totalCount += cartItem.quantity;
  //       });
  //       increase(totalCount);
  //       setCartItems(response.data);
  //       increase(totalCount);
  //     }
  //     if (showCart) {
  //       // console.log("RESOLVING CART COMPLETED")
  //       setCartLoaded(true);
  //       setCartChecked(true);
  //     }
  //     // console.log("REACHING");
  //   } catch (error) {
  //     console.error("Error fetching cart items:", error);
  //   }
  // };

  return (
    <>
      <div className="no-text-select">
        <ViewportHeightSetter />
        <Header2 variation={5}/>
        <Instruction />
        { musicUrl && 
          <>
            <Player musicUrl={musicUrl} />
            <SoundOnOff />
          </>          
        }
        <TradeMark />
        <KeyboardIcon />
        
      </div>
    </>
  );
}

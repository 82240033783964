import { Abc, ArrowBack, ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Upload } from "../../../assets/svg/upload2.svg";
import "../../../style/components/store/CustomizeEnv.scss";
import Gabar from "../../../enviroments/gabar/Gabar";
import useVideoStore from "../../../enviroments/gabar/util/video";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import useAuthStore from "../../../store/authStore";
import useStore from "../../../enviroments/gabar/util/3dstore";

const CustomizeEnv = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [audioMainFile, setAudioMainFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [audioName, setAudioName] = useState("");

  const [videoFile, setVideoFile] = useState(null);
  const [videoMainFile, setVideoMainFile] = useState(null);
  const [videoName, setVideoName] = useState("");
  const { handleVideoFileData, handleAudioFileData } = useVideoStore();
  const { token, setLoading } = useAuthStore();
  const { handleReset } = useStore(
    (state) => state
  );

  useEffect(() => {
    handleReset();
    const getEnvDetails = async () => {
      setLoading(false);
      const file = await fetchData(
        ApiConfig.fileMapping,
        setLoading,
        "GET",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (file?.status === 401 || file?.status === 500) {
        navigate("/login");
      } else if (file?.status === 200) {
        if (
          file?.data?.data?.audio_result[
            file?.data.data.audio_result.length - 1
          ]?.audio_url !== null
        ) {
          handleAudioFileData(
            file?.data?.data?.audio_result[
              file?.data.data.audio_result.length - 1
            ]?.audio_url
          );
          setAudioName(
            getShortenedFileName(
              file?.data?.data?.audio_result[
                file?.data.data.audio_result.length - 1
              ]?.audio_url
            )
          );
          setAudioFile(
            file?.data?.data?.audio_result[
              file?.data.data.audio_result.length - 1
            ]?.audio_url
          );
        }
        if (file?.data?.data?.video_result[0]?.video_url !== null) {
          handleVideoFileData(file?.data?.data?.video_result[0]?.video_url);
          setVideoName(
            getShortenedFileName(file?.data?.data?.video_result[0]?.video_url)
          );
          setVideoFile(file?.data?.data?.video_result[0]?.video_url);
        }
      }
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  const navigate = useNavigate();

  const getShortenedFileName = (fileName) => {    
    if(!(fileName === null || fileName === "" || fileName === undefined)) {
    let lastIndex = fileName.lastIndexOf(".");
    let fileExtension = fileName.slice(lastIndex + 1);
    let shortenedName = fileName.slice(0, Math.min(3, fileName.length));
    if (Math.min(4, fileName.length - fileExtension.length - 1) > 3) {
      return shortenedName + "..." + fileExtension;
    } else {
      return shortenedName + "." + fileExtension;
    }
  }
  else{
    return;
  }
  };

  const handleDropAudio = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setAudioMainFile(file);
    if (audioFile) URL.revokeObjectURL(audioFile);
    const temp = URL.createObjectURL(file);
    setAudioFile(temp);
    handleAudioFileData(temp);
    const fileName = getShortenedFileName(file.name);
    setAudioName(fileName);
  };

  const dropzoneAudio = useDropzone({
    onDrop: handleDropAudio,
    accept: "audio/*",
    multiple: false,
  });

  const removeAudio = (e) => {
    setAudioName("");
    setAudioFile(null);
    handleAudioFileData();
  };

  const handleDropVideo = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setVideoMainFile(file);
    const temp = URL.createObjectURL(file);
    setVideoFile(temp);
    handleVideoFileData(temp);

    const fileName = getShortenedFileName(file.name);
    setVideoName(fileName);
  };

  const dropzoneVideo = useDropzone({
    onDrop: handleDropVideo,
    accept: "video/*",
    multiple: false,
  });

  const removeVideo = (e) => {
    setVideoName("");
    setVideoFile(null);
    handleVideoFileData(null);
  };

  async function handleSubmitAudio() {
    updateStep();
    if (!audioMainFile) {
      console.log("No video file selected.");
      return;
    }
    const formData = new FormData();
    formData.append("audio_url", audioMainFile);
    console.log(formData);
    try {
      const response = await fetchData(
        ApiConfig.audioMapping,
        setLoading,
        "media",
        formData,
        { authorization: `Bearer ${token}` }
      );

      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate("/login");
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitVideo() {
    updateStep();
    if (!videoMainFile) {
      console.log("No video file selected.");
      return;
    }
    const formData = new FormData();
    formData.append("video_url", videoMainFile);
    formData.append("env_location", "");
    console.log(formData);

    setLoading(true);
    try {
      const response = await fetchData(
        ApiConfig.videoMapping,
        setLoading,
        "media",
        formData,
        { authorization: `Bearer ${token}` }
      );

      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate("/login");
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = () => {
    handleSubmitAudio();
    handleSubmitVideo();
  }

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 2 },
      { authorization: `Bearer ${token}` }
    );
    if (response.status === 401 || response.status === 500) {
      navigate("/login");
    }
  }

  return (
    <>
      <div className="env-store-add-product-heading customize-env-header">
        <h2>Your Virtual Retail</h2>
        <p onClick={() => navigate("/dashboard/select-enviroment")}>
          <ArrowBack />
          Back
        </p>
      </div>
      <div className="customize-env-body">
        <div className="tabs">
          <ul>
            <li
              onClick={() => setCurrentStep(1)}
              className={currentStep === 1 ? "active" : ""}
            >
              Audio
            </li>
            <li
              onClick={() => setCurrentStep(2)}
              className={currentStep === 2 ? "active" : ""}
            >
              Content
            </li>
          </ul>
        </div>
        <div className="main-container-body">
          <div className="customize-data">
            <div>
              <p className="cust-title">
                EDIT {currentStep === 1 ? "AUDIO" : "CONTENT"}{" "}
              </p>
              <p className="dropbox-title">
                {currentStep === 1 ? "Add Audio" : "Content Area"}
              </p>
              {currentStep === 1 ? (
                <div
                  className={`custom-drag-box ${
                    dropzoneAudio.isDragActive ? "active" : ""
                  }`}
                  {...dropzoneAudio.getRootProps()}
                >
                  <input {...dropzoneAudio.getInputProps()} />
                  <div className="custom-drag-box-title">
                    Drag and drop or{" "}
                    <span style={{ color: "#EE3A24" }}>Choose</span> file to
                    upload <Upload />
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Files supported: MP3 (max size: 10 mb)
                  </div>
                  {audioName && (
                    <div className="custom-drag-box-title input-box">
                      {audioName}
                      <div>
                        <CloseIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            removeAudio(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`custom-drag-box ${
                    dropzoneVideo.isDragActive ? "active" : ""
                  }`}
                  {...dropzoneVideo.getRootProps()}
                >
                  <input {...dropzoneVideo.getInputProps()} />
                  <div className="custom-drag-box-title">
                    Drag and drop or{" "}
                    <span style={{ color: "#EE3A24" }}>Choose</span> file to
                    upload <Upload />
                  </div>
                  <div className="custom-drag-box-subTitle">
                    Files supported: MP4 (max size: 10 mb)
                  </div>
                  {videoName && (
                    <div className="custom-drag-box-title input-box">
                      {videoName}
                      <div>
                        <CloseIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            removeVideo(e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="buttons">
              <button
                type="button"
                // onClick={() =>
                //   currentStep === 1 ? handleSubmitAudio() : handleSubmitVideo()
                // }
                onClick={() => handleSubmit}
              >
                Save & Next
              </button>
              <button
                type="button"
                onClick={() => { 
                  navigate("/dashboard/select-store");
                  updateStep();
                }}
              >
                Skip <ArrowForward />
              </button>
            </div>
          </div>
          <div className="customize-body">
            <p className="cust-title">PREVIEW</p>
            <div
              className="canvas-preview"
              style={{ width: "100%", height: "24vw" }}
            >
              <Gabar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomizeEnv;

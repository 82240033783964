import { Dialog, IconButton, Tooltip } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-dialog-icon.svg";

export default function TokenExpired({ openTokenExpired, close }) {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={openTokenExpired}
      onClose={close}
      className="common-dialog-container"
      sx={{
        "& .MuiDialog-paper": {
          boxShadow: "none", 
          backgroundColor: "transparent",  
          overflow: "visible",  
        },
      }}
    >
      <div className="email-not-found-container">
        <IconButton className="close-dialog-icon-button" onClick={close}>
          <Tooltip title="Close Dialog">
            <CloseIcon className="close-dialog-icon" />
          </Tooltip>
        </IconButton>
        <div className="header-container">
          <div className="email-not-found-content">
            <div className="email-not-found-heading">
              <h2>Token Expired!</h2>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-create-section.customized-form .custom-create-container .create-stepper-container {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}`, "",{"version":3,"sources":["webpack://./src/gabar/CustomizeForm.scss"],"names":[],"mappings":"AAAA;EACI,gDAAA;AACJ","sourcesContent":[".custom-create-section.customized-form .custom-create-container .create-stepper-container{\n    grid-template-columns: repeat(3, minmax(0, 1fr));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

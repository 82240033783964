import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SpinWheelGame from "./SpinWheelGame";
import Quiz from "./Quiz";
import "./GamePopup.scss";
import { Tooltip } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/close-dialog-icon.svg";
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

function GamePopup(props) {
  const [wheelCoupon, setWheelCoupon] = useState("");
  const [quizCoupon, setQuizCoupon] = useState("");
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [activeGame,setActiveGame] = useState(null);
  const [backArrow, setBackArrow] = useState(false);
  const [isPopupOpen,setPopupOpen ] = useState(false);
  const [triggerPopup, setTriggerPopup] = useState(false);
  const [wheelData,setWheelData] = useState([]);
  const data = props.gameData;

  useEffect(()=>{
    let timeoutId;
    let triggerTime=data[0].trigger_limit;
    data.forEach((game, index) => {
      if (game.trigger_limit > triggerTime) {
        triggerTime = game.trigger_limit;
      }
      if(game.game_id === 1){
        setQuizQuestion()
      }
      if(game.game_id === 2){
        const coupons = game.coupon_code.split(',');
        let val2;
        if(coupons.length === 1){
          val2 = [
            { option: "" },
            { option: coupons[0].trim() },
          ];
        }
        else{
          val2 = [
            { option: "" },
            { option: coupons[0].trim() },
            { option: "" },
            { option: coupons[1].trim() },
          ];
        }
        setWheelData(val2);
      }
    });

   
    if (!triggerPopup) {
      timeoutId = setTimeout(() => {
        setTriggerPopup(true);
        setPopupOpen(true);
      },  triggerTime*1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  },[isPopupOpen,data])

  const handelQuiz = (data) => {
    setWheelCoupon("");
    setQuizQuestion(JSON.parse(data.game_quiz_masters[0].question));
    setQuizQuestion(JSON.parse(data.game_quiz_masters[0].question));
    setBackArrow(true);
  };
  const handleWheel = (data) => {
    setWheelCoupon(data.coupon_code);
    setQuizQuestion([]);
    setBackArrow(true);
  };
  const handleBack = () => {
    setQuizQuestion([]);
    setWheelCoupon("");
    setBackArrow(false);
    setActiveGame(null);
  };

  return (
  <>
    {isPopupOpen &&
      <div className="game-popup">    
        <div className="game-list">
          <div className="closeIcon">
            <Tooltip title="Close Dialog">
              <CloseIcon
                className="close-dialog-icon"
                style={{ cursor: "pointer" }}
                onClick={() => setPopupOpen(false) }
              />
            </Tooltip>  
          </div>  
          {backArrow && <KeyboardBackspaceIcon onClick={handleBack}/>}
          {data.map((game, index) => (
            <div
              key={index}
              className={`${game.game_id === 1 ? "quiz" : "wheel"}-game`}
            >
              <button
                type="button"
                className="button"
                onClick={() =>{
                  game.game_id === 1 ? handelQuiz(game) : handleWheel(game);
                  setActiveGame(game.game_id);
                  }
                }
                style={{ display: activeGame === game.game_id ? 'block' : activeGame !== null ? 'none' : 'block' }}
              >{`${game.game_id === 1 ? "quiz" : "wheel"} game`}</button>
            </div>
          ))}
          <div className="selected-game">
            {(quizQuestion!== undefined) && (quizQuestion.length > 0) && <Quiz questionBank={quizQuestion} coupon={quizCoupon}/>}
            {wheelCoupon.length > 0 && <SpinWheelGame data= {wheelData}/>}
          </div>
        </div>
      </div>
    }
    <VideogameAssetIcon className="videoGameIcon" onClick={()=> {
      setPopupOpen(true);
      setTriggerPopup(true);
      handleBack();
      }
    }/>
  </>  
  );
}

export default GamePopup;

import "./Joystick.css";
import * as React from "react";
import { useRef, useEffect } from "react";
import nipplejs from "nipplejs";
import useStore from "../util/3dstore";
import useDeviceDetect from "../util/useDeviceDetect";

export const Joystick = () => {
  const {
    setJoystickTurn,
    setJoystickForward,
    setMovingWithJoystick,
    setJoystickSpeed,
    setJoystickRotation,
    isEntryAnimationDone,
    isJoystickVisible,
    isMovingToTarget,
  } = useStore();

  const isTouchDevice = useDeviceDetect();
  const containerRef = useRef(null);
  const jostickClassName = `dynamic_joystick ${
    isTouchDevice && isMovingToTarget && isEntryAnimationDone
      ? "joystick_no_event"
      : ""
  } ${isJoystickVisible && isEntryAnimationDone && isTouchDevice ? "shows" : "noshow"}`;
  const size = 60;
  useEffect(() => {
    const manager = nipplejs.create({
      zone: containerRef.current,
      mode: "static",
      color: "#fff",
      maxNumberOfNipples: 1,
      restOpacity: 1,
      size: size,
      fadeTime: 0,
      dynamicPage: true,
      threshold: 6,
      multitouch: false,
    });

    manager.on("move", (event, data) => {
      const { speed, rotation } = calculateSpeedAndRotation(data);
      setJoystickSpeed(speed);
      setJoystickRotation(rotation);
      setJoystickForward(data.vector.y);
      setJoystickTurn(data.vector.x);
      if (!isMovingToTarget) setMovingWithJoystick(true);
    });

    manager.on("end", (event, data) => {
      setJoystickForward(0);
      setJoystickTurn(0);
      setMovingWithJoystick(false);
    });
  }, []);

  return <div className={jostickClassName} ref={containerRef} />;
};

function calculateSpeedAndRotation(data) {
  // Extract values from data.vector.x and data.vector.y
  const x = Math.abs(data.vector.x);
  const y = data.vector.y;

  // Initialize default values
  let speed = 0;
  let rotation = 0;

  // Calculate speed based on data.vector.y
  switch (true) {
    case y >= 0 && y < 0.1:
      speed = 120;
      break;
    case y >= 0.1 && y < 0.2:
      speed = 130;
      break;
    case y >= 0.2 && y < 0.3:
      speed = 140;
      break;
    case y >= 0.3 && y < 0.4:
      speed = 150;
      break;
    case y >= 0.4 && y < 0.5:
      speed = 160;
      break;
    case y >= 0.5 && y < 0.6:
      speed = 170;
      break;
    case y >= 0.6 && y < 0.7:
      speed = 180;
      break;
    case y >= 0.7 && y < 0.8:
      speed = 190;
      break;
    case y >= 0.8 && y < 0.9:
      speed = 200;
      break;
    default:
      speed = 210;
  }

  switch (true) {
    case x >= 0 && x < 0.2:
      rotation = 0;
      break;
    case x >= 0.2 && x < 0.3:
      rotation = 0.75;
      break;
    case x >= 0.3 && x < 0.4:
      rotation = 0.85;
      break;
    case x >= 0.4 && x < 0.5:
      rotation = 0.95;
      break;
    case x >= 0.5 && x < 0.6:
      rotation = 1.05;
      break;
    case x >= 0.6 && x < 0.7:
      rotation = 1.15;
      break;
    case x >= 0.7 && x < 0.8:
      rotation = 1.25;
      break;
    case x >= 0.8 && x < 0.9:
      rotation = 1.35;
      break;
    default:
      rotation = 1.45;
  }

  return { speed, rotation };
}

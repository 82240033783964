import React, { useEffect, useState } from "react";
import "./wondiscovery-styles.css";
import CloseIcon from "@mui/icons-material/Close";
// import FormOverlay from "./Backdrop";
import axios from "axios";
import useGameStore from "../utils/gameStore";
import useDeviceDetect from "../../../utils2/useDeviceDetect";
import ErrroImg from "../../../assets2/images/error.svg";
export default function WonDiscoverySetPopup() {
  const isTouchDevice = useDeviceDetect();

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
  });
  const [isValidFirstName, setValidFirstName] = useState(false);
  const [isValidLastName, setValidLastName] = useState(false);
  const [isValidEmail, setValidEmail] = useState(false);
  const [isValidPostAddress, setValidPostAddress] = useState(false);

  const {
    showGameDiscoveryWinPopup,
    handleShowGameDiscoveryWinPopup,
    handleShowGameThankYouPopup,
    rowIndex,
  } = useGameStore();

  const handleFocus = (event) => {
    event.stopPropagation();
    setValidFirstName(false);
    setValidLastName(false);
    setValidEmail(false);
    setValidPostAddress(false);
  };
  var addressRegex = /^[a-zA-Z0-9._%+-\s#,\/\-]*$/;
  const txtRegx = /^[a-zA-Z0-9._%+-\s]*$/;
  const startsWithSpacesRegex = /^\s/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleOnSubmit = () => {
    if (
      emailRegex.test(user.email) === true &&
      addressRegex.test(user.address) &&
      user.lastName &&
      user.firstName
    ) {
      let data = {
        email: user.email?.trim(),
        firstName: user.firstName,
        lastName: user.lastName,
        postalAddress: user.address,
        index: rowIndex,
        // score: `3`,
        // date: `${new Date()}`,
      };

      // console.log("Win Discovery Details-------", data);
      axios
        .post(
          "https://gabar-prod.onewayx.world/game-win-discovery",
          data
        )
        .then((response) => {
          console.log("DONE CORRECTLY");
        })
        .catch((error) => {
          console.log("NOT DONE CORRECTLY");
        });
      setUser({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
      });
      handleShowGameThankYouPopup(true);
      handleShowGameDiscoveryWinPopup(false);
      return;
    }

    if (
      !user.firstName ||
      user.firstName.length === 0 ||
      user.firstName === null
    )
      setValidFirstName(true);
    if (!user.lastName || user.lastName.length === 0 || user.lastName === null)
      setValidLastName(true);
    if (!user.address || user.address.length === 0 || user.address === null)
      setValidPostAddress(true);
    if (emailRegex.test(user.email) === false) setValidEmail(true);
  };

  const parentFormClassName = `parent-form-test_2  no-text-select ${
    showGameDiscoveryWinPopup ? "show" : "noshow"
  }`;
  return (
    <>
      {showGameDiscoveryWinPopup ? (
        <>
          {/* <FormOverlay /> */}
          {/* <div className="parent-form-test_2  no-text-select"> */}
          <div className={parentFormClassName}>
            <div className="sub-parent-form_21">
              <div
                className="form-close-icon"
                onClick={() => handleShowGameDiscoveryWinPopup(false)}
              >
                <CloseIcon style={{ fontSize: 20, color: "#000" }} />
              </div>
              <div>
                <h1>CONGRATULATIONS</h1>
                <h2>
                  You have won a Discount
                </h2>
                <h2>
                 It pays to be mindful.
                </h2>
                <h4>To claim your prize, fill in the details below</h4>
              </div>
              <div className="wrapper-one">
                <div className="input-wrapper-0">
                  <input
                    type="text"
                    placeholder="First Name*"
                    style={{
                      borderColor: isValidFirstName ? "red" : "white",
                    }}
                    onFocus={handleFocus}
                    value={user.firstName}
                    onChange={(e) => {
                      addressRegex.test(e.target.value) &&
                        setUser({ ...user, firstName: e.target.value });
                    }}
                  />
                  {isTouchDevice ? (
                    isValidFirstName && (
                      <img
                        src={ErrroImg}
                        className="error-icon"
                      />
                    )
                  ) : (
                    <span className="error-text">
                      {isValidFirstName
                        ? "Please enter your first name"
                        : "    "}
                    </span>
                  )}
                </div>

                {/* {!isTouchDevice && (
                  <span className="error-text">
                    {isValidFirstName ? "Please enter your first name" : "    "}
                  </span>
                )} */}
                <div className="input-wrapper-0">
                  <input
                    type="text"
                    placeholder="Last Name*"
                    style={{
                      borderColor: isValidLastName ? "red" : "white",
                    }}
                    value={user.lastName}
                    onChange={(e) => {
                      addressRegex.test(e.target.value) &&
                        setUser({ ...user, lastName: e.target.value });
                    }}
                    onFocus={handleFocus}
                  />
                  {isTouchDevice ? (
                    isValidLastName && (
                      <img
                        src={ErrroImg}
                        className="error-icon"
                      />
                    )
                  ) : (
                    <span className="error-text">
                      {isValidLastName ? "Please enter your last name" : "    "}
                    </span>
                  )}
                </div>
                <div className="input-wrapper-0">
                  <input
                    type="text"
                    placeholder="Email Address*"
                    style={{
                      borderColor: isValidEmail ? "red" : "white",
                    }}
                    value={user.email}
                    onChange={(e) =>
                      !startsWithSpacesRegex.test(e.target.value) &&
                      setUser({ ...user, email: e.target.value })
                    }
                    onFocus={handleFocus}
                  />
                  {isTouchDevice ? (
                    isValidEmail && (
                      <img
                        src={ErrroImg}
                        className="error-icon"
                      />
                    )
                  ) : (
                    <span className="error-text">
                      {isValidEmail
                        ? "Please enter a valid email address"
                        : "    "}
                    </span>
                  )}
                </div>
                <div className="input-wrapper-0">
                  <input
                    type="text"
                    placeholder="Postal Address*"
                    style={{
                      borderColor: isValidPostAddress ? "red" : "white",
                    }}
                    value={user.address}
                    onChange={(e) => {
                      addressRegex.test(e.target.value) &&
                        setUser({ ...user, address: e.target.value });
                    }}
                    onFocus={(event) => {
                      event.stopPropagation();
                      setValidPostAddress(false);
                    }}
                  />
                  {isTouchDevice ? (
                    isValidPostAddress && (
                      <img
                        src={ErrroImg}
                        className="error-icon"
                      />
                    )
                  ) : (
                    <span className="error-text">
                      {isValidPostAddress
                        ? "Please enter a valid postal address"
                        : "    "}
                    </span>
                  )}
                </div>

                <button
                  className="form-submit-button_21"
                  onClick={() => handleOnSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>

            {/* <div className="sub-parent-form_2">
            <div
              className="form-close-icon"
              onClick={() => handleShowGameDiscoveryWinPopup(false)}
            >
              <CloseIcon style={{ fontSize: 20, color: "#fff" }} />
            </div>
            <div className="form-item-container_2">
              <div className="heading-container_2">
                <span
                  className="heading-test-form_2"
                  style={{ fontWeight: "600", marginBottom: "8px" }}
                >
                  CONGRATULATIONS
                </span>
                <span
                  className="heading-test-form_2"
                  // style={{ fontWeight:"500" }}
                >
                  You have won a Gabar discovery set!
                </span>
                <span
                  className="heading-test-form_2"
                  style={{ marginBottom: "6px" }}
                >
                  It pays to be mindful.
                </span>
                <span className="heading-test-form_3">
                  To claim your prize, fill in the details below
                </span>
              </div>
              <div className="form-input-container_2">
                <div className="form-input-container-inside_2">
                  <input
                    onFocus={handleFocus}
                    className="input-fields-form_2"
                    style={{
                      borderColor: isValidFirstName ? "red" : "white",
                    }}
                    value={user.firstName}
                    onChange={(e) => {
                      addressRegex.test(e.target.value) &&
                        setUser({ ...user, firstName: e.target.value });
                    }}
                    placeholder="First Name*"
                  />

                  <span className="form-error-msg_2">
                    {isValidFirstName ? "Please enter your first name" : "    "}
                  </span>
                  <input
                    onFocus={handleFocus}
                    className="input-fields-form_2"
                    style={{ borderColor: isValidLastName ? "red" : "white" }}
                    value={user.lastName}
                    onChange={(e) => {
                      addressRegex.test(e.target.value) &&
                        setUser({ ...user, lastName: e.target.value });
                    }}
                    placeholder="Last Name*"
                  />

                  <span className="form-error-msg_2">
                    {isValidLastName ? "Please enter your last name" : "    "}
                  </span>
                  <input
                    onFocus={handleFocus}
                    className="input-fields-form_2"
                    style={{ borderColor: isValidEmail ? "red" : "white" }}
                    value={user.email}
                    onChange={(e) =>
                      !startsWithSpacesRegex.test(e.target.value) &&
                      setUser({ ...user, email: e.target.value })
                    }
                    placeholder="Email Address*"
                  />
                  <span className="form-error-msg_2">
                    {isValidEmail
                      ? "Please enter a valid email address"
                      : "    "}
                  </span>

                  <input
                    onFocus={(event) => {
                      event.stopPropagation();
                      setValidPostAddress(false);
                    }}
                    className="input-fields-form_2"
                    style={{
                      borderColor: isValidPostAddress ? "red" : "white",
                    }}
                    value={user.address}
                    onChange={(e) => {
                      addressRegex.test(e.target.value) &&
                        setUser({ ...user, address: e.target.value });
                    }}
                    placeholder="Post Address*"
                  />
                  <span className="form-error-msg_2">
                    {isValidPostAddress ? "Please enter a valid address" : " "}
                  </span>
                </div>
              </div>
              <span className="text-test-form_2"></span>
              <button
                className="form-submit-button_2"
                onClick={() => handleOnSubmit()}
              >
                Submit
              </button>
            </div>
          </div> */}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

import React from "react";
import "./PlayAround.scss";
import ThreeScreens from "../components/ThreeScreens";
import Header from "../components/Header";

function PlayAround() {
  return (
    <>
      <ThreeScreens/>      
    </>
  );
}

export default PlayAround;

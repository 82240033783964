import React from 'react'
import "./FailedToRetrieve.scss";
import { ReactComponent as InfoIcon } from "../assests/svg/info-red.svg";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import { ReactComponent as CloseIcon } from "../assests/svg/close-red.svg";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
export default function GameLimitExceed({open, close ,message}) {
  return (
    <>
    <Dialog open={open} className="product-limit-container">
   
    <Box component="div" className="product-limit-wrapper">
    <Box component="div" className="close-dialog">
            <CloseIcon onClick={() => {close()}} style={{cursor: 'pointer'}}/>
          </Box>
          <Box component="div" className="product-limit-content">
          <Box component="div">
              <InfoIcon />
            </Box>
            <Box component="div" className="product-limit-text">
              <h2>{message}</h2>
              {/* <p>The environment you have selected supports only 10 products.</p> */}
            </Box>
            {/* <Box component="div">
              <button className="contact-us">
              Upgrade{" "}
                <span>
                  <ArrowIcon />
                </span>
              </button>
            </Box> */}
          </Box>
        </Box>
    
    </Dialog>
     
    </>
  )
}

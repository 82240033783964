import React, { useEffect, useState } from 'react';
import './CustomizeForm.scss';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as CheckIcon } from "../assests/svg/check-icon.svg";
import { ReactComponent as Upload } from "../assests/svg/upload2.svg";
import { ReactComponent as LogoIcon } from "../assests/svg/logo-icon.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { fetchData } from '../service/http.service';
import ApiConfig from '../config/ApiConfig';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function CustomizeForm({ onFilesSubmit, musicFile , videoFile2}) {
    const [currentStep, setCurrentStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [logoName, setLogoName] = useState("");
    const [fileName, setFileName] = useState("");
    const [isMinimise, setIsMinimise] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoMainFile, setVideoMainFile] = useState(null);
    const [audioMainFile, setAudioMainFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();

    const getShortenedFileName = (fileName) => {
        let lastIndex = fileName.lastIndexOf(".");
        let fileExtension = fileName.slice(lastIndex + 1);
        let shortenedName = fileName.slice(0, Math.min(3, fileName.length));
        if (Math.min(4, fileName.length - fileExtension.length - 1) > 3) {
            return shortenedName + "..." + fileExtension;
        } else {
            return shortenedName + "." + fileExtension;
        }
    };

    useEffect(()=>{
        console.log(musicFile);
        
        if(musicFile !== null){
            setAudioFile(musicFile);
            setLogoName(getShortenedFileName(musicFile));
        }
        if(videoFile2 !== null){
            setVideoFile(videoFile2);
            setFileName(getShortenedFileName(videoFile2));
        }
    },[])

    function onToggleMinimise() {
        setIsMinimise((prevValue) => !prevValue);
    }

    const handleDropAudio = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setAudioMainFile(file);
        if (audioFile) URL.revokeObjectURL(audioFile)
        const temp = URL.createObjectURL(file);
        setAudioFile(temp);  
        const fileName = getShortenedFileName(file.name);
        setLogoName(fileName);
    };

    const handleDropVideo = (acceptedFiles) => {
        const file = acceptedFiles[0];        
        setVideoMainFile(file);
        console.log(file);
        
        const temp = URL.createObjectURL(file);
        setVideoFile(temp);
        const fileName = getShortenedFileName(file.name);
        setFileName(fileName);
    };


    const dropzoneAudio = useDropzone({
        onDrop: handleDropAudio,
        accept: "audio/*",
        multiple: false,
    });

    const dropzoneVideo = useDropzone({
        onDrop: handleDropVideo,
        accept: "video/*",
        multiple: false,
    });

    const handleStep = (event) => {
         if (onFilesSubmit) {
            onFilesSubmit(audioFile, videoFile);
        }
         
        event.preventDefault();
        setCompletedSteps((prevValue) => {
            return [currentStep, ...prevValue];
        });
    };

    const removeAudio = (e) => {
        setFileName('');
        setVideoFile(null);
        if (onFilesSubmit) {
            onFilesSubmit(audioFile, null);
        }
    }

    const removeVideo = (e) => {
        setLogoName('');
        setAudioFile(null);
        if (onFilesSubmit) {
            onFilesSubmit(null, videoFile);
        }
    }

    //  async function handleSubmit() {
    //     console.log(videoMainFile);
        
    //     setLoading(true);
    //     const response = await fetchData(
    //         ApiConfig.videoMapping,
    //         setLoading,
    //         "media",
    //         {
    //             video_url: videoMainFile,
    //             env_location: 'aa'
    //         },
    //         { authorization: `Bearer ${token}` }
    //     )
    //     console.log(response);
        
    //     if(response.statusCode === 401 || response.statusCode === 500) {
    //         navigate("/login");
    //     }
    //     else if(response.statusCode !== 401){
    //         console.log(response.data);            
    //     }
    //  }
    async function updateStep() {
        const response = await fetchData(
          `${ApiConfig.updateStep}`,
          setLoading,
          "PUT",
          { completed_step: 2 },
          { authorization: `Bearer ${token}` }
        );
      }

    async function handleSubmitVideo() {
        if (!videoMainFile) {
            console.log("No video file selected.");
            return;
        }
    
        setLoading(true);
        
        const formData = new FormData();
        formData.append('video_url', videoMainFile); // Assuming the field is called video_url
        formData.append('env_location', ''); // Add other form fields here if needed
    
        try {
            const response = await fetchData(
                ApiConfig.videoMapping,
                setLoading,
                "media",
                formData,
                { authorization: `Bearer ${token}` }
            );
            
            if (response.statusCode === 401 || response.statusCode === 500) {
                navigate("/login");
            } else {
                console.log(response.data);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setLoading(false);
        }
    }

    async function handleSubmitAudio() {
        if (!audioMainFile) {
            console.log("No video file selected.");
            return;
        }
    
        setLoading(true);
        
        const formData = new FormData();
        formData.append('audio_url', audioMainFile); 
    
        try {
            const response = await fetchData(
                ApiConfig.audioMapping,
                setLoading,
                "media",
                formData,
                { authorization: `Bearer ${token}` }
            );
            
            if (response.statusCode === 401 || response.statusCode === 500) {
                navigate("/login");
            } else {
                console.log(response.data);
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setLoading(false);
        }
    }
    

    function handleNext() {
        if (currentStep !== 3) {
            setCurrentStep(currentStep + 1);
        } else {
            // setCurrentStep(1);
            updateStep()
            navigate(`/dashboard/select-store`); 
        }
        if(currentStep === 1){
            handleSubmitAudio();
        }
        else if(currentStep === 2){           
            handleSubmitVideo();
        }
    }

    let toggleButton = (
        <KeyboardArrowDownIcon
            style={{ fill: "#ee3a24" }}
            onClick={onToggleMinimise}
        ></KeyboardArrowDownIcon>
    );

    if (isMinimise) {
        toggleButton = (
            <KeyboardArrowUpIcon
                style={{ fill: "#ee3a24" }}
                onClick={onToggleMinimise}
            ></KeyboardArrowUpIcon>
        );
    }

    return (
        <div
            className={`custom-create-section customized-form`}
            style={{
                position: "fixed",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
                overflow: "hidden",
                height: `${isMinimise ? "3.4rem" : "auto"} `,
                width: "max-content",
                padding: " 0",
            }}
        >
            <div className="minimise-tray">
                <div>
                    {isMinimise ? (
                        <div onClick={onToggleMinimise}></div>
                    ) : (
                        <div onClick={onToggleMinimise}></div>
                    )}
                </div>
                <div>{toggleButton}</div>
            </div>
            <div className="custom-create-container">
                <div className="create-stepper-container">
                    <button
                        className={`${
                            currentStep === 1
                                ? `active-stepper`
                                : completedSteps.includes(1)
                                ? `complete-stepper`
                                : ``
                        } custom-stepper-button `}
                        onClick={() => setCurrentStep(1)}
                    >
                        <div className="stepper-button-title">
                            Add Audio
                            {completedSteps.includes(1) && (
                                <CheckIcon className="stepper-check-icon" />
                            )}
                        </div>
                    </button>
                    <button
                        className={`${
                            currentStep === 2
                                ? `active-stepper`
                                : completedSteps.includes(2)
                                ? `complete-stepper`
                                : ``
                        } custom-stepper-button `}
                        onClick={() => setCurrentStep(2)}
                        style={{ display: "flex", flexDirection: "row" }}
                    >
                        <div>
                            <div className="stepper-button-title">Add Content</div>
                        </div>
                        {completedSteps.includes(2) && (
                            <CheckIcon className="stepper-check-icon" />
                        )}
                    </button>
                    <button
                        className={`${
                            currentStep === 3
                                ? `active-stepper`
                                : completedSteps.includes(3)
                                ? `complete-stepper`
                                : ``
                        } custom-stepper-button `}
                        onClick={() => setCurrentStep(3)}
                    >
                        <div className="stepper-button-title">
                            Customise The Environment
                            {completedSteps.includes(3) && (
                                <CheckIcon className="stepper-check-icon" />
                            )}
                        </div>
                    </button>
                </div>

                {currentStep === 1 && (
                    <div className="custom-drag-file-container">
                        <div className="custom-drag-logo-content">
                            <LogoIcon />
                            <div className="custom-drag-logo-title">
                                Add Your Background Music
                            </div>
                        </div>
                        <div
                            className={`custom-drag-box ${dropzoneAudio.isDragActive ? "active" : ""}`}
                            {...dropzoneAudio.getRootProps()}
                        >
                            <input {...dropzoneAudio.getInputProps()} />
                            <div className="custom-drag-box-title">
                                Drag and drop an audio file here or
                            </div>
                            <div className="custom-drag-box-subTitle">
                                Upload from computer
                            </div>
                            {logoName && (
                                <div className="custom-drag-box-title input-box">
                                    {logoName}
                                    <div>
                                        <CloseIcon  onClick={(e) => {
                                                                        e.stopPropagation(); 
                                                                        removeAudio(e);
                                                                    }} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {currentStep === 2 && (
                    <div className="custom-drag-file-container" style={{ display: 'flex', flexDirection: 'column' }}>
                        <h6 style={{ fontWeight: '400' }}>MP4 only. 50 MB max</h6>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <div className="custom-drag-logo-content">
                                <div className="custom-drag-logo-title">Content Area</div>
                            </div>
                            <div
                                className={`custom-drag-box ${dropzoneVideo.isDragActive ? "active" : ""}`}
                                {...dropzoneVideo.getRootProps()}
                            >
                                <input {...dropzoneVideo.getInputProps()} />
                                {!fileName ? 
                                <div className="custom-drag-box-subTitle" style={{ display: 'flex', alignItems: 'center', gap: '1vw' }}>
                                    <span>Upload here</span>
                                    <Upload />
                                </div>
                                :
                                <div className="custom-drag-box-title input-box" style={{marginTop: '0'}}>
                                    {fileName}
                                    <CloseIcon />
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                )}

                {currentStep === 3 && (
                    <div className="custom-drag-file-container" style={{ gridTemplate: 'none' }}>
                        <div className="custom-drag-logo-content" style={{ justifyContent: 'center' }}>
                            <div className="custom-drag-logo-title">Coming Soon</div>
                        </div>
                    </div>
                )}

                <div className="custom-create-cta-group">
                    <button className={`common-cta-button`} onClick={handleStep}>
                        <span className="common-cta-text">Apply</span>
                    </button>
                    <button className={`common-cta-button`} onClick={handleNext}>
                        <span className="common-cta-text">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import "./shoppingcart.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import useUIStore from "../../../utils2/uistore";
import DeleteIcon from "./icon/DeleteIcon";
import useStore from "../../../utils2/uistore";
const AddToCartItem = {
  name: null,
  image: null,
  price: null,
  total: null,
  count: null,
  id: null,
  nodeId: null,
  checkoutId: null,
  quantitySubtotal: null,
};

const ShoppingCart = ({
  name,
  image,
  price,
  total,
  count,
  id,
  totalItemCount,
  nodeId,
  checkoutId,
  // fetchCart,
  increase,
  // quantitySubTotal,
  getCartItem,
}) => {
  const { apiURL, setCartLoaded, setCartChecked } = useUIStore();
  const {setCounter} = useStore();
  const [quantity, setQuantity] = useState(count);
  const [priceReload, setPriceReload] = useState(false);
  // const [quantitySubTotal, setQuantitySubTotal] = useState(price * quantity);

  const increaseQuantity = () => {
    const updatedQuantity = quantity + 1;
    setQuantity(updatedQuantity);
    updateProduct(updatedQuantity);
    increase(updatedQuantity);
  };

  const decreaseQuantity = () => {
    const updatedQuantity = quantity - 1;
    if (updatedQuantity > 0) {
      setQuantity(updatedQuantity);
      updateProduct(updatedQuantity);
      increase(updatedQuantity);
    }
  };

  const removeProduct = async () => {
    const response = await axios.post(
      "https://gabar-prod.onewayx.world/remove-item",
      {
        checkoutId: checkoutId,
        nodeIds: [nodeId],
      }
    );
    setCartLoaded(false);
    setCartChecked(false);
    getCartItem();
    // fetchCart();
    // console.log(quantity);
  };

  const updateProduct = async (updatedQuantity) => {
    const response = await axios.post(
      "https://gabar-prod.onewayx.world/update-quantity",
      {
        quantity: updatedQuantity,
        checkoutId: checkoutId,
        nodeId: nodeId,
      }
    );
    getCartItem();
    // fetchCart();
  };

  // useEffect(() => {
  //   setQuantitySubTotal(price * quantity);
  // }, [quantity]);
  // const temp = price * quantity;
  const quantitySubTotal = (price * quantity).toFixed(2);
  // console.log(typeof quantitySubTotal);

  const quantityTotal = total * quantity;
  // console.log("cartItem.variant.compareAtPriceV2?.amount ",total)
  // console.log("quantity ",quantity)
  // console.log("quantityTotal: ",quantityTotal)
  // console.log("TYPEOF ",typeof quantityTotal)
  return (
    <div className="product">
      <div className="product-main-container">
        <div className="product-cart-container">
          <div className="div-cart-product-image">
            <img src={image} alt={name} className="cart-product-image" />
          </div>

          <div className="root-container">
            <div className="product-info">
              <span className="product-name">{name}</span>
              <div className="inner-container-price">
                {total !== null &&
                total !== 0 &&
                price !== null &&
                price !== 0 &&
                total !== price ? (
                  <span className="product-name-clone">£{quantityTotal}</span>
                ) : (
                  <span className="product-name">£{quantitySubTotal}</span>
                )}

                {total !== 0 &&
                  price !== null &&
                  price !== 0 &&
                  total !== price && (
                    <div className="discount-price">
                      <span className="product-name">£{quantitySubTotal}</span>
                      <div className="gradient-box">
                        <span className="save-per">
                          Save {((1 - price / total) * 100).toFixed(0)}%
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="main-counter-block">
              <div className="counter-blockss">
                <div className="icon-holderss">
                  <RemoveIcon
                    fontSize={"6px"}
                    onClick={() => {
                      decreaseQuantity();
                      // getCartItem();
                    }}
                    color={"#fff"}
                  />
                </div>

                <input
                  className="inner-counter-eless"
                  value={count} //quantity
                  onChange={(e) => {
                    if (!e.target.value) {
                      setCounter(0);
                      return;
                    }
                    if (e.target.value >= 0 && e.target.value <= 99) {
                      setCounter(parseInt(e.target.value));
                    }
                  }}
                  inputMode="numeric"
                  // onFocus={() => setActiveIndex(id)}
                  disabled
                />
                <div className="icon-holder2ss">
                  <AddIcon
                    fontSize={"10px"}
                    onClick={() => {
                      increaseQuantity();
                      // getCartItem();
                    }}
                    color={"#fff"}
                  />
                </div>
              </div>
              <DeleteIcon onClick={removeProduct} />
            </div>
          </div>
          {/* </div> */}
        </div>
        {id == 2 && (
          <div className="save-per-later">
            <span className="save-per-later-text">Order for later</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCart;

import { useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";

export default function DashboardLayout({children}) {
   const [open,setOpen]=useState(false);
    return <>
   <div className="dashboard-header">
     <Header variation={3}></Header>
   </div>
       
        <div className="dashboard-layout">
       
            <SideNav />
         
            <div  className="home-section">
            <div className="main-content">{children}</div>
            </div>
        
        </div>
       
    </>
}

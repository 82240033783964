// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-counter {
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5vw;
  box-shadow: 0px 4px 7.4px 0px rgba(190, 190, 190, 0.2509803922);
  border-radius: 0.3vw;
  width: 100%;
}
.data-counter h6 {
  font-weight: 400;
  line-height: 1vw;
  font-size: 0.75vw;
  padding-bottom: 0.15vw;
}
.data-counter div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5vw;
}
.data-counter div p.counter {
  font-weight: 500;
  font-size: 1.12vw;
}
.data-counter div p.percent {
  font-size: 0.69vw;
  font-weight: 400;
  padding: 0.2vw;
  border-radius: 0.1vw;
  width: max-content;
  height: unset;
}
.data-counter div p.percent.increment {
  color: #1FB02E;
  background: rgba(182, 229, 215, 0.6);
}
.data-counter div p.percent.decrement {
  color: #ee3a24;
  background: rgba(238, 58, 36, 0.2705882353);
}`, "",{"version":3,"sources":["webpack://./src/components/analyticsData/CounterBox.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,cAAA;EACA,+DAAA;EACA,oBAAA;EACA,WAAA;AACJ;AAAI;EACI,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AAER;AAAI;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,UAAA;AAER;AADQ;EACI,gBAAA;EACA,iBAAA;AAGZ;AADQ;EACI,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;AAGZ;AAFY;EACI,cAAA;EACA,oCAAA;AAIhB;AAFY;EACI,cAAA;EACA,2CAAA;AAIhB","sourcesContent":[".data-counter {\n    background: rgba(255, 255, 255, .9);\n    padding: 1.5vw;\n    box-shadow: 0px 4px 7.4px 0px #BEBEBE40;\n    border-radius: 0.3vw;\n    width: 100%;\n    h6 {\n        font-weight: 400;\n        line-height: 1vw;\n        font-size: .75vw;\n        padding-bottom: .15vw;\n    }\n    div{\n        display: flex;\n        align-items: center;\n        justify-content: flex-start;\n        gap: .5vw;\n        p.counter{\n            font-weight: 500;\n            font-size: 1.12vw;\n        }\n        p.percent{\n            font-size: .69vw;\n            font-weight: 400;\n            padding: .2vw;\n            border-radius: .1vw;\n            width: max-content;\n            height: unset;\n            &.increment {\n                color: #1FB02E;\n                background: #B6E5D799;                \n            }\n            &.decrement {\n                color: #ee3a24;\n                background: rgba(238, 58, 36, 0.2705882353);              \n            }\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import "./onenter-styles.css";
import CloseIcon from "@mui/icons-material/Close";
import useUIStore from "../../../utils2/uistore";
import useStore from "../../../utils2/3dstore";
// import FormOverlay from "./Backdrop";
import axios from "axios";
import useGameStore from "../utils/gameStore";

let formShowTimeout;
export default function GameOnEnterPopup() {
  const [user, setUser] = useState({ subscribed: false, email: "" });
  const [isValidSubscribed, setValidSubscribed] = useState(false);
  const [isValidEmail, setValidEmail] = useState(false);
  const {
    handleFormModal,
    handleThankyouModal,
    formModal,
    showCart,
    showMenu,
    showItems,
  } = useUIStore((state) => state);

  const {
    isShowForm,
    setShowForm,
    isFormOverlay,
    setFormOverlay,
    isEntryAnimationDone,
    isMovingToTarget,
  } = useStore();

  const {
    handleShowGameInstructionsPopup,
    handleShowGameOnEnterPopup,
    showGameUIPopup,
    nextPlayableTime,
    isUserGameRegistered,
    showGameOnEnterPopup,
    setUserGameRegistered,
    handleRegisteredEmail,
  } = useGameStore();

  const startsWithSpacesRegex = /^\s/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleOnSubmit = () => {
    if (user.subscribed && emailRegex.test(user.email) === true) {
      handleRegisteredEmail(user.email?.trim());
      let data = {
        email: user.email?.trim(),
        // subscribed: user.subscribed,
      };
      // console.log("User details-------", data);
      axios
        .post(
          "https://gabar-prod.onewayx.world/game-on-enter",
          data
        )
        .then((response) => {
          console.log("DONE CORRECTLY");
        })
        .catch((error) => {
          console.log("NOT DONE CORRECTLY");
        });
      setUser({ subscribed: false, email: "" });
      setShowForm(undefined);
      // setUserGameRegistered(true);
      handleShowGameInstructionsPopup(true);
      handleShowGameOnEnterPopup(false);
      return;
    }
    if (!user.subscribed) setValidSubscribed(true);
    if (emailRegex.test(user.email) === false) setValidEmail(true);
  };

  //ends here
  useEffect(() => {
    if (isEntryAnimationDone && !isUserGameRegistered) {
      if (
        !showCart &&
        !showMenu &&
        !showItems &&
        !isMovingToTarget &&
        !showGameUIPopup
      ) {
        if (isShowForm === true) {
          formShowTimeout = setTimeout(() => {
            if (new Date() >= nextPlayableTime) {
              handleShowGameOnEnterPopup(true);
              setFormOverlay(false);
            }
            //else {handleShowGameWaitPopup(true)} 
            setShowForm(undefined);
          }, 1000);
        } else {
          if (formShowTimeout) {
            clearTimeout(formShowTimeout);
            formShowTimeout = undefined; // Reset the timeout variable
          }
        }
      } else {
        if (formShowTimeout) {
          clearTimeout(formShowTimeout);
          formShowTimeout = undefined; // Reset the timeout variable
        }
        setFormOverlay(true);
      }
    }
  }, [
    isShowForm,
    isEntryAnimationDone,
    showCart,
    showMenu,
    showItems,
    isMovingToTarget,
    showGameUIPopup,
  ]);

  return (
    <>
      {showGameOnEnterPopup ? (
        <>
          {/* <FormOverlay /> */}
          <div className="parent-form-test_0 no-text-select">
            <div className="sub-parent-form_0">
              <div
                className="form-close-icon no-text-select"
                onClick={() => {
                  setShowForm(undefined);
                  handleShowGameOnEnterPopup(false);
                }}
              >
                <CloseIcon
                  className="no-text-select"
                  style={{ fontSize: 20, color: "#fff" }}
                />
              </div>
              <div className="form-item-container_0">
                {/* <div className="heading-container_0" style={{marginBottom:"15px"}}> */}
                <span
                  className="heading-test-form_0"
                  style={{ marginBottom: "3px", fontWeight: "400" }}
                >
                  Unlock a promo code through a game of
                  <br />
                </span>

                {/* </div> */}
                <span
                  className="heading-test-form_0"
                  style={{ marginBottom: "11px", fontWeight: "400" }}
                >
                  mindfulness. Want to play?
                </span>
                <div
                  className="form-input-container_0"
                  style={{ marginTop: "0px" }}
                >
                  <div className="form-input-container-inside_0">
                    <input
                      onFocus={(event) => {
                        event.stopPropagation();
                        setValidEmail(false);
                      }}
                      className="input-fields-form_0"
                      style={{ borderColor: isValidEmail ? "red" : "white" }}
                      value={user.email}
                      onChange={(e) =>
                        !startsWithSpacesRegex.test(e.target.value) &&
                        setUser({ ...user, email: e.target.value })
                      }
                      placeholder="Enter your Email*"
                    />
                    <span className="form-error-msg_0">
                      {isValidEmail
                        ? "Please enter a valid email address"
                        : "    "}
                    </span>
                    <label htmlFor="subscribe">
                      <input
                        onFocus={(event) => {
                          event.stopPropagation();
                          setValidSubscribed(false);
                        }}
                        type="checkbox"
                        // style={{
                        //   borderColor: isValidSubscribed ? "red" : "white",
                        // }}
                        checked={user.subscribed}
                        onChange={(event) => {
                          setUser({
                            ...user,
                            subscribed: event.target.checked,
                          });
                        }}
                        id="subscribe"
                        name="subscribe"
                      />
                      <span className="label-test-form_0">
                        {" "}
                        I agree to the{" "}
                        <a
                          href="https://gabarmyanmar.com/pages/metaverse-game-terms-and-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#fff", fontWeight: 600 }}
                        >
                          Game T&Cs
                        </a>
                      </span>
                    </label>

                    <span className="form-error-msg_0">
                      {isValidSubscribed ? "Required" : "  "}
                    </span>
                  </div>
                </div>
                {/* <Game /> */}
                <button
                  className="form-submit-button_0"
                  onClick={() => handleOnSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

import React, { useState, useRef, useEffect } from "react";
import "./ThreeTemplates.scss";
import * as THREE from "three";
import Movements from "../modules/movement.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { VideoTexture } from "three";
import { ReactComponent as ShoppingCart } from "../assests/svg/bitcoin-icons_cart-outline.svg";
import { ReactComponent as HomeButton } from "../assests/svg/homeButton.svg";
import { ReactComponent as PoweredBy } from "../assests/svg/poweredby.svg";
import { ReactComponent as Keys } from "../assests/svg/keys-icon.svg";
// Models Urls
import Card from "./Card.js";
import Header from "./Header.jsx";
import Spinner from "./Spinner.js";
import GamePopup from "./games/GamePopup.jsx";
import SideBar from "./cart/SideBar.jsx";
import NavigationInstruction from "./dialog/NavigationInstruction.jsx";
import ProductDetails from "./ProductDetails.jsx";
import WantToPlayScreen from "./dialog/WantToPlayScreen.jsx";
import CongratsMessageTemplate from "./dialog/CongratsMessageTemplate.jsx";
import LostMessage from "./dialog/LostMessage.jsx";
import GameRules from "./dialog/GameRules.jsx";
import QuizDialog from "./dialog/QuizDialog.jsx";
import Cart from "./Cart.jsx";
import modelEnv2 from "../assests/images/potsdamer_platz_1k.hdr";
import modelEnv3 from "../assests/images/Sand_Dunes_high.jpg";
import modelEnv4 from "../assests/images/studio_small_08_2k.hdr";
import modelEnv5 from "../assests/images/Sky_Dome_equirectangular-jpg_pink_sky_1773824017_10340800.hdr";
import PopupGame from "./dialog/PopupGame.jsx";
import GoogleAnalytics from "./googleAnalytics/GoogleAnalytics.js";

function ThreeTemplates(props) {
  const [productVal, setProducts] = useState(props.products);
  const [openCard, setCard] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [varID, setVarID] = useState("");
  const [productCounter, setProductCounter] = useState(0);
  const [checkoutURL, setCheckoutURL] = useState("");
  const [openInstructionDialog, setOpenInstructionDialog] = useState(true);
  const [openCart, setOpenCart] = useState(false);
  const [openGame, setOpenGame] = useState(false);
  let modelPaths = [];
  let productLocation = [];
  const brandID = productVal.brand_id;
  // if(productVal.length>0){
  //   productVal.product.forEach((product, index) => {
  //     productLocation.push({x:product.x_coordinate , y :product.y_coordinate, z: product.z_coordinate });
  //     modelPaths.push(product.product_3D_model_url);
  //   });
  // }

  useEffect(() => {
    if (productVal !== null && productData !== undefined) {
      productVal.env_product_mappings.forEach((product, index) => {
        productLocation.push({
          x: product.x_coordinate,
          y: product.y_coordinate,
          z: product.z_coordinate,
        });
        modelPaths.push(product.brand_product_masters[0].product_3D_model_url);
      });
    }
  }, [productVal]);

  const containerRef = useRef();
  const modelsRef = useRef([]);
  let scene = new THREE.Scene();
  const productData = [];
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.001,
    1000
  );
  camera.fov = 2 * Math.atan(36 / (2 * 50)) * (180 / Math.PI);

  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(window.innerWidth, window.innerHeight);

  useEffect(() => {
    var container = containerRef.current;
    if (containerRef.current) {
      containerRef.current.appendChild(renderer.domElement);
    }
    if (productVal.env_template_id === 4) {
      camera.position.set(-0.38607296060670965, 0.27, 1.4942321515766563);
      camera.rotation.set(0, 0.06283185307179587, 0);
    } else {
      camera.position.set(0.04, 0.32, 1.5);
    }
    if (productVal.env_template_id !== 4) {
      const ambientLight = new THREE.AmbientLight("#ffffff", 2);
      scene.add(ambientLight);
    }

    if (productVal.env_template_id === 4) {
      Enviroment(modelEnv2);
    } else {
      Enviroment2(modelEnv4, modelEnv5);
    }
    loadProducts();
    SceneModel();

    function returnToHomePosition() {
      if (productVal.env_template_id === 4) {
        camera.position.set(-0.38607296060670965, 0.27, 1.4942321515766563);
        camera.rotation.set(0, 0.06283185307179587, 0);
      } else {
        camera.position.set(0.04, 0.32, 1.5);
        camera.rotation.set(0, 0, 0);
      }
    }
    const homeDiv = document.getElementById("home");
    console.log(homeDiv); 
    if (homeDiv) {
      homeDiv.addEventListener("click", returnToHomePosition);
    }

    const animate = () => {
      requestAnimationFrame(animate);
      Movement(camera);
      animateProducts();
      renderer.render(scene, camera);
    };
    animate();
    return () => {
      scene.traverse((obj) => {
        if (obj instanceof THREE.Mesh) {
          obj.geometry.dispose();
          obj.material.dispose();
        }
      });
      renderer.dispose();
      scene = new THREE.Scene();
      container.removeChild(renderer.domElement);
    };
  }, []);

  function Movement(camera) {
    var player = { speed: 0.01, turnSpeed: Math.PI * 0.01 };
    if (Movements.isPressed(87) || Movements.isPressed(38)) {
      camera.rotation.x = 0;
      camera.position.x -= Math.sin(camera.rotation.y) * player.speed;
      camera.position.z -= Math.cos(camera.rotation.y) * player.speed;
    }
    if (Movements.isPressed(83) || Movements.isPressed(40)) {
      camera.rotation.x = 0;
      camera.position.x += Math.sin(camera.rotation.y) * player.speed;
      camera.position.z += Math.cos(camera.rotation.y) * player.speed;
    }
    if (Movements.isPressed(65) || Movements.isPressed(37)) {
      camera.rotation.y += player.turnSpeed;
    }
    if (Movements.isPressed(68) || Movements.isPressed(39)) {
      camera.rotation.y -= player.turnSpeed;
    }
    if (productVal.env_template_id !== 4) {
      if (camera.position.z <= 0) {
        camera.position.z = 0;
      }
      if (camera.position.z >= 1.5) {
        camera.position.z = 1.5;
      }
      if (camera.position.x >= 0.5) {
        camera.position.x = 0.5;
      }
      if (camera.position.x <= -0.4) {
        camera.position.x = -0.4;
      }
    } else {
      const radius = 0.86;
      const meshCenter = new THREE.Vector3(-0.55, 0, 0.65);
      const dx = camera.position.x - meshCenter.x;
      const dz = camera.position.z - meshCenter.z;
      const distance = Math.sqrt(dx * dx + dz * dz);

      if (distance > radius) {
        const angle = Math.atan2(dz, dx);
        camera.position.x = meshCenter.x + radius * Math.cos(angle);
        camera.position.z = meshCenter.z + radius * Math.sin(angle);
      }
    }
  }

  function Enviroment(env) {
    const hdrLoader = new RGBELoader();
    hdrLoader.load(env, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.environment = envMap;
      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
      });
      if (productVal.env_template_id === 4) {
        const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
        const textureLoader = new THREE.TextureLoader();
        const texture2 = textureLoader.load(modelEnv3);
        const material = new THREE.MeshBasicMaterial({
          map: texture2,
          side: THREE.BackSide,
        });
        const backgroundMesh = new THREE.Mesh(backgroundGeometry, material);
        scene.add(backgroundMesh);
      } else {
        const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
        const backgroundMesh = new THREE.Mesh(
          backgroundGeometry,
          backgroundMaterial
        );
        scene.add(backgroundMesh);
      }

      pmremGenerator.dispose();
    });
  }
  function Enviroment2(lightingEnv, backgroundEnv) {
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;

    const hdrLoader = new RGBELoader();

    // Load and set the HDR image for lighting
    hdrLoader.load(lightingEnv, (texture) => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;

      scene.environment = envMap;

      pmremGenerator.dispose();
    });

    // Load and set the HDR image for the background
    hdrLoader.load(backgroundEnv, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.mapping = THREE.EquirectangularReflectionMapping;

      const backgroundMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.BackSide,
        toneMapped: false, // Disable tone mapping for the background
      });

      const backgroundGeometry = new THREE.SphereGeometry(100, 32, 32);
      const backgroundMesh = new THREE.Mesh(
        backgroundGeometry,
        backgroundMaterial
      );
      backgroundMesh.rotation.y = Math.PI; 
      scene.add(backgroundMesh);
    });
  }

  function SceneModel() {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    loader.load(
      productVal.selected_ext_setting,
      (gltf) => {
        const desiredSize = 3;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            if (child.name.includes("campaign-mesh")) {
              if (productVal.campaign_type === "video") {
                const video = document.createElement("video");
                video.src = productVal.campaign_link_url;
                video.crossOrigin = "anonymous";
                video.loop = true;
                video.muted = true;
                if (!video.paused) {
                  video.pause();
                }
                video.play().catch((error) => {
                  console.log("Error playing video:", error);
                });
                const texture = new THREE.VideoTexture(video);
                texture.minFilter = THREE.LinearFilter;
                texture.magFilter = THREE.LinearFilter;
                texture.flipY = false;
                const screenMaterial = new THREE.MeshBasicMaterial({
                  map: texture,
                  side: THREE.FrontSide,
                  toneMapped: false,
                });
                child.material = screenMaterial;
              } else if (productVal.campaign_type === "image") {
                const imageCamp = new THREE.TextureLoader().load(
                  productVal.campaign_link_url
                );
                imageCamp.flipY = false;
                const logoMaterial2 = new THREE.MeshBasicMaterial({
                  map: imageCamp,
                  transparent: true,
                });
                child.material = logoMaterial2;
              }
            }
          }

          if (child.isLight) {
            if (productVal.env_template_id === 4) {
              child.intensity = 0;
            } else {
              child.intensity = 0.5;
            }
          }
        });
        gltf.scene.position.set(0, 0, 0);
        scene.add(gltf.scene);
        setLoading(false);
      },
      () => {},
      (error) => {
        console.error(error);
      }
    );
  }

  function loadProducts() {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    loader.setDRACOLoader(dracoLoader);
    modelPaths.forEach((path, index) => {
      loader.load(path, (gltf) => {
        const desiredSize = 0.07;
        const boundingBox = new THREE.Box3().setFromObject(gltf.scene);
        const scaleFactor =
          desiredSize / boundingBox.getSize(new THREE.Vector3()).length();
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            child.userData.name = `${index + 1}`;
          }
        });
        gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
        gltf.scene.position.set(
          productLocation[index].x,
          productLocation[index].y,
          productLocation[index].z
        );
        gltf.scene.userData.name = `${index + 1}`;
        gltf.scene.name = `${index + 1}`;
        modelsRef.current[index] = gltf.scene;
        scene.add(gltf.scene);
        productData.push(gltf.scene);
      });
    });
  }

  function animateProducts() {
    if (productVal.env_template_id === 4) {
      if (modelsRef.current.length > 0) {
        let models = modelsRef.current;
        models.forEach((model, index) => {
          model.rotation.y += 0.0032;
        });
      }
    }
  }

  function cartPopup(event) {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(productData, true);
    intersects.forEach((intersect) => {
      const product = intersect.object;
      var num = product.userData.name;
      //console.log(num);
      if (productVal.product !== undefined) {
        setCardData(productVal.product[parseInt(num) - 1]);
        setCard(true);
      }
    });
  }
  renderer.domElement.addEventListener("click", cartPopup);

  const handlePopup = (data) => {
    setCard(false);
    setCardData(null);
  };
  const handleID = (data) => {
    setVarID(data);
  };
  const handleProductCount = (data) => {
    setProductCounter(productCounter + data);
  };

  const handleCheckout = (data) => {
    setCheckoutURL(data);
  };
  return (
    <>
      {productVal.google_analytics_id !== null && (
        <GoogleAnalytics trackingId={productVal.google_analytics_id} />
      )}
      <div className="three-template">
        <div className="header-cart-container">
          {!isLoading && (
            <div className="icon-wrapper" onClick={() => setOpenCart(true)}>
              <ShoppingCart />
            </div>
          )}
        
            <div  id="home">
            {!isLoading && (  <div className="icon-wrapper"> <HomeButton /> </div>)}
            </div>
         
        </div>
        {openCard && (
          <Card
            data={cardData}
            closePopup={handlePopup}
            getId={handleID}
            token={props.token}
            brandID={brandID}
            varID={varID}
            productCount={handleProductCount}
            checkout={handleCheckout}
          />
        )}
        <div className={isLoading ? `loadingTab` : `loaded`}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spinner />
            </div>
          )}
          <div
            className={openCard ? "blurred" : ""}
            ref={containerRef}
            style={{
              height: "100%",
              overflow: "hidden",
              position: "relative",
              zIndex: "1",
            }}
          ></div>
        </div>
      </div>
      <div>
        {!isLoading && !openInstructionDialog && props.gameData.length > 0 && (
          <PopupGame gameData={props.gameData} setOpenGame={setOpenGame} />
        )}
      </div>

      <div className="poweredby-wrapper">
        <div>
          <Keys />
        </div>
        <div className="poweredby-icon">
          <PoweredBy />
        </div>
      </div>

      {!isLoading && openInstructionDialog && (
        <NavigationInstruction
          openInstructionDialog={openInstructionDialog}
          setOpenInstructionDialog={setOpenInstructionDialog}
        />
      )}

      {openCart && (
        <SideBar
          openCart={openCart}
          setOpenCart={setOpenCart}
          varID={varID}
          brand={brandID}
          productCounter={productCounter}
          checkoutURL={checkoutURL}
        />
      )}
    </>
  );
}

export default ThreeTemplates;
import React, { useEffect, useState } from "react";
import CounterBox from "./CounterBox";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import GoogleMap from "./GoogleMap";
import Spinner from "../../../helper/Spinner";
import ApiConfig from "../../../helper/ApiConfig";
import { fetchData } from "../../../fetcher/fetchData";
import useAuthStore from "../../../store/authStore";
import "../../../style/components/analytics/VisitorAnalytic.scss";


function VisitorAnalytic({ selectedRange }) {
  const [analyticData, setAnalyticData] = useState({});
  const [analyticData2, setAnalyticData2] = useState({});
  const [analyticValues, setAnalyticValues] = useState({
    totalVisitors: "",
    totalSessions: "",
    averageSessionDuration: "",
    visitorsPercentChange: "",
    sessionsPercentChange: "",
    durationPercentChange: "",
    visitorsInc: true,
    sessionsInc: true,
    durationInc: true,
  });
  const [visitorGraph, setVisitorGraph] = useState({});
  const [durationGraph, setDurationGraph] = useState({});
  const [countryGraph, setCountryGraph] = useState({});
  const [loading, setLoading ] = useState(false);
  const { token } = useAuthStore();

  function getCurrentWeekRange() {
    const today = new Date();
    const dayOfWeek = today.getDay(); 
    const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - (adjustedDayOfWeek - 1));

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return {
        startOfWeek: formatDate(startOfWeek),
        endOfWeek: formatDate(today)
    };
}

function getLastWeekRange() {
  const today = new Date();
  const dayOfWeek = today.getDay(); 
  const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
  const startOfLastWeek = new Date(today);
  startOfLastWeek.setDate(today.getDate() - adjustedDayOfWeek - 6);
  const endOfLastWeek = new Date(today);
  endOfLastWeek.setDate(today.getDate() - adjustedDayOfWeek);

  const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

  return {
      startOfLastWeek: formatDate(startOfLastWeek),
      endOfLastWeek: formatDate(endOfLastWeek)
  };
}

  useEffect(() => {
    const getEnvDetails = async () => {
      const weekRange = getCurrentWeekRange();
      const lastWeekRange = getLastWeekRange();
      setLoading(true);
      try {
        const info = await fetchData(
          (selectedRange && selectedRange.start && selectedRange.end)
            ? `${ApiConfig.runReports}?property_id=468803988&start_date=${selectedRange.start}&end_date=${selectedRange.end}`
            : `${ApiConfig.runReports}?property_id=468803988&start_date=${weekRange.startOfWeek}&end_date=${weekRange.endOfWeek}`,
          setLoading,
          "get",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (info?.status === 200) {
          setAnalyticData(info.data.data[0]);
        }
        if (info?.statusCode === 401 || info?.statusCode === 500) {
          // setErrorMessage(true)
        }

        const info2 = await fetchData(
          (selectedRange && selectedRange.lastStart && selectedRange.lastEnd)
            ? `${ApiConfig.runReports}?property_id=468803988&start_date=${selectedRange.lastStart}&end_date=${selectedRange.lastEnd}`
            : `${ApiConfig.runReports}?property_id=468803988&start_date=${lastWeekRange.startOfLastWeek}&end_date=${lastWeekRange.endOfLastWeek}`,
          setLoading,
          "get",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (info2?.statusCode === 200) {
          setAnalyticData2(info2.data.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    getEnvDetails();
  }, [selectedRange]);

  useEffect(() => {
    const processAnalyticsData = () => {
      if (analyticData.rows && analyticData.rows.length > 0) {
        let totalVisitors = 0;
        let totalSessions = 0;
        let totalSessionDuration = 0;
        let rowCount = analyticData.rows.length;

        analyticData.rows.forEach((row) => {
          totalVisitors += parseInt(row.metricValues[4].value);
          totalSessions += parseInt(row.metricValues[2].value);
          totalSessionDuration += parseFloat(row.metricValues[1].value);
        });

        let averageSessionDuration = totalSessionDuration / rowCount;
        
        const previousTotalVisitors = analyticData2.rows
          ? analyticData2.rows.reduce((acc, row) => acc + parseInt(row.metricValues[4].value), 0)
          : 0;
        const previousTotalSessions = analyticData2.rows
          ? analyticData2.rows.reduce((acc, row) => acc + parseInt(row.metricValues[2].value), 0)
          : 0;
        const previousTotalSessionDuration = analyticData2.rows
          ? analyticData2.rows.reduce((acc, row) => acc + parseFloat(row.metricValues[1].value), 0)
          : 0;

        const previousAverageSessionDuration = previousTotalSessionDuration / (analyticData2.rows ? analyticData2.rows.length : 1);

        const visitorsPercentChange = previousTotalVisitors
          ? ((totalVisitors - previousTotalVisitors) / previousTotalVisitors) * 100
          : 0;
        const sessionsPercentChange = previousTotalSessions
          ? ((totalSessions - previousTotalSessions) / previousTotalSessions) * 100
          : 0;
        const durationPercentChange = previousAverageSessionDuration
          ? ((averageSessionDuration - previousAverageSessionDuration) / previousAverageSessionDuration) * 100
          : 0;

        setAnalyticValues((prevState) => ({
          ...prevState,
          totalVisitors: totalVisitors.toString(),
          totalSessions: totalSessions.toString(),
          averageSessionDuration: (averageSessionDuration / 60).toFixed(2) + " min",
          visitorsPercentChange: visitorsPercentChange.toFixed(2) + "%",
          sessionsPercentChange: sessionsPercentChange.toFixed(2) + "%",
          durationPercentChange: durationPercentChange.toFixed(2) + "%",
          visitorsInc: visitorsPercentChange >= 0,
          sessionsInc: sessionsPercentChange >= 0,
          durationInc: durationPercentChange >= 0,
        }));

        // Process graph data...
        function formatDate(dateString) {
          const year = dateString.substring(0, 4);
          const month = dateString.substring(4, 6);
          const day = dateString.substring(6, 8);
          const date = new Date(`${year}-${month}-${day}`);
          const options = { day: "numeric", month: "short" };
          return date.toLocaleDateString("en-US", options);
        }
        const groupedData2 = {};

        analyticData.rows.forEach((row) => {
          const date = row.dimensionValues[5].value;
          const visitor = parseFloat(row.metricValues[4].value);

          if (!groupedData2[date]) {
            groupedData2[date] = { totalDuration: 0, count: 0 };
          }
          groupedData2[date].totalDuration += visitor;
          groupedData2[date].count += 1;
        });

        const data4 = Object.keys(groupedData2).map((date) => {
          const { totalDuration } = groupedData2[date];
          return {
            name: formatDate(date),
            visitor: totalDuration,
          };
        });

        const groupedData = {};

        analyticData.rows.forEach((row) => {
          const date = row.dimensionValues[5].value;
          const averageDuration = parseFloat(row.metricValues[1].value);

          if (!groupedData[date]) {
            groupedData[date] = { totalDuration: 0, count: 0 };
          }
          groupedData[date].totalDuration += averageDuration;
          groupedData[date].count += 1;
        });

        const data3 = Object.keys(groupedData).map((date) => {
          const { totalDuration, count } = groupedData[date];
          return {
            name: formatDate(date),
            averageDuration: (totalDuration / (count * 60)).toFixed(2),
          };
        });
        console.log(data4);
       
        
        
        setDurationGraph(data3);
        setVisitorGraph(data4);

        function formatCountryData(analyticData) {
          const countryData = {};

          analyticData.rows.forEach((row) => {
            const countryId = row.dimensionValues[1].value;
            const countryName = row.dimensionValues[2].value;
            const activeUsers = parseFloat(row.metricValues[4].value);
            if (countryName !== "(not set)") {
              if (!countryData[countryId]) {
                countryData[countryId] = {
                  country: countryId,
                  name: countryName,
                  users: 0,
                };
              }
              countryData[countryId].users += activeUsers;
            }
          });

          const demoData = Object.values(countryData).map((country) => ({
            country: country.country,
            name: country.name,
            users: Math.round(country.users),
            flag: `https://flagcdn.com/${country.country.toLowerCase()}.svg`,
          }));

          return demoData;
        }
        const demoData = formatCountryData(analyticData);
        setCountryGraph(demoData);
      } else {
        console.log("No data available in analyticData.rows");
        setCountryGraph([]);
        setDurationGraph([{
          "name": "NA",
          "averageDuration": "0"
      }]);
        setVisitorGraph([{
          "name": "NA",
          "visitor": 0
      }]);
      }
    };

    processAnalyticsData();
  }, [analyticData, analyticData2]);

  return (
    <> 
      {!loading ? (
        <>
         {/* {errorMessage && <p style={{marginBottom:'.5vw',color:'red'}}>Analytic data not available, to configure kindly reach us on support@onewayx.com.</p>} */}
        <div className="data-box">
          <div className="counter-box">
            <div className="container">
              <div className="analytic-data">
                <CounterBox
                  title="Total No. of Visitors"
                  counter={analyticValues.totalVisitors}
                  percent={analyticValues.visitorsPercentChange}
                  inc={analyticValues.visitorsInc}
                />
                <CounterBox
                  title="Total No. of Sessions"
                  counter={analyticValues.totalSessions}
                  percent={analyticValues.sessionsPercentChange}
                  inc={analyticValues.sessionsInc}
                />
                <CounterBox
                  title="Average Session Duration"
                  counter={analyticValues.averageSessionDuration}
                  percent={analyticValues.durationPercentChange}
                  inc={analyticValues.durationInc}
                />
              </div>
              <div className="visitor-graph">
                <ResponsiveContainer>
                  <LineChart width={660} height={200} data={visitorGraph}>
                    <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="visitor"
                      stroke="#B01F8D"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="conversion">
              <h5 className="conversion-title">Conversion Rate (Coming Soon)</h5>
              <p>NA</p>
              <div className="conversion-data">
                <ul>
                  <li>
                    <p>
                      <span className="conversion-data-title">Total Revenue</span>
                      <span className="value">NA</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="conversion-data-title">Total No. Orders</span>
                      <span className="value">NA</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="conversion-data-title">Abandonment Cart</span>
                      <span className="value">NA</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="counter-box">
            <div className="average-duration">
              <h2>Average duration of stay in the Virtual Store</h2>
              {durationGraph.length > 0 && (
                <ResponsiveContainer>
                  <LineChart width={660} height={200} data={durationGraph}>
                    <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                    <YAxis domain={[Math.ceil(Math.min(...durationGraph.map(item => item.averageDuration))), Math.ceil(Math.max(...durationGraph.map(item => item.averageDuration)))]}/>
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="averageDuration"
                      stroke="#2E3896"
                      activeDot={{ r: 5 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </div>
            <div className="country-map">
              <h2>Top Locations by Visitors</h2>
              <div>
                { countryGraph.length && <GoogleMap data={countryGraph} />}
              </div>
            </div>
          </div>
        </div>
        </>
      ) : (
        <div style={{display:'flex',alignItems:'center', padding:'12vw 0', justifyContent:'center'}}>
          <Spinner/>
        </div>
      )}
    </>
  );
}

export default VisitorAnalytic;


import React, { useState, useEffect } from "react";
import { Dialog, IconButton, TextField, Tooltip } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/close-dialog-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";

const EmailDialog = ({
  handleCloseDialog,
  openEmailDialog,
  setOpenEmailDialog,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [timerCount, setTimerCount] = useState(5);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const data = await fetchData(ApiConfig.addFeedback,setLoading,'POST',{ feedback_email: values.email }); 
      if (data) {
        setCurrentStep(2);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Error submitting feedback. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentStep === 2) {
      let interval = setInterval(() => {
        setTimerCount((lastTimerCount) => {
          lastTimerCount <= 1 && clearInterval(interval);
          if (lastTimerCount <= 1) {
            setOpenEmailDialog(false);
            setCurrentStep(1);
            setTimerCount(5);
          }
          if (lastTimerCount <= 0) return lastTimerCount;
          return lastTimerCount - 1;
        });
      }, 1000); 
      return () => clearInterval(interval);
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 2) {
      const timeout = setTimeout(() => {
        setOpenEmailDialog(false);
      }, timerCount * 1000);
      return () => clearTimeout(timeout);
    }
  }, [currentStep, setOpenEmailDialog, timerCount]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <>
      {openEmailDialog && (
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          open={openEmailDialog}
          className="common-dialog-container congratulation-dialog-container"
        >
          <ToastContainer />
          <form onSubmit={formik.handleSubmit}>
            <IconButton
              className="close-dialog-icon-button"
              onClick={() => setOpenEmailDialog(false)}
            >
              <Tooltip title="Close Dialog">
                <CloseIcon className="close-dialog-icon" />
              </Tooltip>
            </IconButton>
            {currentStep === 1 && (
              <>
                <div className="dialog-title email-title">
                  Kindly share your email, and our team will get in touch.
                </div>
                <div className="dialog-input-container email-input-container">
                  <TextField
                    sx={{ margin: "20px 0" }}
                    className="common-input-field"
                    label="Your Email*"
                    type="email"
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="dialog-submit-container">
                  <button
                    type="submit"
                    className="dialog-input-submit"
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <div className="success-dialog">
                <div className="dialog-title no-line-height">
                  Your information has been successfully submitted.
                </div>
                <div className="dialog-subTitle">
                  Our team will reach out to you shortly. Thank you for your
                  interest, and we look forward to connecting with you!
                </div>
                <div className="dialog-submit-container">
                </div>
                <div className="button-redirection-text">
                  Auto close in {timerCount} sec
                </div>
              </div>
            )}
          </form>
        </Dialog>
      )}
    </>
  );
};

export default EmailDialog;

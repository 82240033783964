// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analytic h2.title {
  font-weight: 400;
  font-size: 2.2vw;
  color: #3A3A3A;
  margin-bottom: 1.5vw;
}
.analytic .tab-container {
  margin-bottom: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analytic .tab-container button {
  border: none;
  font-size: 1.05vw;
  line-height: 1.25vw;
  font-weight: 400;
  color: #3A3A3A;
  cursor: pointer;
  background-color: transparent;
  padding: 0.555vw;
  margin-right: 1.5vw;
}
.analytic .tab-container button:after {
  width: calc(100% - 1.11vw);
  left: 0.555vw;
}
.analytic .tab-container button:first-child {
  padding-left: 0;
}
.analytic .tab-container button:first-child:after {
  width: calc(100% - 0.555vw);
  left: 0;
}
.analytic .tab-container button.active {
  position: relative;
}
.analytic .tab-container button.active:after {
  content: "";
  position: absolute;
  height: 3px;
  bottom: 0;
  background: #EE3A24;
}`, "",{"version":3,"sources":["webpack://./src/pages/Analytics.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,oBAAA;AAAR;AAEI;EACI,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAR;AACQ;EACI,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AACZ;AAAY;EACI,0BAAA;EACA,aAAA;AAEhB;AACY;EACI,eAAA;AAChB;AAAgB;EACI,2BAAA;EACA,OAAA;AAEpB;AACY;EACI,kBAAA;AAChB;AACgB;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,mBAAA;AACpB","sourcesContent":[".analytic{\n    h2.title{\n        font-weight: 400;\n        font-size: 2.2vw;\n        color: #3A3A3A;\n        margin-bottom: 1.5vw;\n    }\n    .tab-container{\n        margin-bottom: 1.5vw;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        button{\n            border: none;\n            font-size: 1.05vw;\n            line-height: 1.25vw;\n            font-weight: 400;\n            color: #3A3A3A;\n            cursor: pointer;\n            background-color: transparent;\n            padding: 0.555vw;\n            margin-right: 1.5vw;\n            &:after{\n                width:calc(100% - 1.11vw);\n                left: .555vw;\n            }\n            \n            &:first-child{\n                padding-left: 0;\n                &:after{\n                    width:calc(100% - .555vw);\n                    left:0;\n                }\n            }\n            &.active {\n                position: relative;\n\n                &:after {\n                    content: '';\n                    position: absolute;\n                    height: 3px;\n                    bottom: 0;\n                    background: #EE3A24;\n                }\n            }\n        }\n    }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enviroment.congratulations-heading .congratulations-container {
  background: transparent;
}
.enviroment.congratulations-heading .congratulations-container .image-wrapper {
  padding: 0;
  background: transparent;
}
.enviroment.congratulations-heading .congratulations-container .btn-container {
  max-width: 67.37vw;
}
.enviroment.congratulations-heading .congratulations-container .btn-container button {
  cursor: pointer;
}
.enviroment.congratulations-heading .congratulations-container .btn-container .go-live {
  line-height: 2.15vw;
  padding: 0.75vw 5.5vw;
}
.enviroment.congratulations-heading .congratulations-container .btn-container .view-live {
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/style/components/store/Enviroment.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACE;EACE,UAAA;EACA,uBAAA;AACJ;AACE;EACE,kBAAA;AACJ;AAAI;EACE,eAAA;AAEN;AAAI;EACE,mBAAA;EACA,qBAAA;AAEN;AAAI;EACE,uBAAA;AAEN","sourcesContent":[".enviroment.congratulations-heading .congratulations-container {\n  background: transparent;\n\n  .image-wrapper {\n    padding: 0;\n    background: transparent;\n  }\n  .btn-container {\n    max-width: 67.37vw;\n    button {\n      cursor: pointer;\n    }\n    .go-live {\n      line-height: 2.15vw;\n      padding: 0.75vw 5.5vw;\n    }\n    .view-live {\n      background: transparent;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game-popup {
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  z-index: 2;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.game-popup .game-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.game-popup .game-list .closeIcon svg {
  top: -30px;
  left: auto;
  right: -30px;
}
.game-popup .game-list .closeIcon svg path {
  fill: #000;
}
.game-popup .game-list svg {
  position: absolute;
  top: 25px;
  left: 20px;
  cursor: pointer;
  z-index: 999999;
}
.game-popup .selected-game {
  width: 100%;
}

.theme-backdrop .videoGameIcon {
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 10px;
  cursor: pointer;
  fill: #EE3A24;
}`, "",{"version":3,"sources":["webpack://./src/components/games/GamePopup.scss","webpack://./src/assests/styles/_colors.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAF;AAEE;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,UAAA;EACA,UAAA;EACA,YAAA;AAAR;AAEQ;EACI,UAAA;AAAZ;AAII;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;AAFR;AAME;EACE,WAAA;AAJJ;;AAOA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,eAAA;EACI,aCpDQ;ADgDhB","sourcesContent":["@import '../../assests/styles/colors';\n.game-popup {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  position: absolute;\n  z-index: 2;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n\n  .game-list {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n    background: #fff;\n    padding: 20px;\n    border-radius: 10px;\n    position: relative;\n\n    .closeIcon svg{\n        top: -30px;\n        left: auto;\n        right: -30px;\n\n        path{\n            fill: #000;\n        }\n    }\n\n    svg{\n        position: absolute;\n        top: 25px;\n        left: 20px;\n        cursor: pointer;\n        z-index: 999999;\n    }\n  }\n\n  .selected-game {\n    width: 100%;\n  }\n}\n.theme-backdrop .videoGameIcon {\n    position: absolute;\n    bottom: 0;\n    z-index: 2;\n    left: 10px;\n    cursor: pointer;\n        fill: $primary-color;\n\n}","$primary-color: #EE3A24;\n$secondary-color: #923D32;\n\n$text_black : #000000;\n$color_white : #fff;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

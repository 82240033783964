// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/inter/Inter-VariableFont.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Inter";
  src: local("Inter"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
.button2 {
  font-size: 1.040625vw;
  font-weight: 600;
  line-height: 1.665vw;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 0.555vw;
  border: 1px solid #FFFFFF;
  color: #EE3A24;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.2);
  height: 3.12243vw;
  min-width: 12.4875vw;
}

.button2:not([disabled]):hover {
  background: #EE3A24;
  color: #fff;
}

.button2:not([disabled]):active {
  background: #CA311E;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922) inset;
  border: none;
}

.button2 svg {
  background: transparent;
  transition: 0.2s ease-in-out all;
}

.button2 svg path {
  fill: #EE3A24;
}

.button2:hover svg path {
  fill: #fff;
}

.button2:disabled,
.button2[disabled] {
  background: rgba(77, 77, 77, 0.2);
  border: 1px solid rgba(77, 77, 77, 0.2);
  color: rgba(46, 46, 46, 0.6);
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/assests/styles/_fonts.scss","webpack://./src/components/Button2.scss","webpack://./src/assests/styles/_colors.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,+EAAA;ACCJ;AADA;EACI,qBAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,yBAAA;EACA,cCVY;EDWZ,gCAAA;EACA,eAAA;EACA,cAAA;EACA,oCAAA;EACA,iBAAA;EACA,oBAAA;AAGJ;;AAAA;EACI,mBCpBY;EDqBZ,WCjBW;ADoBf;;AAAA;EACI,mBAAA;EACA,6DAAA;EACA,YAAA;AAGJ;;AAAA;EACI,uBAAA;EACA,gCAAA;AAGJ;;AAAA;EACI,aCpCY;ADuChB;;AAAA;EACI,UCpCW;ADuCf;;AAAA;;EAEI,iCAAA;EACA,uCAAA;EACA,4BAAA;EACA,eAAA;AAGJ","sourcesContent":["@font-face {\n    font-family: \"Inter\";\n    src: local(\"Inter\"),\n     url(\"../../fonts/inter/Inter-VariableFont.ttf\") format(\"truetype\");\n   }\n@mixin primeFont() {\n    font-family: \"Inter\";\n}","@import '../assests/styles/mixins';\n\n.button2 {\n    font-size: 1.040625vw;\n    font-weight: 600;\n    line-height: 1.665vw;\n    letter-spacing: 0em;\n    text-align: center;\n    border-radius: 0.555vw;\n    border: 1px solid #FFFFFF;\n    color: $primary-color;\n    transition: .2s ease-in-out all;\n    cursor: pointer;\n    color: #FFFFFF;\n    background: #FFFFFF33;\n    height: 3.12243vw;\n    min-width: 12.4875vw;\n}\n\n.button2:not([disabled]):hover {\n    background: $primary-color;\n    color: $color_white;\n}\n\n.button2:not([disabled]):active {\n    background: #CA311E;\n    box-shadow: 0px 4px 4px 0px #00000040 inset;\n    border:none;\n}\n\n.button2 svg {\n    background: transparent;\n    transition: .2s ease-in-out all;\n}\n\n.button2 svg path {\n    fill: $primary-color;\n}\n\n.button2:hover svg path {\n    fill: $color_white;\n}\n\n.button2:disabled,\n.button2[disabled] {\n    background: #4D4D4D33;\n    border: 1px solid #4D4D4D33;\n    color: #2E2E2E99;\n    cursor: default;\n}","$primary-color: #EE3A24;\n$secondary-color: #923D32;\n\n$text_black : #000000;\n$color_white : #fff;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import "./instruction.css";
import useStore from "../../util/3dstore";
import useDeviceDetect from "../../util/useDeviceDetect";
import joystick from "../../../../assets/three/images/joystick.svg";
import finger1 from "../../../../assets/three/images/finger-1.svg";
import finger2 from "../../../../assets/three/images/finger-2.svg";
import mouse1 from "../../../../assets/three/images/mouse-1.png";
import mouse2 from "../../../../assets/three/images/mouse-2.png";
import wasd from "../../../../assets/three/images/wasd.svg";
import useSoundStore from "../AudioFinal/soundStore";
import useAudioContainer from "../../util/useAudioContainer";


export default function Instruction({ variation }) {
  const { setPlaying } = useSoundStore();
  const { triggerAudioPlayback } = useAudioContainer();
  const isTouchDevice = useDeviceDetect();
  const { handleInstructionModal, instructionModal } = useStore(
    (state) => state
  );

  const {
    isLoadingDone,
    setReady,
    isEntryAnimationDone,
    setDoneEntryAnimation,
    setPanEnabled,
    setInstructionDone,
  } = useStore();

  const instructionClassName = `parent-container-dummy ${
    isLoadingDone ? "show_screen" : "noshow_screen"
  }`;
  const insDivisionClassName = `ins-division ${
    isLoadingDone ? "show_screen" : "noshow_screen"
  }`;

  const startJourney = () => {
    setPanEnabled(false);
    setReady(true);
    setInstructionDone();
    handleInstructionModal(true);
    if(variation === 3){
      triggerAudioPlayback();
      setPlaying(true);
    }
    if (isEntryAnimationDone === undefined) setDoneEntryAnimation(false);
  };
  return (
    <>
      {!instructionModal ? (
        <div className={instructionClassName}>
          <div className={insDivisionClassName}>
            <div className={`heading-form_0`}>Welcome to Gabar</div>
            <div className="heading-form">
              Instructions To Navigate Our New World
            </div>
            <div className="ins-sub-container">
              <div className="ins-item-container">
                <img
                  className="ins-img2"
                  src={
                    isTouchDevice
                      ? joystick
                      : wasd
                  }
                />
                <div className="ins-partition" />
                {isTouchDevice ? (
                  <span className="ins-light-txt-mouse">
                    Use joystick to move around the space
                  </span>
                ) : (
                  <div className="ins-txt-sub-container">
                    <div className="ins-txt">
                      <span className="ins-bold-txt">W</span>
                      <span
                        className="ins-bold-txt"
                        style={{ marginLeft: "1px" }}
                      >
                        :
                      </span>
                      <span className="ins-light-txt"> Move forward</span>
                    </div>
                    <div className="ins-txt">
                      <span className="ins-bold-txt">A</span>
                      <span
                        className="ins-bold-txt"
                        style={{ marginLeft: "4px" }}
                      >
                        :
                      </span>
                      <span className="ins-light-txt"> Move left</span>
                    </div>
                    <div className="ins-txt">
                      <span className="ins-bold-txt">S</span>
                      <span
                        className="ins-bold-txt"
                        style={{ marginLeft: "5px" }}
                      >
                        :
                      </span>
                      <span className="ins-light-txt"> Move backward</span>
                    </div>
                    <div className="ins-txt">
                      <span className="ins-bold-txt">D</span>
                      <span
                        className="ins-bold-txt"
                        style={{ marginLeft: "4px" }}
                      >
                        :
                      </span>
                      <span className="ins-light-txt"> Move right</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="ins-border" />
              <div className="ins-item-container">
                <img
                  className="ins-img"
                  src={
                    isTouchDevice
                      ? finger1
                      : mouse1
                  }
                />
                <div className="ins-partition" />
                <span className="ins-light-txt-mouse">
                  Click and drag to look around the space
                </span>
              </div>
              <div className="ins-border" />
              <div className="ins-item-container">
                <img
                  className="ins-img"
                  src={
                    isTouchDevice
                      ? finger2
                      : mouse2
                  }
                />
                <div className="ins-partition" />
                <span className="ins-light-txt-mouse">
                  Double click to move around the space
                </span>
              </div>
            </div>
            <input
              onClick={startJourney}
              className="styled no-text-select"
              type="button"
              value="Enter"
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

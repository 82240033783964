// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* * {
  box-sizing: border-box;
} */

html,
body,
#root {
  width: 100%;
  /* 
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  -webkit-touch-callout: none; */
  /* -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  /* overflow: hidden; */
  background-color: #fff;
}




.fullscreen {
  position: absolute;
  background-color: #000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  z-index: 5;
}

.fullscreen.ready{
  pointer-events: none;
  display: none !important;
}

.fullscreen_notready {
  opacity: 0.5;
}



.fullscreen .logout {
  background: transparent;
  border: none;
  width: auto;
  color: white;
  display: block;
  margin: 10px auto;
}

.fullscreen .continue-link {
  text-decoration: none;
  transition: all 1s;
  width: 20%;
  text-align: center;
}

.fullscreen.notready .continue-link {
  cursor: auto;
  color: #606060;
}

.fullscreen.ready .continue-link {
  cursor: pointer;
  color: white;

}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/gabar/components2/UI/Product/backdrop.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;;;EAGE,WAAW;EACX;;;;;gCAK8B;EAC9B;;;;sBAIoB;EACpB,sBAAsB;EACtB,sBAAsB;AACxB;;;;;AAKA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,oBAAoB;EACpB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;AACd;;;;AAIA;EACE,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,YAAY;;AAEd;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ","sourcesContent":["/* * {\n  box-sizing: border-box;\n} */\n\nhtml,\nbody,\n#root {\n  width: 100%;\n  /* \n  height: 100%;\n  margin: 0;\n  padding: 0;\n  background-color: black;\n  -webkit-touch-callout: none; */\n  /* -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none; */\n  /* overflow: hidden; */\n  background-color: #fff;\n}\n\n\n\n\n.fullscreen {\n  position: absolute;\n  background-color: #000000;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  transition: all 1s;\n  z-index: 5;\n}\n\n.fullscreen.ready{\n  pointer-events: none;\n  display: none !important;\n}\n\n.fullscreen_notready {\n  opacity: 0.5;\n}\n\n\n\n.fullscreen .logout {\n  background: transparent;\n  border: none;\n  width: auto;\n  color: white;\n  display: block;\n  margin: 10px auto;\n}\n\n.fullscreen .continue-link {\n  text-decoration: none;\n  transition: all 1s;\n  width: 20%;\n  text-align: center;\n}\n\n.fullscreen.notready .continue-link {\n  cursor: auto;\n  color: #606060;\n}\n\n.fullscreen.ready .continue-link {\n  cursor: pointer;\n  color: white;\n\n}\n\n.fullscreen.clicked {\n  pointer-events: none;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { Chart } from "react-google-charts";

const colors = [
  "#FF5733",
  "#33FF57",
  "#3357FF",
  "#FF33A1",
  "#A133FF",
  "#33FFF5",
  "#F5FF33",
  "#FF8F33",
  "#FF3333",
  "#33FF8F",
];

const GoogleMap = ({ data }) => {
const totalUsers = data.reduce((sum, item) => sum + item.users, 0);

const sortedData = [...data].sort((a, b) => b.users - a.users);
const topCountries = sortedData.slice(0, 5);

const chartData = [
  ['Country', 'Users', { role: 'tooltip', type: 'string', p: { html: true } }],
  ...data.map(item => [
    item.country,
    item.users,
    `<div style="padding:10px"><strong>${item.name}</strong><img src="${item.flag}" alt="${item.country}" style="width:20px;height:14px;margin: 0 .5vw;"/><br/>Users: ${item.users}</div>`
  ])
];


const options = {
    tooltip: { isHtml: true },
    backgroundColor: "#fff",
    datalessRegionColor: "ececec",
    defaultColor: "#f5f5f5",
    colorAxis: { colors: colors.slice(0, data.length) },
    legend: 'none',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' , justifyContent:'flex-end'}}>
    <Chart
      chartType="GeoChart"
      width="90%"
      height="200px"
      data={chartData}
      options={options}
    />
    <div className="country-list" style={{ width: '50%' }}>
      <h3>Top 5 Countries</h3>
         <div className="country-box">
      {topCountries.map((country, index) => (
          <div key={country.country} style={{ marginBottom: '15px' }} className="country-data">
            <div>
                <img src={country.flag} alt={country.country} className="country-flag"/>
            </div>
            <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p><span>{country.name}</span></p>
            </div>
            <div className="percentage-data">
                <div className="percentage-bar">
            <div className="percentage-bar-val" style={{
              height: '10px',
              backgroundColor: colors[topCountries.length - index - 1],
              width: `${(country.users / totalUsers) * 100}%`
            }}></div>
            </div>
            <p> <span>{((country.users / totalUsers) * 100).toFixed(2)}%</span></p>
            </div>
            </div>
          </div>
        ))}
        </div>
    </div>
  </div>
  );
};

export default GoogleMap;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.demo-button-main {
  /* background-color: aqua; */
  position: absolute;
  z-index: 4;
  bottom: 20px;
  right: 80px;
  background-color: antiquewhite;
  padding: 10px;
}
.demo-button-main-item {
  position: absolute;
  z-index: 4;
  bottom: 20px;
  left: 15px;
  background-color: antiquewhite;
  padding: 10px;
}


`, "",{"version":3,"sources":["webpack://./src/enviroments/gabar/ui/ui.css"],"names":[],"mappings":";;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,WAAW;EACX,8BAA8B;EAC9B,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,UAAU;EACV,8BAA8B;EAC9B,aAAa;AACf","sourcesContent":["\n\n.demo-button-main {\n  /* background-color: aqua; */\n  position: absolute;\n  z-index: 4;\n  bottom: 20px;\n  right: 80px;\n  background-color: antiquewhite;\n  padding: 10px;\n}\n.demo-button-main-item {\n  position: absolute;\n  z-index: 4;\n  bottom: 20px;\n  left: 15px;\n  background-color: antiquewhite;\n  padding: 10px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { ReactComponent as CloseIcon } from "../assests/svg/close-dialog-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SubtractIcon } from "../assests/svg/minus.svg";
import "../components/ProductDetails.scss";
import { useNavigate } from "react-router-dom";
import useAudioContainer from "./utils2/useAudioContainer";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useSoundStore from "./components2/UI/Audio/soundStore";

function AddtoCart({ data, closePopup}) {
  const [counterData, changeCounterData] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isCartLoaded, setCartLoaded] = useState(false);
  const [cartData, setCartData] = useState({ checkoutId: "", quantity: counterData, variantId: "" });
  const navigate = useNavigate();
  const {brandId, varID, setVarID, setCheckoutURL} = useAudioContainer();
  const { isMuted, setMuted } = useSoundStore();  
  const [isMuteVisible, setMuteVisibity] = useState(0);
  const [isUnmuteVisible, setUnMuteVisibility] = useState(1);

  useEffect(() => {
    if (isMuted) {
      setUnMuteVisibility(0);
      setMuteVisibity(1);
    } else {
      setUnMuteVisibility(1);
      setMuteVisibity(0);
    }
  }, [isMuted]);



  useEffect(() => {
    if (varID !== null) {
      setCartData({
        variantId: '',
        quantity: counterData,
        brandId: brandId,
        checkoutId: varID,
      });
    } else {
      setCartData({
        variantId: '',
        quantity: counterData,
        brandId: brandId,
      });
    }
}, [counterData, varID]);

  useEffect(() => {
    const initialOptions = data.productDetail.options.reduce((acc, option) => {
      acc[option.name.toLowerCase()] = option.values[0];
      return acc;
    }, {});
    setSelectedOptions(initialOptions);
  }, [data.productDetail.options]);

  useEffect(() => {
    updateSelectedVariant();
  }, [selectedOptions]);

  const updateOption = (name, value) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [name.toLowerCase()]: value
    }));
  };

  const updateSelectedVariant = () => {
    changeCounterData(1);
    const variant = data.productDetail.variants.find(variant => {
      return Object.keys(selectedOptions).every(key => {
        return variant[`option${Object.keys(selectedOptions).indexOf(key) + 1}`] === selectedOptions[key];
      });
    });
    setSelectedVariant(variant);
  };

  const incrementCounter = () => {
    if (counterData < (selectedVariant ? selectedVariant.inventory_quantity : 0)) {
      changeCounterData(counterData + 1);
    }
  };

  const decrementCounter = () => {
    if (counterData > 1) {
      changeCounterData(counterData - 1);
    }
  };

  const handleAddToBag = async () => {
    closePopup();
    const temp = { ...cartData, variantId: `gid://shopify/ProductVariant/${selectedVariant.id}` };
    
    if (selectedVariant && counterData > 0) {
      setCartLoaded(true);
      const response = await fetchData(
        ApiConfig.addToCart,
        setCartLoaded,
        "post",
        temp,
        {}
      );
      setCartLoaded(false);

      if (response.status === 401 ||response.status === 500) {
        navigate("/login");
      }

      if (response.status === 200) {
        setVarID(response.data.checkout.id);
        setCheckoutURL(response.data.checkout.webUrl);
      }
    }
  };


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };


 

  return (
    // <div className="product-detail-container">
    //   <div className="product-detail-wrapper">
    //     <div className="product-detail-title">
    //       <h3>{data.name}</h3>
    //       <div className="close-icon">
    //         <Tooltip title="Close Dialog">
    //           <CloseIcon
    //             className="close-dialog-icon"
    //             onClick={() => closePopup()}
    //             style={{ cursor: "pointer" }}
    //           />
    //         </Tooltip>
    //       </div>
    //     </div>
    //     <div className="product-image">
    //       <div className="product-big-img">
    //         <img src={data.product_image} alt="Product" />
    //       </div>
    //       <hr />
    //       <div className="product-small-img">
    //       <div  className={`img-1`}>
    //             <img
    //               src={"https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-murilo-papini-2682930-6215974.jpg?v=1720015997"}
    //               alt="images"
                 
    //             />
    //           </div>
    //           <div  className={`img-2`}>
    //             <img
    //               src={"https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-murilo-papini-2682930-6215974.jpg?v=1720015997"}
    //               alt="images"
                 
    //             />
    //           </div>
    //           <div  className={`img-3`}>
    //             <img
    //               src={"https://cdn.shopify.com/s/files/1/0842/3676/3483/files/pexels-murilo-papini-2682930-6215974.jpg?v=1720015997"}
    //               alt="images"
                 
    //             />
    //           </div>
    //         {/* {props.data.productDetail.images.map((data, index) => (
    //           <div key={index} className={`img-${index + 1}`}>
    //             <img
    //               src={data.src}
    //               alt="images"
    //               onClick={() => setSelectedImage(index)}
    //             />
    //           </div>
    //         ))} */}
    //       </div>
    //     </div>
    //     {/* {props.data.productDetail.options.map((data, index) => (
    //       <div key={index} className={`product-${data.name.toLowerCase()}`}>
    //         <h3 className="title">{data.name}</h3>
    //         <div className={`${data.name.toLowerCase()}-container`}>
    //           {data.name.toLowerCase() === "color" ? (
    //             <div className="product-colors" style={{ display: "flex" }}>{data.values.map((value, valueIndex) => (
    //               <div
    //                 key={valueIndex}
    //                 className={`color ${selectedOptions[data.name.toLowerCase()] === value ? 'selected' : ''}`}
    //                 style={{ backgroundColor: `${value}` }}
    //                 onClick={() => updateOption(data.name, value)}
    //               ></div>
    //             ))}</div>
    //           ) : (
    //             <div className="container-list">{data.values.map((value, valueIndex) => (
    //               <button
    //                 key={valueIndex}
    //                 className={selectedOptions[data.name.toLowerCase()] === value ? 'selected' : ''}
    //                 onClick={() => updateOption(data.name, value)}
    //               >
    //                 {value}
    //               </button>
    //             ))}</div>
    //           )}
    //         </div>
    //       </div>
    //     ))} */}
    //     <div className="prod-description">
    //       <p dangerouslySetInnerHTML={{ __html: data.product_description }} />
    //     </div>
    //     <div className="prod-price">
    //       <p>£{selectedVariant ? selectedVariant.price : '0.00'}</p>
    //     </div>
    //     <div className="prod-button-container">
    //       <button className="prod-count">
    //         <span><SubtractIcon style={{ cursor: 'pointer' }} 
    //         // onClick={decrementCounter} 
    //         /></span>
    //         <span className="count">{counterData}</span>
    //         <span><AddIcon style={{ cursor: 'pointer' }} 
    //         // onClick={incrementCounter} 
    //         /></span>
    //       </button>
    //       <button className="add-to-bag"
    //       //  onClick={handleAddToBag}
    //        >Add to Bag</button>
    //     </div>
    //   </div>
    // </div>
    <div className="product-detail-container">
    <div className="product-detail-wrapper">
      <div className="product-detail-title">
        <h3>{data.title}</h3>
        <div className="close-icon">
          <Tooltip title="Close Dialog">
            <CloseIcon
              className="close-dialog-icon"
              onClick={() => closePopup()}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </div>
      </div>
      <div className="product-image">
        <div className="product-big-img">
          <img src={data.productDetail.images[selectedImage].src} alt="Product" />
        </div>
        <hr />
        <div className="product-small-img">
          {data.productDetail.images.map((data, index) => (
            <div key={index} className={`img-${index + 1}`}>
              <img
                src={data.src}
                alt="images"
                onClick={() => setSelectedImage(index)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="product-image-mobile" style={{display:'none'}}>
      <Slider {...settings}>
      {data.productDetail.images.map((data, index) => (
            <div key={index} className={`img-${index + 1}`}>
              <img
                src={data.src}
                alt="images"
                onClick={() => setSelectedImage(index)}
              />
            </div>
          ))}
          </Slider>
          <div
        className="music_icon"
        onClick={() => {
          setMuted(!isMuted);
        }}
      >
        <img
          className="sound-icon-img hide-selection"
          style={{
            fontSize: 30,
            opacity: isUnmuteVisible,
            position: "absolute",
            zIndex: 10,
          }}
          src="/assets/images/play.png"
        />
        <img
          className="sound-icon-img hide-selection"
          style={{
            fontSize: 30,
            opacity: isMuteVisible,
            position: "absolute",
            zIndex: 11,
          }}
          src="/assets/images/pause.png"
        />
      </div>
      </div>
       {data.productDetail.options.map((data, index) => (
        <div key={index} className={`product-${data.name.toLowerCase()}`}>
          <h3 className="title">{data.name}</h3>
          <div className={`${data.name.toLowerCase()}-container`}>
            {data.name.toLowerCase() === "color" ? (
              <div className="product-colors" style={{ display: "flex" }}>{data.values.map((value, valueIndex) => (
                <div
                  key={valueIndex}
                  className={`color ${selectedOptions[data.name.toLowerCase()] === value ? 'selected' : ''}`}
                  style={{ backgroundColor: `${value}` }}
                  onClick={() => updateOption(data.name, value)}
                ></div>
              ))}</div>
            ) : (
              <div className="container-list">{data.values.map((value, valueIndex) => (
                <button
                  key={valueIndex}
                  className={selectedOptions[data.name.toLowerCase()] === value ? 'selected' : ''}
                  onClick={() => updateOption(data.name, value)}
                >
                  {value}
                </button>
              ))}</div>
            )}
          </div>
        </div>
      ))}
      <div className="prod-description">
        <p dangerouslySetInnerHTML={{ __html: data.productDetail.body_html }} />
      </div>
      <div className="prod-price">
        <p>£{selectedVariant ? selectedVariant.price : '0.00'}</p>
      </div>
      <div className="prod-button-container">
        <button className="prod-count">
          <span><SubtractIcon style={{ cursor: 'pointer' }} onClick={decrementCounter} /></span>
          <span className="count">{counterData}</span>
          <span><AddIcon style={{ cursor: 'pointer' }} onClick={incrementCounter} /></span>
        </button>
        <button className="add-to-bag" onClick={handleAddToBag}>Add to Bag</button>
      </div> 
    </div>
  </div>
  );
}

export default AddtoCart;
import React, { useState } from "react";
import { Box, Dialog } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../assets/svg/info-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import ContactInformation from "./ContactInformation";
import "../../../style/components/store/FailedToRetrieve.scss";
export default function FailedToRetrieve({
  failedToRetrieve,
  setFailedToRetrieve,
  close,
}) {
  const [contactUsPageOpen, setContactUsPageOpen] = useState(false);
  const handleCloseDialog = () => {
    setFailedToRetrieve(false);
    setContactUsPageOpen(false);
  };
  return (
    <>
      <Dialog
        open={failedToRetrieve}
        className="failed-container"
        style={{ display: contactUsPageOpen ? "none" : "block" }}
        maxWidth="xl"
      >
        <Box component="div" className="failed-inner-container">
          <Box component="div" className="close-dialog">
            <CloseIcon style={{ cursor: "pointer" }} onClick={close} />
          </Box>
          <Box component="div" className="failed-wrapper">
            <Box component="div" className="failed-content">
              <Box component="div">
                <InfoIcon />
              </Box>
              <Box component="div" className="failed-text">
                <h2>Failed to Retrieve</h2>
                <p>Your eCommerce URL is not valid or supported by ONEWAYX.</p>
              </Box>
              <Box component="div">
                <button
                  className="contact-us"
                  onClick={() => setContactUsPageOpen(true)}
                >
                  Contact Us{" "}
                  <span>
                    <ArrowIcon />
                  </span>
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {contactUsPageOpen && (
        <ContactInformation
          contactUsPageOpen={contactUsPageOpen}
          setContactUsPageOpen={setContactUsPageOpen}
          setFailedToRetrieve={setFailedToRetrieve}
          close={handleCloseDialog}
        />
      )}
    </>
  );
}
import React from "react";
import "./keyboard-icon.css";
import useDeviceDetect from "../../util/useDeviceDetect";
import wasd from "../../../../assets/svg/wasd.svg";


function KeyboardIcon() {
  const isTouchDevice = useDeviceDetect();
  return (
    <>
      {!isTouchDevice && (
        <div className="keyboard-icon">
          <img className="keyboard-icon-img" src={wasd} />
        </div>
      )}
    </>
  );
}

export default KeyboardIcon;

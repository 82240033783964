// import React from 'react';
// import PlayAround2 from '../../pages/PlayAround2';

// function Env() {
//   return (
//     <>
//       <PlayAround2/>
//     </>
//   )
// }

// export default Env

import React, { useEffect, useState } from "react";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
import Gabar from "../../gabar/Gabar";

export default function Env() {
  const [loading, setLoading] = useState(true);
  const [loadingAll, setLoadingAll] = useState(true);
  const [audioFile, setAudioFile] = useState(null);
  const [videoFile, setVideoile] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [cartProductDetails, setCartProductDetails] = useState(null);

  useEffect(() => {
    async function envDetails() {
      setLoadingAll(true);
      setLoading(true);
      const url = window.location.href;
      const parts = url.split("/");
      const data = parts[parts.length - 1];
      const response = await fetchData(
        ApiConfig.brandFileMapping,
        setLoading,
        "post",
        {
          brand_param: data,
        },
        {}
      );
      const response2 = await fetchData(
        `${ApiConfig.gameSelection}?brandParam=${data}`,
        setLoading,
        "get",
        {},
        {}
      );

      if (response?.status === 200) {
        setVideoile(
          response?.data?.data?.video_data[
            response?.data?.data?.video_data?.length - 1 || 0
          ]?.video_url || ""
        );
        setAudioFile(
          response?.data?.data?.audio_data[
            response?.data?.data?.audio_data?.length - 1 || 0
          ]?.audio_url || ""
        );
        setProductData(response?.data?.data?.product_result || "");
        setCartProductDetails(response?.data?.data?.product || "");
      }
      if (response2?.status === 200) {
        setGameData(response2?.data?.data);
      }
      setLoading(false);
      setLoadingAll(false);
    }
    envDetails();
  }, []);
  return (
    <>
      {!loading && !loadingAll && (
        <Gabar
          audioFile={audioFile}
          videoFile={videoFile}
          productData={productData}
          cartProductDetails={cartProductDetails}
          gameData={gameData}
        />
      )}
    </>
  );
}

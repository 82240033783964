import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { ReactComponent as PauseIcon } from '../assests/svg/pause-icon.svg';
import { ReactComponent as CloseIcon } from '../assests/svg/close-red.svg';

import './VideoPopup.scss';
export default function SetUpGuide({
  openPopupSetupGuide,
  setOpenPopupSetupGuide,
}) {
  let videoRef = document.querySelector("video");
  const [playState, setPlayState] = useState(true);
  function pauseAndPlayVideo(event) {
    if (event.target.paused) {
      event.target.play();
      setPlayState(true);
    } else {
      event.target.pause();
      setPlayState(false);
    }
  }

  return (
    <>
      <Dialog open={openPopupSetupGuide} className="setUpGuideModal">
        <DialogTitle>
          <Box
            component={'div'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Typography>Setup guide</Typography>
            <CloseIcon
              cursor={'pointer'}
              onClick={() => setOpenPopupSetupGuide(false)}
            />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ position: 'relative', padding: 0 }}>
        <video
              width="100%"
              height="100%"
              autoPlay
              muted
              loop
              onClick={pauseAndPlayVideo}
            >
              <source src="https://conceptstorebucket.s3.amazonaws.com/appzlogic/Platform-Guide.mp4
" type="video/mp4" />
            </video>
          {/* <img src={ImageSrc} width="100%" height="100%" alt="setup guide" /> */}
          {!playState ? (
            <PauseIcon
              className="pause-icon" cursor={'pointer'}
              onClick={() => {
                videoRef.play();
                setPlayState(true);
              }}
            ></PauseIcon>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import './Analytics.scss';
import { useNavigate } from "react-router-dom";
import VisitorAnalytic from '../components/analyticsData/VisitorAnalytic';
import { fetchData } from '../service/http.service';
import { useSelector } from "react-redux";
import ApiConfig from "../config/ApiConfig";
import Calendar from '../components/analyticsData/Calendar';
import ProductAnalytic from '../components/analyticsData/ProductAnalytic';
import ComingSoon from '../components/ComingSoon';

function Analytics() {
    const navigate = useNavigate();
    const [currentTab,setCurrentTab] = useState(2);
    const [loading, setLoading] = useState(false);
    const token = useSelector((state) => state.token);
    const [selectedDateRange, setSelectedDateRange] = useState(null);

    const handleDateRangeChange = (range) => {
      setSelectedDateRange(range);
    };

    useEffect(()=>{
      const getEnvDetails = async () => {
        setLoading(false);
        const response = await fetchData(
          `${ApiConfig.info}`,
          setLoading,
          "post",
          {},          
          { authorization: `Bearer ${token}` }
        );
        if (response.statusCode === 401 || response.statusCode === 500) {
          navigate("/login");
        } else if (response.data.statusCode === 200) {
          
        }
        setLoading(true);
      };
      getEnvDetails();
    },[token,navigate])
  return (
    <div className='analytic'>
      <h2 className='title'>Analytics</h2>
      <div className='tab-container'>
        <div>
        <button type='button' onClick={() => setCurrentTab(2)}  className={currentTab === 2 ? 'active':''}>Visitors Analytics</button>
        <button type='button' onClick={() => setCurrentTab(3)}  className={currentTab === 3 ? 'active':''}>Product Analytics</button>
        <button type='button' onClick={() => setCurrentTab(1)}  className={currentTab === 1 ? 'active':''}>Heat Map</button>
        </div>
        <div>
          <Calendar onDateRangeChange={handleDateRangeChange}/>
        </div>
      </div>
      <div>
        {currentTab === 1 ? <ComingSoon/> : currentTab === 2 ? <VisitorAnalytic selectedRange={selectedDateRange} /> : <ProductAnalytic selectedRange={selectedDateRange}/>}
      </div>
      <div>
      </div>
    </div>
  )
}

export default Analytics

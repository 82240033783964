// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customProduct {
  position: relative;
}
.customProduct .textContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  gap: 10px;
  background: linear-gradient(180.14deg, rgba(238, 58, 36, 0.8) 39.22%, rgba(151, 71, 255, 0.8) 65.37%);
}
.customProduct .textContainer h2 {
  color: #fff;
  font-weight: 400;
  font-size: 2.1vw;
}
.customProduct .textContainer p {
  color: #fff;
  margin: 0vw auto 0.5vw;
  font-weight: 500;
  font-size: 1vw;
}
.customProduct .textContainer button {
  background-color: #EE3A24;
  color: #fff;
  font-size: 1vw;
  font-weight: 500;
  padding: 0.75vw 2vw;
}
.customProduct .textContainer button svg {
  position: relative;
  top: 1.4px;
  margin-left: 0.4vw;
}`, "",{"version":3,"sources":["webpack://./src/pages/CustomProduct.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,kBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EAEA,aAAA;EACA,YAAA;EACA,SAAA;EACA,qGAAA;AADR;AAEQ;EACI,WAAA;EACA,gBAAA;EACA,gBAAA;AAAZ;AAEQ;EACI,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;AAAZ;AAEQ;EACI,yBAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AAAZ;AACY;EACI,kBAAA;EACA,UAAA;EACA,kBAAA;AAChB","sourcesContent":[".customProduct {\n    position: relative;\n    \n    .textContainer {\n        position: absolute;\n        // margin-top: 50px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n        // height: calc(100vh - 50px);\n        height: 100vh;\n        width: 100vw;\n        gap: 10px;\n        background: linear-gradient(180.14deg, rgba(238, 58, 36, 0.8) 39.22%, rgba(151, 71, 255, 0.8) 65.37%);\n        h2{\n            color: #fff;\n            font-weight: 400;\n            font-size: 2.1vw;\n        }\n        p{\n            color: #fff;\n            margin: 0vw auto .5vw;\n            font-weight: 500;\n            font-size: 1vw;\n        }\n        button{\n            background-color: #EE3A24;\n            color: #fff;\n            font-size: 1vw;\n            font-weight: 500;\n            padding: 0.75vw 2vw;\n            svg{\n                position: relative;\n                top: 1.4px;\n                margin-left: .4vw;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

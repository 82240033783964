import React from "react";
import "../shoppingcart.css";
function DeleteIcon({ onClick }) {
  const iconStyle = {
    height: "16px",
    width: "16px",
  };
  return (
    <div onClick={onClick} className="delete-image" style={iconStyle}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.2727 2.90909H11.6364V0.727273C11.6364 0.534388 11.5597 0.349403 11.4234 0.213013C11.287 0.0766231 11.102 0 10.9091 0H5.09091C4.89802 0 4.71304 0.0766231 4.57665 0.213013C4.44026 0.349403 4.36364 0.534388 4.36364 0.727273V2.90909H0.727273C0.534388 2.90909 0.349403 2.98571 0.213013 3.1221C0.0766231 3.25849 0 3.44348 0 3.63636C0 3.82925 0.0766231 4.01423 0.213013 4.15062C0.349403 4.28701 0.534388 4.36364 0.727273 4.36364H1.53964L2.91491 15.3629C2.93689 15.5388 3.02232 15.7006 3.15517 15.8179C3.28802 15.9352 3.45913 16 3.63636 16H12.3636C12.5409 16 12.712 15.9352 12.8448 15.8179C12.9777 15.7006 13.0631 15.5388 13.0851 15.3629L14.4604 4.36364H15.2727C15.4656 4.36364 15.6506 4.28701 15.787 4.15062C15.9234 4.01423 16 3.82925 16 3.63636C16 3.44348 15.9234 3.25849 15.787 3.1221C15.6506 2.98571 15.4656 2.90909 15.2727 2.90909ZM5.81818 1.45455H10.1818V2.90909H5.81818V1.45455ZM11.7215 14.5455H4.27855L3.00582 4.36364H12.9942L11.7215 14.5455Z"
          fill="#fff"
        />
      </svg>
    </div>
  );
}

export default DeleteIcon;

import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./Layout.scss";
import "../components/Button.scss";
import "./Dialog.scss";
import Loader from "../components/Loader";
import "react-toastify/dist/ReactToastify.css";
import LearnMore from "../components/dialog/LearnMore";
import { HeaderContext } from "../store/Header-context";
import { ToastContainer } from "react-toastify";
import Spinner from "../components/Spinner";
function Layout() {
  const [timerCount, setTimerCount] = useState(5);
  const { currentStep, loading, openDialog, handlefinalSubmit, handleCloseDialog } = useContext(HeaderContext);
  useEffect(() => {
    if (currentStep === 2) {
      let interval = setInterval(() => {
        setTimerCount((lastTimerCount) => {
          lastTimerCount <= 1 && clearInterval(interval);
          if (lastTimerCount <= 1) {
            handleCloseDialog();
            setTimerCount(5);
          }
          if (lastTimerCount <= 0) return lastTimerCount;
          return lastTimerCount - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentStep]);
  return (<>
    {/* {loading && <Spinner />} */}
    <div className="theme-backdrop">
      <ToastContainer />
      <Outlet />
    </div>
    {/* {openDialog && (
      <LearnMore timerCount={timerCount}></LearnMore>
    )} */}
  </>
  );
}

export default Layout;

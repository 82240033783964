// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.launching-page-container {
  background: linear-gradient(122.82deg, #9747FF 15.54%, #EE3A24 85.74%);
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 3.82vw);
}

.logo {
  padding: 3.33vw;
}

.launching-page-content {
  text-align: center;
  box-shadow: -0.35vw -0.35vw 17.34vw 0 rgba(255, 255, 255, 0.0196078431) inset;
  border: 0.14vw solid;
  width: 21.6vw;
  margin-top: -3.5%;
  margin-bottom: 1.11vw;
  padding: 4.44vw 6.66vw;
  border-radius: 0.7vw;
  color: #FFFFFF;
  border-image-source: linear-gradient(169.15deg, rgba(255, 255, 255, 0.4) 0%, rgba(238, 237, 237, 0.2) 96.79%);
  background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
}

.launch-icon {
  margin: 1.11vw 0 0.555vw 0;
}

.launching-page-content h2 {
  font-weight: 400;
  font-size: 2.08125vw;
  line-height: 2.08125vw;
}

.launching-page-content p {
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 1.25vw;
  margin: 0.555vw 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Launching.scss"],"names":[],"mappings":"AAAA;EACE,sEAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;AACF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,kBAAA;EACA,6EAAA;EACA,oBAAA;EACA,aAAA;EACA,iBAAA;EAEA,qBAAA;EACA,sBAAA;EACA,oBAAA;EACA,cAAA;EACF,6GAAA;EACA,yGAAA;AACA;;AAGA;EACE,0BAAA;AAAF;;AAEA;EACE,gBAAA;EACA,oBAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;AACF","sourcesContent":[".launching-page-container{\n  background: linear-gradient(122.82deg, #9747FF 15.54%, #EE3A24 85.74%);\n  height: 100%;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: calc(100vh - 3.82vw);\n \n}\n.logo{\n  padding: 3.33vw;\n}\n.launching-page-content{\n  text-align: center;\n  box-shadow: -0.35vw -0.35vw 17.34vw 0 #FFFFFF05 inset;\n  border: 0.14vw solid;\n  width: 21.6vw;\n  margin-top: -3.5%;\n  // margin: auto;\n  margin-bottom: 1.11vw;\n  padding: 4.44vw 6.66vw;\n  border-radius: 0.70vw;\n  color: #FFFFFF;\nborder-image-source: linear-gradient(169.15deg, rgba(255, 255, 255, 0.4) 0%, rgba(238, 237, 237, 0.2) 96.79%);\nbackground: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;\n}\n\n\n.launch-icon{\n  margin: 1.11vw 0 0.555vw 0;\n}\n.launching-page-content h2{\n  font-weight:400 ;\n  font-size: 2.08125vw;\n  line-height: 2.08125vw;\n \n}\n.launching-page-content p{\n  font-weight:500 ;\n  font-size: 1.04vw;\n  line-height: 1.25vw;\n  margin: 0.555vw 0;\n}\n// @media only screen and (max-width: 414px) {\n//   .launching-page-content{\n//     padding:2rem 4rem;\n//   }\n\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

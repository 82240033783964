import "../../Home2.css";
import { Environment } from "@react-three/drei";
import { Physics } from "@react-three/rapier";
import { Molecules } from "./Molecules";
import { HDRI_HIGH, HDRI_LOW } from "./HDRI";
import { Ground } from "./Ground";
import { Player } from "../Controls/Player";
import { CustomCameraControls } from "../Controls/CustomCameraControls";
import { Canvas } from "@react-three/fiber";
import { Joystick } from "../Controls/Joystick";
import { EntryCollider } from "./EntryCollider";
import { Perf } from "r3f-perf";
import { degToRad } from "three/src/math/MathUtils.js";
import { Suspense, useEffect, useRef, useState } from "react";
import { GameBoards } from "../Game/GameBoard";
import { Building_Products } from "./Building_Products";
import Molecules1 from "./Molecule1";
import { RigidBody } from "@react-three/rapier";
import Box from "./Box";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../service/http.service";
import ApiConfig from "../../../config/ApiConfig";



function Experience() {
  // const [dpr, setDpr] = useState(1);
  const [dropLocation,setDropLoctaion] = useState({x:1000,y:1000,z:1000});
  const token = useSelector((state) => state.token);
  const [loading,setLoading] =  useState(false);
  const navigate = useNavigate(); 
  const [productData,getProductData] = useState([]);
  const [productLocation, changeProductLocation] = useState({});
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData( 
      `${ApiConfig.productList}?page=1&limit=100`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate('/login');
      }
      else if (response.data.statusCode === 200) {      
        getProductData(response.data.data);
      }      
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  const handleDropImage = (position) => {
    console.log("Dropped at position: ", position);
  };

  const handleApply = (position) => {
    changeProductLocation(position);
    // console.log("Dropped at position: ", position);
  };

  const handleDropLocation = (location) => {
    console.log("Drop locationssss:", location);
    setDropLoctaion(location);
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "video/mp4") {
      const videoUrl = URL.createObjectURL(file);
      setVideoUrl(videoUrl);
    }
  };


  return (
    <>
      {/* <div style={{position:'absolute', zIndex: '2', backgroundColor: '#fff'}}>
        <input type="file" accept="video/mp4" onChange={handleVideoUpload} />
      </div> */}
      <div>
        <Joystick />
        <div className="canvas3d">
          <Canvas
            // dpr={dpr}
            camera={{
              fov: 65,
              // near: 0.01,
              // zoom: 0.85,
            }}
            // shadows
          >
            <Suspense fallback={<HDRI_LOW />}>
              <HDRI_HIGH />
            </Suspense>
            {/* <Perf matrixUpdate deepAnalyze position="top-left" /> */}
            <ambientLight intensity={1.5} />
            <Environment background={false} preset="city" />
            <CustomCameraControls />
            <Physics gravity={[0, -20, 0]}>
              <group rotation={[0, degToRad(175), 0]} position={[-28.5, -1, 0]}>
                <Ground />
                <EntryCollider />
                <Player />
                {/* <GameBoards /> */}
                {/* <Building_Products onDropLocation={handleDropLocation} />/ */}
                <Building_Products videoUrl={videoUrl}  onDropLocation={handleDropLocation}/>
              </group>
              <group rotation={[0, degToRad(180), 0]} position={[-28.5, 0, 0]}>
                <Molecules />
              </group>
              {/* <RigidBody type="fixed">
                
                {productData  && <Molecules1 dropLocation={dropLocation} productData={productData} productLocationChange={productLocation}/> }
              </RigidBody> */}
            </Physics>
          </Canvas>
        </div>
        {/* {productData && <Box onDropImage={handleDropImage}  onApply={handleApply} productData={productData}/>} */}
      </div>
    </>
  );
}

export default Experience;

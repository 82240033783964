import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import {
  TextureLoader,
  SRGBColorSpace,
  EquirectangularReflectionMapping,
  LinearFilter,
} from "three";
import { degToRad } from "three/src/math/MathUtils.js";
import hdriLow from '../../../assets/three/hdri/Gabar/Sand_Dunes_low.jpg';
import hdriHigh from '../../../assets/three/hdri/Gabar/Sand_Dunes_high.jpg';

function HDRI_LOW() {
  const { scene } = useThree();
  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(hdriLow, (texture) => {
      texture.mapping = EquirectangularReflectionMapping;
      texture.rotation.y = degToRad(40);
      // texture.flipY = false;
      texture.colorSpace = SRGBColorSpace;
      texture.minFilter = texture.magFilter = LinearFilter;
      scene.background = texture;
    });
  }, []);
  return null;
}
function HDRI_HIGH() {
  const { scene } = useThree();
  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(hdriHigh, (texture) => {
      texture.mapping = EquirectangularReflectionMapping;
      texture.rotation = Math.PI * 2;
      texture.colorSpace = SRGBColorSpace;
      texture.minFilter = texture.magFilter = LinearFilter;
      scene.background = texture;
    });
  }, []);
  return null;
}

export { HDRI_LOW, HDRI_HIGH };

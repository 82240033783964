import { create } from "zustand";

const useSoundStore = create((set) => ({
  isPlaying: false,
  isMuted: false,
  isCountDown: false,
  playCorrectAnswer: false,
  playWrongAnswer: false,
  playQuizEnd: false,
  setPlaying: (val) => set((state) => ({ isPlaying: val })),
  setMuted: (val) => set((state) => ({ isMuted: val })),
  setCountDown: (val) => set((state) => ({ isCountDown: val })),
  setCorrectAnswer: (val) => set((state) => ({ playCorrectAnswer: val })),
  setWrongAnswer: (val) => set((state) => ({ playWrongAnswer: val })),
  setQuizEnd: (val) => set((state) => ({ playQuizEnd: val })),
}));
 
export default useSoundStore;

import React, { useEffect } from "react";
import useGameStore from "./gameStore";
import axios from "axios";
import useAnalytics from "../../../utils2/google-analytics/useGTAGTracker";
import { useRandomQuestions } from "./useRandomQuestions";

const GameWinningLogic = () => {
  // Global State variables
  const {
    gamesPlayed,
    correctAnswers,
    wrongAnswers,
    handleShowGame25WinPopup,
    handleShowGame40WinPopup,
    handleShowGameLostPopup,
    handleGameReset,
    nextPlayableTime,
    handleNextPlayableTime,
    registeredEmail,
    handleGameLock,
    isGameLocked,
    setSelectedRandomQuestions,
    setRowIndex,
    gameStartTimestamp,
  } = useGameStore();

  const { trackGameFinalResult, trackTotalGameDuration } = useAnalytics();

  // Call useRandomQuestions at the top level
  const randomQuestions = useRandomQuestions();

  const handleGameEnd = () => {
    setTimeout(() => {
      // Use the result of useRandomQuestions inside the callback
      setSelectedRandomQuestions(randomQuestions);
    }, 2000);

    let data = {
      email: registeredEmail,
      score: `${correctAnswers}`,
      date: `${new Date()}`,
    };

    console.log("Game Data-------", data);
    if (registeredEmail)
      axios
        .post("https://gabar-prod.onewayx.world/game-on-end", data)
        .then((response) => {
          console.log("DONE CORRECTLY");
          setRowIndex(response.data.index);
        })
        .catch((error) => {
          console.log("NOT DONE CORRECTLY");
        });
    setUpcomingPlayableTime();
    handleGameReset();
  };

  const setUpcomingPlayableTime = () => {
    const nowTime = new Date();
    const oneSecondLater = new Date(nowTime.getTime() + 0.1 * 1000); // One second later
    window.localStorage.setItem(
      "nextPlayableTime",
      JSON.stringify(oneSecondLater)
    );
    handleNextPlayableTime(oneSecondLater);
    handleGameLock(true);
  };

  const postGameDuration = () => {
    const nowTime = new Date();
    const startTime = new Date(gameStartTimestamp);
    const durationInSeconds = (nowTime.getTime() - startTime.getTime()) / 1000;
    trackTotalGameDuration(`${Math.ceil(durationInSeconds)}`);
  };

  useEffect(() => {
    window.localStorage.setItem(
      "nextPlayableTime",
      JSON.stringify(nextPlayableTime)
    );
  }, [nextPlayableTime]);

  useEffect(() => {
    if (wrongAnswers === 2) {
      trackGameFinalResult("No Win");
      postGameDuration();
      setTimeout(() => {
        handleShowGameLostPopup(true);
      }, 2000);
      handleGameEnd();
    } else if (correctAnswers === 3 && gamesPlayed === 3) {
      trackGameFinalResult("40% Off Win");
      postGameDuration();
      setTimeout(() => {
        handleShowGame40WinPopup(true);
      }, 2000);
      handleGameEnd();
    } else if (correctAnswers === 2 && gamesPlayed === 3) {
      trackGameFinalResult("25% Off Win");
      postGameDuration();
      setTimeout(() => {
        handleShowGame25WinPopup(true);
      }, 2000);
      handleGameEnd();
    } else if (correctAnswers <= 1 && gamesPlayed === 3) {
      trackGameFinalResult("No Win");
      postGameDuration();
      setTimeout(() => {
        handleShowGameLostPopup(true);
      }, 2000);
      handleGameEnd();
    }
  }, [gamesPlayed]);

  return null;
};

export default GameWinningLogic;

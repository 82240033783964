import React from "react";
import { Dialog} from "@mui/material";
import './PopupForMobile.scss';

function PopupForMobile() {
  return (
    <>
    <div className="popup-mobile">
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      className="common-dialog-container"
      open={true}
    >
      <div className="email-not-found-container mobile-text">
        <div className="header-container">
          <div className="email-not-found-content">
            <div className="email-not-found-heading">
              <p>The platform is not compatible with your device. Please switch to desktop for a better experience</p>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    </div>
    </>
  );
}

export default PopupForMobile;

export const getColor = (selectedColour) => {
    switch (selectedColour) {
      case "#FFFFFF":
        return "White";
      case "#A59385":
        return "Cappuccino";
      case "#FF0000":
        return "Red";
      case "#FFD700":
        return "Gold";
      case "#F5D7D5":
        return "Rose Pink";
      default:
        return "default";
    }
  };
// import "./Home2.css";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";

// import Loading from "./components2/UI/Loading/Loader";
// import Landscape from "./components2/UI/Landscape/Landscape";
// import {
//   EnterFullScreenButton,
//   ExitFullScreenButton,
// } from "./components2/Controls/FullScreen";
// import UI from "./components2/UI/UI";
// import Campaign from "./components2/Experience/Campaign";
// export const Home3 = () => {
//   const handle = useFullScreenHandle();

//   return (
//     <>
//       <div className="App">
//         <div className="ReactFullScreen">
//           <EnterFullScreenButton handle={handle} />
//           <FullScreen handle={handle}>
//             <div>
//               <ExitFullScreenButton handle={handle} />
//               <UI />
//               <Campaign/>
//             </div>
//           </FullScreen>
//         </div>
//       </div>
//       <Loading />
//       <Landscape />
//     </>
//   );
// };

// export default Home3;

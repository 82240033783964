import { useEffect, useState } from "react";
import * as Yup from "yup";
import ForgotPassword from "./ForgotPassword";
import EmailNotFound from "./EmailNotFound";
import Spinner from "../../helper/Spinner";
import useAuthStore from "../../store/authStore";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import { fetchData } from "../../fetcher/fetchData";
import ApiConfig from "../../helper/ApiConfig";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Visibility } from "../../assets/svg/Eye-show.svg";
import { ReactComponent as VisibilityOff } from "../../assets/svg/Eye-hide.svg";
import Logo from "../../assets/images/white-logo.svg";

export default function Login() {
  const [loading, setLoading ] = useState(false);
  const navigate = useNavigate();
  const {
    openForgotPasswordDialog,
    setOpenForgotPasswordDialog,
    login,
    updateUser,
  } = useAuthStore();

  const [openEmailNotFoundPopup, setOpenEmailNotFoundPopup] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/");
    const token = parts[parts.length - 1];    
    if (token !== "login") {
      async function getProductList() {
        // login({ token: token });
        login( token );
        setLoading(true);
        const info = await fetchData(
          ApiConfig.info,
          setLoading,
          "post",
          {},
          { authorization: `Bearer ${token}` }
        );
        setLoading(false);
        if (info.status === 200) {
          localStorage.removeItem("brand_email");
          const brandInfo = info.data.data.brandProfile;          
          updateUser( brandInfo );
          navigate("/dashboard");
        }
      }
      getProductList();
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#EE3A24CC",
      },
    },
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter valid Email")
        .matches(/@[^.]*\./, "Please enter valid Email")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: handleLogin,
  });

  async function handleLogin() {
    setLoading(true);
    const response = await fetchData(
      ApiConfig.login,
      setLoading,
      "post",
      formik.values,
      {}
    );
    setLoading(false);

    if (response) {
      if (response?.statusCode === 401 || response?.statusCode === 500) {
        setOpenEmailNotFoundPopup(true);
        setErrorMessage(response?.message);
      }
      const token = response.data.data;
      login( token );
      setLoading(true);
      const info = await fetchData(
        ApiConfig.info,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      setLoading(false);
      localStorage.removeItem("brand_email");
      const brandInfo = info.data.data.brandProfile;
      updateUser(brandInfo);
      localStorage.setItem("sessionState", 
        JSON.stringify(
        {
        isAuthenticated: true,
        token: token,
        user: brandInfo
      }))
      navigate("/dashboard");
    }
  }

  function handleOpenResetPopup() {
    setOpenForgotPasswordDialog(true);
  }
  function handleCloseResetPopup() {
    setOpenForgotPasswordDialog(false);
    setOpenEmailNotFoundPopup(false);
  }
  const handleRecaptchaChange = (value) => {
    setIsVerified(!!value);
  };




  return (
    <div
      className="main-container"
      style={{
        background: `${"linear-gradient(140.22deg, #9747FF -5.17%, #EE3A24 112.64%)"} `,
      }}
    >
      <header className="sigupHeader">
        <nav>
          <ul>
            <li>
              <Tooltip title="Back to Website">
                <Link to="https://www.onewayx.com/" target="_blank">
                  <img src={Logo} alt="logo" />
                </Link>
              </Tooltip>
            </li>
          </ul>
        </nav>
      </header>
      <div
        className={
          "login-section" +
          (openForgotPasswordDialog || openEmailNotFoundPopup
            ? " bg-blurred"
            : "")
        }
      >
        <div className="login-container">
          <h3 className="heading">Login</h3>
          <p className="sub-heading">
            Dont have an account?{" "}
            <span style={{ fontWeight: "700" }}>
              <Link className="link" to="/signup">
                Sign Up Here
              </Link>
            </span>{" "}
          </p>

          {loading && (
            <div className="spinner-wrapper">
              <Spinner />{" "}
            </div>
          )}

          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="input-container">
              <TextField
                sx={style}
                className="my-input set-pwd"
                placeholder="Your Email*"
                type="email"
                variant="outlined"
                required
                name="email"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                sx={style}
                className="my-input set-pwd"
                placeholder="Password*"
                variant="outlined"
                required
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </div>
            <p className="forgot-pwd">
              Forgot password?{" "}
              <span>
                <Link onClick={handleOpenResetPopup} className="link">
                  Reset
                </Link>
              </span>
            </p>
            {process.env.REACT_APP_RECAPTCHA_MODE === "true" && (
              <div className="dialog-recaptcha">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptchaChange}
                />
              </div>
            )}
            <div className="dialog-submit-container">
              <button
                type="submit"
                disabled={
                  !isVerified && process.env.REACT_APP_RECAPTCHA_MODE === "true"
                }
                className="dialog-input-submit button2"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>

      {openForgotPasswordDialog && (
        <ForgotPassword
          open={openForgotPasswordDialog}
          close={handleCloseResetPopup}
        ></ForgotPassword>
      )}
      {openEmailNotFoundPopup && (
        <EmailNotFound
          errorMessage={errorMessage}
          openEmailNotFoundPopup={openEmailNotFoundPopup}
          setOpenEmailNotFoundPopup={setOpenEmailNotFoundPopup}
          close={handleCloseResetPopup}
        />
      )}
    </div>
  );
}

import React from "react";
import "./ui.css";
import ViewportHeightSetter from "../util/useViewportHeightSetter";
import Instruction from "./Instruction/Instruction";
import { Player, SoundOnOff } from "./Audio";
import useVideoStore from "../util/video";
import useStore from "../util/3dstore";
import { Players, SoundOnOffs } from "./AudioFinal/AudioFinal";
import TradeMark from "./TradeMark/TradeMark";
import KeyboardIcon from "./KeyboardIcon/KeyboardIcon";

const UI = ({ musicUrl, variation }) => {
  const { audioFileData } = useVideoStore();
  const { isEntryAnimationDone } = useStore();
  return (
    <div className="no-text-select">
      <ViewportHeightSetter />
      <Instruction variation={variation} />

      {variation !== 3 && audioFileData && isEntryAnimationDone && (
        <>
          <Player musicUrl={audioFileData} />
          <SoundOnOff />
        </>
      )}
      {variation === 3 && isEntryAnimationDone &&(
        <>
          <Players musicUrl={musicUrl} />
          <SoundOnOffs />
          <TradeMark />
          <KeyboardIcon />
        </>
      )}
    </div>
  );
};

export default UI;

import { useRef, useEffect, useState } from "react";
import "./sound.css";
import useSoundStore from "./soundStore";
import useGameStore from "../../Game/utils/gameStore";

// const Player = ({musicUrl}) => {
//   const audioElement = useRef();
//   const {
//     isPlaying,
//     isMuted,
//     isCountDown,
//   } = useSoundStore();
//   const { isGamePlaying } = useGameStore();

//   useEffect(() => {
//     if (isPlaying) {
//       audioElement.current.play();
//     } else {
//       audioElement.current.pause();
//     }
//   });

//   useEffect(() => {
//     if (isGamePlaying || isCountDown) {
//       audioElement.current.volume = 0.1; 
//     } else {
//       audioElement.current.volume = 0.5; 
//     }
//   }, [isGamePlaying]);

//   useEffect(() => {
//     if (isMuted) {
//       audioElement.current.muted = true;
//     } else {
//       audioElement.current.muted = false;
//     }
//   }, [isMuted]);

//   useEffect(()=>{
//     console.log(audioElement.current);   
    
//     if (audioElement.current) {
//       audioElement.current.pause();
//       audioElement.current.load();
//       audioElement.current.play();
//     }
//   },[musicUrl])
//   return (
//     <>
//     <div className="sound-control">
//       <audio loop ref={audioElement}>
//         <source src={musicUrl} type="audio/mpeg" />
//       </audio>
//     </div>
//     </>
//   );
// };


const Player = ({ musicUrl, finalScreen }) => {
  const audioElement = useRef(null); // Initialize with null to avoid undefined errors
  const { isPlaying, isMuted, isCountDown } = useSoundStore();
  const { isGamePlaying } = useGameStore();

  useEffect(() => {
    if(!finalScreen){
    if (audioElement.current) {
      if (isPlaying) {
        audioElement.current.play().catch(error => {
          // Handle any play errors (like autoplay restrictions)
          console.error('Error playing audio:', error);
        });
      } else {
        audioElement.current.pause();
      }
    }
    }
  }, [isPlaying]); // Add isPlaying as a dependency

  useEffect(() => {
    if (audioElement.current) {
      if (isGamePlaying || isCountDown) {
        audioElement.current.volume = 0.1; // 10% volume
      } else {
        audioElement.current.volume = 0.5; // 50% volume
      }
    }
  }, [isGamePlaying, isCountDown]); // Add dependencies for changes in game state or countdown

  useEffect(() => {
    if (audioElement.current) {
      audioElement.current.muted = isMuted;
    }
  }, [isMuted]); // Add isMuted as a dependency

  useEffect(() => {
    if (audioElement.current) {
      // Pause, load new source, and play
      audioElement.current.pause();
      audioElement.current.load();
      audioElement.current.play().catch(error => {
        // Handle any play errors
        console.error('Error playing new audio:', error);
      });
    }
  }, [musicUrl]); // Run this effect when musicUrl changes

  return (
    <div className="sound-control">
      <audio loop ref={audioElement}>
        <source src={musicUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};


const SoundOnOff = () => {
  const { isMuted, setMuted } = useSoundStore();
  const [isMuteVisible, setMuteVisibity] = useState(0);
  const [isUnmuteVisible, setUnMuteVisibility] = useState(1);

  useEffect(() => {
    if (isMuted) {
      setUnMuteVisibility(0);
      setMuteVisibity(1);
    } else {
      setUnMuteVisibility(1);
      setMuteVisibity(0);
    }
  }, [isMuted]);
  return (
    <div
      className="music_icon"
      onClick={() => {
        setMuted(!isMuted);
      }}
    >
      <img
        className="sound-icon-img hide-selection"
        style={{
          fontSize: 30,
          opacity: isUnmuteVisible,
          position: "absolute",
          zIndex: 10,
        }}
        src="/assets/images/audible.png"
      />
      <img
        className="sound-icon-img hide-selection"
        style={{
          fontSize: 30,
          opacity: isMuteVisible,
          position: "absolute",
          zIndex: 11,
        }}
        src="/assets/images/muted.png"
      />
    </div>
  );
};

export { SoundOnOff, Player };

import "./styles.css";
import useStore from "../../../../utils2/3dstore";
import useUIStore from "../../../../utils2/uistore";
function HeaderOverlay() {
  const { isHeaderOverlay, setHeaderOverlay } = useStore();
  const { showMenu, showCart, handleShowMenu, handleShowCart } = useUIStore(
    (state) => state
  );
  const closeOverlay = (e) => {
    e.stopPropagation();
    if (showMenu || showCart) {
      setHeaderOverlay(true);
      handleShowMenu(false);
      handleShowCart(false);
    }
  };
  return (
    <>
      <div
        className={`fullscreen ${
          isHeaderOverlay ? "ready" : "fullscreen_notready notready"
        } ${isHeaderOverlay && "clicked"}`}
        onClick={(e) => closeOverlay(e)}
      ></div>
    </>
  );
}

export default HeaderOverlay;

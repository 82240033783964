import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import ApiConfig from "../../helper/ApiConfig";
import { fetchData } from "../../fetcher/fetchData";
import Spinner from "../../helper/Spinner";
import useAuthStore from "../../store/authStore";
import { ReactComponent as Cross } from "../../assets/svg/bitcoin-icons_cross-outline.svg";
import { ReactComponent as Tick } from "../../assets/svg/teenyicons_tick-solid.svg";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow.svg";
import "../../style/components/dashboard/Subscription.scss";

export default function Subscription() {
  const navigate = useNavigate();
  const { token } = useAuthStore();
  const [loading, setLoading ] = useState(false);
  const [checked, setChecked] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [property, setProperties] = useState(null);
  const [highlightedColumn, setHighlightedColumn] = useState(null);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState(null);
  const [subVal, setSubVal] = useState(0);

  useEffect(() => {
    setChecked(subscriptionFrequency === "6 months");
  }, [subscriptionFrequency]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleMouseEnter = (colIndex) => {
    setHighlightedColumn(colIndex);
  };

  const handleMouseLeave = () => {
    setHighlightedColumn(null);
  };

  const handleSubText = async () => {
    setLoading(true);
    const info = await fetchData(
      ApiConfig.info,
      setLoading,
      "post",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (info.statusCode === 401 || info.statusCode === 500) {
      navigate("/login");
    }
    if (info.data.statusCode === 200) {
      setSubVal(info.data.data.brandProfile.subscription_id);
      setSubscriptionFrequency(info.data.data.envDetail.payment_frequency);
      setChecked(info.data.data.envDetail.payment_frequency !== "6 months");
    }
    setLoading(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getEnvDetails = async () => {
      setLoading(true);
      try {
        const response = await fetchData(
          `${ApiConfig.subscriptionList}?page=1&limit=10`,
          setLoading,
          "get",
          {},
          { authorization: `Bearer ${token}` },
          signal
        );

        if (response.statusCode === 401 || response.statusCode === 500) {
          navigate("/login");
        }
        if (response.data.statusCode === 200) {
          setSubscriptionData(response.data.data);
          setProperties([
            "customisable_env",
            "ecommerce_integration",
            "enhanced_support",
            "number_of_products",
            "number_of_gamification",
            "visitor_analytics",
            "heatmap",
            "sales_conversion",
            "product_analystics",
          ]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    handleSubText();
    getEnvDetails();
    return () => {
      abortController.abort();
    };
  }, []);

  const handleText = (value) => {
    switch (value) {
      case "customisable_env":
        return "Customisable Environment";
      case "ecommerce_integration":
        return "ECommerce Integration";
      case "enhanced_support":
        return "Enhanced 24/7 Support";
      case "number_of_products":
        return "Number of Products";
      case "number_of_gamification":
        return "Number of Games";
      case "visitor_analytics":
        return "Visitor Analytics";
      case "heatmap":
        return "Heatmap";
      case "sales_conversion":
        return "Sales Conversion";
      case "product_analystics":
        return "Product Analytics";
      default:
        return value;
    }
  };

  const handleSubscribtion = async (data) => {
    setLoading(true);
    try {
      const response = await fetchData(
        ApiConfig.postSubscription,
        setLoading,
        "POST",
        {
          subscription_id: data,
          payment_frequency: checked ? "12 months" : "6 months",
        },
        { authorization: `Bearer ${token}` }
      );
      console.log(response);
      if (response?.statusCode === 401 || response?.statusCode === 500) {
        navigate("/login");
      }
      if (response?.statusCode !== 200) {
        console.log(response.statusCode);
        throw new Error(response.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    handleSubText();
    navigate("/dashboard/select-enviroment");
  };

  return (
    <>
      <div className="subscription-container">
        <div className="subscription-heading">
          <h2>Pick Your Subscription Plan</h2>
        </div>

        <div className="toggle-switch">
          <label
            className={"subscription-6-months" + (!checked ? " active" : "")}
          >
            6 Months
          </label>
          <label>
            <Switch
              onChange={handleChange}
              checked={checked}
              className="react-switch"
            />
          </label>
          <label
            className={"subscription-12-months" + (checked ? " active" : "")}
          >
            12 Months <span className="ten-percent-off">-10% </span>
          </label>
        </div>

        <div className="subscription-wrapper" style={{ overflowX: "auto" }}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              {" "}
              <Spinner />{" "}
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th></th>
                  {subscriptionData !== null &&
                    subscriptionData.map((subscription, index) => (
                      <th
                        key={subscription.subscription_id}
                        onMouseEnter={() => handleMouseEnter(index + 1)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          background:
                            highlightedColumn === index + 1 ? "#fff" : "",
                        }}
                      >
                        <div
                          key={subscription.subscription_id}
                          onMouseEnter={() => handleMouseEnter(index + 1)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            background:
                              highlightedColumn === index + 1
                                ? "linear-gradient(239.05deg, #EE3A24 27.74%, #9747FF 91.86%)"
                                : "",
                            transform:
                              highlightedColumn === index + 1
                                ? "scale(1.1)"
                                : "",
                          }}
                        >
                          <p className="table-sub">
                            {index === 1 && (
                              <span className="caption">Most Popular</span>
                            )}
                            <span className="sub-name">
                              {subscription.subscription_name}
                            </span>
                            <br />
                            <span className="sub-desc">
                              {subscription.subscription_desc}
                            </span>
                            <br />
                            <span className="sub-price">
                              {subscription.currency}
                              {subscription.monthly_price}
                              {"/month"}
                            </span>
                          </p>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {property !== null &&
                <>
                  {property.map((prop) => (                    
                      <tr key={prop}>
                        {prop === "number_of_gamification" ||
                        prop === "number_of_products" ? (
                          <td className="table-col">{handleText(prop)}</td>
                        ) : (
                          <td>{handleText(prop)}</td>
                        )}
                        {subscriptionData.map((subscription, index) =>
                          prop !== "number_of_gamification" ? (
                            <td
                              key={subscription.subscription_id}
                              onMouseEnter={() => handleMouseEnter(index + 1)}
                              onMouseLeave={handleMouseLeave}
                              style={{
                                backgroundColor:
                                  highlightedColumn === index + 1
                                    ? "#ffffff"
                                    : "",
                              }}
                              className={
                                subscription[prop] === 0
                                  ? ``
                                  : subscription[prop] === 1
                                  ? ``
                                  : `table-col`
                              }
                            >
                              {subscription[prop] === 0 ? (
                                <Cross />
                              ) : subscription[prop] === 1 ? (
                                <Tick />
                              ) : (
                                subscription[prop]
                              )}
                            </td>
                          ) : (
                            <td
                              key={subscription.subscription_id}
                              onMouseEnter={() => handleMouseEnter(index + 1)}
                              onMouseLeave={handleMouseLeave}
                              style={{
                                backgroundColor:
                                  highlightedColumn === index + 1
                                    ? "#ffffff"
                                    : "",
                              }}
                              className="table-col"
                            >
                              {subscription[prop]}
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                      <tr className="button-row">
                        <td></td>
                        <td
                          onMouseEnter={() => handleMouseEnter(1)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            backgroundColor:
                              highlightedColumn === 1 ? "#ffffff" : "",
                          }}
                        >
                          <button
                            onClick={() => handleSubscribtion(1)}
                            className={
                              highlightedColumn === 1
                                ? "continue active"
                                : "continue"
                            }
                          >
                            {subVal === null
                              ? "Continue"
                              : subVal > 1
                              ? "Downgrade"
                              : subVal === 1
                              ? "Your Plan"
                              : "Upgrade"}{" "}
                            <span>
                              <ArrowIcon />
                            </span>
                          </button>
                        </td>
                        <td
                          onMouseEnter={() => handleMouseEnter(2)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            backgroundColor:
                              highlightedColumn === 2 ? "#ffffff" : "",
                          }}
                        >
                          <button
                            onClick={() => handleSubscribtion(2)}
                            className={
                              highlightedColumn === 2
                                ? "continue active"
                                : "continue"
                            }
                          >
                            {subVal === null
                              ? "Continue"
                              : subVal > 2
                              ? "Downgrade"
                              : subVal === 2
                              ? "Your Plan"
                              : "Upgrade"}{" "}
                            <span>
                              <ArrowIcon />
                            </span>
                          </button>
                        </td>
                        <td
                          onMouseEnter={() => handleMouseEnter(3)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            backgroundColor:
                              highlightedColumn === 3 ? "#ffffff" : "",
                          }}
                        >
                          <button
                            onClick={() => handleSubscribtion(3)}
                            className={
                              highlightedColumn === 3
                                ? "continue active"
                                : "continue"
                            }
                          >
                            {subVal === null
                              ? "Continue"
                              : subVal > 3
                              ? "Downgrade"
                              : subVal === 3
                              ? "Your Plan"
                              : "Upgrade"}{" "}
                            <span>
                              <ArrowIcon />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </>
                  }
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

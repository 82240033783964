import React from "react";
import "./trademark.css";
import trademarkImg from "../../../../assets/svg/poweredby_1.svg";
import trademarkImg2 from "../../../../assets/images/Symbol.png";

function TradeMark() {
  return (
    <>
      <div className="trade-mark">
        <img className="trade-mark-img" src={trademarkImg} />
      </div>
      <div className="trade-mark-icon">
        <img className="trade-mark-logo" src={trademarkImg2 } style={{width: '25px'}} />
      </div>
    </>
  );
}

export default TradeMark;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Homepage.scss";
import { ReactComponent as ArrowIcon } from "../assests/svg/arrow.svg";
import { ReactComponent as RightArrowIcon } from "../assests/svg/right-arrow.svg";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";
import Spinner from "./Spinner";
import PopupPage from "./PopupPage";
import Congratulations from "./Congratulations";

export default function Homepage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPoint, setCurrentPoint] = useState();
  const [subVal, setSubVal] = useState(0);
  const [congratsPage,setCongratsPage] = useState(false);
  const [openPopup, setPopupOpen] = useState(false);
  const token = useSelector((state) => state.token);
  const username = useSelector((state) => state.user.first_name);

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate("/login");
      } else if (response.data.statusCode === 200) {
        if (response.data.data.completed_step === 5) {
          //navigate("/congratulation/env");
          setCongratsPage(true);
        }
        setCurrentPoint(response.data.data.completed_step);
      }

      const info = await fetchData(
        ApiConfig.info,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info.statusCode === 401 || info.statusCode === 500) {
        navigate("/login");
      } else if (info.data.statusCode === 200) {
        setSubVal(info.data.data.brandProfile.subscription_id);
      }

      setLoading(true);
    };
    getEnvDetails();
  }, []);

  const handlePopup = (data) => {
    setPopupOpen(false);
  };
  if(congratsPage){
    return(
      <>
      <Congratulations/>
      </>
    )
  }
  return (
    <>
      <div className="home-container">
        <div className="user-name">
          <h1>Hi {username || "username"}, </h1>
          <p>Welcome to your dashboard!</p>
        </div>
        <div>
          <div className="pick-subscription-plan">
            <p>To Start, Pick Your Subscription Plan</p>
            <button
              className="continue-btn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/dashboard/subscription")}
            >
              {loading && (subVal > 0 ? `Update` : `Continue`)}{" "}
              <span>
                <ArrowIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="build-virtual-reality">
          <h1>Build Your Virtual Retail in 5 Easy Steps</h1>
        </div>
        {!loading && (
          <div style={{ textAlign: "center" }}>
            {" "}
            <Spinner />{" "}
          </div>
        )}
        {loading && (
          <div className="step-container">
            <div className={`step ${currentPoint === 1 ? "step-active" : ""}`}>
              <div
                onClick={() => {
                  if (subVal !== null) {
                    if (1 <= currentPoint) {
                      navigate("/select-enviroment");
                    }
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="circle">1</div>
              </div>

              <div
                className="first_step step_box"
                onClick={() => {
                  if (subVal !== null) {
                    if (1 <= currentPoint) {
                      navigate("/select-enviroment");
                    }
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="customise_env">
                  <div className="title">
                    <p>Select and customise your environment</p>
                  </div>
                  <div className="caption">
                    <p>
                      Choose a environment from the marketplace and customise it
                      to suit your brand.
                    </p>
                  </div>
                </div>
                <span>
                  <RightArrowIcon />
                </span>
              </div>
            </div>
            <div
              className={`step ${
                currentPoint === 2
                  ? "step-active"
                  : currentPoint < 2
                  ? "step-not-active"
                  : ""
              }`}
            >
              <div
                onClick={() => {
                  if (subVal !== null) {
                    navigate("/dashboard/select-store");
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="circle">2</div>
              </div>
              <div
                className="second_step step_box"
                onClick={() => {
                  if (subVal !== null) {
                    navigate("/dashboard/select-store");
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="customise_env">
                  <div className="title">
                    <p>Add your products</p>
                  </div>
                  <div className="caption">
                    <p>Upload your products from your website.</p>
                  </div>
                </div>
                <span>
                  <RightArrowIcon />
                </span>
              </div>
            </div>
            <div
              className={`step ${
                currentPoint === 3
                  ? "step-active"
                  : currentPoint < 3
                  ? "step-not-active"
                  : ""
              }`}
            >
              <div
                onClick={() => {
                  if (subVal !== null) {
                    if(currentPoint < 3){
                      setPopupOpen(true);
                    }else{
                      navigate("/customize-env");
                    }
                    
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="circle">3</div>
              </div>
              <div
                className="third_step step_box"
                onClick={() => {
                  if (subVal !== null) {
                    if(currentPoint < 3){
                      setPopupOpen(true);
                    }
                    else{
                    navigate("/customize-env");
                    }
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="customise_env">
                  <div className="title">
                    <p>Merchandise your virtual retail store</p>
                  </div>
                  <div className="caption">
                    <p>Merchandise your products in your environment.</p>
                  </div>
                </div>
                <span>
                  <RightArrowIcon />
                </span>
              </div>
            </div>
            <div
              className={`step ${
                currentPoint === 4
                  ? "step-active"
                  : currentPoint < 4
                  ? "step-not-active"
                  : ""
              }`}
            >
              <div
                onClick={() => {
                  if (subVal !== null) {
                    navigate("/dashboard/add-game");
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="circle">4</div>
              </div>
              <div
                className="fourth_step step_box"
                onClick={() => {
                  if (subVal !== null) {
                    navigate("/dashboard/add-game");
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="customise_env">
                  <div className="title">
                    <p>Add Gamification</p>
                  </div>
                  <div className="caption">
                    <p>
                      Keep your customers engaged with gamification, reward your
                      customers with prizes and discounts.
                    </p>
                  </div>
                </div>
                <span>
                  <RightArrowIcon />
                </span>
              </div>
            </div>
            <div
              className={`step ${
                currentPoint === 5
                  ? "step-active"
                  : currentPoint < 5
                  ? "step-not-active"
                  : ""
              }`}
            >
              <div
                onClick={() => {
                  if (subVal !== null) {
                    if (5 <= currentPoint) {
                      navigate("/launch");
                    }
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="circle">5</div>
              </div>
              <div
                className="fifth_step step_box"
                onClick={() => {
                  if (subVal !== null) {
                    if (5 <= currentPoint) {
                      navigate("/launch");
                    }
                  } else {
                    navigate("/dashboard/subscription");
                  }
                }}
              >
                <div className="customise_env">
                  <div className="title">
                    <p>Launch</p>
                  </div>
                  <div className="caption">
                    <p>
                      Connect your virtual retail to your website within 7 days.
                    </p>
                  </div>
                </div>
                <span>
                  <RightArrowIcon />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {openPopup && <PopupPage  closePopup={handlePopup}/>}
    </>
  );
}

import { CuboidCollider, RigidBody } from "@react-three/rapier";
import useStore from "../util/3dstore";

export const Ground = () => {
  const { setHitPoint, setDoubleClick } = useStore();

  const animateCamera = (e) => {
    e.stopPropagation();
    if (e.object.name === "ground") {
      setHitPoint(e.point);
      setDoubleClick(true);
    }
  };

  return (
    <RigidBody type="fixed" colliders={false}>
      <mesh
        name="ground"
        onDoubleClick={(e) => animateCamera(e)}
        receiveShadow
        position={[-14, 0, -5]}
        rotation-x={-Math.PI / 2}
        visible={false}
      >
        <circleGeometry args={[27, 27]} />
      </mesh>
      <CuboidCollider args={[500, 2, 500]} position={[0, -2, 0]} />
    </RigidBody>
  );
};

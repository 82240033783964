import React from "react";
import "./keyboard-icon.css";
import useDeviceDetect from "../../../utils2/useDeviceDetect";

function KeyboardIcon() {
  const isTouchDevice = useDeviceDetect();
  return (
    <>
      {!isTouchDevice && (
        <div className="keyboard-icon">
          <img className="keyboard-icon-img" src="/assets/images/wasd.svg" />
        </div>
      )}
    </>
  );
}

export default KeyboardIcon;

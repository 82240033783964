import React from 'react';
import ModelProduct from './ModelProduct';

const ModelProducts = ({ productData, hasToken=true, cartProductDetails}) => {
    return ( 
        <>
          {productData.map((product,index) => (            
            <ModelProduct
              key={product.product_uid}
              url={product.product_3D_model_url}
              name={product.product_uid}
              scale={[.5,.5,.5]} 
              hasToken={hasToken}
              productData= {product}
              cartData= {cartProductDetails? cartProductDetails[index] : ''}
            />            
          ))}
        </>
      );
}

export default ModelProducts
import { Box, Dialog } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../assets/svg/info-red.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close-red.svg";
import "../../../style/components/store/FailedToRetrieve.scss";

export default function GameLimitExceed({ open, close, message }) {
  return (
    <>
      <Dialog open={open} className="product-limit-container">
        <Box component="div" className="product-limit-wrapper">
          <Box component="div" className="close-dialog">
            <CloseIcon
              onClick={() => {
                close();
              }}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box component="div" className="product-limit-content">
            <Box component="div">
              <InfoIcon />
            </Box>
            <Box component="div" className="product-limit-text">
              <h2>{message}</h2>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

import React from "react";
import Moleculers from "./Moleculers";


function Molecules1({ productData, showProduct, hasToken=true, cartProductDetails}) {
  return (
    <>
      {productData.map((product,index) => (
        <Moleculers
          key={product.product_uid}
          url={product.product_3D_model_url}
          name={product.product_uid}
          scale={[.5,.5,.5]} 
          showProduct={showProduct}
          hasToken={hasToken}
          productData= {product}
          cartData= {cartProductDetails? cartProductDetails[index] : ''}
        />
      ))}
    </>
  );
}

export default Molecules1;

import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js";
import { CapsuleCollider, RigidBody } from "@react-three/rapier";
import { useEffect, useRef } from "react";
import { usePersonControls } from "../util/hooks";
import { useFrame } from "@react-three/fiber";
import { isBrowser } from "react-device-detect";
import useStore from "../util/3dstore";
import useAudioContainer from "../util/useAudioContainer";

const entryAnimationDuration = 6000;
const teleportingAnimationDuration = 80;
const MOVE_SPEED = 300;
const direction = new THREE.Vector3();
const frontVector = new THREE.Vector3();
const sideVector = new THREE.Vector3();
let requiredDistance;
let cameraRequiredDistance;
let playerRequiredDistance;
export const Player = () => {
  const {
    hitPoint,
    isDoubleClick,
    setDoubleClick,
    isMovingWithJoystick,
    joystickFoward,
    joystickTurn,
    isProductClick,
    setProductClick,
    isEntryAnimationDone,
    setDoneEntryAnimation,
    setRotateCamera,
    joystickSpeed,
    setPanEnabled,
    setShowForm,
    isLcdCapsuleHit,
    setLcdCapsuleHit,
    isMovingToTarget,
    setIsMovingToTarget,
    setTotalDistance,
    setZeroRequiredDistance,
    isGamePopupOpen,
    t
  } = useStore();
  const { isCartOpen } = useAudioContainer();

  const playerRef = useRef();
  const { forward, backward, left, right } = usePersonControls();
  const easing = TWEEN.Easing.Cubic.Out;
  const teleportEasing = TWEEN.Easing.Cubic.Out;


















  const STARTING_POSITION = new THREE.Vector3(-10.5, 0.7475031018257141, -16.997562408447266); // Adjust this as per your starting point
  const TELEPORT_DURATION = 1000; // Duration of teleportation animation
  const teleportToStart = () => {
    if (!playerRef.current) return;
    const currentPosition = playerRef.current.translation();
    new TWEEN.Tween({ t: 0 })
      .to({ t: 1 })
      .duration(TELEPORT_DURATION)
      .easing(easing)
      .onUpdate((object) => {
        const newPosition = new THREE.Vector3().lerpVectors(currentPosition, STARTING_POSITION, object.t);
        playerRef.current.setTranslation(newPosition, true);
      })
      .onComplete(() => {
        setIsMovingToTarget(false);
        setPanEnabled(true);
      })
      .start();
  };

  useEffect(()=>{
    teleportToStart()
  },[t]);

























  useEffect(() => {
    if (!playerRef.current) return;
    playerRef.current.setTranslation(
      {
        x: -10.857938766479492,
        y: 3,
        z: -43,
      },
      true
    );
    return () => {
      TWEEN.removeAll();  
    };
  }, []);

  useEffect(() => {
    if (!playerRef.current) return;
  }, []);

  useFrame((state, delta) => {
    if (!isGamePopupOpen && !isCartOpen) {
      if (!playerRef.current) return;
      const velocity = playerRef.current.linvel();
      if (isEntryAnimationDone === false && !isDoubleClick && !isProductClick) {
        if (!isMovingToTarget) {
          // Set-up Player
          const { x, y, z } = playerRef.current.translation();
          const playerInitialPosition = new THREE.Vector3(x, y, z);
          const playerFinalPosition = new THREE.Vector3(-10.5, 3, -17);
          const playerTotalDistance =
            playerInitialPosition.distanceTo(playerFinalPosition);
          const playerMoveDirection = direction
            .subVectors(playerFinalPosition, playerInitialPosition)
            .normalize();
          if (isProductClick) {
            playerRequiredDistance = playerTotalDistance;
          } else {
            playerRequiredDistance = playerTotalDistance;
          }
          const PlayerY = playerInitialPosition.y;
          const playerTargetPosition = playerInitialPosition
            .clone()
            .add(playerMoveDirection.multiplyScalar(playerRequiredDistance));
          playerTargetPosition.y = PlayerY;

          //Set-up camera
          const cameraInitialPosition = state.camera.position;
          const cameraFinalPosition = new THREE.Vector3(-10.5, 3, -17);
          const cameraTotalDistance =
            cameraInitialPosition.distanceTo(cameraFinalPosition);

          const cameraMoveDirection = direction
            .subVectors(cameraFinalPosition, cameraInitialPosition)
            .normalize();
          if (isProductClick) {
            cameraRequiredDistance = cameraTotalDistance;
          } else {
            cameraRequiredDistance = cameraTotalDistance;
          }
          const cameraTargetPosition = cameraInitialPosition
            .clone()
            .add(cameraMoveDirection.multiplyScalar(cameraRequiredDistance));
          setIsMovingToTarget(true);
          new TWEEN.Tween({ t: 0 })
            .to({ t: 1 })
            .duration(entryAnimationDuration)
            .easing(easing)
            .onUpdate((object) => {
              // Interpolate position based on the tween progress
              // move Player
              const playerAnimationPosition = new THREE.Vector3().lerpVectors(
                playerInitialPosition,
                playerTargetPosition,
                object.t
              );
              playerRef.current.setTranslation(
                {
                  x: playerAnimationPosition.x,
                  y: playerAnimationPosition.y,
                  z: playerAnimationPosition.z,
                },
                true
              );
              if (object.t > 0.95) setPanEnabled(true);
              //move Camera
              const cameraAnimationPosition = new THREE.Vector3().lerpVectors(
                cameraInitialPosition,
                cameraTargetPosition,
                object.t
              );
              if (cameraAnimationPosition.z <= 50) {
                // console.log("HEIGHT VIEW");
                setRotateCamera(true);
              }
              // console.log(cameraAnimationPosition.z);
              state.camera.position.set(
                cameraAnimationPosition.x,
                cameraAnimationPosition.y,
                cameraAnimationPosition.z
              );
            })
            .onComplete(function (object) {
              setDoneEntryAnimation(true);
              setDoubleClick(false);
              setProductClick(false);
              setIsMovingToTarget(false);
              setTimeout(() => {
                setShowForm(true);
              }, 5000);
            })
            .start();
        }
      }

      if (!isDoubleClick && !isProductClick) {
        if (!isMovingToTarget) {
          if (!isMovingWithJoystick) {
            frontVector.set(0, 0, 0);
            sideVector.set(0, 0, 0);
            playerRef.current.wakeUp();
            playerRef.current.setLinvel({
              x: 0,
              y: velocity.y,
              z: 0,
            });
          }

          if (isMovingWithJoystick && isEntryAnimationDone) {
            if (joystickFoward != 0 || joystickTurn != 0) {
              // Math.abs(joystickTurn) >= 0.5 || Math.abs(joystickFoward) >= 0.5
              //   ? setMoveSpeed(150)
              //   : setMoveSpeed(100);
              direction
                .set(0, 0, -joystickFoward * joystickSpeed * delta)
                .applyEuler(state.camera.rotation);
              playerRef.current.wakeUp();
              playerRef.current.setLinvel({
                x: direction.x,
                //not changing the height of the element
                y: velocity.y,
                z: direction.z,
              });
            }
          } else {
            if (
              // !isTouchDevice &&
              isBrowser &&
              isEntryAnimationDone
            ) {
              //button clicked updating the amount of length
              if (forward) console.log(forward);

              frontVector.set(0, 0, backward - forward);
              sideVector.set(left - right, 0, 0);
              //set direction of movement
              direction
                .subVectors(frontVector, sideVector)
                .normalize()
                .multiplyScalar(MOVE_SPEED * delta)
                .applyEuler(state.camera.rotation);

              playerRef.current.wakeUp();
              playerRef.current.setLinvel({
                x: direction.x,
                //not changing the height of the element
                y: velocity.y,
                z: direction.z,
              });
            }
          }
        }
      } else {
        if (isDoubleClick || isProductClick) {
          if (!isMovingToTarget) {
            setIsMovingToTarget(true);
            const { x, y, z } = playerRef.current.translation();
            const playerPosition = new THREE.Vector3(x, y, z);
            const totalDistance = playerPosition.distanceTo(hitPoint);
            const moveDirection = direction
              .subVectors(hitPoint, playerPosition)
              .normalize();
            setTotalDistance(totalDistance);
            if (isProductClick && totalDistance - 7 > 0) {
              requiredDistance = totalDistance - 7;
            } else if (isLcdCapsuleHit && totalDistance - 7 > 0) {
              requiredDistance = totalDistance - 7;
            } else if (isDoubleClick && totalDistance - 7 > 0) {
              requiredDistance = totalDistance - 7;
            } else {
              requiredDistance = 0;
            }
            const PlayerY = playerPosition.y;
            const targetPosition = playerPosition
              .clone()
              .add(moveDirection.multiplyScalar(requiredDistance));
            targetPosition.y = PlayerY;
            setPanEnabled(false);
            new TWEEN.Tween({ t: 0 })
              .to({ t: 1 })
              .duration(teleportingAnimationDuration * requiredDistance)
              .easing(teleportEasing)
              .onUpdate((object) => {
                const newPosition = new THREE.Vector3().lerpVectors(
                  playerPosition,
                  targetPosition,
                  object.t
                );

                if (object.t >= 0.9) {
                  // console.log("_____________________________");
                  setPanEnabled(true);
                }
                playerRef.current.setTranslation(
                  {
                    x: newPosition.x,
                    y: newPosition.y,
                    z: newPosition.z,
                  },
                  true
                );
              })
              .onComplete(function (object) {
                setZeroRequiredDistance(false);
                setLcdCapsuleHit(false);
                setDoubleClick(false); // Reset the double-click flag
                setProductClick(false); // Reset the double-click flag

                setIsMovingToTarget(false);
              })
              .start();
          }
        }
      }
    }

    // moving camera
    const { x, y, z } = playerRef.current.translation();
    if (isEntryAnimationDone) {
      state.camera.position.set(x, 3, z);
    }

    TWEEN.update();
    // }
  });

  return (
    <>
      <RigidBody
        mass={1}
        ref={playerRef}
        friction={1}
        enabledRotations={[false, false, false]}
      >
        <mesh>
          <capsuleGeometry args={[0.1, 0.1]} />
          <CapsuleCollider args={[0.75, 0.75]} />
        </mesh>
      </RigidBody>
    </>
  );
};

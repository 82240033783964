import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as CopyIcon } from "../../assests/svg/solar_copy-outline.svg";
import "react-toastify/dist/ReactToastify.css";
import "../dialog/NavigationInstruction.scss";
import { Link } from "react-router-dom";

const QuizDialog = ({
  openQuizDialog,setOpenQuizDialog
}) => {
  return (
    <>
      {/* <div className="navigation-instruction"> */}
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openQuizDialog}
        className="quiz-dialog"
      >
        <div className="close">
          <div
            className="close-icon"
            onClick={() => setOpenQuizDialog(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="quiz-dialog-container">
        <div className="quiz-dialog-wrapper">
          <h4>What is the color of the table on which all the products are kept?</h4>
          <div className="questions-container">
            <div className="questions">
              <p>Yellow</p>
            </div>
            <div className="questions">
            <p>White</p>
            </div>
            <div className="questions">
            <p>Gray</p>
            </div>
          </div>
         </div>
        </div>
      </Dialog>
      {/* </div> */}
    </>
  );
};

export default QuizDialog;

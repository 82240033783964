import Logo from '../../assests/images/white-logo.svg'
import { ReactComponent as MessageIcon } from "../../assests/svg/Message.svg";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { HeaderContext } from "../../store/Header-context";
import { fetchData } from "../../service/http.service";
import ApiConfig from "../../config/ApiConfig";
export default function EmailVerification() {
    const token=useSelector(state=>state.token);
    const navigate=useNavigate();
    const { setLoading } = useContext(HeaderContext);
    const [disabled, setDisabled] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const value = JSON.parse(localStorage.getItem('brand_email'));
    const payload={
        brand_email:value,
        type:1
    }
    // const handleClick = () => {
    //     setDisabled(true);
      
    // };
    async function handleSendLink() {
        setDisabled(true);
        const response = await fetchData(ApiConfig.resendLink,setLoading,'post',payload,{ authorization: `Bearer ${token}` })
        if(response.data.statusCode === 200){
       navigate('/Email-verification')
        }
        else if (response.status === 401 ||response.status === 500) {
            navigate("/login");
          }
                }

    useEffect(() => {
        let interval;
        if (disabled) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 1) {
                        setDisabled(false);
                        clearInterval(interval);
                        return 30;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [disabled]);
    return <div className='main-container' style={{background: 'linear-gradient(90deg, #9747FF -1.09%, #EE3A24 103.17%)'}}>
        <header className="sigupHeader">
            <nav>
                <ul>
                    <li>
                        <Tooltip   Tooltip title="Back to Website">
                            <Link to="https://www.onewayx.com/" target="_blank">
                                <img src={Logo} alt="logo" />
                            </Link>
                        </Tooltip>
                    </li>
                </ul>
            </nav>
        </header>
        <div className='email-verification-section'>
        <div className="email-verification-container">
            <div className="header-container">
                <MessageIcon></MessageIcon>
                <div className='email-verification-heading'>
                <h2>Check Your Email</h2>
                <p>Sign in using your verification link</p>
                
                </div>
                <button className="button2" onClick={()=>{handleSendLink()}} disabled={disabled} style={{fontSize:'1.3875vw'}}
                >Resend</button>
                {disabled && <p style={{fontSize:'0.9396vw'}}>Resend in {seconds}s</p>}
            </div>
        </div>
        </div>
       
    </div>
}
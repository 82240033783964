import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as Keys } from "../../assests/svg/keys-icon.svg";
import { ReactComponent as DblClick } from "../../assests/svg/dblclick-icon.svg";
import { ReactComponent as ClickDrag } from "../../assests/svg/click-drag-icon.svg";
import "react-toastify/dist/ReactToastify.css";
import "../dialog/NavigationInstruction.scss";

const NavigationInstruction = ({
  openInstructionDialog,
  setOpenInstructionDialog,
}) => {
  return (
    <>
      {/* <div className="navigation-instruction"> */}
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openInstructionDialog}
        className="navigation-instruction-dialog"
      >
        <div className="navigation-instruction-container">
          <h3>Navigation Instructions</h3>
          <div className="navigation-instruction-wrapper">
            <div className="keys-instruction">
              <Keys />
              <div className="instruction-text">
                <p>
                  W<span style={{fontWeight:'400'}}>:</span><span style={{fontWeight:'500'}}> Move forward </span>
                  </p>
                  <p>A<span style={{fontWeight:'400'}}>:</span><span style={{fontWeight:'500'}}> Move left </span></p>
                <p>S<span style={{fontWeight:'400'}}>:</span><span style={{fontWeight:'500'}}> Move backward </span> </p>
                <p>D<span style={{fontWeight:'400'}}>:</span><span style={{fontWeight:'500'}}> Move right</span>
                </p>
              </div>
            </div>
            <hr />
            <div className="click-drag">
              <ClickDrag />
             
              <p>Click and drag to look around the space</p>
             
             
            </div>
            <hr />
            <div className="double-click">
              <DblClick />
              <p>Double click to move around the space</p>
            </div>
          
          </div>
          <div className="enter-btn">
            <button onClick={()=>setOpenInstructionDialog(false)}>Enter</button>
          </div>
        </div>
      </Dialog>
      {/* </div> */}
    </>
  );
};

export default NavigationInstruction;

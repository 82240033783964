// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assests/images/home-background-img.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-mobile:after {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-size: cover;
  filter: blur(2px);
}

.mobile-text {
  width: 70%;
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/PopupForMobile.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yDAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;AACJ;;AACA;EACI,UAAA;EACA,aAAA;AAEJ","sourcesContent":[".popup-mobile:after {\n    content: '';\n    background-image: url(../assests/images//home-background-img.png);\n    width: 100vw;\n    height: 100vh;\n    position: absolute;\n    background-size: cover;\n    filter: blur(2px);\n}\n.mobile-text{\n    width:70%;\n    padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

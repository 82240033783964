import React, { useEffect, useState } from "react";
import {
  format,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addDays,
  subDays,
  isSameMonth,
  isBefore,
  isAfter,
} from "date-fns";
import { ReactComponent as Previous } from "../../../assets/svg/Previous.svg";
import { ReactComponent as Next } from "../../../assets/svg/next.svg";
import calendarImage from "../../../assets/icons/calendar.png";
import "../../../style/components/analytics/Calendar.scss";

const Calendar = ({ onDateRangeChange, onCalendarClose }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
    lastStart: null,
    lastEnd: null,
  });
  const [isSelectingStart, setIsSelectingStart] = useState(true);
  const [activeButton, setActiveButton] = useState("this-week");
  const [showCalendar, setCalendar] = useState(false);

  useEffect(() => {
    setThisWeek();
  }, []);

  const renderHeader = () => {
    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <p>{format(currentMonth, "MMMM yyyy")}</p>
        </div>
        <div className="col col-end">
          <Previous onClick={prevMonth} />
          <Next onClick={nextMonth} />
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), "EEEEEE")}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, "d");
        const cloneDay = day;
        const isInRange =
          selectedRange.start &&
          selectedRange.end &&
          cloneDay >= selectedRange.start &&
          cloneDay <= selectedRange.end;
        days.push(
          <div
            className={`col cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isInRange
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const onDateClick = (day) => {
    setActiveButton("custom");
    if (isSelectingStart) {
      setSelectedRange({
        start: day,
        end: day,
        lastStart: subDays(day, 1),
        lastEnd: subDays(day, 1),
      });
    } else {
      setSelectedRange((prevRange) => {
        const start = isBefore(day, prevRange.start) ? day : prevRange.start;
        const end = isAfter(day, prevRange.start) ? day : prevRange.end;

        let lastStart, lastEnd;
        if (start.getTime() === end.getTime()) {
          // If start and end are the same, set lastStart and lastEnd to the day before start
          lastStart = subDays(start, 1);
          lastEnd = subDays(start, 1);
        } else {
          // If start and end are different, calculate the previous equivalent period
          const duration = Math.abs(
            (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
          );
          lastStart = subDays(start, duration + 1);
          lastEnd = subDays(start, 1);
        }

        return { start, end, lastStart, lastEnd };
      });
    }
    setIsSelectingStart(!isSelectingStart);
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
    setActiveButton("custom");
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
    setActiveButton("custom");
  };

  const setThisWeek = () => {
    const today = new Date();
    const start = startOfWeek(today, { weekStartsOn: 1 });
    const end = today;
    const lastStart = startOfWeek(subDays(start, 1), { weekStartsOn: 1 });
    const lastEnd = endOfWeek(subDays(end, 1), { weekStartsOn: 1 });
    setSelectedRange({ start, end, lastStart, lastEnd });
    setCurrentMonth(today);
    setActiveButton("this-week");
  };

  const setThisMonth = () => {
    const today = new Date();
    const start = startOfMonth(today);
    const end = today;
    const lastStart = startOfMonth(subMonths(start, 1));
    const lastEnd = endOfMonth(subMonths(end, 1));
    setSelectedRange({ start, end, lastStart, lastEnd });
    setCurrentMonth(today);
    setActiveButton("this-month");
  };

  const handleClose = () => {
    setCalendar((prev) => !prev);
    if (showCalendar) {
      if (selectedRange.start && selectedRange.end) {
        onDateRangeChange({
          start: format(selectedRange.start, "yyyy-MM-dd"),
          end: format(selectedRange.end, "yyyy-MM-dd"),
          lastStart: format(selectedRange.lastStart, "yyyy-MM-dd"),
          lastEnd: format(selectedRange.lastEnd, "yyyy-MM-dd"),
        });
      }
    }
  };

  const renderOptions = () => {
    return (
      <div className="options">
        <button
          className={activeButton === "this-week" ? "active" : ""}
          onClick={setThisWeek}
        >
          This week
        </button>
        <button
          className={activeButton === "this-month" ? "active" : ""}
          onClick={setThisMonth}
        >
          This month
        </button>
        <button
          className={activeButton === "custom" ? "active" : ""}
          style={{ cursor: "default" }}
        >
          Custom
        </button>
      </div>
    );
  };

  return (
    <div className="calender-container">
      <div className="calendar-selected">
        <Previous />
        <button onClick={() => handleClose()}>
          <span>
            <img src={calendarImage} alt="Calendar-image" />
          </span>
          <span>
            {activeButton
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </span>
        </button>
        <Next />
      </div>
      {showCalendar && (
        <div className="calendar">
          <div className="calendar-option">{renderOptions()}</div>
          <div className="calendar-data">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;

import React, { useState, useRef, useEffect } from "react";
import useUIStore from "../../../utils2/uistore";
import useStore from "../../../utils2/3dstore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./header.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HeaderOverlay from "./Backdrop";
import Tiktok from "./icons/Tiktok";
import Instagram from "./icons/Instagram";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import ShoppingBag from "./icons/shopping-bag";
import useDeviceDetect from "../../../utils2/useDeviceDetect";
const Header = () => {
  const { setHeaderOverlay, isEntryAnimationDone, setOtherModalVisible } =
    useStore();
  const isTouchDevice = useDeviceDetect();
  const {
    handleShowMenu,
    count,
    showMenu,
    handleShowCart,
    showCart,
    instructionModal,
    handleItems,
    handleFormModal,
    handleShowProductList,
    showproducts,
    handleThankyouModal,
  } = useUIStore((state) => state);
  const menuRef = useRef(null);
  const circleStyle = {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "8px",
  };

  useEffect(() => {
    if (showCart || showMenu) {
      setHeaderOverlay(false);
    }
    if (!showCart && !showMenu) setHeaderOverlay(true);
  }, [showCart, showMenu]);
  return (
    <>
      {isEntryAnimationDone && <HeaderOverlay />}
      <div className="header-containers">
        <div className="headerss">
          {showMenu ? (
            <CloseIcon
              onClick={() => {
                // if (isEntryAnimationDone) {
                setHeaderOverlay(true);
                handleShowMenu(false);
                // }
              }}
              style={{ fontSize: 30, cursor: "pointer",color:"#000" }}
              className="hide-selection"
            />
          ) : (
            <MenuIcon
              onClick={() => {
                // if (isEntryAnimationDone) {
                setHeaderOverlay(false);
                handleShowMenu(true);
                handleFormModal(false);
                handleItems(false);
                handleShowCart(false);
                // }
              }}
              style={{ fontSize: 30, cursor: "pointer" }}
              className="hide-selection"
            />
          )}
          <img
            src="/assets/images/gabar.png"
            alt="Gabar"
            className="logo-image"
          />
          <>
            {/* <div
              style={{
                padding: "0.5rem",
                backgroundColor: isTouchDevice ? "green" : "red",
              }}
            >{`I am ${isTouchDevice ? "" : "NOT"} a touch device`}</div> */}
          </>
          {/* <div
            style={{
              position: "absolute",
              right: "15%",
              cursor: "pointer",
              opacity: !instructionModal ? 0 : 1,
            }}
            onClick={() => {
              console.log("CLICKED");
              handleShowCart(false);
              handleItems(false);
              handleFormModal(false);
              handleShowMenu(false);
              handleShowProductList(!showproducts);
              handleThankyouModal(false);
            }}
          >
            <span style={{ fontSize: 14, fontWeight: "bold" }}>
              Product List
            </span>
          </div> */}
          <div
            style={{
              position: "relative",
              // opacity: !instructionModal ? 0 : 1,
              cursor: "pointer",
            }}
            onClick={() => {
              // setHeaderOverlay(true)
              if (isEntryAnimationDone) {
                handleShowMenu(false);
                handleShowCart(!showCart);
              }
            }}
          >
            {isEntryAnimationDone ? (
              <>
                <div className="itemCount">
                  <div style={circleStyle}>{count}</div>
                </div>
                <ShoppingBag/>
                {/* <img
                  src="/assets/images/Vector.png"
                  // style={{ height: 20, width: 20, opacity: !instructionModal ? 0 : 1 }}
                  style={{ height: 23, width: 23 }}
                /> */}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {showMenu && (
        <div className="modalss" style={getModalPosition(menuRef)}>
          <div className="modal-contentss">
            <div className="items-option-container">
              <span
                className="option-text"
                onClick={() => handleShowMenu(false)}
              >
                <a
                  href="https://gabarmyanmar.com/pages/gabar-for-myanmar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gabar for Myanmar
                </a>
              </span>
              <div className="bottom-border" />
              <span
                className="option-text"
                onClick={() => handleShowMenu(false)}
              >
                <a
                  href=" https://gabarmyanmar.com/pages/stores/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stores
                </a>
              </span>
              <div className="bottom-border" />
              <span
                className="option-text"
                onClick={() => handleShowMenu(false)}
              >
                <a
                  href="https://gabarmyanmar.com/pages/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
              </span>
              <div className="bottom-border" />
              <span
                className="option-text"
                onClick={() => handleShowMenu(false)}
              >
                <a href="https://gabarmyanmar.com/" target="_self">
                  Back to Store
                </a>
              </span>
              <div className="bottom-last-border" />
              <div className="social-links-container">
                <div className="social-links">
                  <a
                    href=" https://www.instagram.com/gabar/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="remove-underline"
                  >
                    <div className="social-link-img">
                      <Instagram />
                      {/* <img
                      src="/assets/images/instagram.svg"
                      alt="Instagram"
                      className="instagram-img" 
                    /> */}
                    </div>

                    <span className="social-link-text">/gabar</span>
                  </a>
                </div>

                <div className="social-links">
                  <a
                    href="https://www.tiktok.com/@gabarinthewild"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="remove-underline"
                  >
                    <Tiktok />
                    {/* <img
                      src="/assets/images/tiktok.svg"
                      alt="TikTok"
                      className="social-link-img"
                      
                    /> */}
                    <span className="social-link-text">@gabarinthewild</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    // </div>
  );
};

const getModalPosition = (menuRef) => {
  if (menuRef.current) {
    const rect = menuRef.current.getBoundingClientRect();
    return {};
  }
  return {};
};

export default Header;

import ReactGA from "react-ga4";

const useAnalytics = () => {
  const trackAddToCart = (item_name) => {
    ReactGA.gtag("event", "Product Add To Cart", {
      product_added_to_cart: item_name,
    });
  };

  const track3DCapsuleView = (item_name) => {
    ReactGA.gtag("event", "Product Capsule View", {
      product_capsule_view: item_name,
    });
  };

  const trackProductInfoView = (item_name) => {
    ReactGA.gtag("event", "Product Info_View", {
      product_info_view: item_name,
    });
  };

  const trackCartCheckOut = (item_name) => {
    ReactGA.gtag("event", "Product at CheckOut", {
      product_check_out: item_name,
    });
  };
  const trackQuestionBoardInitialClick = (item_name) => {
    // console.log(item_name);
    ReactGA.gtag("event", "Question Board Initial Click", {
      question_board_clicked: item_name,
    });
  };
  const trackQuestionBoardAnswer = (item_name) => {
    // console.log(item_name);
    ReactGA.gtag("event", "Question Board Answer", {
      question_board_answer: item_name,
    });
  };
  const trackGameFinalResult = (item_name) => {
    // console.log(item_name);
    ReactGA.gtag("event", "Game Final Result", {
      game_final_result: item_name,
    });
  };
  const trackTotalGameDuration= (item_name) => {
    // console.log(item_name);
    ReactGA.gtag("event", "Total Game Duration", {
      game_play_duration_sec: item_name,
    });
  };

  return {
    trackAddToCart,
    track3DCapsuleView,
    trackProductInfoView,
    trackCartCheckOut,
    trackQuestionBoardInitialClick,
    trackQuestionBoardAnswer,
    trackGameFinalResult,
    trackTotalGameDuration
  };
};

export default useAnalytics;

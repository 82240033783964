import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
  persist(
    (set) => ({
      currentStep: 1,
      loading: false,
      openForgotPasswordDialog: false,
      openEmailNotFoundDialog: false,
      openDialog: false, 

      // Auth
      isAuthenticated: false,
      token: null,
      user: null,

      // State setters
      setLoading: (value) => set({ loading: value }),
      setCurrentStep: (step) => set({ currentStep: step }),
      setOpenForgotPasswordDialog: (isOpen) =>
        set({ openForgotPasswordDialog: isOpen }),
      setOpenEmailNotFoundDialog: (isOpen) =>
        set({ openEmailNotFoundDialog: isOpen }),
      toggleLearnMorePopup: () =>
        set((state) => ({ openDialog: !state.openDialog })),
      handleCloseDialog: () => set({ openDialog: false }),
      setToken: (value) => set({ token: value }),

      // Auth Controller
      login: (token) =>
        set({
          isAuthenticated: true,
          token,
        }),

      logout: () =>
        set({
          isAuthenticated: false,
          user: null,
          token: null,
        }),

      updateUser: (updatedUser) =>
        set((state) => ({
          user: { ...state.user, ...updatedUser },
        })),
    }),
    {
      name: 'auth-storage', 
      getStorage: () => localStorage,
    }
  )
);

export default useAuthStore;

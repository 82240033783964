import React, { useEffect, useState } from "react";

import useAuthStore from "../../store/authStore";
import ApiConfig from "../../helper/ApiConfig";
import { fetchData } from "../../fetcher/fetchData";
import ComingSoon from "../../components/dashboard/ComingSoon";
import { useNavigate } from "react-router-dom";
import VisitorAnalytic from "../../components/dashboard/analytic/VisitorAnalytic";
import Calendar from "../../components/dashboard/analytic/Calendar";
import ProductAnalytic from "../../components/dashboard/analytic/ProductAnalytic";
import "../../style/components/analytics//Analytics.scss";

function Analytics() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(2);
  const { token, setLoading } = useAuthStore();
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const handleDateRangeChange = (range) => {
    setSelectedDateRange(range);
  };

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.info}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response.statusCode === 401 || response.statusCode === 500) {
        navigate("/login");
      } else if (response.data.statusCode === 200) {
      }
      setLoading(true);
    };
    getEnvDetails();
  }, [token, navigate]);
  return (
    <div className="analytic">
      <h2 className="title">Analytics</h2>
      <div className="tab-container">
        <div>
          <button
            type="button"
            onClick={() => setCurrentTab(2)}
            className={currentTab === 2 ? "active" : ""}
          >
            Visitors Analytics
          </button>
          <button
            type="button"
            onClick={() => setCurrentTab(3)}
            className={currentTab === 3 ? "active" : ""}
          >
            Product Analytics
          </button>
          <button
            type="button"
            onClick={() => setCurrentTab(1)}
            className={currentTab === 1 ? "active" : ""}
          >
            Heat Map
          </button>
        </div>
        <div>
          <Calendar onDateRangeChange={handleDateRangeChange} />
        </div>
      </div>
      <div>
        {currentTab === 1 ? (
          <ComingSoon />
        ) : currentTab === 2 ? (
          <VisitorAnalytic selectedRange={selectedDateRange} />
        ) : (
          <ProductAnalytic selectedRange={selectedDateRange} />
        )}
      </div>
      <div></div>
    </div>
  );
}

export default Analytics;
import { Box, Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close-red.svg";
import "../../../style/components/store/FailedToRetrieve.scss";

export default function ThankyouPage({ openThankyouPage, close }) {
  return (
    <>
      <Dialog
        open={openThankyouPage}
        className="thankyou-container"
        maxWidth="xl"
      >
        <Box component="div" className="thankyou-wrapper">
          <Box component="div" className="close-dialog">
            <CloseIcon onClick={close} style={{ cursor: "pointer" }} />
          </Box>
          <Box component="div" className="thankyou-content">
            <Box component="div" className="thankyou-text">
              <h3>
                Thank you for your information, our support team will be in
                touch with you shortly.
              </h3>
              <p>
                Meanwhile, if you wish to get in touch with us, please reach out
                to{" "}
                <span>
                  {" "}
                  <a href="mailto:support@onewayx.com">support@onewayx.com</a>
                </span>
              </p>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

import "./Home2.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Experience from "./gabar/components2/Experience";
import Loading from "./gabar/components2/UI/Loading/Loader";
import Landscape from "./gabar/components2/UI/Landscape/Landscape";
import {
  EnterFullScreenButton,
  ExitFullScreenButton,
} from "./gabar/components2/Controls/FullScreen";
import UI from "./gabar/components2/UI/UI";
import UIfinal from "./gabar/components2/UI/UIfinal";
export const Home2 = () => {
  const handle = useFullScreenHandle();

  return (
    <>
      <div className="App">
        <div className="ReactFullScreen">
          <EnterFullScreenButton handle={handle} />
          <FullScreen handle={handle}>
            <div>
              <ExitFullScreenButton handle={handle} />
              <UIfinal/>
              <Experience />
            </div>
          </FullScreen>
        </div>
      </div>
      <Loading />
      <Landscape />
    </>
  );
};

export default Home2;

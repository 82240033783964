import React, { useEffect, useState } from "react";
import "./gamewait-styles.css";
import CloseIcon from "@mui/icons-material/Close";
// import FormOverlay from "./Backdrop";
import useGameStore from "../utils/gameStore";

export default function GameWaitPopup() {
  const { showGameWaitPopup, handleShowGameWaitPopup } = useGameStore();

  return (
    <>
      {/* {!isFormOverlay && <FormOverlay />} */}
      {showGameWaitPopup ? (
        <>
          {/* <FormOverlay /> */}
          <div className="parent-form-test_4  no-text-select">
            <div className="sub-parent-form_4">
              <div
                className="form-close-icon"
                onClick={() => handleShowGameWaitPopup(false)}
              >
               <CloseIcon style={{ fontSize: 20, color: "#fff" }} />
              </div>
              <div className="form-item-container_4">
                <div className="heading-container_4">
                  <span className="heading-test-form_4">
                    Play locked, please wait.
                  </span>
                  <span
                    className="heading-test-form_4"
                    style={{ fontWeight: 400 }}
                  >
                    The hour is not over yet.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

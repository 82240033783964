import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as CopyIcon } from "../../assests/svg/solar_copy-outline.svg";
import "react-toastify/dist/ReactToastify.css";
import "../dialog/NavigationInstruction.scss";
import { Link } from "react-router-dom";

const GameRules = ({
  openGameRulesDialog,setOpenGameRulesDialog
}) => {
  return (
    <>
      {/* <div className="navigation-instruction"> */}
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openGameRulesDialog}
        className="lostmessage-dialog"
      >
        <div className="close">
          <div
            className="close-icon"
            onClick={() => setOpenGameRulesDialog(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="game-rules-container">
        <div className="game-rules-wrapper">
          <h3>GAME RULES
        
          </h3>
        <p className="ans-text">Answer 3 questions correctly to win a prize</p>
        <div className="enter-btn">
            <button type="submit">Start Game</button>
          </div>
        </div>
         </div>
        
      </Dialog>
      {/* </div> */}
    </>
  );
};

export default GameRules;

import {create} from 'zustand';

const useModelStore = create((set) => ({
  models: {}, 

  setModelPosition: (name, position) =>
    set((state) => ({
      models: {
        ...state.models,
        [name]: position,
      },
    })),
}));

export default useModelStore;
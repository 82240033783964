import { BrowserRouter } from 'react-router-dom';
import SystemRoutes from './components/SystemRoutes';
import './style/App.scss';
import './style/components/Dialog.scss';

function App() {
  return (
    <BrowserRouter>
      <SystemRoutes/>    
    </BrowserRouter>
  );
}

export default App;

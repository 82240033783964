import React from "react";
import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assests/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as CopyIcon } from "../../assests/svg/solar_copy-outline.svg";
import "react-toastify/dist/ReactToastify.css";
import "../dialog/NavigationInstruction.scss";
import { Link } from "react-router-dom";

const LostMessage = ({
  openLostMessageDialog,setOpenLostMessageDialog
}) => {
  return (
    <>
      {/* <div className="navigation-instruction"> */}
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openLostMessageDialog}
        className="lostmessage-dialog"
      >
        <div className="close">
          <div
            className="close-icon"
            onClick={() => setOpenLostMessageDialog(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="want-to-pay-game-container">
        <div className="want-to-pay-game-wrapper">
          <h3>SORRY, YOU HAVE NOT WON THIS TIME.
        
          </h3>
        
          <p className="try-again">Try again in one hour</p> 
         </div>
        </div>
      </Dialog>
      {/* </div> */}
    </>
  );
};

export default LostMessage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-create-section .custom-create-container .custom-drag-file-container .custom-drag-box .custom-drag-box-title.input-box {
  width: 140px;
  margin: 10px auto 0;
  padding: 0px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: white;
  color: #ee3a24;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.custom-create-section .custom-create-container .custom-drag-file-container .custom-drag-box .custom-drag-box-title.input-box svg {
  width: 1rem;
  color: red;
}

.loadingTab div {
  z-index: 1;
}
.loadingTab:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
}

.homeIcon, .keyboadButton {
  position: absolute;
  bottom: 1rem;
  right: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.homeIcon {
  background-color: #fff;
  width: 30px;
  height: 30px;
}

.keyboadButton {
  left: 4rem;
  right: auto;
  width: 45px;
  height: 45px;
}
.keyboadButton img {
  width: 100%;
}

.audio-controls svg {
  border: 1px solid #000;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/ThreeScreens.scss"],"names":[],"mappings":"AAEQ;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;AADR;AAGQ;EACI,WAAA;EACA,UAAA;AADZ;;AAOI;EACI,UAAA;AAJR;AAOI;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;AALR;;AASA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAPJ;;AASA;EACI,sBAAA;EACA,WAAA;EACA,YAAA;AANJ;;AAQA;EACI,UAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AALJ;AAOI;EACI,WAAA;AALR;;AASA;EACI,sBAAA;EACA,kBAAA;AANJ","sourcesContent":[".custom-create-section {\n    \n        .custom-create-container .custom-drag-file-container .custom-drag-box .custom-drag-box-title.input-box {\n        width: 140px;\n        margin: 10px auto 0;\n        padding: 0px 10px;\n        border-radius: 5px;\n        border: 1px solid #ddd;\n        background: #ffff;\n        color: #ee3a24;\n        display: flex;\n        align-items: center;\n        justify-content: space-around;\n\n        svg {\n            width: 1rem;\n            color: red;\n        }\n    }\n}\n\n.loadingTab {\n    div {\n        z-index: 1;\n    }\n\n    &:after {\n        content: \"\";\n        width: 100%;\n        height: 100%;\n        background-color: #fff;\n        position: absolute;\n        top: 0;\n    }\n}\n\n.homeIcon,.keyboadButton {\n    position: absolute;\n    bottom: 1rem;\n    right: 4rem;    \n    border-radius: 50%;\n   \n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n.homeIcon{\n    background-color: #fff;\n    width: 30px;\n    height: 30px;\n}\n.keyboadButton{\n    left: 4rem;\n    right: auto;\n    width: 45px;\n    height: 45px;\n\n    img{\n        width: 100%;\n    }\n}\n\n.audio-controls svg {\n    border: 1px solid #000;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

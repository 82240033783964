import { create } from "zustand";

const useShopifyStore = create((set) => ({
  productList: [],
  appCartItems: [],
  shopCartItems: [],
  checkOutId:null,
  // subTotal:0,
  setCheckOutId: (val) => set((state) => ({ checkOutId: val })),
  setAppCartItems: (val) => set((state) => ({ appCartItems: [...val] })),
  setShopCartItems: (val) => set((state) => ({ shopCartItems: [...val] })),
  setProductList: (val) => set((state) => ({ productList: [...val] })),

  localCartItems: [],
  cartItems: [],
  // setSubTotal: (val) => set((state) => ({ subTotal: val })),
  setCartItems: (val) => set((state) => ({ cartItems: val })),
  setLocalCartItems: (val) => set((state) => ({ localCartItems: val })),
}));

export default useShopifyStore;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thankyou-block {
  position: relative;
  width: 584px;
  height: 141.42px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thankyou-text {
  font-size: 16px;
  font-family: InterLight, Arial, Helvetica, sans-serif;
}
@media (max-width: 420px) {
  .thankyou-block {
    width: 280px;
    height: 80px;
  }
  .thankyou-text {
    font-size: 12px;
  }
}
@media only screen and (min-width: 421px) and (max-width: 740px) {
  .thankyou-block {
    width: 280px;
    height: 80px;
  }
  .thankyou-text {
    font-size: 12px;
  }
}
@media only screen and (min-width: 741px) and (max-width: 770px) {
  .thankyou-block {
    width: 280px;
    height: 80px;
  }
  .thankyou-text {
    font-size: 12px;
  }
}
@media only screen and (min-width: 771px) and (max-width: 950px) {
    .thankyou-block {
        width: 280px;
        height: 80px;
      }
      .thankyou-text {
        font-size: 14px;
      }
}
@media only screen and (min-width: 951px) and (max-width: 1248px) {
    .thankyou-block {
        width: 320px;
        height: 110px;
      }
      .thankyou-text {
        font-size: 14px;
      }
}
`, "",{"version":3,"sources":["webpack://./src/gabar/components2/UI/ThankYou/thankyou.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,qDAAqD;AACvD;AACA;EACE;IACE,YAAY;IACZ,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,YAAY;IACZ,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,YAAY;IACZ,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF;AACA;IACI;QACI,YAAY;QACZ,YAAY;MACd;MACA;QACE,eAAe;MACjB;AACN;AACA;IACI;QACI,YAAY;QACZ,aAAa;MACf;MACA;QACE,eAAe;MACjB;AACN","sourcesContent":[".thankyou-block {\n  position: relative;\n  width: 584px;\n  height: 141.42px;\n  background-color: #fff;\n  padding: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.thankyou-text {\n  font-size: 16px;\n  font-family: InterLight, Arial, Helvetica, sans-serif;\n}\n@media (max-width: 420px) {\n  .thankyou-block {\n    width: 280px;\n    height: 80px;\n  }\n  .thankyou-text {\n    font-size: 12px;\n  }\n}\n@media only screen and (min-width: 421px) and (max-width: 740px) {\n  .thankyou-block {\n    width: 280px;\n    height: 80px;\n  }\n  .thankyou-text {\n    font-size: 12px;\n  }\n}\n@media only screen and (min-width: 741px) and (max-width: 770px) {\n  .thankyou-block {\n    width: 280px;\n    height: 80px;\n  }\n  .thankyou-text {\n    font-size: 12px;\n  }\n}\n@media only screen and (min-width: 771px) and (max-width: 950px) {\n    .thankyou-block {\n        width: 280px;\n        height: 80px;\n      }\n      .thankyou-text {\n        font-size: 14px;\n      }\n}\n@media only screen and (min-width: 951px) and (max-width: 1248px) {\n    .thankyou-block {\n        width: 320px;\n        height: 110px;\n      }\n      .thankyou-text {\n        font-size: 14px;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { RigidBody } from "@react-three/rapier";
import { DoubleSide } from "three";
import { degToRad } from "three/src/math/MathUtils.js";

export const EntryCollider = () => {
  const animateCamera = (e) => {
    e.stopPropagation();
  };

  return (
    <RigidBody type="fixed" colliders="trimesh">
      <mesh
        name="entry_wall"
        onClick={(e) => animateCamera(e)}
        onDoubleClick={(e) => animateCamera(e)}
        receiveShadow
        position={[-13.082, 10, -2.2]}
        rotation={[degToRad(0.66), degToRad(-22), degToRad(-1.02)]}
      >
        <cylinderGeometry args={[22, 22, 20, 32, 1, true, 0, 1]} />
        <meshBasicMaterial side={DoubleSide} transparent opacity={0} />      
      </mesh>      
    </RigidBody>
  );
};
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as SubtractIcon } from "../assets/svg/minus.svg";
import { ReactComponent as CloseIcon } from "../assets/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as BoxIcon } from "../assets/svg/mage_box-3d.svg";
import "../style/components/three/ProductDetails2.scss";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../fetcher/fetchData";
import ApiConfig from "../helper/ApiConfig";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import useAudioContainer from "./gabar/util/useAudioContainer";
import useSoundStore from "./gabar/ui/AudioFinal/soundStore";
import playIcon from "../assets/images/audible.png";
import muteIcon from "../assets/images/muted.png";
import ModelViewer from "./ModelViewer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { logEvent } from "./analytic/Analytic";

const AddtoCart2 = ({ data, closePopup }) => {
  const [counterData, changeCounterData] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isCartLoaded, setCartLoaded] = useState(false);
  const [isImageActive, setImageActive] = useState(false);

  useEffect(() => {
    if (data && data.title && data.product_uid) {
      logEvent(`products_clicked`, {
          product_name: data.title,
          product_id: data.product_uid,
          product_image: data.productDetail.images[0].src,
      });
    }
  }, [data]);

  

  const [cartData, setCartData] = useState({
    checkoutId: "",
    quantity: counterData,
    variantId: "",
  });
  const navigate = useNavigate();
  const { brandId, varID, setVarID, setCheckoutURL } = useAudioContainer();
  const { isMuted, setMuted } = useSoundStore();
  const [isMuteVisible, setMuteVisibity] = useState(0);
  const [isUnmuteVisible, setUnMuteVisibility] = useState(1);

  useEffect(() => {
    if (isMuted) {
      setUnMuteVisibility(0);
      setMuteVisibity(1);
    } else {
      setUnMuteVisibility(1);
      setMuteVisibity(0);
    }
  }, [isMuted]);

  useEffect(() => {
    if (varID !== null) {
      setCartData({
        variantId: "",
        quantity: counterData,
        brandId: brandId,
        checkoutId: varID,
      });
    } else {
      setCartData({
        variantId: "",
        quantity: counterData,
        brandId: brandId,
      });
    }
  }, [counterData, varID]);

  useEffect(() => {
    const initialOptions = data.productDetail.options.reduce((acc, option) => {
      acc[option.name.toLowerCase()] = option.values[0];
      return acc;
    }, {});
    setSelectedOptions(initialOptions);
  }, [data.productDetail.options]);

  useEffect(() => {
    updateSelectedVariant();
  }, [selectedOptions]);

  const updateOption = (name, value) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name.toLowerCase()]: value,
    }));
  };

  const updateSelectedVariant = () => {
    changeCounterData(1);
    const variant = data.productDetail.variants.find((variant) => {
      return Object.keys(selectedOptions).every((key) => {
        return (
          variant[`option${Object.keys(selectedOptions).indexOf(key) + 1}`] ===
          selectedOptions[key]
        );
      });
    });
    setSelectedVariant(variant);
  };

  const incrementCounter = () => {
    if (
      counterData < (selectedVariant ? selectedVariant.inventory_quantity : 0)
    ) {
      changeCounterData(counterData + 1);
    }
  };

  const decrementCounter = () => {
    if (counterData > 1) {
      changeCounterData(counterData - 1);
    }
  };

  const handleAddToBag = async () => {
    closePopup();
    const temp = {
      ...cartData,
      variantId: `gid://shopify/ProductVariant/${selectedVariant.id}`,
    };

    if (selectedVariant && counterData > 0) {
      setCartLoaded(true);
      const response = await fetchData(
        ApiConfig.addToCart,
        setCartLoaded,
        "post",
        temp,
        {}
      );
      setCartLoaded(false);

      if (response.status === 401 || response.status === 500) {
        navigate("/login");
      }

      if (response.status === 200) {
        setVarID(response.data.checkout.id);
        setCheckoutURL(response.data.checkout.webUrl);
        logEvent(`products_add_to_cart`, {
          product_uid_cart: selectedVariant.product_id,
          product_value: counterData,
      });
      }
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
  };

  return (
    <div className="product-detail-container2">
      <div className="product-image-3dModel">
        {isImageActive ? (
          <div className="product-big-img">
            <LazyLoadImage
              src={data.productDetail.images[selectedImage].src}
              alt={`Product`}
              effect="blur"
              placeholderSrc="placeholder-image.png" // Optional placeholder
            />
            {/* <img
              src={data.productDetail.images[selectedImage].src}
              alt="Product"
            /> */}
          </div>
        ) : (
          <div className="model-3d">
            <ModelViewer path={data.product_3D_model_url} />
          </div>
        )}
        {/* 
        <div className="product-image-mobile" style={{ display: "none" }}>
          <Slider {...settings}>
            {data.productDetail.images.map((data, index) => (
              <div key={index} className={`img-${index + 1}`}>
                <img
                  src={data.src}
                  alt="images"
                  onClick={() => setSelectedImage(index)}
                />
              </div>
            ))}
          </Slider>
          <div
            className="music_icon"
            onClick={() => {
              setMuted(!isMuted);
            }}
          >
            <img
              className="sound-icon-img hide-selection"
              style={{
                fontSize: 30,
                opacity: isUnmuteVisible,
                position: "absolute",
                zIndex: 10,
              }}
              src={play}
            />
            <img
              className="sound-icon-img hide-selection"
              style={{
                fontSize: 30,
                opacity: isMuteVisible,
                position: "absolute",
                zIndex: 11,
              }}
              src={pause}
            />
          </div>
        </div> */}

        <div className="close-icon music_icon">
          <Tooltip title="Close Dialog">
            <CloseIcon
              className="close-dialog-icon"
              onClick={() => closePopup()}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        </div>

        <div
          className="music_icon music_button"
          onClick={() => {
            setMuted(!isMuted);
          }}
        >
          <img
            className="sound-icon-img hide-selection"
            style={{
              fontSize: 30,
              opacity: isUnmuteVisible,
              position: "absolute",
              zIndex: 10,
            }}
            src={playIcon}
          />
          <img
            className="sound-icon-img hide-selection"
            style={{
              fontSize: 30,
              opacity: isMuteVisible,
              position: "absolute",
              zIndex: 11,
            }}
            src={muteIcon}
          />
        </div>
      </div>
      <div className="product-detail">
        <div className="img-view-model">
          <div className="product-small-img">
            {data.productDetail.images.map((data, index) => (
              <div key={index} className={`img-${index + 1}`}>
                {/* <img
                  src={data.src}
                  alt="images"
                  onClick={() => {
                    setSelectedImage(index);
                    setImageActive(true);
                  }}
                /> */}
                <LazyLoadImage
                  key={index}
                  src={data.src}
                  alt={`Product ${index}`}
                  onClick={() => {
                    setSelectedImage(index);
                    setImageActive(true);
                  }}
                  effect="blur"
                  placeholderSrc="placeholder-image.png" // Optional placeholder
                />
              </div>
            ))}
          </div>
          <div className="text-3d" onClick={() => setImageActive(false)}>
            <BoxIcon />
            <p>View 3D</p>
          </div>
        </div>
        <h3 className="product-detail-title">{data.title}</h3>
        <div className="product-data">
          {data.productDetail.options.map((data, index) => (
            <div key={index} className={`product-${data.name.toLowerCase()}`}>
              <h3 className="title">{data.name}</h3>
              <div className={`${data.name.toLowerCase()}-container`}>
                {data.name.toLowerCase() === "color" ? (
                  <div className="product-colors" style={{ display: "flex" }}>
                    {data.values.map((value, valueIndex) => (
                      <div
                        key={valueIndex}
                        className={`color ${
                          selectedOptions[data.name.toLowerCase()] === value
                            ? "selected"
                            : ""
                        }`}
                        style={{ backgroundColor: `${value}` }}
                        onClick={() => updateOption(data.name, value)}
                      ></div>
                    ))}
                  </div>
                ) : (
                  <div className="container-list">
                    {data.values.map((value, valueIndex) => (
                      <button
                        key={valueIndex}
                        className={
                          selectedOptions[data.name.toLowerCase()] === value
                            ? "selected"
                            : ""
                        }
                        onClick={() => updateOption(data.name, value)}
                      >
                        {value}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className="prod-description">
            <p
              dangerouslySetInnerHTML={{ __html: data.productDetail.body_html }}
            />
          </div>
        </div>
        <div className="prod-price">
          <p>£{selectedVariant ? selectedVariant.price : "0.00"}</p>
        </div>
        <div className="prod-button-container">
          <button className="prod-count">
            <span>
              <SubtractIcon
                style={{ cursor: "pointer" }}
                onClick={decrementCounter}
              />
            </span>
            <span className="count">{counterData}</span>
            <span>
              <AddIcon
                style={{ cursor: "pointer" }}
                onClick={incrementCounter}
              />
            </span>
          </button>
          <button className="add-to-bag" onClick={handleAddToBag}>
            Add to Bag
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddtoCart2;

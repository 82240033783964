// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sc-gsTCUz.bhdLno {
  max-height: 350px;
  max-width: 350px;
}

.roulette-container h3 {
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  font-size: 1.41vw;
  line-height: 2.18vw;
  margin-bottom: 1.5vw;
}

.want-to-pay-game-container.spin-game {
  padding: 2vw;
  border-radius: 0.5vw;
}

@media screen and (max-width: 720px) {
  .roulette-container h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .navigation-instruction-dialog .MuiPaper-root.MuiDialog-paper .want-to-pay-game-container {
    padding: 20px 10px;
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/style/components/three/game/SpinWheelGame.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAgBA;EACI,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,oBAAA;AAbJ;;AAeA;EACI,YAAA;EAEA,oBAAA;AAbJ;;AAgBA;EACI;IACI,eAAA;IACA,iBAAA;EAbN;EAeE;IACI,kBAAA;IACA,SAAA;EAbN;AACF","sourcesContent":[".sc-gsTCUz.bhdLno{\n    max-height:350px;\n    max-width: 350px;\n}\n.won {\n    // position: absolute;\n    // width: 100%;\n    // top: 0;\n    // left: 0;\n    // z-index: 99999;\n    // padding: 15px;\n    // box-sizing: border-box;\n    // height: 100%;\n    // display: flex;\n    // align-items: center;\n    // justify-content: center;\n    // background-color: rgba(255,255,255,.6);\n\n}\n.roulette-container h3{\n    font-weight: 700;\n    text-align: center;\n    color: #FFFFFF;\n    font-size: 1.41vw;\n    line-height: 2.18vw;\n    margin-bottom: 1.5vw;\n}\n.want-to-pay-game-container.spin-game{\n    padding: 2vw;\n    // background: radial-gradient(100% 100% at 0% 0%, rgba(53, 38, 38, 0.8) 0%, rgba(38, 26, 26, 0.8) 100%);\n    border-radius: 0.5vw;\n}\n\n@media screen and (max-width: 720px) {\n    .roulette-container h3{\n        font-size: 20px;\n        line-height: 20px;\n    }\n    .navigation-instruction-dialog .MuiPaper-root.MuiDialog-paper .want-to-pay-game-container{\n        padding: 20px 10px;\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

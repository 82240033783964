import React, { useState } from "react";
import useModelStore from "../store";
import ApiConfig from "../../../config/ApiConfig";
import { fetchData } from "../../../service/http.service";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

function Box({ productData, onApply }) {
  const [formValues, setFormValues] = useState([]);
  const [name, changeName] = useState("");
  const [location, setLocation] = useState("location0");
  const [isLoading, setLoading] = useState(false);
  const setModelPosition = useModelStore((state) => state.setModelPosition);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();

  // Handle product selection
  const handleProductChange = (product_uid) => {
    const selectedProduct = productData.find(
      (product) => product.product_uid === parseInt(product_uid)
    );
    setFormValues((prevValues) => {
      const existingProduct = prevValues.find(
        (item) => item.product_uid === product_uid
      );
      if (!existingProduct) {
        return [
          ...prevValues,
          {
            product_uid,
            productPosition: "",
            product_id: selectedProduct.product_id,
          },
        ];
      }
      return prevValues;
    });
    changeName(product_uid);
  };

  // Handle position selection
  const handlePositionChange = (position) => {
    setFormValues((prevValues) =>
      prevValues.map((item) =>
        item.product_uid === selectedProduct
          ? { ...item, productPosition: position }
          : item
      )
    );
    setLocation(position);
  };

  // Handle product selection from dropdown
  const [selectedProduct, setSelectedProduct] = useState("");

  const getPositionFromLocation = (location) => {
    switch (location) {
      case "location0":
        return [1000, 1000, 1000];
      case "location1":
        return [-5.01337813128718, 0.75, -6.020227980977997];
      case "location2":
        return [1.9916866740908432, 1.55, 13.53984951053118];
      case "location3":
        return [-5.953889676150517, 2.3, 21.4005676390457];
      case "location4":
        return [-30.49039677915537, 2, 13.560054539298182];
      case "location5":
        return [-30.321714223481858, 1.55, 0.935591221166235];
      case "location6":
        return [-26.521714223481858, 0.75, -7.335591221166235];
      default:
        return [0, 0, 0];
    }
  };

  const handleSubmit = () => {
    onApply(formValues);
    if (name.length && location !== "location0") {
      setModelPosition(name, getPositionFromLocation(location));
    }
    setLocation("location0");
  };

  async function handleProductMapping(product_id, product_uid, x, y, z) {
    let val = {
      product_id: product_id,
      product_uid: product_uid,
      x_coordinate: x,
      y_coordinate: y,
      z_coordinate: z,
    };
    setLoading(true);
    const response = await fetchData(
      ApiConfig.productMapping,
      setLoading,
      "post",
      val,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    if (response) {
      console.log(response);
    }
  }

  async function handleUUID() {
    const uuid = uuidv4();
    setLoading(true);
    const response = await fetchData(
      ApiConfig.brandTokenUpdate,
      setLoading,
      "post",
      {
        brand_param: uuid,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setLoading(false);
    navigate("/dashboard/add-game");
  }

  const handleData = () => {
    formValues.map((product) => {
      handleProductMapping(
        product.product_id,
        product.product_uid,
        getPositionFromLocation(product.productPosition)[0],
        getPositionFromLocation(product.productPosition)[1],
        getPositionFromLocation(product.productPosition)[2]
      );
    });
    handleUUID();
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        gap: "10px",
        padding: "13px",
        borderRadius: "5px",
        background: "#fff",
      }}
    >
      {/* Product Selection */}
      <select
        value={selectedProduct}
        onChange={(e) => {
          setSelectedProduct(e.target.value);
          handleProductChange(e.target.value);
        }}
      >
        <option value="">--Select Product--</option>

        {productData.map((product) => (
          <option key={product.product_uid} value={product.product_uid}>
            {product.name}
          </option>
        ))}
      </select>

      <select
        value={
          formValues.find((item) => item.product_uid === selectedProduct)
            ?.productPosition || "location0"
        }
        onChange={(e) => handlePositionChange(e.target.value)}
      >
        <option value="location0">--Select Position--</option>
        <option value="location1">Location 1</option>
        <option value="location2">Location 2</option>
        <option value="location3">Location 3</option>
        <option value="location4">Location 4</option>
        <option value="location5">Location 5</option>
        <option value="location6">Location 6</option>
      </select>

      <button
        onClick={handleSubmit}
        style={{
          cursor: "pointer",
          padding: "0px 13px",
          backgroundColor: "#fff",
          borderRadius: "2px",
          borderWidth: "1px",
        }}
      >
        Apply
      </button>
      <button
        onClick={handleData}
        style={{
          color: "unset",
          borderColor: "unset",
          borderRadius: "2px",
          padding: "0px 13px",
          backgroundColor: "#eee",
        }}
      >
        Save
      </button>
    </div>
  );
}

export default Box;

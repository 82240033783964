import lightIcon from "../assests/images/light_error.png";
import "./PopupPage.scss";
import closeIcon from "../assests/images/akar-icons_cross.png";
import { Tooltip } from "@mui/material";

function PopupStore(props) {
  const close = () => {
    props.closePopup(false);
  };


  const launch = () => {
    props.launch(true);
   close();

  }
  return (
    <div className="background popup">
      <div className="textContainer">
        <Tooltip title="Close Dialog">
          <img
            src={closeIcon}
            className="close-dialog-icon"
            onClick={close}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        <img src={lightIcon} alt="errorIcon" />
        <h2>Do you want to overwrite the current store ?</h2>
        <button
          className="button"
          type="button"
          onClick={() => {
           launch();
        }}
        >
         Yes
        </button>
      </div>
    </div>
  );
}

export default PopupStore;



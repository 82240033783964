// import React, { useEffect, useRef, useState } from "react";
// import { useGLTF } from "@react-three/drei";
// import { useFrame } from "@react-three/fiber";
// import { RigidBody } from "@react-three/rapier";
// import useModelStore from "../util/modelStore";
// import { fetchData } from "../../../fetcher/fetchData";
// import ApiConfig from "../../../helper/ApiConfig";
// import { useNavigate } from "react-router-dom";
// import useAuthStore from "../../../store/authStore";

// const ModelProduct = ({
//   url,
//   scale,
//   name,
//   hasToken,
//   productData,
//   cartData,
// }) => {
//   const group = useRef();
//   const { scene } = useGLTF(url);
//   const [isHovered, setIsHovered] = useState(false);
//   //   const [loading, setLoading] = useState(false);

//   const position = useModelStore(
//     (state) => state.models[name] || [1000, 1000, 1000]
//   );
//   //   const token = useSelector((state) => state.token);
//   const { setLoading, token } = useAuthStore();
//   const navigate = useNavigate();
//   //   const { openProductCartWithAudio, setBrandId } = useAudioContainer();

//   useEffect(() => {
//     if (hasToken) {
//       const getEnvDetails = async () => {
//         setLoading(false);
//         const response = await fetchData(
//           `${ApiConfig.env}`,
//           setLoading,
//           "post",
//           {},
//           { authorization: `Bearer ${token}` }
//         );
//         if (response.statusCode === 401 || response.statusCode === 500) {
//           navigate("/login");
//         } else if (response.data.statusCode === 200) {
//           if (response.data.data.env_product_mappings.length) {
//             response.data.data.env_product_mappings.forEach((model) => {
//               if (model.product_uid === name) {
//                 useModelStore.setState((state) => ({
//                   models: {
//                     ...state.models,
//                     [model.product_uid]: [
//                       model.x_coordinate,
//                       model.y_coordinate,
//                       model.z_coordinate,
//                     ],
//                   },
//                 }));
//               }
//             });
//           }
//         }
//         setLoading(true);
//       };
//       getEnvDetails();
//     } else {
//       const getEnvDetails = async () => {
//         setLoading(false);
//         const url = window.location.href;
//         const parts = url.split("/");
//         const data = parts[parts.length - 1];
//         const response = await fetchData(
//           `${ApiConfig.commonEnv}?brandParam=${data}`,
//           setLoading,
//           "get",
//           {},
//           {}
//         );

//         if (response.statusCode === 401 || response.statusCode === 500) {
//           navigate("/login");
//         } else if (response.data.statusCode === 200) {
//         //   setBrandId(response.data.data.brand_id);

//           if (response.data.data.env_product_mappings.length) {
//             response.data.data.env_product_mappings.forEach((model) => {
//               if (model.product_uid === name) {
//                 useModelStore.setState((state) => ({
//                   models: {
//                     ...state.models,
//                     [model.product_uid]: [
//                       model.x_coordinate,
//                       model.y_coordinate,
//                       model.z_coordinate,
//                     ],
//                   },
//                 }));
//               }
//             });
//           }
//         }
//         setLoading(true);
//       };
//       getEnvDetails();
//     }
//   }, []);

//   useFrame(() => {
//     if (group.current) {
//       group.current.position.set(position[0], position[1], position[2]);
//       if (!isHovered) {
//         group.current.rotation.y += 0.01;
//       }
//     }
//   });

//   const handlePointerOver = () => {
//     document.body.style.cursor = "pointer";
//     setIsHovered(true);
//   };

//   const handlePointerOut = () => {
//     document.body.style.cursor = "default";
//     setIsHovered(false);
//   };

//   //   const handleCard = () => {
//   //     if (cartData === "") {
//   //       openProductCartWithAudio(productData.audio_url, productData);
//   //     } else {
//   //       openProductCartWithAudio(productData.audio_url, cartData);
//   //     }
//   //   };

//   return (
//     <>
//       <group
//         ref={group}
//         dispose={null}
//         scale={scale}
//         onPointerOver={handlePointerOver}
//         onPointerOut={handlePointerOut}
//         // onClick={handleCard}
//       >
//         <RigidBody type="fixed">
//           <primitive object={scene} />
//         </RigidBody>
//       </group>
//     </>
//   );
// };

// export default ModelProduct;

import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import React, { useRef, useState, useEffect } from "react";
import useModelStore from "../util/modelStore";
import { useNavigate } from "react-router-dom";
import { useFrame } from "@react-three/fiber";
import useAuthStore from "../../../store/authStore";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import useAudioContainer from "../util/useAudioContainer";

const ModelProduct = ({
  url,
  scale,
  name,
  hasToken,
  productData,
  cartData,
}) => {
  const { scene } = useGLTF(url);
  const group = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const { setLoading, token } = useAuthStore();
  const navigate = useNavigate();
  const { openProductCartWithAudio, setBrandId } = useAudioContainer();
  const position = useModelStore((state) => state.models[name]);

  useEffect(() => {
    if (hasToken) {
      const getEnvDetails = async () => {
        setLoading(false);
        const response = await fetchData(
          `${ApiConfig.env}`,
          setLoading,
          "post",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (response.statusCode === 401 || response.statusCode === 500) {
          navigate("/login");
        } else if (response.data.statusCode === 200) {
          if (response.data.data.env_product_mappings.length) {
            response.data.data.env_product_mappings.forEach((model) => {
              if (model.product_uid === name) {
                useModelStore.setState((state) => ({
                  models: {
                    ...state.models,
                    [model.product_uid]: [
                      model.x_coordinate,
                      model.y_coordinate,
                      model.z_coordinate,
                    ],
                  },
                }));
              }
            });
          }
        }
        setLoading(true);
      };
      getEnvDetails();
    } else {
      const getEnvDetails = async () => {
        setLoading(false);
        const url = window.location.href;
        const parts = url.split("/");
        const data = parts[parts.length - 1];
        const response = await fetchData(
          `${ApiConfig.commonEnv}?brandParam=${data}`,
          setLoading,
          "get",
          {},
          {}
        );

        if (response.statusCode === 401 || response.statusCode === 500) {
          navigate("/login");
        } else if (response.data.statusCode === 200) {
          setBrandId(response.data.data.brand_id);

          if (response.data.data.env_product_mappings.length) {
            response.data.data.env_product_mappings.forEach((model) => {
              if (model.product_uid === name) {
                useModelStore.setState((state) => ({
                  models: {
                    ...state.models,
                    [model.product_uid]: [
                      model.x_coordinate,
                      model.y_coordinate,
                      model.z_coordinate,
                    ],
                  },
                }));
              }
            });
          }
        }
        setLoading(true);
      };
      getEnvDetails();
    }
  }, []);

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer";
    setIsHovered(true);
  };

  const handlePointerOut = () => {
    document.body.style.cursor = "default";
    setIsHovered(false);
  };

  const handleCard = () => {
    if (cartData === "") {
      openProductCartWithAudio(productData.audio_url, productData);
    } else {
      openProductCartWithAudio(productData.audio_url, cartData);
    }
  };

  useFrame(() => {
    if (group.current) {
      if (position !== undefined) {
        group.current.position.set(position[0], position[1], position[2]);
        if (!isHovered) {
          group.current.rotation.y += 0.01;
        }
      } else {
        group.current.position.set(1000, 1000, 1000);
      }
    }
  });

  return (
    <group
      ref={group}
      dispose={null}
      scale={scale}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      onClick={handleCard}
    >
      <RigidBody type="fixed">
        <primitive object={scene} />
      </RigidBody>
    </group>
  );
};

export default ModelProduct;

import { LOGIN, LOGOUT, UPDATE_USER } from './actionTypes';

const initialState = {
  isAuthenticated: false,
  token:null,
  user: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        token:action.token
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token:null
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default sessionReducer;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../fetcher/fetchData";
import ApiConfig from "../../helper/ApiConfig";
import useAuthStore from "../../store/authStore";
import CongratulationsBgImage from "../../assets/images/rectangle47.png";
import { ReactComponent as ViewLiveIcon } from "../../assets/svg/Maximize.svg";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow.svg";
import "../../style/components/dashboard/Congratulation.scss";
import "../../style/components/Card.scss";
import Spinner from "../../helper/Spinner";

export default function Congratulations() {
  const [uuid, setUuid] = useState();
  // const [imageURL,setImageURL] = useState(null);
  // const [paymentDate,setPaymentDate] = useState(null);
  const [retainTrai, setRetainTrai] = useState(null);
  const [nextPaymentDate, setNextpaymentDate] = useState(null);
  const navigate = useNavigate();
  const { user, token } = useAuthStore();
  const [loading, setLoading ] = useState(false);
  const company = user.company;
  const first_name = user.first_name;

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response?.data.statusCode === 200) {
        setUuid(response?.data.data.brand_param);
        // setImageURL(response?.data.data.env_template_master.env_thumbnail_url);
        // setPaymentDate(response?.data.data.payment_date);
        setNextpaymentDate(response?.data.data.next_payment_date);
        setRetainTrai(response?.data.data.retain_trail);
      }
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  function navigateEnv() {
    if (uuid !== null) {
      window.open(`/${company}/playAround/${uuid}`, "_blank");
    } else {
      navigate(`/dashboard`);
    }
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();
  const differenceInMilliseconds = new Date(nextPaymentDate) - new Date(currentDate);
  return (
    <>
      <div className="congratulations-heading">
        <div className="congrats-heading">
          <h2>Congratulations!</h2>
          <p>Your Virtual Reality is ready now.</p>
        </div>
        <div className="congratulations-container">
          <div className="image-wrapper">
            {loading ? 
            <>
            {loading &&
              (differenceInMilliseconds / (1000 * 60 * 60 * 24) >= 0 ? (
                <p className="demo-text">
                  Demo Ends in :{" "}
                  {differenceInMilliseconds / (1000 * 60 * 60 * 24)} Days
                </p>
              ) : retainTrai ? (
                <div>
                  <p className="demo-text">
                    Hi {first_name} <br></br>Congratulation! Your demo period
                    has been extended. <br></br>If you require further
                    assistance, please get in touch with support@onewayx.com.
                  </p>
                  <p className="demo-text">ONEWAYX Team </p>
                </div>
              ) : (
                <div>
                  <p className="demo-text">
                    Hi {first_name} <br></br>Your demo period with ONEWAYX has
                    expired. <br></br>Please get in touch with
                    support©onewayx.com to reactivate your account.{" "}
                  </p>
                  <p className="demo-text">ONEWAYX Team </p>
                </div>
              ))}
            {
              <img
                src={CongratulationsBgImage}
                width="100%"
                alt="coming soon"
              />
            }          


            <div className="btn-container">
              <button
                className="view-live"
                type="button"
                style={{ cursor: "pointer" }}
                disabled={!loading}
                onClick={() => navigateEnv()}
              >
                {loading ? "View Live Environment" : "loading"}{" "}
                <span>
                  <ViewLiveIcon />
                </span>
              </button>
              <button
                className="go-live"
                style={{ cursor: "pointer", display: "none" }}
              >
                Go Live{" "}
                <span>
                  <ArrowIcon />
                </span>
              </button>
            </div>

            </>
            :
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh'}}>
              <Spinner/>
            </div>
          }
          </div>
        </div>
      </div>
    </>
  );
}
import React, { useState, useEffect } from "react";
import ApiConfig from "../../config/ApiConfig";
import { fetchData } from "../../service/http.service";
import { ReactComponent as CloseIcon } from "../../assests/svg/bitcoin-icons_cross-filled.svg";
import { ReactComponent as AddIcon } from "../../assests/svg/add.svg";
import { ReactComponent as SubtractIcon } from "../../assests/svg/minus.svg";
import { ReactComponent as RemoveIcon } from "../../assests/svg/remove-icon.svg";
import Spinner from "../Spinner";
import { logEvent } from '../../components/analytic/Analytic';
import { useNavigate } from "react-router-dom";

function SideBar(props) {
  const [cartProduct, setCartProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [cartData, setCardData] = useState([]);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  let productCounter = [];
  let subtotal = 0;

  useEffect(() => {
    const cartData = {
      checkoutId: props.varID,
      brandId: props.brand,
    };
    if (cartData.checkoutId.length > 0) {
      async function handleCartData() {
        setLoading(true);
        const response = await fetchData(
          ApiConfig.cart,
          setLoading,
          "post",
          cartData,
          {}
        );

        if (response.status === 200) {
          setCartProducts(response.data.data.cartItems);
          setCardData(response.data.data.cartData);
        }
        else if (response.status === 401 ||response.status === 500) {
          navigate("/login");
        }
        setLoading(false);
      }
      handleCartData();
    }
  }, [props.productCounter, counter]); 

  async function removeProduct(data) {
    const cartData = {
      checkoutId: props.varID,
      nodeIds: data,
      brandId: props.brand,
    };
    setLoading(true);
    const response = await fetchData(
      ApiConfig.removeItem,
      setLoading,
      "post",
      cartData,
      {}
    );
    if (response.status === 200) {
      setCounter(counter + 1);
    }
    setLoading(false);
  }

  async function updateQuantity(data, val, node) {
    const cartData = {
      checkoutId: props.varID,
      nodeId: node,
      quantity: data + val,
      brandId: props.brand,
    };
    setLoading(true);
    const response = await fetchData(
      ApiConfig.updateQuantity,
      setLoading,
      "post",
      cartData,
      {}
    );
    if (response.status === 200) {
      setCounter(counter + 1);
    }
    setLoading(false);
  }


  function handleCheckout(data){
    if(cartProduct.length > 0){
      cartProduct.map((product, index) => {
        logEvent(`products_checkout`, {
            product_name_checkout: product.title,
            product_uid_checkout: product.variant.id,
            product_count_checkout: product.quantity,
        });
      })
    }    
    if (data.length > 0) {
      window.location.href = data;
    } else {
      alert("Please Add product to the cart");
    }  
  }

  return (
    <>
      <div className={`cart-detail-container`}>
        <div className="close">
          <div className="close-icon">
            {" "}
            <CloseIcon onClick={() => props.setOpenCart(false)} />
          </div>
        </div>
        <div className="cart-detail">
          <div className="cart-detail-title">
            <h3>Your Cart</h3>
          </div>
          {isLoading && <div style={{minHeight:"100px", display:"flex", alignItems: "center", justifyContent: "center"}}><Spinner/></div>}
          {
            !isLoading &&
          <div className="cart-detail-wrapper">
            {productCounter}
            {cartProduct.length > 0 &&
              cartProduct.map((product, index) => {
                subtotal += product.variant.priceV2.amount * product.quantity;
                return (
                  <div key={index} className={`prod-wrapper product-${index}`}>
                    <div className="prod-det-wrapper">
                      <div className="prod-img">
                        <img
                          src={product.variant.product.images[0].originalSrc}
                          alt="product_image"
                        />
                      </div>
                      <div className="prod-quantity-wrapper">
                        <p className="prod-title">{product.title}</p>
                        <div className="quantity">
                          <button className="prod-count">
                            <span className="subtract">
                              <SubtractIcon
                                onClick={() =>
                                  updateQuantity(
                                    product.quantity,
                                    -1,
                                    cartData.lineItems.edges[index].node.id
                                  )
                                }
                              />
                            </span>{" "}
                            <span className="count">{product.quantity}</span>
                            <span className="add">
                              <AddIcon
                                onClick={() =>
                                  updateQuantity(
                                    product.quantity,
                                    1,
                                    cartData.lineItems.edges[index].node.id
                                  )
                                }
                              />
                            </span>
                          </button>

                          <div className="delete">
                            <RemoveIcon
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                removeProduct(
                                  cartData.lineItems.edges[index].node.id
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="prod-price">
                      <p className="amount">
                      £{" "}
                        {(
                          product.variant.priceV2.amount * product.quantity
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                )
              })}
          </div>
}
          <hr/>
         
          <div className="subtotal">
            <p>Subtotal</p>
            <p className="total-amt">£{subtotal.toFixed(2)}</p>
          </div>
          <div className="checkout-btn">
            <button onClick={() => handleCheckout(props.checkoutURL)}>Checkout</button>
            <p>By pressing Checkout, you agree to our Store Policy.</p>
          </div>
        </div>
      </div>

    </>
  );
}

export default SideBar;

import React, { Suspense, useEffect, useRef } from "react";
import { useGLTF, useTexture, useVideoTexture } from "@react-three/drei";
import { Color,  SRGBColorSpace } from "three";
import { RigidBody } from "@react-three/rapier";
import useStore from "../util/3dstore";
import buildingModel from "../../../assets/three/gabar/models/building_product.glb"
import metaverse_1_TopImg from "../../../assets/three/gabar/videos/metaverse_1_Bottom.jpg"
import metaverse_1_TopVideo from "../../../assets/three/gabar/videos/Metaverse_1_Bottom_Optimised_1.mp4"
import metaverse_2_TopImg from "../../../assets/three/gabar/videos/metaverse_2_Top.jpg"
import metaverse_2_TopVideo from "../../../assets/three/gabar/videos/Metaverse_2 _Top_Optimised_1.mp4"
import useVideoStore from "../util/video";

export function Building_Products(props) {
  const {videoFileData} = useVideoStore();
  const newColor = new Color("#AAAAAB");
  const { nodes, materials } = useGLTF(buildingModel);

  const {
    setHitPoint,
    setDoubleClick,
    isCameraPanning,
    setLcdCapsuleHit,
  } = useStore();


 

  const actionMap = {      
    main_wall: {
      doubleClick: true,
    },
    lcd_capsule: {
      doubleClick: true,
      lcdCapsuleHit: true,
    },
    Lightbox_Long: {
      doubleClick: true,
    },
    Lightbox_Long001: {
      doubleClick: true,
    },
    Lightbox_Long002: {
      doubleClick: true,
    },
    Lightbox_Long003: {
      doubleClick: true,
    },
    Lightbox_Long004: {
      doubleClick: true,
    },
    Lightbox_Long005: {
      doubleClick: true,
    },
  };


  const animateCamera = (e) => {
    e.stopPropagation();
    const action = actionMap[e.object.name];
    if (action) {
      setHitPoint(e.point);
      console.log(e.object.name);
      if (!isCameraPanning) {
        if (action.customAction) action.customAction();
      
        if (action.doubleClick) setDoubleClick(true);

        if (action.lcdCapsuleHit) setLcdCapsuleHit(true);
      }
    }
  };

  return (
    <>
      <RigidBody type="fixed" colliders="trimesh">
        <group {...props} dispose={null}>
          <mesh
            geometry={nodes.LCD_Capsule_Holder.geometry}
            material={materials.LCD_Holder_Material}
            position={[-15.122, 14.528, -9.585]}
          />

          
          <group
            position={[-15.039, 2.351, -4.902]}
            name="lcd_capsule"
            onClick={(e) => animateCamera(e)}
            onDoubleClick={(e) => animateCamera(e)}
          >
            <mesh
              geometry={nodes.Mesh011.geometry}
              material={materials.LCD_Capsule_Material}
            />
            <mesh
              geometry={nodes.Mesh011_1.geometry}
              material={materials.LCD_Capsule_Belt_Material}
            />
            <mesh
              geometry={nodes.Mesh011_2.geometry}
              material={materials.LCD_FrontTop_Material}
              position={[0, 1.58, 0]}
              scale={[1, 0.75, 1]}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url={metaverse_2_TopImg} />
                }
              >
                <VideoMaterial
                  url={ videoFileData || "https://conceptstorebucket.s3.us-east-1.amazonaws.com/app.onewayx.com/admin/gabaar-environment/Metaverse_1_Bottom_Optimised_1.mp4"
                    // videoFileData || metaverse_2_TopVideo
                  }
                />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_3.geometry}
              material={materials.LCD_FrontTop_Material}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url={metaverse_1_TopImg} />
                }
              >
                <VideoMaterial
                  url={videoFileData || "https://conceptstorebucket.s3.us-east-1.amazonaws.com/app.onewayx.com/admin/gabaar-environment/Metaverse_1_Bottom_Optimised_1.mp4"
                    // videoFileData || metaverse_1_TopVideo
                  }
                />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_4.geometry}
              material={materials.LCD_FrontTop_Material}
              position={[0, 1.58, 0]}
              scale={[1, 0.75, 1]}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url={metaverse_2_TopImg} />
                }
              >
                <VideoMaterial
                  url={
                    videoFileData || "https://conceptstorebucket.s3.us-east-1.amazonaws.com/app.onewayx.com/admin/gabaar-environment/Metaverse_1_Bottom_Optimised_1.mp4"
                    // videoFileData || metaverse_2_TopVideo
                  }
                />
              </Suspense>
            </mesh>
            <mesh
              geometry={nodes.Mesh011_5.geometry}
              material={materials.LCD_FrontTop_Material}
            >
              <Suspense
                fallback={
                  <FallbackMaterial url={metaverse_1_TopImg} />
                }
              >
                {videoFileData !== null ? (
                  <VideoMaterial
                    url={
                      videoFileData || "https://conceptstorebucket.s3.us-east-1.amazonaws.com/app.onewayx.com/admin/gabaar-environment/Metaverse_1_Bottom_Optimised_1.mp4"
                      // videoFileData || metaverse_1_TopVideo
                    }
                  />
                ) : (
                  // <VideoMaterial url={metaverse_1_TopVideo} />
                  <VideoMaterial url={"https://conceptstorebucket.s3.us-east-1.amazonaws.com/app.onewayx.com/admin/gabaar-environment/Metaverse_1_Bottom_Optimised_1.mp4"} />
                )}
              </Suspense>
            </mesh>
          </group>
          {/* CHANGES -> The building and floor were separated into 2 meshes, Should be joined */}
          <mesh
            name="main_wall"
            onDoubleClick={(e) => animateCamera(e)}
            geometry={nodes.Floor.geometry}
            material={materials.Metallic_Silver_Material}
            position={[-14.04, -0.001, -4.603]}
            material-roughness={0.35}
            material-metalness={0.75}
            material-envMapIntensity={1.5}
            material-color={newColor}
          />

          <group>
            <mesh
              name="Lightbox_Long"
              geometry={nodes.Lightbox_Long.geometry}
              materials={materials.Lighbox_Material}
              position={[-1.471, 0, 7.527]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long001"
              geometry={nodes.Lightbox_Long001.geometry}
              materials={materials.Lighbox_Material}
              position={[-31.52, 0, -10.701]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long002"
              geometry={nodes.Lightbox_Long002.geometry}
              materials={materials.Lighbox_Material}
              position={[-24.197, 0, -19.356]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long003"
              geometry={nodes.Lightbox_Long003.geometry}
              materials={materials.Lighbox_Material}
              position={[0.765, 0, -13.701]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long004"
              geometry={nodes.Lightbox_Long004.geometry}
              materials={materials.Lighbox_Material}
              position={[1.692, 0, -1.246]}
              onDoubleClick={(e) => animateCamera(e)}
            />
            <mesh
              name="Lightbox_Long005"
              geometry={nodes.Lightbox_Long005.geometry}
              materials={materials.Lighbox_Material}
              position={[-22.757, 0, 8.039]}
              onDoubleClick={(e) => animateCamera(e)}
            />
          </group>
        </group>
      </RigidBody>
      <group dispose={null}>
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[1.692, 0.532, -1.246]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[0.765, 0.532, -13.701]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-24.197, 0.54, -19.356]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-31.52, 0.54, -10.701]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-1.471, 0.54, 7.527]}
        />
        <pointLight
          intensity={100}
          decay={30}
          color="#8ea992"
          position={[-22.757, 0.54, 8.039]}
        />
      </group>

      <mesh
        geometry={nodes.Walkway_Path.geometry}
        material={materials.Walkway_Material}
        position={[-14.763, 0.288, -4.851]}
        material-roughness={0.1}
        material-metalness={1}
        material-envMapIntensity={1}
      />
    </>
  );
}

function VideoMaterial({ url }) {
  const texture = useVideoTexture(url);
  texture.flipY = false;
  texture.colorSpace = SRGBColorSpace;
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url);
  texture.flipY = false;
  texture.colorSpace = SRGBColorSpace;
  return <meshBasicMaterial map={texture} toneMapped={false} />;
}

useGLTF.preload(buildingModel);
import { createContext, useState } from "react";

export const HeaderContext = createContext({ currentStep: '', openDialog: '', loading: '', setLoading: () => { },setCurrentStep:()=>{},openForgotPasswordDialog:'',setOpenForgotPasswordDialog:() =>{ }, openEmailNotFoundDialog: '', setOpenEmailNotFoundDialog:()=>{},toggleLearnMorePopup: () => { }, handleCloseDialog: () => { } });
export default function HeaderContextProvider({ children }) {
    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [openEmailNotFoundDialog, setOpenEmailNotFoundDialog] = useState(false);

    const headerContextValue = {
        currentStep: currentStep,
        openDialog: false,
        loading: loading,
        setLoading: setLoading,
        setCurrentStep:setCurrentStep,
        openForgotPasswordDialog: openForgotPasswordDialog,
        setOpenForgotPasswordDialog: setOpenForgotPasswordDialog,
        openEmailNotFoundDialog:openEmailNotFoundDialog,
        setOpenEmailNotFoundDialog: setOpenEmailNotFoundDialog
    }
    return <HeaderContext.Provider value={headerContextValue}>
        {children}
    </HeaderContext.Provider>
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quiz-container .Mui-checked svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium, .quiz-container .MuiFormControlLabel-root:has(.Mui-checked) span {
  color: #B01F8D !important;
}`, "",{"version":3,"sources":["webpack://./src/style/components/game/QuizGame.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR","sourcesContent":[".quiz-container{\n    .Mui-checked svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium, .MuiFormControlLabel-root:has(.Mui-checked) span {\n        color:#B01F8D!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

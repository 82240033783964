import React, { useEffect, useState } from "react";
import "./CustomProduct.scss";
import Spinner from "../components/Spinner";
import CustomThreeScene3 from "../components/addProducts/CustomThreeScene3";

const CustomizeEnv3 = () => {
  const [loading2, setLoading2] = useState(true);
  const [envData, setEnvData] = useState({});

  useEffect(() => {
    let envlist = window.location.href.split("/");
    let currentEnv = envlist[envlist.length - 1];
    let env = "";
    switch (currentEnv) {
      case "1":
        env = "https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/metaverse_office.glb";
        break;
      case "2":
        env = "https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/vr_metaverse_spaceship_interior_03.glb";
        break;
      default:
        env = "https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/vr_gallery__museum__baked.glb";
    }
          setEnvData({
            logo: null,
            campaign: null,
            campaignType:null,
            light: "Warm",
            color: "",
            texture: "default",
            backgroundEnv:"https://conceptstorebucket.s3.amazonaws.com/appzlogic/sandbox-environment/thumbnail/Futuristic+Environment+-+Thumbnail+Image.png",
            env: env,
            templateNumber: currentEnv,
            sameEnv: false,
            productData: [],
            hide : true,
          });

      setLoading2(false);

  }, []);



  return (
    <div className="customProduct">
      {!loading2  ? (
        <CustomThreeScene3 envData={envData} />           
      ) : (
        <div
          style={{
            width: "100",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CustomizeEnv3;

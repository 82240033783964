import React, { useContext, useState, useEffect } from "react";
import Logo from "../assests/images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../assests/svg/play-icon.svg";
import { ReactComponent as DownArrowIcon } from "../assests/svg/down-arrow.svg";
import Avatar from "react-avatar";
import { useLocation } from "react-router-dom";
import { HeaderContext } from "../store/Header-context";
import { Tooltip } from "@mui/material";
import VideoPopup from "./VideoPopup";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../store/actionTypes";
import { fetchData } from "../service/http.service";
import ApiConfig from "../config/ApiConfig";

function Header({ variation }) {
  const [playVideoPopup, setVideoPopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const headerContext = useContext(HeaderContext);
  const { setLoading } = useContext(HeaderContext);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const location = useLocation();
  const dispatch = useDispatch();
  async function handleLogout() {
    setLoading(true);
    const res = await fetchData(
      ApiConfig.logout,
      setLoading,
      "get",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (res.status === 401 ||res.status === 500) {
      navigate("/login");
    }
    if (res) {
      dispatch({ type: LOGOUT });
    }
    setLoading(false);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (showDropdown && !event.target.closest(".dropdown-menu")) {
        setShowDropdown(false);
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [showDropdown]);

  let dynamicHeader = null;
  const handlePopup = (data) => {
    setVideoPopup(data);
  };
  if (variation == 1) {
    dynamicHeader = (
      <li className="flex-list-item">
        <Link to="/login">
          <button className="button">Login</button>
        </Link>
      </li>
    );
  }
  if (variation == 2) {
    dynamicHeader = (
      <li className="flex-list-item">
        <button
          className="play-icon-button"
          onClick={() => {
            setVideoPopup(!playVideoPopup);
          }}
        >
          <PlayIcon></PlayIcon>
        </button>
      </li>
    );
  }
  if (variation == 3) {
    dynamicHeader = (
      <div className="header-content">

        <ul className="nav-list">
          <li>Help</li>
          <li className="user-image" style={{cursor:'pointer'}}>
            <Avatar
              name={`${user?.first_name} ${user?.last_name}`}
              size={31}
              round="0.17316vw"
              onClick={(event) => {
                event.stopPropagation(); 
                setShowDropdown((prev) => !prev);
              }} 
            />
            <span
              className="dropdown-menu"
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
            >
              <a href="#">
                <DownArrowIcon />
              </a>
              <ul className={showDropdown ? "show" : "dropdown"}>
                <li>
                  <p>
                    {user?.first_name} {user?.last_name}
                    <br />
                    <Tooltip title={user?.brand_email} placement="bottom" arrow>
                      <span className="user-email">{user?.brand_email}</span>
                    </Tooltip>
                  </p>
                </li>

                <li>
                  <a href="/dashboard/company-profile">Company Profile</a>
                </li>
                <li onClick={handleLogout}>
                  <a href="#">Logout</a>
                </li>
              </ul>
            </span>
          </li>
          {/* {dynamicHeader} */}
        </ul>
      </div>
    );
    // dynamicHeader = <li className="flex-list-item">
    //   <button className="play-icon-button" onClick={() => { setVideoPopup(!playVideoPopup) }}><PlayIcon></PlayIcon></button>
    // </li>
  }
  if (variation == 4) {
    dynamicHeader = (
      <li className="flex-list-item">
        <Link to="/play-around">
          <button className="button">Play Around</button>
        </Link>
      </li>
    );
  }
  if (variation == 5) {
    dynamicHeader = (
      <div className="header-content">
        {/* <Tooltip title="Back to Website">
              <Link to="https://www.onewayx.com/" target="_blank">
                <img src={Logo} alt="logo" />
              </Link>
            </Tooltip> */}
        <ul className="nav-list">
          <li>Help</li>
          <li className="user-image" onClick={() => {
                setShowDropdown(!showDropdown);
              }}>
            {/* <img src=""  /> */}
            <Avatar
              name={`${user?.first_name} ${user?.last_name}`}
              size={31}
              round="0.17316vw"
            />
            <span
              className="dropdown-menu"
              // onClick={() => {
              //   setShowDropdown(!showDropdown);
              // }}
            >
              <a href="#">
                <DownArrowIcon />
              </a>
              <ul className={showDropdown ? "show" : "dropdown"}>
                <li>
                  <p>
                    {user?.first_name} {user?.last_name}
                    <br />
                    <Tooltip title={user?.brand_email} placement="bottom" arrow>
                      <span className="user-email">{user?.brand_email}</span>
                    </Tooltip>
                  </p>
                </li>

                <li>
                  <a href="/dashboard/company-profile">Company Profile</a>
                </li>
                <li onClick={handleLogout}>
                  <a href="#">Logout</a>
                </li>
              </ul>
            </span>
          </li>
          {/* {dynamicHeader} */}
        </ul>
      </div>
    );
    // dynamicHeader = <li className="flex-list-item">
    //   <button className="play-icon-button" onClick={() => { setVideoPopup(!playVideoPopup) }}><PlayIcon></PlayIcon></button>
    // </li>
  }


  if (variation == 6) {
    dynamicHeader = (
      <div className="header-content">
        {/* <Tooltip title="Back to Website">
              <Link to="https://www.onewayx.com/" target="_blank">
                <img src={Logo} alt="logo" />
              </Link>
            </Tooltip> */}
      </div>
    );
  }


  
  return (
    <>
      <header
        className={
          location.pathname === "/play-around" ||
          location.pathname === "/play-around2" ||
          variation === 2  || variation === 5 
            ? "header-absolute"
            : "header"
        }
      >
        <nav>
          <ul>
            <li>
              <Tooltip title="Back to Website">
                <Link
                  to="https://www.onewayx.com/"
                  target="_blank"
                  style={{ display: "flex" }}
                >
                  <img src={Logo} alt="logo" />
                </Link>
              </Tooltip>
            </li>
            {dynamicHeader}
          </ul>
        </nav>
      </header>
      {playVideoPopup && (
        <VideoPopup
          open={playVideoPopup}
          videoUrl={
            "https://conceptstorebucket.s3.amazonaws.com/appzlogic/Platform-Guide.mp4"
          }
          closePopup={handlePopup}
        />
      )}
    </>
  );
}

export default Header;

// import React, { useEffect, useState } from "react";
// import "./CustomProduct.scss";
// import Spinner from "../components/Spinner";
// import CustomThreeScene2 from "../components/addProducts/CustomThreeScene2";
// import backgroundENV2 from "../assests/images/Advanced_no_style_equirectangular-png_VR360_Sephora_inspired_visuals_1472281687_11695046.png";
// import backgroundENV3 from "../assests/images/M3_Playground_equirectangular-png_A_Wellbeing_Hub_large_2113797410_11817382.png";
// import backgroundENV4 from "../assests/images/M3_UHD_Render_equirectangular-png_An_Aesop_Store_bathed_1361786032_11789358.png";

// const CustomizeEnv2 = () => {
//   const [loading2, setLoading2] = useState(true);
//   const [envData, setEnvData] = useState({});

//   useEffect(() => {
//     let envlist = window.location.href.split("/");
//     let currentEnv = envlist[envlist.length - 1];
//     let bg_env = "";
//     switch (currentEnv) {
//       case "1":
//         bg_env =
//           "https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/3d_image_5.png";
//         break;
//       case "2":
//         bg_env = "https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/env3.png";
//         break;
//       case "3":
//         bg_env = backgroundENV2;
//         break;
//       case "4":
//         bg_env = backgroundENV3;
//         break;
//       case "5":
//         bg_env = backgroundENV4;
//         break;
//       default:
//         bg_env =
//           "https://conceptstorebucket.s3.amazonaws.com/appzlogic/sandbox-environment/thumbnail/Futuristic+Environment+-+Thumbnail+Image.png";
//     }

//     setEnvData({
//       logo: null,
//       campaign: null,
//       campaignType: null,
//       light: "Warm",
//       color: "",
//       texture: "default",
//       backgroundEnv: bg_env,
//       env: "https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/metaverse_office.glb",
//       templateNumber: "400",
//       sameEnv: false,
//       productData: [],
//       hide: true,
//     });

//     setLoading2(false);
//   }, []);

//   return (
//     <div className="customProduct">
//       {!loading2 ? (
//         <CustomThreeScene2 envData={envData} />
//       ) : (
//         <div
//           style={{
//             width: "100",
//             height: "100vh",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Spinner />
//         </div>
//       )}
//     </div>
//   );
// };

// export default CustomizeEnv2;


import React from 'react'
import CustomizeForm from '../gabar/CustomizeForm'

export default function CustomizeEnv2() {
  return (
    <>
    <CustomizeForm/>
    </>
  )
}

import React, { useEffect, useState } from "react";
import "./gameinstructions-styles.css";
import CloseIcon from "@mui/icons-material/Close";
// import FormOverlay from "./Backdrop";
import useGameStore from "../utils/gameStore";
import useStore from "../../../utils2/3dstore";

export default function GameInstructionsPopup_2() {
  const { hitPoint } = useStore();
  const {
    showGameInstructionsPopup,
    handleShowGameInstructionsPopup,
    setGameClick,
    setUserGameRegistered,
  } = useGameStore();

  return (
    <>
      {/* {!isFormOverlay && <FormOverlay />} */}
      {showGameInstructionsPopup ? (
        <>
          {/* <FormOverlay /> */}
          <div className="parent-form-test_1  no-text-select">
            <div className="sub-parent-form_1">
              <div
                className="form-close-icon"
                onClick={() => {
                  if (hitPoint) {
                    console.log("MOVED");
                    setGameClick(true);
                  }

                  setUserGameRegistered(true);
                  handleShowGameInstructionsPopup(false);
                }}
              >
                <CloseIcon style={{ fontSize: 20, color: "#fff" }} />
              </div>
              <div className="form-item-container_1">
                <div className="heading-container_1">
                  <span
                    className="heading-test-form_1"
                    // style={{ fontWeight: "600" }}
                  >
                    Game Rules
                  </span>
                </div>
                <div
                  className="heading-container_1"
                  style={{ marginTop: "15px" }}
                >
                  <span className="text-test-form_1">
                    Click on the 3 spotlights around the metaverse store to
                  </span>
                  <span className="text-test-form_1">answer the questions</span>
                </div>
                <div className="listing-container">
                  <div className="listing-sub-container">
                    <ul
                      style={{ listStyleType: "disc" }}
                      className="text-test-form_1"
                    >
                      <li className="list-items">
                        WIN 40% OFF - 3 correct answers
                      </li>
                      <li className="list-items">
                        WIN 25% OFF - 2 correct answers
                      </li>
                      {/* <li className="list-items">
                        PLAY AGAIN IN ONE HOUR - 1 or less correct answer
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}


import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import React, { useEffect, useMemo, useRef } from "react";
import { Box3, Vector3 } from "three";

const RotatingModel = ({ modelPath, controlsRef }) => {
  const { scene } = useGLTF(modelPath);
  const clonedScene = useMemo(() => clone(scene), [scene]);

  useEffect(() => {
    const boundingBox = new Box3().setFromObject(clonedScene);
    const center = new Vector3();
    boundingBox.getCenter(center);

    clonedScene.position.set(-center.x, -center.y, -center.z);

    if (controlsRef.current) {
      controlsRef.current.target.set(0, 0, 0);
      controlsRef.current.update();
    }
  }, [clonedScene, controlsRef]);

  return <primitive object={clonedScene} scale={[1.5,1.5,1.5]}/>;
};

const ModelViewer = ({ path }) => {
  const controlsRef = useRef();

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.target.set(0, 0, 0);
      controlsRef.current.update();
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "275px" }}>
      <Canvas style={{ width: "100%", height: "100%" }} camera={{ position: [0, 2, 5] }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} intensity={1} />
        <RotatingModel modelPath={path} controlsRef={controlsRef} />
        <OrbitControls ref={controlsRef} minDistance={0.3} maxDistance={10} />
      </Canvas>
    </div>
  );
};

export default ModelViewer;





















// import { Canvas } from "@react-three/fiber";
// import { Center, OrbitControls, Plane, useGLTF } from "@react-three/drei";
// import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
// import React, { useEffect, useRef } from "react";
// import { Physics } from "@react-three/rapier";
// import { Box3, Vector3 } from "three";

// const RotatingModel = ({ modelPath,  controlsRef }) => {
//   const { scene } = useGLTF(modelPath);
//   const clonedScene = clone(scene);

//   useEffect(() => {
//     const boundingBox = new Box3().setFromObject(clonedScene);
//     const center = new Vector3();
//     const size = new Vector3();
//     boundingBox.getCenter(center);
//     boundingBox.getSize(size);
//     //  const offsetY = size.y * 0.25;

//     // clonedScene.position.set(-center.x, -center.y + size.y / 2, -center.z);
//     clonedScene.position.set(-center.x, -center.y , -center.z);
//     if (controlsRef.current) {
//         controlsRef.current.target.set(0, 0, 0); // Adjust the target if needed
//         controlsRef.current.update();
//       }
//     }, [clonedScene, controlsRef]);

//   return (
//     <primitive object={clonedScene} />   
//   );
// };

// // function SemiTransparentFloor() {
// //   return (
// //     <Plane args={[10, 10]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
// //       <meshStandardMaterial color="gray" transparent opacity={0.5} />
// //     </Plane>
// //   );
// // }

// const ModelViewer = ({ path }) => {
//     const controlsRef = useRef();
//   return (
//     <div style={{ width: "100%", height: "275px" }}>
//       <Canvas style={{ width: "100%", height: "100%" }} camera={{ position: [0, 2, 5]}}>
//         <ambientLight intensity={0.5} />
//         <directionalLight position={[5, 5, 5]} intensity={1} />
//         <Physics>
//           <RotatingModel modelPath={path} controlsRef={controlsRef}/>
//           {/* <SemiTransparentFloor /> */}
//         </Physics>

//         <OrbitControls ref={controlsRef} minDistance={0.3} maxDistance={10} />
//       </Canvas>
//     </div>
//   );
// };

// export default ModelViewer;

// idhrrrrr

// import { Canvas, useFrame, useThree } from "@react-three/fiber";
// import { OrbitControls, Plane, useGLTF } from "@react-three/drei";
// import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
// import React, { useEffect, useRef } from "react";
// import { Physics } from "@react-three/rapier";
// import { Box3, Raycaster, Vector2, Vector3 } from "three";

// const RotatingModel = ({ modelPath }) => {
//   const { scene } = useGLTF(modelPath);
//   const clonedScene = clone(scene);

//   useEffect(() => {
//     const boundingBox = new Box3().setFromObject(clonedScene);
//     const center = new Vector3();
//     const size = new Vector3();
//     boundingBox.getCenter(center);
//     boundingBox.getSize(size);

//     clonedScene.position.set(-center.x, -center.y, -center.z);
//   }, [clonedScene]);

//   return <primitive object={clonedScene} scale={[1.5,1.5,1.5]} />;
// };

// // function SemiTransparentFloor() {
// //   return (
// //     <Plane args={[10, 10]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
// //       <meshStandardMaterial color="gray" transparent opacity={0.5} />
// //     </Plane>
// //   );
// // }

// const CursorFocusControls = ({ controlsRef, modelRef }) => {
//   const { camera, gl } = useThree();
//   const raycaster = useRef(new Raycaster());
//   const mouse = useRef(new Vector2());

//   const handleMouseMove = (event) => {
//     // Convert mouse position to normalized device coordinates (-1 to +1)
//     mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
//     mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;

//     // Perform raycasting
//     raycaster.current.setFromCamera(mouse.current, camera);
//     if (modelRef.current) {
//       const intersects = raycaster.current.intersectObject(modelRef.current, true);
//       if (intersects.length > 0) {
//         // Update OrbitControls target to the intersected point
//         const targetPoint = intersects[0].point;
//         controlsRef.current.target.copy(targetPoint);
//         controlsRef.current.update();
//       }
//     }
//   };

//   useEffect(() => {
//     gl.domElement.addEventListener("mousemove", handleMouseMove);
//     return () => gl.domElement.removeEventListener("mousemove", handleMouseMove);
//   }, [gl]);

//   return null;
// };

// const ModelViewer = ({ path }) => {
//   const controlsRef = useRef(); // Reference for OrbitControls
//   const modelRef = useRef(); // Reference for the GLB model

//   return (
//     <div style={{ width: "100%", height: "275px" }}>
//       <Canvas style={{ width: "100%", height: "100%" }} camera={{ position: [0, 2, 5] }}>
//         <ambientLight intensity={0.5} />
//         <directionalLight position={[5, 5, 5]} intensity={1} />
//         <Physics>
//           <group ref={modelRef}>
//             <RotatingModel modelPath={path} />
//           </group>
//           {/* <SemiTransparentFloor /> */}
//         </Physics>

//         {/* Attach controlsRef to OrbitControls */}
//         <OrbitControls ref={controlsRef} minDistance={1} maxDistance={10} />

//         {/* Add Cursor Focus */}
//         <CursorFocusControls controlsRef={controlsRef} modelRef={modelRef} />
//       </Canvas>
//     </div>
//   );
// };

// export default ModelViewer;







// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Plane, useGLTF } from "@react-three/drei";
// import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
// import React, { useEffect, useState } from "react";
// import { Box3, Vector3 } from "three";

// const RotatingModel = ({ modelPath, setModelBottomY }) => {
//   const { scene } = useGLTF(modelPath);
//   const clonedScene = clone(scene);

//   useEffect(() => {
//     const boundingBox = new Box3().setFromObject(clonedScene);
//     const center = new Vector3();
//     const size = new Vector3();
//     boundingBox.getCenter(center);
//     boundingBox.getSize(size);

//     // Center the model
//     clonedScene.position.set(-center.x, -center.y + size.y / 2, -center.z);

//     // Calculate the bottom Y of the model
    
//   }, [clonedScene, setModelBottomY]);

//   return <primitive object={clonedScene} />;
// };

// function SemiTransparentFloor() {

//     <Plane args={[10, 10]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
//       <meshStandardMaterial color="gray" transparent opacity={0.5} />
//     </Plane>
  
// }

// const ModelViewer = ({ path }) => {
//   const [modelBottomY, setModelBottomY] = useState(null); // Start with null to delay rendering

//   return (
//     <div style={{ width: "100%", height: "275px" }}>
//       <Canvas style={{ width: "100%", height: "100%" }} camera={{ position: [0, 2, 5] }}>
//         <ambientLight intensity={0.5} />
//         <directionalLight position={[5, 5, 5]} intensity={1} />

//         {/* Pass the function to calculate model's bottom Y */}
//         <RotatingModel modelPath={path} setModelBottomY={setModelBottomY} />

//         {/* Render floor only after the bottom Y is calculated */}
//         <SemiTransparentFloor positionY={modelBottomY !== null ? modelBottomY - 0.01 : null} />

//         <OrbitControls minDistance={0.3} maxDistance={10} />
//       </Canvas>
//     </div>
//   );
// };

// export default ModelViewer;


// // check from here
// import { Canvas, useFrame, useThree } from "@react-three/fiber";
// import { OrbitControls, useGLTF } from "@react-three/drei";
// import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
// import React, { useEffect, useRef, useState } from "react";
// import { Physics } from "@react-three/rapier";
// import { Box3, Raycaster, Vector2, Vector3 } from "three";

// const RotatingModel = ({ modelPath }) => {
//   const { scene } = useGLTF(modelPath);
//   const clonedScene = clone(scene);
//   const rotationRef = useRef();

//   useEffect(() => {
//     const boundingBox = new Box3().setFromObject(clonedScene);
//     const center = new Vector3();
//     boundingBox.getCenter(center);
//     clonedScene.position.set(-center.x, -center.y, -center.z);
//   }, [clonedScene]);

//   useFrame(() => {
//     if (rotationRef.current && !rotationRef.current.isHovered) {
//       rotationRef.current.rotation.y += 0.01; 
//     }
//   });

//   return (
//     <primitive
//       object={clonedScene}
//       scale={[1.5, 1.5, 1.5]}
//       ref={rotationRef}
//       onPointerOver={() => (rotationRef.current.isHovered = true)} 
//       onPointerOut={() => (rotationRef.current.isHovered = false)} 
//     />
//   );
// };

// const CursorFocusControls = ({ controlsRef, modelRef }) => {
//   const { camera, gl } = useThree();
//   const raycaster = useRef(new Raycaster());
//   const mouse = useRef(new Vector2());

//   const handleMouseMove = (event) => {
//     mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
//     mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;

//     raycaster.current.setFromCamera(mouse.current, camera);

//     if (modelRef.current) {
//       const intersects = raycaster.current.intersectObject(modelRef.current, true);
//       if (intersects.length > 0) {
//         const targetPoint = intersects[0].point;
//         controlsRef.current.target.copy(targetPoint);
//       }
//       controlsRef.current.update();
//     }
//   };

//   useEffect(() => {
//     gl.domElement.addEventListener("mousemove", handleMouseMove);
//     return () => gl.domElement.removeEventListener("mousemove", handleMouseMove);
//   }, [gl]);

//   return null;
// };

// const ModelViewer = ({ path }) => {
//   const controlsRef = useRef(); 
//   const modelRef = useRef();

//   return (
//     <div style={{ width: "100%", height: "275px" }}>
//       <Canvas style={{ width: "100%", height: "100%" }} camera={{ position: [0, 2, 5] }}>
//         <ambientLight intensity={0.5} />
//         <directionalLight position={[5, 5, 5]} intensity={1} />
//         <Physics>
//           <group ref={modelRef}>
//             <RotatingModel modelPath={path} />
//           </group>
//         </Physics>
//         <OrbitControls ref={controlsRef} minDistance={1} maxDistance={10} />
//         <CursorFocusControls controlsRef={controlsRef} modelRef={modelRef} />
//       </Canvas>
//     </div>
//   );
// };

// export default ModelViewer;



// const RotatingModel = ({ modelPath }) => {
//   const { scene } = useGLTF(modelPath);
//   const clonedScene = clone(scene);
//   const rotationRef = useRef();
//   const [isHovered, setIsHovered] = useState(false);

//   useEffect(() => {
//     const boundingBox = new Box3().setFromObject(clonedScene);
//     const center = new Vector3();
//     boundingBox.getCenter(center);
//     clonedScene.position.set(-center.x, -center.y, -center.z); // Center the model
//     console.log("Position adjusted to:", clonedScene.position);
//   }, []);

//   useFrame(() => {
//     if (rotationRef.current && !isHovered) {
//       rotationRef.current.rotation.y += 0.01;
//     }
//   });

//   return (
//     <primitive
//       object={clonedScene}
//       scale={[1.5, 1.5, 1.5]}
//       ref={rotationRef}
//       onPointerOver={() => setIsHovered(true)}
//       onPointerOut={() => setIsHovered(false)}
//     />
//   );
// };

// const CursorFocusControls = ({ controlsRef, modelRef }) => {
//   const { camera, gl } = useThree();
//   const raycaster = useRef(new Raycaster());
//   const mouse = useRef(new Vector2());

//   const handleMouseMove = (event) => {
//     mouse.current.x = (event.clientX / window.innerWidth) * 2 - 1;
//     mouse.current.y = -(event.clientY / window.innerHeight) * 2 + 1;

//     raycaster.current.setFromCamera(mouse.current, camera);

//     if (modelRef.current) {
//       const intersects = raycaster.current.intersectObject(modelRef.current, true);
//       if (intersects.length > 0) {
//         const targetPoint = intersects[0].point;
//         controlsRef.current.target.copy(targetPoint);
//       }
//       controlsRef.current.update();
//     }
//   };

//   useEffect(() => {
//     gl.domElement.addEventListener("mousemove", handleMouseMove);
//     return () => gl.domElement.removeEventListener("mousemove", handleMouseMove);
//   }, [gl]);

//   return null;
// };

// const ModelViewer = ({ path }) => {
//   const controlsRef = useRef();
//   const modelRef = useRef();

//   return (
//     <div style={{ width: "100%", height: "275px" }}>
//       <Canvas style={{ width: "100%", height: "100%" }} camera={{ position: [0, 2, 5] }}>
//         <ambientLight intensity={0.5} />
//         <directionalLight position={[5, 5, 5]} intensity={1} />
//         <group ref={modelRef}>
//           <RotatingModel modelPath={path} />
//         </group>
//         <OrbitControls ref={controlsRef} minDistance={1} maxDistance={10} />
//         <CursorFocusControls controlsRef={controlsRef} modelRef={modelRef} />
//       </Canvas>
//     </div>
//   );
// };

// export default ModelViewer;

